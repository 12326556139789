import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';
import TextareaAutosize from 'react-textarea-autosize';
import { Link, useNavigate } from 'react-router-dom';
import {updateMembershipBenefit} from '../../../../services/space';
import routes from '../../../../routes';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import {CustomValidators} from "../../../../utils/CustomValidators";
import {setBenefitMonthlyPrice} from "../../../../components/commons/helpers/MembershipItemHelpers";

const EditMonthlyBenefit = (props) => {
	const { membershipData, benefitData, spaceInfo, authUserInfo } = props;
	const navigate = useNavigate();
	const [updateMonthBenefitMembership] = Form.useForm();
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [alert, setAlert] = useState(null);

	const handleBenefitFormOnBlur = useCallback(async () => {
		await updateMonthBenefitMembership
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleBenefitFormChanges = useCallback(async () => {
		await updateMonthBenefitMembership
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	});

	const handleBenefitFormSubmit = useCallback(
		async (values) => {
			setAlert(null);

			const data = values;
			data.benefit_id = benefitData.id;
			data.membership_id = benefitData.membership_id;
			data.currency = 'USD';

			if (benefitData) {
				try {
					if (authUserInfo) {
						data.user_id = authUserInfo.id;
						data.space_id = spaceInfo.id;
						setSubmitted(true);

						const response = await updateMembershipBenefit(data);
						if (response && response.result) {
							setAlert({
								variant: 'success',
								message: response.message
							});

							const route = routes.manageMembership();
							let url = route.replace(':space_username', '@' + spaceInfo.username);
							url = url.replace(':membership_id', benefitData.membership_id);
							navigate(url);
						} else {
							setAlert({
								variant: 'danger',
								message: response.message
							});
						}
						setSubmitted(false);
					}
				} catch (error) {
					console.log(error);
				}
			} else {
				setAlert({
					variant: 'danger',
					message: 'No membership details'
				});
			}
		},
		[authUserInfo, spaceInfo, benefitData]
	);

	const redirectToMembershipAddBenefitLink = useCallback(
		(route, membership_id) => {
			if (spaceInfo) {
				let url = route.replace(':space_username', '@' + spaceInfo.username);
				return url.replace(':membership_id', membership_id);
			}
			return '';
		},
		[spaceInfo]
	);

	useEffect(() => {
		if (benefitData) {
			updateMonthBenefitMembership.setFieldsValue({
				title: benefitData.title,
				monthly_price: parseFloat(benefitData.monthly_price).toFixed(2),
				included_months: benefitData.included_months,
				description: benefitData.description,
				renewal_discount: benefitData.renewal_discount
			});

			updateMonthBenefitMembership
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		}
	}, [authUserInfo, spaceInfo, membershipData, benefitData]);

	return (
		<div className="block-full card">
			<div className="memberships">
				<div className="dashboard-wrapper">
					<div className="grid grid-cols-12 gap-2 md:gap-8">
						<div className="col-span-12">
							<h3 className="header-medium">Edit Monthly Subscription Benefit</h3>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-2 md:gap-8 mt-5">
						<div className="col-span-12">
							<p className="body-text--small">
								The Monthly Subscription Benefit is required based on the settings
								in the{' '}
								<a className="body-txtitalic--small gradient-color-txt">
									Membership Details
								</a>
								. Any changes made here will only apply to future sales and renewals
								of this Membership.
							</p>
						</div>
					</div>
				</div>
				<Form
					form={updateMonthBenefitMembership}
					name="updateMonthBenefitMembership"
					id="updateMonthBenefitMembership"
					validateMessages={VALIDATE_MESSAGES}
					onFocus={() => {
						setFormIsOnFocus(true);
					}}
					onBlur={() => {
						setFormIsOnFocus(false);
						handleBenefitFormOnBlur();
					}}
					onChange={handleBenefitFormChanges}
					onFinish={handleBenefitFormSubmit}
					className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
				>
					<div className="dashboard-wrapper mt-5">
						<div className="grid grid-cols-12 gap-2 md:gap-8">
							<div className="col-span-12 md:col-span-9">
								<h3 className="header-small">
									Monthly Subscription for {membershipData.name}
								</h3>
							</div>
							<div className="col-span-12 md:col-span-3">
								<p className="body-txtitalic--small gradient-color-txt text-end">
									View Guide
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="btn btn-secondary btn-md">Monthly Subscription</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									The Monthly Subscription Benefit is required when the Expiration
									Date option is enabled.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4">
							<div className="col-span-12 md:col-span-8 flex items-center">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Title*
									</label>
									<Form.Item
										label="Title"
										name="title"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<Input
											placeholder="Add Title"
											className="input-md body-text--small color-reg"
											disabled={submitted}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4 flex align-top">
								<p className="body-txtitalic--smallest">
									Share details on the value of the subscription and what Members
									can expect.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Currency*
									</label>
									<input
										placeholder="Start typing your currency and select from the list."
										className="input-md body-text--small color-reg"
										value="USD"
										disabled
									/>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Your Currency options are based on the details specified on your
									Membership/Item. You can select from the same currency of the
									Membership/Item or a related cryptocurrency.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Monthly Price*
									</label>
									<Form.Item
										label="Monthly Price"
										name="monthly_price"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										onChange={(event) => setBenefitMonthlyPrice(event, updateMonthBenefitMembership, "monthly_price")}
										rules={[
											{ required: true },
											{ validator: CustomValidators.monthlyPriceMinMax(membershipData.currency)}
										]}
									>
										<Input
											placeholder="Minimum is based on selected Currency."
											className="input-md body-text--small color-reg"
											disabled={submitted}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									For traditional currencies, you can specify up to 2 decimal
									places, i.e. ¥500 JPY or $5.00 USD. For cryptocurrencies, you
									can specify up to 8 decimal places, i.e. ethereUm 0.12345678
									ETH. Minimums vary by Currency.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-50">
										Included Months*
									</label>
									<Form.Item
										label="Included Months"
										name="included_months"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														(typeof value !== 'undefined' &&
															value !== '' &&
															(isNaN(value) || value < 1)) ||
														(typeof value === 'string' &&
															value.match(/\./g))
													) {
														return Promise.reject(
															new Error('Invalid value.')
														);
													}

													return Promise.resolve();
												}
											})
										]}
									>
										<Input
											placeholder="Whole numbers only, 1 or more."
											className="input-md body-text--small color-reg"
											disabled={submitted}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									You must include at least 1 Month. Once activated, the
									subscription starts and when the Months are used up, the Monthly
									Subscription must be renewed or topped up.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4">
							<div className="col-span-12 md:col-span-8 flex items-center">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Description*
									</label>
									<Form.Item
										label="Description"
										name="description"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<TextareaAutosize
											minRows={3}
											placeholder="Up to 160 characters, including spaces."
											className="input-md body-text--small color-reg"
											maxLength={160}
											disabled={submitted}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4 flex align-top">
								<p className="body-txtitalic--smallest">
									Share details on the value of the subscription and what Members
									can expect.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-50">
										Renewal Discount
									</label>
									<Form.Item
										label="Renewal Discount"
										name="renewal_discount"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{ validator: CustomValidators.discount }
										]}
									>
										<Input
											placeholder="Whole numbers only, up to 15. Saved as a percent, e.g. “15” equals 15%."
											className="input-md body-text--small color-reg"
											disabled={submitted}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									<a className="body-txtitalic--small gradient-color-txt">
										You can offer discounts for longer renewals.
									</a>{' '}
									The percent discount is added at each renewal option of 3, 6,
									and 12 months. e.g. a 5% Renewal Discount would give 5% off 3
									Month, 10% off 6 Month, and 15% off 12 Month renewals.
								</p>
							</div>
						</div>

						<>
							{alert && (
								<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
									<div className="col-span-12">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								</div>
							)}
						</>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12">
								<Form.Item className="mb-0">
									<button
										className="btn btn-primary btn-md"
										type="submit"
										disabled={submitted || formHasError}
									>
										{submitted && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										Save and Return to Manage Membership
									</button>
								</Form.Item>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-2 md:gap-8 mt-5 flex items-center">
							<div className="col-span-12">
								<Link
									className="btn btn-secondary btn-md"
									to={redirectToMembershipAddBenefitLink(
										routes.manageMembership(),
										benefitData.membership_id
									)}
								>
									Return without Saving
								</Link>
							</div>
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default memo(EditMonthlyBenefit);
