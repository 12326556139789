import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { getExplicitContent } from '../../../../services/space';
import {MODAL_CUSTOM_STYLE} from "../../../../constants/common";

ReactModal.setAppElement('#root');

const ExplicitContent = (props) => {
	const { modalOpen, setModalOpen, onSelectedItems, selectedItems, isExplicitContent } = props;
	const [initialLoad, setInitialLoad] = useState(true);
	const [explicitContent, setExplicitContent] = useState('');
	const [selectedExplicitIds, setSelectedExplicitIds] = useState([]);
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: 'white',
			width:'80%',
			borderRadius: 32,
            
		},
		overlay: { background: 'rgba(0, 0, 0, 0.6)' }
	};

	const setSelected = (id) => {
        const isIdPresent = selectedExplicitIds.includes(id);
        console.log(isIdPresent)
		if (isIdPresent) {
			selectedExplicitIds.splice(selectedExplicitIds.indexOf(id), 1);
		} else {
			selectedExplicitIds.push(Number(id));
		}
	};

	const saveExplicitSettings = () => {
		onSelectedItems(selectedExplicitIds);
		setModalOpen(false);
	};

	const populateExplicitContent = async () => {
		try {
			const response = await getExplicitContent();
			if (response && response.result) {
				setExplicitContent(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

    useEffect(() => {
        let footer_class = document.querySelector('.cs-footer');
        if(modalOpen){
            
            footer_class.style.zIndex = 'unset'; // Change z-index to unset
        }else{
            footer_class.style.zIndex = 250;
        }
    }, [modalOpen])

    useEffect(() => {
        // console.log(selectedExplicitIds)
    }, [selectedExplicitIds.length])

	useEffect(() => {
		setSelectedExplicitIds(selectedItems ?? []);
		if (initialLoad) {
			populateExplicitContent().then((r) => {});
			setInitialLoad(false);
		}
	}, [initialLoad, selectedItems]);
	// console.log(explicitContent)
	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={MODAL_CUSTOM_STYLE}
			portalClassName={"screen-modal p-section-safeview theme-transparent" + (modalOpen ? "" : " hide")}
			overlayClassName="centerframe-flex frame-modal"
			className="block-lg modal-block card"
		>
			{/*<section id="df-modal" className="screen-modal p-section-safeview theme-transparent">*/}
			{/*	<div className="centerframe-flex frame-modal">*/}
			{/*		<div className="block-lg modal-block card">*/}
						<div className="stack-row split">
							<h2>Explicit {isExplicitContent ? 'Content' : 'Access'} Settings</h2>
							<a onClick={() => setModalOpen(false)} id="modal-close" className="toggle-menu-gradient"><i className="fa-solid fa-xmark-large"></i></a>
						</div>
						<p>
							Please select the types of Explicit or Sensitive Content that may be visible.
							Depending on the type of content, viewers may get a warning prior to viewing.
						</p>
						<div>
							{Object.values(explicitContent).map((Item, Key) => (
								<button key={Key} type="submit" className={'m-2 ' + (selectedItems ? selectedItems.includes(Item.id) ? 'primary-gradient' : 'secondary-gradient' : 'secondary-gradient')} onClick={() => setSelected(Item.id)}>
                                	<span>
                                    	<p>{Item.content}</p>
                                	</span>
								</button>
							))}
						</div>
						<button
							type="submit"
							onClick={saveExplicitSettings}
							className="primary-gradient w-full"
						>
							<span><p>Save Explicit {isExplicitContent ? 'Content' : 'Access'} Settings</p></span>
						</button>
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</section>*/}
		</ReactModal>
		// <ReactModal
		// 	isOpen={modalOpen}
		// 	onRequestClose={() => setModalOpen(false)}
		// 	style={customStyles}
		// >
        //     <div className='z-[300]'>
        //         <div className='grid grid-cols-12 gap-4'>
        //             <div className='col-span-12 flex justify-between'>
        //                 <h3 className="header-medium mt-2">
        //                     Explicit {isExplicitContent ? 'Content' : 'Access'} Settings
        //                 </h3>
        //                 <button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
        //                     <i className="fa-solid fa-xmark"></i>
        //                 </button>
        //             </div>
        //             <div className='col-span-12'>
        //                 <p className="body-text--small mt-5">
        //                     Please select the types of Explicit or Sensitive Content that may be visible.
        //                     Depending on the type of content, viewers may get a warning prior to viewing.
        //                 </p>
        //             </div>
        //             <div className='col-span-12 flex gap-4'>
        //                 {Object.values(explicitContent).map((Item, Key) => (
        //                     <button key={Key} type="submit" className={selectedItems ? selectedItems.includes(Item.id) ? 'primary-gradient' : 'secondary-gradient' : 'secondary-gradient'} onClick={() => setSelected(Item.id)}>
        //                         <span>
        //                             <p>{Item.content}</p>
        //                         </span>
        //                     </button>
        //                 ))}
        //             </div>
        //             <div className='col-span-12 flex justify-center'>
        //                 <button  type="submit" className='primary-gradient w-full' onClick={saveExplicitSettings} >
        //                         <span>
        //                             <p>Save Explicit {isExplicitContent ? 'Content' : 'Access'} Settings</p>
        //                         </span>
        //                     </button>
        //         </div>
        //         </div>
        //
        //     </div>
		// </ReactModal>
	);
};

export default ExplicitContent;
