import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { VALIDATE_MESSAGES } from '../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import { SPACE_VISIBILITY } from '../../../constants/space';
import { isAlphaNumeric } from '../../../utils/form';
import { createSpaceLaunchStatus, updateSpaceVisibility } from '../../../services/space';
import { getSitewideLink } from '../../../utils/sitewideLinks';

const PreviewAndLaunch = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [selectedSpaceVisibility, setSelectedSpaceVisibility] = useState('public');
	const [addSpacePasswordForm] = Form.useForm();
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);

	const handleFormChanges = useCallback(async () => {
		await addSpacePasswordForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormOnBlur = useCallback(async () => {
		await addSpacePasswordForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const setFormSpaceData = async (createdSpace) => {
		setSelectedSpaceVisibility(createdSpace.visibility);
		await addSpacePasswordForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	};

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setSubmitted(false);

			try {
				if (spaceInfo && authUserInfo) {
					const data = {
						user_id: authUserInfo.id,
						space_id: spaceInfo.id,
						visibility: selectedSpaceVisibility,
						password: values.password
					};

					setSubmitted(true);
					const responseLaunch = await createSpaceLaunchStatus(data);
					if (responseLaunch && responseLaunch.result) {
						const response = await updateSpaceVisibility(data);
						if (response && response.result) {
							const url = routes.viewUserSpace() + space_username;
							navigate(url);
						} else {
							setAlert({ variant: 'danger', message: response.message });
						}
					}
				}
				setSubmitted(false);
			} catch (error) {
				setAlert({ variant: 'danger', message: error.message });
				setSubmitted(false);
			}
		},
		[space_username, spaceInfo, authUserInfo, selectedSpaceVisibility]
	);

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
        <main id="cs-platform-main" className="cs-main main-theme">
            <section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
                <div className="centerframe-grid gap-2gutter">
                    <div className="block-full">
                        <div className="cs-stack-heading">
                            <div className="stack-row split">
                                <h1>Review and Launch Your Space!</h1>
                                <h3>Step 5 of 5</h3>
                            </div>
                            <p><a >Congratulations!</a>  Your Space is ready to launch! Take a moment to
		 						preview it and set Visibility, then once you’re ready, click the
		 						launch button. Let’s go!
                            </p>
                        </div>
                        <div className="cs-stack-form-guided">
                            <div className="formrow title">
                                <div>
                                    <h2>Set Space Visibility</h2>
                                    <p>Required</p>
                                </div>
                                {/* {getSitewideLink('view_guide_create_space', 'btn btn-lg btn-secondary')} */}
                                <a href="help.common.space" className="button-secondary-gradient"><span><p>Space Creation Guide <i className="fa-regular fa-arrow-up-right-from-square i-r"></i></p></span></a>
                            </div>
                            <Form
                                form={addSpacePasswordForm}
                                name="addSpacePasswordForm"
                                id="addSpacePasswordForm"
                                validateMessages={VALIDATE_MESSAGES}
                                onFocus={() => {
                                    setFormIsOnFocus(true);
                                }}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setFormIsOnFocus(false);
                                        handleFormOnBlur();
                                    }, 100);
                                }}
                                onChange={handleFormChanges}
                                onFinish={handleSubmit}
                                className='w-full stack-form-col'
                            >
                                <div id="cscxl-visibility-select" className="formrow ">
                                    <div className="cs-stack-form-buttons form-input air">                              
                                        <DropdownWithItems
                                            title="Update Visibility"
                                            dropDownClass="dropdown-bg"
                                            customButtonClass="button gradient secondary distribute w-full"
                                            selectedValue={selectedSpaceVisibility}
                                            listItems={SPACE_VISIBILITY}
                                            onDropdownChange={(value) => setSelectedSpaceVisibility(value)}
                                        />
                                    </div>
                                    <div className="cs-formrow-helper-end">
                                        <p>You can set your Space to be visible to the Public, Password
		 							Protected, or Invite Only. This setting applies to all visitors
		 							who are not logged in.</p>
                                    </div>
                                </div>
                                {selectedSpaceVisibility === 'offline_maintenance' && (
                                    <p>
                                        Your Space will be set to Offline Maintenance. To manage
		 									Offline Maintenance, visit your Manage Space page in
		 									your Dashboard.
                                    </p>
                                )}
                                {selectedSpaceVisibility === 'invite_only' && (
                                    <p>
                                    Your Space will be set to Invite Only. To manage
                                    Invites, visit your Manage Space page in your Dashboard.
                                    </p>
                                )}
                                {selectedSpaceVisibility === 'password_protected' && (
                                    <>
                                    <Form.Item
                                        name="password"
                                        validateTrigger="onBlur"
                                        disabled={submitted}
                                        rules={[{ required: true }]}
                                    >
                                        <div className='formrow'>
                                            <div className="form-input air">
                                                <label htmlFor="text">Password*</label>
                                                <input id="legal_name" name="legal_name" type="password" required/>
                                                {addSpacePasswordForm.getFieldsError()[0]?.errors.length > 0 && (
                                                    <div className="alert callout warning !w-full">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{addSpacePasswordForm.getFieldsError()[0]?.errors}</h6>
                                                                
                                                            </div>  
                                                        </div>
                                                        
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cs-formrow-helper-end">
                                                <p>This will only apply to visitors who are not logged in.
		 									When accessing your Space, visitors will have the option
		 									to Log In or enter this password to view the Space.</p>
                                            </div>
                                        </div>
                                        
                                    </Form.Item>
                                    <Form.Item
                                        name="confirm_password"
                                        validateTrigger="onBlur"
                                        disabled={submitted}
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password.'
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        getFieldValue('password') === value
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Password does not match.'
                                                        )
                                                    );
                                                }
                                            })
                                        ]}
                                    >
                                        <div className='formrow'>
                                            <div className="form-input air">
                                                <label htmlFor="text">Confirm Password*</label>
                                                <input id="confirm_password" name="confirm_password" type="password" required/>
                                                {addSpacePasswordForm.getFieldsError()[0]?.errors.length > 0 && (
                                                    <div className="alert callout warning !w-full">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{addSpacePasswordForm.getFieldsError()[0]?.errors}</h6>
                                                                
                                                            </div>  
                                                        </div>
                                                        
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cs-formrow-helper-end">
                                                <p>This will only apply to visitors who are not logged in.
		 									When accessing your Space, visitors will have the option
		 									to Log In or enter this password to view the Space.</p>
                                            </div>
                                        </div>                                       
                                    </Form.Item>
                                    
                                    </>
                                )}
                                <div className='formrow'>
                                        <button type="submit" className="secondary-gradient"><span>
                                            <p>Preview your Space {' '} <i className="fa-light fa-arrow-up-right-from-square ms-2"></i></p>
                                            </span>
                                        </button>
                                        <button type="submit" className="secondary-gradient"><span>
                                            <p>Save & Launch your Space {' '} <i className="fa-solid fa-rocket-launch"></i></p>
                                            </span>
                                        </button>
                                    </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
		// <>
		// 	<main id="member-profile" className="dashboard-wrapper flex">
		// 		<div className="container !py-20">
		// 			<div className="grid grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
		// 				<div className="col-span-12 md:col-span-8 flex items-center">
		// 					<h1 className="header-large">
		// 						Review and Launch Your Space!
		// 					</h1>
		// 				</div>
		// 				<div className="col-span-12 md:col-span-4 flex items-center justify-center md:justify-end">
		// 					<p className="body-txtitalic--large">Step 5 of 5</p>
		// 				</div>
		// 				<div className="col-span-12 mt-4">
		// 					<h5 className="body-text--reg">
		// 						<span className="gradient-color-txt body-text--reg">
		// 							Congratulations!
		// 						</span>{' '}
		// 						 Your Space is ready to launch! Take a moment to
		// 						preview it and set Visibility, then once you’re ready, click the
		// 						launch button. Let’s go!
		// 					</h5>
		// 				</div>
		// 			</div>
		// 			<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5">
		// 				<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
		// 					<h5 className="header-medium leading-10">Set Space Visibility</h5>
		// 					<span className="body-text--reg">Required</span>
		// 				</div>
		// 				<div className="md:col-span-4 flex justify-center items-center !order-first md:!order-last">
							
		// 					{getSitewideLink(
		// 						'view_guide_preview_launch',
		// 						'btn btn-lg btn-secondary'
		// 					)}
		// 				</div>
		// 			</div>

		// 			<Form
		// 				form={addSpacePasswordForm}
		// 				name="addSpacePasswordForm"
		// 				id="addSpacePasswordForm"
		// 				validateMessages={VALIDATE_MESSAGES}
		// 				onFocus={() => {
		// 					setFormIsOnFocus(true);
		// 				}}
		// 				onBlur={() => {
		// 					setTimeout(() => {
		// 						setFormIsOnFocus(false);
		// 						handleFormOnBlur();
		// 					}, 100);
		// 				}}
		// 				onChange={handleFormChanges}
		// 				onFinish={handleSubmit}
		// 				className={formIsOnFocus ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
		// 			>
		// 				<>
		// 					{alert && (
		// 						<div className="grid grid-cols-12 gap-8 mt-5">
		// 							<div className="col-span-8">
		// 								<Alert
		// 									key={alert.variant}
		// 									variant={alert.variant}
		// 									className={
		// 										'custom-alert ' +
		// 										(alert.variant === 'danger'
		// 											? 'custom-alert-danger'
		// 											: 'custom-alert-success')
		// 									}
		// 								>
		// 									{alert.message}
		// 								</Alert>
		// 							</div>
		// 						</div>
		// 					)}
		// 				</>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5 items-center">
		// 					<div className="md:col-span-8 !order-last md:!order-first">
		// 						<DropdownWithItems
		// 							title="Update Visibility"
		// 							dropDownClass="dropdown-bg"
		// 							customButtonClass="btn-lg btn-secondary"
		// 							selectedValue={selectedSpaceVisibility}
		// 							listItems={SPACE_VISIBILITY}
		// 							onDropdownChange={(value) => setSelectedSpaceVisibility(value)}
		// 						/>
		// 					</div>
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							You can set your Space to be visible to the Public, Password
		// 							Protected, or Invite Only. This setting applies to all visitors
		// 							who are not logged in.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				{selectedSpaceVisibility === 'offline_maintenance' && (
		// 					<>
		// 						<div className="grid grid-cols-12 mt-5 items-center">
		// 							<div className="col-span-12 md:col-span-8">
		// 								<p className="body-txtitalic--smallest color-reg m-0">
		// 									Your Space will be set to Offline Maintenance. To manage
		// 									Offline Maintenance, visit your Manage Space page in
		// 									your Dashboard.
		// 								</p>
		// 							</div>
		// 						</div>
		// 					</>
		// 				)}

		// 				{selectedSpaceVisibility === 'invite_only' && (
		// 					<>
		// 						<div className="grid grid-cols-12 mt-5 items-center">
		// 							<div className="col-span-12 md:col-span-8">
		// 								<p className="body-txtitalic--smallest color-reg m-0">
		// 									Your Space will be set to Invite Only. To manage
		// 									Invites, visit your Manage Space page in your Dashboard.
		// 								</p>
		// 							</div>
		// 						</div>
		// 					</>
		// 				)}

		// 				{selectedSpaceVisibility === 'password_protected' && (
		// 					<>
		// 						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5 items-center">
		// 							<div className="md:col-span-8 !order-last md:!order-first">
		// 								<div className="input-group-com ">
		// 									<label className="text-label body-text--small max-[450px]:w-32">
		// 										Choose Password*
		// 									</label>
		// 									<Form.Item
		// 										label="Password"
		// 										name="password"
		// 										className="mb-0 hidden-label"
		// 										validateTrigger="onBlur"
		// 										disabled={submitted}
		// 										rules={[{ required: true }]}
		// 									>
		// 										<Input
		// 											type="password"
		// 											className="input-lg body-text--small !pl-[40%]"
		// 										/>
		// 									</Form.Item>
		// 								</div>
		// 							</div>
		// 							<div className="md:col-span-4 !order-first md:!order-last">
		// 								<p className="body-txtitalic--smallest color-reg m-0">
		// 									This will only apply to visitors who are not logged in.
		// 									When accessing your Space, visitors will have the option
		// 									to Log In or enter this password to view the Space.
		// 								</p>
		// 							</div>
		// 						</div>

		// 						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5 items-center">
		// 							<div className="md:col-span-8 !order-last md:!order-first">
		// 								<div className="input-group-com ">
		// 									<label className="text-label body-text--small max-[450px]:w-32">
		// 										Confirm Password*
		// 									</label>
		// 									<Form.Item
		// 										label="Confirm Password"
		// 										name="confirm_password"
		// 										className="mb-0 hidden-label"
		// 										validateTrigger="onBlur"
		// 										disabled={submitted}
		// 										dependencies={['password']}
		// 										rules={[
		// 											{
		// 												required: true,
		// 												message: 'Please confirm your password.'
		// 											},
		// 											({ getFieldValue }) => ({
		// 												validator(_, value) {
		// 													if (
		// 														!value ||
		// 														getFieldValue('password') === value
		// 													) {
		// 														return Promise.resolve();
		// 													}
		// 													return Promise.reject(
		// 														new Error(
		// 															'Password does not match.'
		// 														)
		// 													);
		// 												}
		// 											})
		// 										]}
		// 									>
		// 										<Input
		// 											type="password"
		// 											className="input-lg body-text--small !pl-[40%]"
		// 										/>
		// 									</Form.Item>
		// 								</div>
		// 							</div>
		// 							<div className="md:col-span-4 !order-first md:!order-last">
		// 								<p className="body-txtitalic--smallest color-reg m-0">
		// 									This password will the same for any and all visitors.
		// 								</p>
		// 							</div>
		// 						</div>
		// 					</>
		// 				)}

		// 				<div className="grid grid-cols-2  gap-y-8 md:gap-y-0  md:gap-8 mt-5">
		// 					<div className="col-span-2 md:col-span-1">
		// 						<Link
		// 							to={routes.viewUserSpace() + space_username}
		// 							target="_blank"
		// 							className="btn btn-lg btn-secondary mt-5"
		// 						>
		// 							Preview Your Space{' '}
		// 							<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>
		// 						</Link>
		// 					</div>
		// 					<div className="col-span-2 md:col-span-1 flex items-end">
		// 						<Button
		// 							className="btn btn-lg btn-primary whitespace-pre-wrap"
		// 							type="submit"
		// 							disabled={submitted || formHasError}
		// 						>
		// 							Save & Launch Your Space&nbsp;
		// 							<i className="fa-solid fa-rocket-launch"></i>
		// 						</Button>
		// 					</div>
		// 				</div>
		// 			</Form>
		// 			<p className="mt-5 pt-3">
		// 				<span className="body-txtitalic--small">Not ready to launch? </span>
		// 				<Link
		// 					to='/dashboard'
		// 					className="gradient-color-txt body-txtitalic--small"
		// 				>
		// 					Click here to return to your dashboard.
		// 				</Link>
		// 			</p>
		// 		</div>
		// 	</main>
		// </>
	);
};

export default memo(PreviewAndLaunch);
