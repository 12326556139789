import { memo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import { useSelector } from 'react-redux';

const ItemType = () => {
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);

	const redirectCreateItemTypeLink = useCallback(
		(route) => {
			if (spaceInfo) {
				return route.replace(':space_username', '@' + spaceInfo.username);
			}
			return '';
		},
		[spaceInfo]
	);

	useEffect(() => {}, [authUserInfo, spaceInfoLoading, spaceInfo]);

	return (
		<div className="block-full card">
			<div className="memberships">
				<div className="dashboard-wrapper">
					<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
						<div className="col-span-12">
							<h3 className="header-medium">Choose a Item Type</h3>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">
						<div className="col-span-12">
							<p className="body-text--small">
								Different Item Types offer different functions and features. You can
								offer many different Items and each one can have Benefits added to
								it. Members can collect and activate as many as then want.{' '}
								<a className="body-txtitalic--small gradient-color-txt">
									Learn more about Items and Item Types.
								</a>
							</p>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">
					<div className="col-span-12 md:col-span-6">
						<Link
							className="select-membership-type"
							to={redirectCreateItemTypeLink(routes.createItemCommunity())}
						>
							<div className="dashboard-wrapper h-full">
								<p className="text-center header-medium">
									<i className="gradient-color-txt fa-light fa-cubes"></i>
								</p>
								<h5 className="header-small text-center">Community Item</h5>
								<p className="body-text--smallest mt-4">
									Create a free or paid item that can be sold for a one-time cost
									or have a monthly renewable price, be setup as Member-bound or
									tradable, and have many other configurable options.
								</p>
								<p className="body-txtitalic--smallest text-center mt-5">
									Created with Polygon
								</p>
							</div>
						</Link>
					</div>
					<div className="col-span-12 md:col-span-6">
						<Link
							className="select-membership-type"
							to={redirectCreateItemTypeLink(routes.createItemNFT())}
						>
							<div className="dashboard-wrapper h-full">
								<p className="text-center header-medium">
									<i className="gradient-color-txt fa-brands fa-ethereum"></i>
								</p>
								<h5 className="header-small text-center">NFT Collection</h5>
								<p className="body-text--smallest mt-4">
									You can use an existing NFT Collection on Ethereum to create an
									Item. Any holder will be able to connect their wallet and claim
									an Item for free. To use an NFT, you must authenticate the Owner
									Wallet.{' '}
									<span className="body-txtitalic--small gradient-color-txt">
										Learn more.
									</span>
								</p>
								<p className="body-txtitalic--smallest text-center mt-5">
									Ethereum Blockchain
								</p>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(ItemType);
