import { memo, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { GOOGLE_FONT_KEY } from '../../../constants/common';
import FontPicker from 'font-picker-react';

const FontsDropdownWithSearch = (parentProps) => {
	const { componentId, selectedValue, onDropdownChange } = parentProps;
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [isClearable, setIsClearable] = useState(true);
	const [isSearchable, setIsSearchable] = useState(true);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRtl, setIsRtl] = useState(false);
	const [allOptions, setAllOptions] = useState([]);
	const [first50Options, setFirst50Options] = useState([]);
	const [optionsForDisplay, setOptionsForDisplay] = useState([]);

	const loadGoogleApiClient = async () => {
		setTimeout(() => {
			/* global gapi */
			gapi.load('client');
			setTimeout(() => {
				gapi.client.setApiKey(GOOGLE_FONT_KEY);
				gapi.client
					.load('https://www.googleapis.com/discovery/v1/apis/webfonts/v1/rest')
					.then(
						function () {
							// console.log("GAPI client loaded for API");
						},
						function (err) {
							// console.error("Error loading GAPI client for API", err);
						}
					);

				setTimeout(() => {
					executeGoogleApiClient();
				}, 2000);
			}, 2000);
		}, 2000);
	};

	const executeGoogleApiClient = () => {
		/* global gapi */
		gapi.client.webfonts.webfonts.list().then(
			function (response) {
				// Handle the results here (response.result has the parsed body).
				const fonts = response.result.items;
				const allFonts = [];
				fonts.forEach((item) => {
					allFonts.push({ value: item.family, label: item.family });
				});
				setAllOptions(allFonts);
			},
			function (err) {
				console.error('Execute error', err);
			}
		);

		gapi.client.webfonts.webfonts
			.list({
				sort: 'popularity'
			})
			.then(
				function (response) {
					// Handle the results here (response.result has the parsed body).
					const fonts = response.result.items;
					const fiftyPopularFonts = [];
					fonts.forEach((item) => {
						if (fiftyPopularFonts.length < 50) {
							fiftyPopularFonts.push({ value: item.family, label: item.family });
						}
					});
					setFirst50Options(fiftyPopularFonts);
				},
				function (err) {
					console.error('Execute error', err);
				}
			);
	};

	const checkIfSelectedFontExistsInList = useCallback(async () => {
		const index = first50Options.findIndex(x => x.value === selectedValue);
		if (index >= 0) {
			// found
		} else {
			// set value to default 'Noto Sans'
			onDropdownChange('Noto Sans');
		}

		setIsLoading(false);
	}, [selectedValue, first50Options]);

	const handleInputChange = useCallback(
		(inputValue) => {
			if (inputValue && inputValue !== '') {
				setOptionsForDisplay(allOptions);
			} else {
				setOptionsForDisplay(first50Options);
			}
		},
		[allOptions, first50Options]
	);

	const handleDropdownChange = (value) => {
		onDropdownChange(value);
	};

	useEffect(() => {
		if (isInitialLoad) {
			setIsLoading(true);
			loadGoogleApiClient().then((r) => {});
			setIsInitialLoad(false);
		}
	}, [isInitialLoad]);

	useEffect(() => {
		if (selectedValue && first50Options.length > 0) {
			checkIfSelectedFontExistsInList(first50Options).then((r) => {});
		}
	}, [selectedValue, first50Options]);

	return (
		<>
			{isLoading ? (
				<i className="fa-solid fa-spinner fa-spin"></i>
			) : (
                <div id="cscxl-font-picker" className='border-2 button-dropdown-gradient-alt secondary z-50'>               
                    <span>
                    <i className="fa-light fa-book-font"></i>
                        <FontPicker
                            pickerId={componentId}
                            apiKey={GOOGLE_FONT_KEY}
                            activeFontFamily={selectedValue}
                            onChange={(nextFont) => handleDropdownChange(nextFont.family)}
                        />
                        <i className="fa-solid fa-caret-down"></i>
                    </span>                
                </div>
			)}
		</>
	);

	// TODO: look for ways to implement and display font family style on each dropdown item
	// return (
	// 	<>
	// 		<Select
	// 			className="fonts-dropdown-with-search"
	// 			classNamePrefix="select"
	// 			defaultValue={selectedValue ? selectedValue : 'Noto Sans'}
	// 			isDisabled={isDisabled}
	// 			isLoading={isLoading}
	// 			isClearable={isClearable}
	// 			isRtl={isRtl}
	// 			isSearchable={isSearchable}
	// 			name="color"
	// 			options={optionsForDisplay}
	// 			onInputChange={handleInputChange}
	// 		/>
	// 	</>
	// );
};

export default memo(FontsDropdownWithSearch);
