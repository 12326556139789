import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Form, Input } from 'antd';
import DropdownWithItems from '../../DropdownWithItems';
import { MANAGE_MEMBERS_FILTER } from '../../../../constants/space';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { useSelector } from 'react-redux';
import { getSpaceMemberships } from '../../../../services/space';
import {CustomValidators} from "../../../../utils/CustomValidators";

ReactModal.setAppElement('#root');

const InviteGrantMembershipModal = (props) => {
	const { modalOpen, setModalOpen } = props;
	const [selectedFilter, setSelectedFilter] = useState('default');
	const [selectedMembership, setSelectedMembership] = useState(null);
	const [membershipsList, setMembershipsList] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [membershipsFetching, setMembershipsFetching] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [filteredMembership, setFilteredMembership] = useState([
		{ label: 'Select a Membership to Grant', value: 'default', disabled: true }
	]);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: 'white',
			width:'80%',
			borderRadius: 32
		},
		overlay: { background: 'rgba(0, 0, 0, 0.6)' }
	};

	const filterMembership = () => {
		if (membershipsList != null) {
			membershipsList.map((item) => {
				const data = { label: item.name, value: item.space_id };
				if (!filteredMembership.some(({ label }) => label == data.label)) {
					filteredMembership.push(data);
				}
			});
			setFilteredMembership(filteredMembership);
		}
	};

	useEffect(() => {
		if (authUserInfo && spaceInfoLoading && spaceInfoLoading === 'done') {
			if (initialLoad) {
				getMembershipsRequest().then((r) => {});
			}

			setInitialLoad(false);
		}
		filterMembership();
	}, [
		authUserInfo,
		spaceInfoLoading,
		spaceInfo,
		filteredMembership,
		membershipsList,
		selectedMembership
	]);

	const getMembershipsRequest = useCallback(
		async (pageNumber = null, filter = null, sort = null) => {
			try {
				if (authUserInfo && spaceInfo) {
					const data = {
						viewing_user_id: authUserInfo.id,
						space_id: spaceInfo.id,
						list_count: 10,
						page: pageNumber ? pageNumber : 1
					};
					if (filter) {
						data['filter_type'] = filter['filter_type'];
						data['filter_value'] = filter['filter_value'];
					}
					if (sort) {
						data['sort_value'] = filter['sort_value'];
					}
					setMembershipsFetching(true);
					const response = await getSpaceMemberships(data);
					if (response && response.result) {
						if (response.data) {
							setMembershipsList(response.data);
							// setTotalMembershipsCount(response.total_memberships_count);
							// buildPagination(parseInt(response.page), parseInt(response.page_range));
						} else {
							setMembershipsList(null);
						}
					}
					setMembershipsFetching(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo, spaceInfo]
	);

	const filterDropdownChange = useCallback(
		async (value) => {
			const membershipTypes = ['free', 'paid', 'recurring'];
			const accountTypes = ['<30', '>30', '1space', '2plusSpace'];
			const activityTypes = [
				'lastday',
				'last7days',
				'last30days',
				'last90days',
				'last180days'
			];

			const data = {
				filter_value: value
			};
			if (membershipTypes.includes(value)) {
				data['filter_type'] = 'membership';
			}
			if (accountTypes.includes(value)) {
				data['filter_type'] = 'account';
			}
			if (activityTypes.includes(value)) {
				data['filter_type'] = 'activity';
			}

			if (filteredMembership != null) {
				filteredMembership.map((item) => {
					if (item.value == value) {
						console.log(item.label);
						setSelectedMembership(item);
					}
				});
				// setFilteredMembership(filteredMembership);
			}

			setSelectedFilter(value);

			// await getMembersRequest(1, data);
		}
		// [authUserInfo, spaceInfo]
	);

	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={customStyles}
		>
			<button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
				<i className="fa-solid fa-xmark"></i>
			</button>
			<h3 className="header-medium mt-2">Invite & Grant Membership</h3>
			<Form
				// form={form}
				name="loginForm"
				// validateMessages={VALIDATE_MESSAGES}
				// onFocus={() => {
				// 	setFormIsOnFocus(true);
				// }}
				// onBlur={() => {
				// 	setFormIsOnFocus(false);
				// 	handleFormOnBlur();
				// }}
				// autoComplete="off"
				// onChange={handleFormChanges}
				// onFinish={handleSubmit}
				className={'add-category'}
			>
				<div className="input-group-com mb-5 mt-5 flex items-center">
					<label className="text-label body-text--small">Email or @username</label>
					<Form.Item
						name="email"
						className="mb-0 hidden-label"
						validateTrigger="onBlur"
						rules={[
							{ required: true },
							{ validator: CustomValidators.emailUsername }
						]}
					>
						<Input
							type="text"
							className="txt-xlong input-md"
							placeholder="Enter email or @username to invite someone to your Space."
						/>
					</Form.Item>
				</div>
			</Form>

			<div className="row mt-5">
				<DropdownWithItems
					title="test"
					dropDownClass="dropdown-bg"
					customButtonClass="btn-md btn-secondary"
					selectedValue={selectedFilter}
					listItems={filteredMembership}
					onDropdownChange={(value) => filterDropdownChange(value)}
				/>
			</div>
			<p className="body-text--small mt-5">
				You can invite anyone directly to your Space and provide them a Membership for free.
				Once you invite them, the invite cannot be canceled and they will have 7 days to
				redeem the Membership Grant before it expires.
			</p>

			{selectedMembership != null && (
				<Button
					className="btn btn-md btn-primary mb-2"
					// onClick={web3Login}
					type="submit"
					// disabled={submitted}
				>
					<i className={isLoading ? 'fa-solid fa-spinner fa-spin' : 'fa-solid'}></i>
					Invite to @{selectedMembership.label}
				</Button>
			)}
		</ReactModal>
	);
};

export default InviteGrantMembershipModal;
