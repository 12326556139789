import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import { Button, Form } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import moment from 'moment/moment';
import TextareaAutosize from 'react-textarea-autosize';
import Pagination from 'react-bootstrap/Pagination';
import ReactPlayer from 'react-player/lazy';
import routes from '../../../../routes';
import {
	createSpaceModelComment, deleteSpaceComment,
	getSpaceCategories,
	getSpaceModelComments,
	getSpaceUpdateData, updateSpaceModelComment, userUpdateHeartComment, userUpdateUnHeartComment
} from '../../../../services/space';
import { displayDataContent } from '../../../../utils/spaceUpdateDataView';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import CommentRow from '../../../../components/commons/CommentRow';
import './UpdatesContent.scss';
import ChannelSharePost from "../../../../components/commons/Modals/ChannelSharePost";
import {deleteSpacePost, updateSpacePost, userHeartPost, userPinPost, userUnheartPost, userUnpinPost, userUpdateHeartPost, userUpdateUnheartPost} from "../../../../services/post";
import default_user_profile from "../../../../assets/images/default_user_profile.png";
import EllipsisDropdown from "../../../Channel/EllipsisDropdown";
import {getPostDate} from "../../../../utils/DateTimeUtils";
import {UPLOAD_FILE_TYPE} from "../../../../constants/file";
import {setEditComment, setEditPost, setEditSubComment, setEditSubCommentReply} from "../../../../states/channelSlice";
import CustomPagination from "../../../../components/commons/CustomPagination";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/minimal.css';

const UpdatesContent = (parentProps) => {
	const { updatesViewingUserIsMember, spaceData } = parentProps;
	const { space_username, update_id } = useParams();
	const [commentForm] = Form.useForm();
	const [editCommentForm] = Form.useForm();
	const [editSubCommentForm] = Form.useForm();
	const [subCommentForm] = Form.useForm();
	const [subCommentReplyForm] = Form.useForm();
	const navigate = useNavigate();
	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [updateData, setUpdateData] = useState(null);
	const [categories, setCategories] = useState(null);
	const [previousLocation, setPreviousLocation] = useState(false);
	const [comments, setComments] = useState([]);
	const [commentToReply, setCommentToReply] = useState(null);
	const [commentsPagination, setCommentsPagination] = useState([]);
	const [commentsCurrentPage, setCommentsCurrentPage] = useState(1);
	const [commentsFetching, setCommentsFetching] = useState(false);
	const [disableCommentButton, setDisableCommentButton] = useState(true);
    const [playVideo, setPlayVideo] = useState(false);
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);
	const [isHeartSubmitting, setIsHeartSubmitting] = useState(false);

	const [alert, setAlert] = useState(null);
	const [commentIdOpened, setCommentIdOpened] = useState(null);
	const [subCommentIdOpened, setSubCommentIdOpened] = useState(null);
	const [replyIdOpened, setReplyIdOpened] = useState(null);
	
	const commentIdToDelete = useRef(null);
	const subCommentIdToDelete = useRef(null);
	const subReplyIdToDelete = useRef(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isBtnPostEnabled, setIsBtnPostEnabled] = useState(false);
	const [modelId, setModelId] = useState(null);
	const [commentLevelOneId, setCommentLevelOneId] = useState(null);
	const [subReplyId, setSubReplyId] = useState(null);
	const [commentReplyId, setCommentReplyId] = useState(null);
	const [replyToId, setReplyToId] = useState(null);
	const [selectedAction, setSelectedAction] = useState(null);
	const [dataToEdit, setDataToEdit] = useState(false);

	const [currentPage, setCurrentPage] = useState(0);
	const [maxPage, setMaxPage] = useState(0);
	const pageSize = 6;

	const buildPagination = (current_page, max_page) => {
		const items = [];
		if (max_page) {
			for (let number = 1; number <= max_page; number++) {
				items.push(
					<Pagination.Item key={number} active={number === current_page}>
						{number}
					</Pagination.Item>
				);
			}
		}
		setCommentsPagination(items);
	};

	const onPageChange = (page) => {
		setCurrentPage(page);
		const section = document.getElementById('cs-space-updates-browse');
		if (section) {
			section.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handleOnClickPagination = useCallback(
		async (event) => {
			const itemClicked = event.target.text;
			if (itemClicked) {
				const number = parseInt(itemClicked);
				setCommentsCurrentPage(number);
				getModelComments(number).then();
			}
		},
		[spaceInfo, update_id]
	);

	const handlePageChange = (page) => {
		setCurrentPage(page);
		getModelComments(page).then();
		// ... do something with `page`
	}

	const getUpdateDataRequest = useCallback(
		async (update_id) => {
			const data = {
				update_id: update_id
			};

			if (authUserInfo) {
				data['viewing_user_id'] = authUserInfo.id;
			}

			if (spaceInfo) {
				data['viewing_space_id'] = spaceInfo.id;
			}

			const response = await getSpaceUpdateData(data);

			if (response && response.result) {
                // console.log(response.space_update)
				const spaceUpdateData = response.space_update;

				if (spaceInfo) {
					if (spaceInfo.id === spaceUpdateData.space_id) {
						setUpdateData(spaceUpdateData);
						let urlTitle = spaceUpdateData.title;
						if (urlTitle) {
							urlTitle = urlTitle.toLowerCase();
							urlTitle = urlTitle.replaceAll(' ', '-');
							const url =
								'/@' +
								spaceInfo.username +
								'/update/' +
								spaceUpdateData.id +
								'/' +
								urlTitle;
							window.history.replaceState(null, null, url);
						}
						getModelComments().then();
					}
				}
			} else {
				// if (authUserInfo) {
				// 	navigate(routes.dashboard());
				// }
				// else {
				// 	navigate(routes.login());
				// }
			}
		},
		[space_username, authUserInfo, spaceInfo, currentLocation]
	);

	const getSpaceCategoriesRequest = useCallback(async () => {
		await getSpaceCategories({ space_id: spaceInfo.id, with_updates_usage_count: true }).then(
			(response) => {
				if (response && response.result) {
					setCategories(response.categories);
				}
			}
		);
	}, [spaceInfo]);

	const navigateToViewUpdate = useCallback(async (link) => {
		navigate(link);
	}, []);

	const CategoryLinkRow = (data) => {
		return (
			<>
				<Link to={'#'}>
					{updateData.name}
					<span>{updateData.usage_count}</span>
				</Link>
			</>
		);
	};

	const getAuthorDisplay = (author, data) => {
		if (author) {
			if (author === 'me') {
				return 'by ' + (updateData.username ? '@' + updateData.username : updateData.full_name);
			} else {
				return 'by ' + (updateData.username ? '@' + updateData.username : space_username);
			}
		}
		return 'by ' + space_username;
	};

	const getModelComments = useCallback(
		async (pageNumber = null) => {
			setCommentsFetching(true);
			const data = {
				space_id: spaceInfo.id,
				model: 'update',
				model_id: update_id,
				list_count: 5,
				page: pageNumber ? pageNumber : commentsCurrentPage
			};
			if (authUserInfo) {
				data.viewing_user_id = authUserInfo.id
			}

			await getSpaceModelComments(data).then((response) => {
				if (response && response.result) {
					setComments(response.data);
					setCurrentPage(parseInt(response.page));
					setMaxPage(parseInt(response.page_range));
					// buildPagination(parseInt(response.page), parseInt(response.page_range));
				}
			});
			setCommentsFetching(false);
		},
		[spaceInfo, update_id, commentsCurrentPage, authUserInfo]
	);

	const handleUpdateCommentIdToReply = (id) => {
		setCommentToReply(id);
	};

	const handleFetchUpdatedComments = () => {
		getModelComments().then();
	};

	const commentKeypress = (event) => {
		if (!event.shiftKey && event.key === 'Enter') {
			event.preventDefault();
			commentForm.submit();
		}
	};

	const handleChanges = useCallback(() => {
			setDisableCommentButton(commentForm.getFieldValue('comment').trim() === '');
		},
		[disableCommentButton]
	);

	const handleEditCommentChanges = useCallback(() => {
			setDisableCommentButton(editCommentForm.getFieldValue('comment').trim() === '');
		},
		[disableCommentButton]
	);

	const handleEditSubCommentChanges = useCallback(() => {
			setDisableCommentButton(editSubCommentForm.getFieldValue('comment').trim() === '');
		},
		[disableCommentButton]
	);

	const handleSubmit = useCallback(
		async (values) => {
			let okToSubmit = (commentForm.getFieldValue('comment') !== undefined && commentForm.getFieldValue('comment').trim() !== '');

			if (okToSubmit) {
				setSubmitted(true);

				const data = {
					space_id: spaceInfo.id,
					model: 'update',
					model_id: update_id,
					comment: values.comment
				};

				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}

				const response = await createSpaceModelComment(data);
				if (response && response.result) {
					getModelComments().then();
					if (commentForm) {
						commentForm.setFieldValue('comment','');
					}
				}

				setSubmitted(false);
				setDisableCommentButton(true);
			}
		},
		[authUserInfo, spaceInfo, update_id]
	);

	const toggleHeart = useCallback(async (update_id, state, thread_id=null) => {
		if(!state){
			state = false;
		}
		if (authUserInfo) {
			const data = {
				user_id: authUserInfo.id,
				update_id: update_id
			};
			if (thread_id) {
				data.thread_id = thread_id;
			}
			setIsHeartSubmitting(true);
			let response = {}
			if(state){
				response = await userUpdateUnheartPost(data);
			} else{
				response = await userUpdateHeartPost(data);
			}
			if(response && response.result){
				setIsHeartSubmitting(false);
				let update = response.update;
				if (update) {
					updateData.hearted_by_users = update.hearted_by_users;
					updateData.hearts_count = update.hearts_count;
					updateData.is_hearted = !state;
					setUpdateData(updateData)
				}
			} else {
				setIsHeartSubmitting(false);
			}
		}
	}, [updateData, authUserInfo])


	const toggleHeartComment = useCallback(async (update_id, state, thread_id=null, sub_thread_id=null, sub_thread_reply_id=null) => {
		if(!state){
			state = !state;
		}
		if (authUserInfo) {
			const data = {
				user_id: authUserInfo.id,
				update_id: update_id
			};
			if (thread_id) {
				data.thread_id = thread_id;
			}
			if (sub_thread_id) {
				data.sub_thread_id = sub_thread_id;
			}
			if (sub_thread_reply_id) {
				data.sub_thread_reply_id = sub_thread_reply_id;
			}
			setIsHeartSubmitting(true);
			let response = {}
			if(state){
				response = await userUpdateUnHeartComment(data);
			} else{
				response = await userUpdateHeartComment(data);
			}
			if(response && response.result){
				setIsHeartSubmitting(false);
				let comment = response.comment;
				if (comment) {
					if (thread_id && sub_thread_id == null && sub_thread_reply_id === null) {

						let thread_index = comments.findIndex(x => x.id === thread_id);
						comments[thread_index].hearted_by_users = comment.hearted_by_users
						comments[thread_index].hearts_count = comment.hearts_count
						comments[thread_index].is_hearted = comment.is_hearted

					} else if (thread_id && sub_thread_id && sub_thread_reply_id === null) {

						let thread_index = comments.findIndex(x => x.id === thread_id);
						let sub_thread_index = comments[thread_index].replies.findIndex(x => x.id === sub_thread_id);
						// comments[thread_index].replies[sub_thread_index] = comment
						comments[thread_index].replies[sub_thread_index].hearted_by_users = comment.hearted_by_users
						comments[thread_index].replies[sub_thread_index].hearts_count = comment.hearts_count
						comments[thread_index].replies[sub_thread_index].is_hearted = comment.is_hearted

					} else if (thread_id && sub_thread_id && sub_thread_reply_id) {

						let thread_index = comments.findIndex(x => x.id === thread_id);
						let sub_thread_index = comments[thread_index].replies.findIndex(x => x.id === sub_thread_id);
						let sub_thread_reply_index = comments[thread_index].replies[sub_thread_index].sub_replies.findIndex(x => x.id === sub_thread_reply_id);
						comments[thread_index].replies[sub_thread_index].sub_replies[sub_thread_reply_index] = comment

					}

				}
			} else {
				setIsHeartSubmitting(false);
			}
		}
	}, [updateData, authUserInfo, comments])


	const setIdsForDeletion = (commentId=null, subCommentId=null, subreplyId = null) => {
		if (commentId){
			commentIdToDelete.current = commentId;
		}
		if (subCommentId){
			subCommentIdToDelete.current = subCommentId;
		}
	}

	const setReplyModelId = (modelId, replyId) => {
		setModelId(modelId);
		setReplyToId(replyId);
	}

	const setSubReplyModelId = (modelId, levelOneId, replyId) => {
		if (modelId) setModelId(modelId);
		if (levelOneId) setCommentLevelOneId(levelOneId);
		if (replyId) setReplyToId(replyId);
	}

	const handleDropdownSelection = (selection, data, type) => {
		setSelectedAction(selection);
		if (selection === 'delete'){
			setDataToDelete(data.id, type).then();
		} else if (selection === 'edit'){
			setDataToEdit(data)
		} else if (selection === 'cancel'){
			setDataToEdit(null)
		}
	}

	const setDataToDelete = useCallback(
		async (values, type) => {
			const data = {};
			setIsSubmitting(true);
			if(type === "post"){
				data.post_id = values;
				const response = await deleteSpacePost(data);
				if (response && response.result) {
					let post_index = comments.findIndex(x => x.id === values);
					comments.splice(post_index, 1);
					// processPostsCount(false, spaceChannel);
				}

			}else if(type === "comment"){
				data.comment_id = values;
				const response = await deleteSpaceComment(data);
				if (response && response.result) {
					let comment_index = comments.findIndex(x => x.id === commentIdToDelete.current);
					comments.splice(comment_index, 1);

				}
			} else if(type === "sub-comments"){
				data.comment_id = values;
				const response = await deleteSpaceComment(data);
				if (response && response.result) {
					let comment_index = comments.findIndex(x => x.id === commentIdToDelete.current);
					let sub_comment_index = comments[comment_index].replies.findIndex(x => x.id === subCommentIdToDelete.current);
					comments[comment_index].replies.splice(sub_comment_index, 1);

				}
			} else if(type === "sub-replies"){
				data.comment_id = values;
				const response = await deleteSpaceComment(data);
				if (response && response.result) {
					let comment_index = comments.findIndex(x => x.id === commentIdToDelete.current);
					let sub_comment_index = comments[comment_index].replies.findIndex(x => x.id === subCommentIdToDelete.current);
					let sub_reply_index = comments[comment_index].replies[sub_comment_index].sub_replies.findIndex(x => x.id === subReplyIdToDelete.current);
					comments[comment_index].replies[sub_comment_index].sub_replies.splice(sub_reply_index, 1);
				}
			}
			setIsSubmitting(false);
		}, [
			comments,
			commentIdToDelete,
			subCommentIdToDelete,
			subReplyIdToDelete,
		])


	const handleSubmitSubComment = useCallback(async (values) => {
			let okToSubmit = (subCommentForm.getFieldValue('reply') !== undefined && subCommentForm.getFieldValue('reply').trim() !== '');
			setIsSubmitting(false)
			if (okToSubmit) {
				setSubmitted(true);
				const data = {
					space_id: spaceInfo.id,
					model: 'update',
					model_id: modelId,
					comment: values.reply,
					reply_to_id: replyToId
				};
				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}
				const response = await createSpaceModelComment(data);
				if (response && response.result) {
					let comment_index = comments.findIndex(x => x.id === replyToId);
					// let comment_index = comments[post_index].replies.findIndex(x => x.id === replyToId);
					if(comments[comment_index].replies){
						comments[comment_index].replies.push(response.comment);
					}else{
						comments[comment_index].replies = new Array();
						comments[comment_index].replies.unshift(response.comment);
					}
					subCommentForm.resetFields();
					// setSpaceUpdates(null)
					setIsSubmitting(false);
				} else {
					setIsSubmitting(false);
				}
				setSubmitted(false);
				// setDisableReplyButton(true);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			commentReplyId,
			replyToId,
			modelId,
			comments
		]
	);

	const handleSubmitSubReply = useCallback(async (values) => {
			let okToSubmit = (subCommentReplyForm.getFieldValue('reply') !== undefined && subCommentReplyForm.getFieldValue('reply').trim() !== '');
			setIsSubmitting(true)
			if (okToSubmit) {
				setSubmitted(true);
				const data = {
					space_id: spaceInfo.id,
					model: 'update',
					model_id: modelId,
					comment: values.reply,
					reply_to_id: replyToId
				};
				if (subReplyId) {
					data['reply_to_sub_id'] = subReplyId;
				}
				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}
				const response = await createSpaceModelComment(data);
				if (response && response.result) {
					let comment_index = comments.findIndex(x => x.id === commentLevelOneId);
					let sub_comment_index = comments[comment_index].replies.findIndex(x => x.id === replyToId);
					let sub_comment_reply_index = comments[comment_index].replies[sub_comment_index].sub_replies.findIndex(x => x.id === replyToId);
					if(sub_comment_reply_index >= 0 && comments[comment_index].replies[sub_comment_index].sub_replies){
						comments[comment_index].replies[sub_comment_index].sub_replies.push(response.comment);
					} else{
						let reply = {...comments[comment_index].replies[sub_comment_index].sub_replies[sub_comment_reply_index]};
						reply.sub_replies = [];
						comments[comment_index].replies[sub_comment_index].sub_replies[sub_comment_reply_index] = reply;
						comments[comment_index].replies[sub_comment_index].sub_replies.push(response.comment);
					}
					subCommentReplyForm.resetFields();
					setIsSubmitting(false);
					// setSpaceUpdates(null);
					setCommentLevelOneId(null);
				}
				setSubmitted(false);
				// setDisableReplyButton(true);
			}
		},
		[
			authUserInfo,
			comments,
			commentReplyId,
			replyToId,
			modelId,
			subReplyId,
			spaceInfo,
			commentLevelOneId
		]
	);

	const handleSubmitEditComment = useCallback(async (values) => {
			setIsSubmitting(false)
			setSubmitted(true);
			const data = {
				space_id: spaceInfo.id,
				model: 'update',
				model_id: dataToEdit.model_id,
				comment_id: dataToEdit.id,
				comment: values.comment ? values.comment : ''
			};
			if (authUserInfo) {
				data['user_id'] = authUserInfo.id;
			}
			const response = await updateSpaceModelComment(data);
			if (response && response.result) {
				let comment_index = comments.findIndex(x => x.id === dataToEdit.id);
				// let comment_index = comments[post_index].replies.findIndex(x => x.id === replyToId);
				if (comments[comment_index]) {
					comments[comment_index] = response.comment
				}
				setIsSubmitting(false);
				editCommentForm.resetFields();
				setIsSubmitting(false);
				setDataToEdit(null);
			}
			setSubmitted(false);
		},
		[
			comments,
			authUserInfo,
			spaceInfo,
			dataToEdit]
	);

	const handleSubmitEditSubComment = useCallback(async (values) => {
		setIsSubmitting(false)
		setSubmitted(true);
		const data = {
			space_id: spaceInfo.id,
			model: 'update',
			model_id: dataToEdit.model_id,
			comment_id: dataToEdit.id,
			comment: values.comment ? values.comment : ''
		};
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const response = await updateSpaceModelComment(data);
		if (response && response.result) {
			let comment_index = comments.findIndex(x => x.id === dataToEdit.thread_id);
			let comment = response.comment;
			if (comment.level === 3) {
				let sub_comment_index = comments[comment_index].replies.findIndex(x => x.id === dataToEdit.reply_to_id);
				let sub_comment_reply_index = comments[comment_index].replies[sub_comment_index].sub_replies.findIndex(x => x.id === dataToEdit.id);
				if (comments[comment_index].replies[sub_comment_index].sub_replies[sub_comment_reply_index]) {
					comments[comment_index].replies[sub_comment_index].sub_replies[sub_comment_reply_index] = comment
				}
			} else {
				let sub_comment_index = comments[comment_index].replies.findIndex(x => x.id === dataToEdit.id);
				if (comments[comment_index].replies[sub_comment_index]) {
					comments[comment_index].replies[sub_comment_index] = comment
				}
			}

			setIsSubmitting(false);
			editSubCommentForm.resetFields();
			setIsSubmitting(false);
			setDataToEdit(null);
		}
		setSubmitted(false);
		},
		[
			comments,
			authUserInfo,
			spaceInfo,
			dataToEdit]
	);

	useEffect(() => {
		setCommentReplyId(commentReplyId);
		setModelId(modelId);
		setSubReplyId(subReplyId);
	}, [modelId, commentReplyId, subReplyId])

	useEffect(() => {
	}, [
		isSubmitting,
		dataToEdit,
		selectedAction,
		isSubmitting,
		commentIdToDelete,
		subCommentIdToDelete,
		commentIdOpened,
		subCommentIdOpened
	]);

	const updatesContent = () => {
		return (
			<>
				<ChannelSharePost
					// post={selectedPost}
					modalOpen={isShareModalOpen}
					setModalOpen={setIsShareModalOpen}
				/>
				<div className="cs-updates-article-cover-media block-xl order-2 lg:order-1">
					<a className="cs-updates-media">
						{displayDataContent(
							updateData,
							updatesViewingUserIsMember,
							'cover'
						)}
					</a>
				</div>

                <div className="cs-stack-update-info stack-col">
					<div className="cs-stack-tags stack-row-wrap">
						{updateData && updateData.tags_arr && updateData.tags_arr.length > 0 && updateData.tags_arr.map((data, index) => (
							<a key={index} className="chip-secondary-gradient"><span><p>{data}</p></span></a>
						))}
					</div>
				</div>

				<div className="cs-stack-updates-post stack-col">
					<div className="cs-updates-post-body stack-col">
						{updateData.content && (
							<>{parse(updateData.content.toString())}</>
						)}
					</div>
				</div>

				{updatesViewingUserIsMember ? (
                    <>
                        <div className="cs-stack-post-actions stack-row split">
							<div className="cs-stack-comment-hearts stack-row">
								<a className="button-icon-base-gradient"
								   onClick={() => toggleHeart(updateData?.id, updateData?.is_hearted)}
								>
									<span className="sr-only">Love Post</span>
									<i className={`${updateData && updateData?.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
								</a>
								{updateData && updateData?.hearts_count > 0 &&
									<p>{updateData?.hearts_count}</p>
								}
							</div>
							<a className="button-icon-base-gradient"
							   onClick={()=>setIsShareModalOpen(true)}
							>
								<span className="sr-only">Share Post</span>
								<i className="fa-light fa-share"/>
							</a>
                        </div>

                        <div className="cs-stack-post-activity stack-row-wrap split">
                            <div className="stack-col sm:stack-row">
								<div className="stack-avatar">
									{updateData && updateData.hearted_by_users && updateData.hearted_by_users.length > 0 && updateData.hearted_by_users.slice(0, 3).map((user, index) => (
										<div key={index} className="avatar-gradient relative inline-block">
											<img alt="member-thumb" src={user.photo_thumb ? user.photo_thumb : default_user_profile} />
											{/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
										</div>
									))}
								</div>
								{updateData && updateData.hearted_by_users && updateData.hearted_by_users.length > 0 && (
									<p>
										Loved by
										{updateData && updateData.hearted_by_users && updateData.hearts_count > 0 && updateData.hearts_count > 3 ?
											<>
												{updateData.hearted_by_users.slice(0,3).map((user, index) => (
													<a key={index} className='overflow-hidden ...'>
														&nbsp;@{user.username ? user.username : user.full_name}
													</a>
												))}
											</>
											:
											<>
												{updateData && updateData.hearted_by_users && updateData.hearts_count > 0 && updateData.hearted_by_users.map((user, index) => (
													<a key={index} className='overflow-hidden ...'>
														{updateData.hearts_count === 2 &&
															<>
																&nbsp;{1 === index ? ' and ' : ''}
															</>
														}
														&nbsp;@{user.username ? user.username : user.full_name}
														{updateData.hearts_count === 3 &&
															<>
																&nbsp;{0 === index ? ', ' :  1 === index ? ', and' : ''}
															</>
														}
													</a>
												))}
											</>
										}
										{(updateData && updateData.hearted_by_users && updateData.hearts_count > 0 && updateData.hearts_count > 3) &&
											<a>
												&nbsp;and {updateData.hearts_count - 3} {(updateData.hearts_count - 3) > 1 ? 'others' : 'other'}
											</a>
										}
									</p>
								)}
                            </div>
                            {comments &&(
                            	<p>{comments.length} comment{comments.length > 1 ? 's': ''}</p>
                            )}
                        </div>
                    
                        {updateData.comments_availability === 'enabled' && (
                            <>
                                <h4 className="header-small mt-5">Comments</h4>
                                <div className="cs-stack-post-comment-form stack-row">
                                	<div className="avatar-gradient">
										<img src={authUserInfo?.profile?.photo_thumb} alt=""/>
									</div>
									<Form
										className="w-full form-input-combo"
										form={commentForm}
										name="commentForm"
										id="commentForm"
										onFinish={handleSubmit}
										onChange={handleChanges}
										validateMessages={VALIDATE_MESSAGES}
									>
										<Form.Item
											label="Comment"
											name="comment"
											className="w-full !p-0 !m-0"
										>
											<input id="comment" name="comment" type="comment" placeholder={'Comment as ' + authUserInfo?.profile?.username}/>
										</Form.Item>

										<button disabled={submitted || disableCommentButton}
												type="submit"
												className="secondary-gradient"
										>
												<span>
													<p>
														{submitted && (
															<i className="fa-solid fa-spinner fa-spin me-1 comment-spinner"/>
														)}
														Post
													</p>
												</span>
										</button>
									</Form>
                                </div>

                                {/*<div className="col-span-12 mb-2">*/}
                                {/*    <Comments/>*/}
                                {/*</div>*/}

								{/*BEGIN - COMMENT - DOUBLE NESTED REPLIES EXAMPLE*/}
								{comments && comments.map((comment, index) => (
									<div key={index} className="cs-comment stack-col">
										<div className="stack-row">
											<a href="fam-profile.html" className="cs-comment-avatar relative shrink-0">
												<img src={comment?.commenter?.photo_thumb ? comment.commenter.photo_thumb : default_user_profile}
													 alt=""
												/>
												{/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
											</a>

											<div className="cs-comment-thread stack-col">
												<div className="cs-stack-comment-body stack-col-base">
													<div className="cs-stack-comment-header stack-row split">
														<div className="stack-row">
															<div className="cs-stack-comment-published stack-col-base sm:stack-row">
																<h4>
																	<a href="fam-profile.html">
																		@{comment?.commenter?.username ? comment?.commenter?.username : (comment?.commenter?.full_name ? comment?.commenter?.full_name : '')}
																	</a>
																</h4>
																<p>
																	{getPostDate(comment?.created_on)}
																</p>
															</div>
														</div>
														{authUserInfo && authUserInfo.id === comment.user_id &&
															<a className='button-icon-base-gradien' onClick={() => setIdsForDeletion(comment.id)}>
																<EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={comment} type={"comment"} dataToEditCopy={dataToEdit}/>
															</a>
														}
													</div>
													<p className="cs-comment-body">
														{(dataToEdit && dataToEdit.id === comment.id && dataToEdit.reply_to_id == null) ?
															<Form
																className="w-full form-input-combo"
																form={editCommentForm}
																name="editCommentForm"
																id="editCommentForm"
																onFinish={handleSubmitEditComment}
																onChange={handleEditCommentChanges}
																validateMessages={VALIDATE_MESSAGES}
																fields={[
																	{
																		name: ['comment'],
																		value: dataToEdit.comment,
																	},
																]}
															>

																<Form.Item
																	label="Comment"
																	name="comment"
																	className="w-full !p-0 !m-0"
																	// validateTrigger="onBlur"
																	// rules={[{ required: true }]}
																>
																	<input id="comment" name="comment" type="comment" />
																</Form.Item>

																<button
																	disabled={submitted || disableCommentButton}
																	type="submit"
																	className="secondary-gradient"
																>
																	<span>
																		<p>
																			{submitted && (
																				<i className="fa-solid fa-spinner fa-spin me-1 comment-spinner"/>
																			)}
																			Update
																		</p>
																	</span>
																</button>
															</Form>
															:
															<>
																{comment && (
																	comment.comment
																)}
															</>
														}
													</p>
													{/*{commentId !== comment?.id && (*/}
													<div className="cs-stack-comment-actions stack-row split">
														{/*CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled.*/}
														<div className="cs-stack-comment-hearts stack-row">
															<a className="button-icon-base-gradient"
															   onClick={() => toggleHeartComment(updateData?.id, comment.is_hearted, comment.id)}
															>
																<span className="sr-only">Love Post</span>
																<i className={`${comment.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
															</a>
															{comment.hearted_by_users.length > 0 &&
															    <p>{comment.hearted_by_users.length}</p>
															}
														</div>
														<a className="button-icon-base-gradient" onClick={() => {
															subCommentForm.resetFields();
															subCommentReplyForm.resetFields();
															setSubCommentIdOpened(null);
															setCommentIdOpened(commentIdOpened === comment?.id ? null : comment?.id);
														}}>
															<span className="sr-only">Post Reply</span>
															<i className={`${commentIdOpened === comment?.id ? 'fa-solid' : 'fa-light' } fa-reply`}/>
														</a>
													</div>
													{/*)}*/}
												</div>
												{commentIdOpened === comment?.id && (
													<div className="cs-stack-post-comment-form stack-row">
														<a href="fam-profile.html" className="cs-comment-avatar shrink-0 relative">
															<img
																src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
																alt=""
															/>
															{/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
														</a>
														<Form className="w-full form-input-combo"
															  form={subCommentForm}
															  name="subCommentForm"
															  onFinish={handleSubmitSubComment}
															// onChange={handleCommentChanges()}
															  validateMessages={VALIDATE_MESSAGES}
														>
															<Form.Item
																name="reply"
																id="reply"
																className="w-full !p-0 !m-0"
															>
																<input
																	placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
																	// maxRows={4}
																	// onChange={handlePostChanges}
																	onClick={() => setReplyModelId(comment?.model_id, comment?.id)}
																	// onKeyPress={handlePostKeypress}
																/>
															</Form.Item>
															<button
																disabled={isSubmitting}
																type="submit"
																className={'secondary-gradient'}
															>
																<span><p>Reply</p></span>
															</button>
														</Form>
													</div>
												)}
												{/*BEGIN - Thread Reply - Nesting Point*/}

												{/*BEGIN - COMMENT - SINGLE NESTED COMMENT*/}
												{comment && comment.replies && comment.replies.map((reply, index) => (
														<div key={index} className="cs-comment stack-col">
															<div className="stack-row">
																<a href="fam-profile.html" className="cs-comment-avatar relative shrink-0">
																	<img
																		src={reply?.commenter?.photo_thumb ? reply.commenter.photo_thumb : default_user_profile}
																		alt=""
																	/>
																	{/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
																</a>
																<div className="cs-comment-thread stack-col">
																	<div className="cs-stack-comment-body stack-col-base">
																		<div className="cs-stack-comment-header stack-row split">
																			<div className="stack-row">
																				<div className="cs-stack-comment-published stack-col-base sm:stack-row">
																					<h4>
																						<a href={reply?.commenter?.url}>
																							@{reply?.commenter?.username ? reply?.commenter?.username :
																							(reply?.commenter?.full_name ? reply?.commenter?.full_name : '')}
																						</a>
																					</h4>
																					<p>{getPostDate(reply?.created_on)}</p>
																				</div>
																			</div>
																			{authUserInfo && authUserInfo.id === reply.user_id &&
																				<a className='button-icon-base-gradient' onClick={() => setIdsForDeletion(comment.id)}>
																					<EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={reply} type={"sub-comments"} dataToEditCopy={dataToEdit}/>
																				</a>
																			}
																		</div>
																		<p className="cs-comment-body">
																			{(dataToEdit && dataToEdit.id === reply.id && dataToEdit.reply_to_id != null) ?
																				<Form
																					className="w-full form-input-combo"
																					form={editSubCommentForm}
																					name="editSubCommentForm"
																					id="editSubCommentForm"
																					onFinish={handleSubmitEditSubComment}
																					onChange={handleEditSubCommentChanges}
																					validateMessages={VALIDATE_MESSAGES}
																					fields={[
																						{
																							name: ['comment'],
																							value: dataToEdit.comment,
																						},
																					]}
																				>
																					<Form.Item
																						label="Comment"
																						name="comment"
																						className="w-full !p-0 !m-0"
																					>
																						<input id="comment" name="comment" type="comment" />
																					</Form.Item>
																					<button
																						disabled={submitted || disableCommentButton}
																						type="submit" className="secondary-gradient"
																					>
																						<span>
																							<p>
																								{submitted && (
																									<i className="fa-solid fa-spinner fa-spin me-1 comment-spinner"></i>
																								)}
																								Update
																							</p>
																						</span>
																					</button>
																				</Form>
																				:
																				<>
																					{reply && (
																						reply.comment
																					)}
																				</>
																			}
																		</p>
																		{/*{commentId !== reply?.id && (*/}
																		<div className="cs-stack-comment-actions stack-row split">
																			{/*CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled.*/}
																			<div className="cs-stack-comment-hearts stack-row">
																				<a className="button-icon-base-gradient"
																				   onClick={() => toggleHeartComment(updateData?.id, reply?.is_hearted, comment?.id, reply?.id)}
																				>
																					<span className="sr-only">Love Post</span>
																					<i className={`${reply?.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
																				</a>
																				{reply && reply.hearted_by_users && reply.hearted_by_users.length > 0 &&
																					<p>{reply?.hearted_by_users.length}</p>
																				}
																			</div>
																			<a className="button-icon-base-gradient"
																			   onClick={() => {
																				   subCommentReplyForm.resetFields();
																				   subCommentForm.resetFields();
																				   setCommentIdOpened(null);
																				   setReplyIdOpened(null);
																				   setSubCommentIdOpened(subCommentIdOpened === reply?.id ? null : reply?.id);
																			   }}
																			>
																				<span className="sr-only">Post Reply</span>
																				<i className={`${subCommentIdOpened === reply?.id ? 'fa-solid' : 'fa-light'} fa-reply`}/>
																			</a>
																		</div>
																		{/*)*/}
																		{/*}*/}
																		{subCommentIdOpened === reply?.id && (
																			<div className="cs-stack-post-comment-form stack-row">
																				<a href="fam-profile.html" className="cs-comment-avatar shrink-0 relative">
																					<img
																						src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
																						alt=""
																					/>
																					{/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
																				</a>
																				<Form className="w-full form-input-combo"
																					  form={subCommentReplyForm}
																					  name="subCommentRepyForm"
																					  id="subCommentRepyForm"
																					  onFinish={handleSubmitSubReply}
																					// onChange={handleCommentChanges()}
																					  validateMessages={VALIDATE_MESSAGES}
																				>
																					<Form.Item
																						name="reply"
																						id="reply"
																						className="w-full !p-0 !m-0"
																					>
																						<input
																							placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
																							// maxRows={4}
																							// onChange={handlePostChanges}
																							onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply?.id)}
																							// onKeyPress={handlePostKeypress}
																						/>
																					</Form.Item>

																					<button
																						disabled={isSubmitting}
																						type="submit"
																						className={'secondary-gradient'}
																					>
																						<span><p>Reply</p></span>
																					</button>
																				</Form>
																			</div>
																		)}

																	</div>
																	{/*BEGIN - Thread Reply - Nesting Point*/}

																	{/*BEGIN - COMMENT - DOUBLE NESTED COMMENT*/}
																	{reply && reply.sub_replies && reply.sub_replies.map((sub_reply, index) => (
																			<div key={index} className="cs-comment stack-col">
																				<div className="stack-row">
																					<a href="fam-profile.html" className="cs-comment-avatar relative shrink-0">
																						<img
																							src={sub_reply?.commenter?.photo_thumb ? sub_reply?.commenter?.photo_thumb  : default_user_profile}
																							alt=""
																						/>
																						{/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
																					</a>
																					<div className="cs-comment-thread stack-col">
																						<div className="cs-stack-comment-body stack-col-base">
																							<div className="cs-stack-comment-header stack-row split">
																								<div className="stack-row">
																									<div className="cs-stack-comment-published stack-col-base sm:stack-row">
																										<h4>
																											<a href={sub_reply?.commenter?.url} className="gradient-color-txt font-bold">
																												@{sub_reply?.commenter?.username ? sub_reply?.commenter?.username :
																												(sub_reply?.commenter?.full_name ? sub_reply?.commenter?.full_name : '')}
																											</a>
																										</h4>
																										<p>{getPostDate(reply?.created_on)}</p>
																									</div>
																								</div>
																								{authUserInfo && authUserInfo.id === reply.user_id &&
																									<a className='button-icon-base-gradient' onClick={() => setIdsForDeletion(comment?.id, reply?.id, sub_reply?.id)}>
																										<EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={sub_reply} type={"sub-replies"} dataToEditCopy={dataToEdit}/>
																									</a>
																								}
																							</div>
																							<p className="cs-comment-body">
																								{(dataToEdit && dataToEdit.id === sub_reply.id && dataToEdit.reply_to_id != null && dataToEdit.reply_to_id === sub_reply.reply_to_id) ?
																									<Form
																										className="w-full form-input-combo"
																										form={editSubCommentForm}
																										name="editSubCommentForm"
																										id="editSubCommentForm"
																										onFinish={handleSubmitEditSubComment}
																										onChange={handleEditSubCommentChanges}
																										validateMessages={VALIDATE_MESSAGES}
																										fields={[
																											{
																												name: ['comment'],
																												value: dataToEdit.comment,
																											},
																										]}
																									>
																										<Form.Item
																											label="Comment"
																											name="comment"
																											className="w-full !p-0 !m-0"
																										>
																											<input id="comment" name="comment" type="comment" />
																										</Form.Item>

																										<button
																											disabled={submitted || disableCommentButton}
																											type="submit"
																											className="secondary-gradient"
																										>
																												<span>
																													<p>
																														{submitted && (
																															<i className="fa-solid fa-spinner fa-spin me-1 comment-spinner"></i>
																														)}
																														Update
																													</p>
																												</span>
																										</button>
																									</Form>
																									:
																									<>
																										{reply && (
																											sub_reply.comment
																										)}
																									</>
																								}
																							</p>
																							{/*{commentId !== reply?.id && (*/}
																							<div className="cs-stack-comment-actions stack-row split">
																								{/*CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled.*/}
																								<div className="cs-stack-comment-hearts stack-row">
																									<a className="button-icon-base-gradient"
																									   onClick={() => toggleHeartComment(updateData?.id, sub_reply?.is_hearted, comment?.id, reply?.id, sub_reply?.id)}
																									>
																										<span className="sr-only">Love Post</span>
																										<i className={`${sub_reply?.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
																									</a>
																									{sub_reply && sub_reply.hearted_by_users && sub_reply.hearted_by_users.length > 0 &&
																										<p>{sub_reply?.hearted_by_users.length}</p>
																									}
																								</div>
																								{/*CSJS fa-solid class change when toggled/active. fa-light when inactive.*/}
																								<a className="button-icon-base-gradient"
																								   onClick={() => {
																									   subCommentReplyForm.resetFields();
																									   subCommentForm.resetFields();
																									   setReplyIdOpened(replyIdOpened === sub_reply?.id ? null : sub_reply?.id);
																									   setCommentIdOpened(null);
																									   setSubCommentIdOpened(null);
																								   }
																									   // showSubCommentSubReply(sub_reply?.id)
																								   }
																								>
																									<span className="sr-only">Post Reply</span>
																									<i className={`${replyIdOpened === sub_reply?.id ? 'fa-solid' : 'fa-light'} fa-reply`}/>
																								</a>
																							</div>
																							{/*)}*/}

																						</div>
																						{/*BEGIN - Thread Reply - Nesting Point*/}
																						{/*/END - Thread Reply - Nesting Point*/}
																					</div>
																				</div>
																				{replyIdOpened === sub_reply?.id && (
																					<div className="cs-stack-post-comment-form stack-row">
																						<a href="fam-profile.html" className="cs-comment-avatar shrink-0 relative">
																							<img
																								src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
																								alt=""
																							/>
																							{/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
																						</a>
																						<Form className="w-full form-input-combo"
																							  form={subCommentReplyForm}
																							  name="subCommentRepyForm"
																							  id="subCommentRepyForm"
																							  onFinish={handleSubmitSubReply}
																							// onChange={handleCommentChanges()}
																							  validateMessages={VALIDATE_MESSAGES}
																						>
																							<Form.Item
																								name="reply"
																								id="reply"
																								className="w-full !p-0 !m-0"
																							>
																								<input
																									name="comment"
																									placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
																									// maxRows={4}
																									// onChange={handlePostChanges}
																									onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply?.id)}
																									// onKeyPress={handlePostKeypress}
																								/>
																							</Form.Item>

																							<button
																								disabled={isSubmitting}
																								type="submit"
																								className={'secondary-gradient'}
																							>
																								<span><p>Reply</p></span>
																							</button>
																						</Form>
																					</div>
																				)}
																			</div>
																		)
																	)}
																	{/*/END COMMENT - DOUBLE NESTED COMMENT*/}
																	{/*/END - Thread Reply - Nesting Point*/}
																</div>
															</div>
															{/*BEGIN - Thread Reply - Inline Point*/}
															{/*/END - Thread Reply - Inline Point*/}
														</div>
													)
												)}
												{/*/END COMMENT - SINGLE NESTED COMMENT*/}
												{/*/END - Thread Reply - Nesting Point*/}
											</div>
										</div>
										{/*BEGIN - Thread Reply - Inline Point*/}
										{/*/END - Thread Reply - Inline Point*/}
									</div>)
								)}
								{/*/END COMMENT - DOUBLE NESTED REPLIES EXAMPLE*/}


								{/*{commentsPagination.length > 1 && (*/}
								{/*	<div className="cs-pagination block-full">*/}
								{/*		<CustomPagination*/}
								{/*			items={comments.length} // 100*/}
								{/*			currentPage={currentPage} // 1*/}
								{/*			pageSize={pageSize} // 10*/}
								{/*			onPageChange={onPageChange}*/}
								{/*		/>*/}
								{/*	</div>*/}
								{/*)}*/}

                                {maxPage > 1 && (
									<ResponsivePagination
										total={maxPage}
										current={currentPage}
										onPageChange={page => handlePageChange(page)}
									/>
									// <div className="block-full mt-5">
									// 	<Pagination
									// 		size="sm"
									// 		onClick={(event) =>
									// 			handleOnClickPagination(event)
									// 		}
									// 	>
									// 		{commentsPagination}
									// 	</Pagination>
									// </div>
                                )}
                            </>
                        )}
                	</>
                ) : (
                    <div className="cs-stack-updates-post stack-col">
						<div className="cs-updates-post-body stack-col">
							<p className="cs-post-intro">The #1 travel destination is back! With Japan opening up its borders for travel over the winter, Tokyo is finally available to visit. The city with the most Michelin Stars and a cocktail culture rivaling London is calling! Get the exclusive City Guide and register for this year’s FAM trip! Early signup and returning discounts for members.</p>
							<div className="cs-stack-noaccess stack-col">
								<hgroup className="heading">
									<h2>Want to access the full post?</h2>
									<p>Join Friends & Mike for exclusive invites to culinary and culture experiences around the world featuring Michelin Star Chefs and award winning bartenders.</p>
								</hgroup>

								<a className="button-primary-gradient" target="_self"><span><p>Join the Community</p></span></a>
								{/*class="block-lgx card"*/}
								<div id="cs-space-home-welcome-image" className="rounded-2xl"  style={ {backgroundImage: 'url(' + spaceData?.welcome_photo + ')'} } >
									{/*<img*/}
									{/*	className="object-cover"*/}
									{/*	src={spaceData?.welcome_photo}*/}
									{/*/>*/}
									 <ReactPlayer
										 playing={true}
										 loop={true}
										 muted={true}
										 className="videoPreview"
										 controls={false}
										 width="100%"
										 height="100%"
										 url={spaceData?.welcome_video}
									/>
								</div>
							</div>
						</div>
					</div>
                )}
			</>
		)
	}

	const Comments = useCallback(() => {
		return (
			<>
				{comments && (
					<>
						{comments.map((comment, commentIndex) => (
							<div key={commentIndex}>
								<CommentRow
									authUserInfo={authUserInfo}
									spaceInfo={spaceInfo}
									modelId={update_id}
									comment={comment}
									commentIdToReply={commentToReply}
									updateCommentIdToReply={handleUpdateCommentIdToReply}
									commentLevel={1}
									fetchUpdatedComments={handleFetchUpdatedComments}
								/>
								{comment.replies && (
									<>
										{comment.replies.map((reply, replyIndex) => (
											<div key={replyIndex}>
												<CommentRow
													authUserInfo={authUserInfo}
													spaceInfo={spaceInfo}
													modelId={update_id}
													comment={reply}
													commentIdToReply={commentToReply}
													updateCommentIdToReply={
														handleUpdateCommentIdToReply
													}
													commentLevel={2}
													fetchUpdatedComments={
														handleFetchUpdatedComments
													}
												/>
												{reply.sub_replies && (
													<>
														{reply.sub_replies.map(
															(subReply, subReplyIndex) => (
																<div key={subReplyIndex}>
																	<CommentRow
																		authUserInfo={authUserInfo}
																		spaceInfo={spaceInfo}
																		modelId={update_id}
																		comment={subReply}
																		commentIdToReply={
																			commentToReply
																		}
																		updateCommentIdToReply={
																			handleUpdateCommentIdToReply
																		}
																		commentLevel={3}
																		fetchUpdatedComments={
																			handleFetchUpdatedComments
																		}
																		parentReplyId={reply.id}
																	></CommentRow>
																</div>
															)
														)}
													</>
												)}
											</div>
										))}
									</>
								)}
							</div>
						))}
					</>
				)}
			</>
		);
	}, [comments, authUserInfo, spaceInfo, update_id, commentToReply]);

	useEffect(() => {
		if (
			spaceInfoLoading &&
			spaceInfoLoading === 'done' &&
			spaceInfo &&
			spaceInfo.id &&
			// authUserInfo &&
			// authUserInfo.id &&
			initialLoad
		) {
			getUpdateDataRequest(update_id).then(() => {});
			getSpaceCategoriesRequest().then((r) => {});
			setInitialLoad(false);
		}

		if (previousLocation !== currentLocation.pathname) {
			setInitialLoad(true);
			setPreviousLocation(currentLocation.pathname);
		}
	}, [
		currentLocation.pathname,
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		update_id,
		initialLoad,
		categories,
		previousLocation,
		commentToReply,
		updateData
	]);


	return (
		<>
            <section id="cs-space-updates-post-c1" className="p-section-safeview theme-transparent">
                <div className="centerframe-grid lg:gap-2gutter">
					{updateData && (
						<>
							{/* <div className="grid grid-cols-12 gap-4">
								<div className="xsm:col-span-12 lg:col-span-9">
									<h1 className="header-large m-0">
										{displayDataContent(
											updateData,
											updatesViewingUserIsMember,
											'title'
										)}
									</h1>
									<p className="body-text--small color-light mb-5">
										{updateData.publish_date ? (
											<>
												{moment(updateData.publish_date).format('LL')}{' '}
												{getAuthorDisplay(
													updateData.author,
													updateData.author_data
												)}
											</>
										) : (
											<>
												{moment(updateData.created_on).format('LL')}{' '}
												{getAuthorDisplay(
													updateData.author,
													updateData.author_data
												)}
											</>
										)}
									</p>

									<div className="lg:inline xsm:hidden p-0">
										{updatesContent()}
									</div>

								</div>

								<div className="xsm:col-span-12 lg:col-span-3">
									<div className="input-group-com gradient-border-box input-with-btn">
										<input
											className="input-md color-light ps-4 body-txtitalic--smallest bring-front"
											type="text"
											placeholder="Search Updates"
										/>
										<button className="btn btn-secondary btn-md w-1/2">
											Search
										</button>
									</div>

									<ul className="category-list mt-5 pt-5">
										{categories && (
											<>
												{categories.map((data, index) => (
													<li
														key={index}
														onClick={() =>
															navigateToViewUpdate(
																'/' +
																space_username +
																'/update?category=' +
																updateData.slug
															)
														}
													>
														{CategoryLinkRow(data)}
													</li>
												))}
											</>
										)}
									</ul>
									<ul className="tag-list mt-5">
										{updateData.tags && (
											<>
												{updateData.tags_arr.map((data, index) => (
													<li key={index}>
														<Link>{data}</Link>
													</li>
												))}
											</>
										)}
									</ul>
								</div>

							</div> */}
                            <article className="cs-updates-article block-xl order-2 lg:order-1">
                                <div className="cs-updates-article-header">
                                    <hgroup className="heading">
                                        <h2>{displayDataContent(
											updateData,
											updatesViewingUserIsMember,
											'title'
										)}</h2>
                                        <p>
											<time>
												{updateData.publish_date ? (
													<>
														{moment(updateData.publish_date).format('LL')}{' '}
														{getAuthorDisplay(
															updateData.author,
															updateData.author_data
														)}
													</>
												) : (
													<>
														{moment(updateData.created_on).format('LL')}{' '}
														{getAuthorDisplay(
															updateData.author,
															updateData.author_data
														)}
													</>
												)}
                                       	 	</time>
										</p>
                                    </hgroup>
                                </div>
                                {updatesContent()}
                            </article>

                            <aside className="cs-stack-updates-aside block-md order-1 lg:order-2">
								<div className="cs-stack-updates-tools stack-col">
									<form>
										<div className="form-input-combo">
											<label>Search</label>
											<input id="search-field" name="search" type="search" placeholder="Search Updates"/>
											<button type="submit" className="secondary-gradient"><span><p>Search</p></span></button>
										</div>
									</form>
								</div>
								<div className="cs-stack-categories stack-col">
									<h3>Categories</h3>
									<div className="cs-stack-category-links stack-row-wrap lg:stack-col">
												{categories && categories.length > 0 && categories.map((data, index) => (
													<a key={index} className="button-secondary-gradient squared"><span className="stack-row-base split"><p>{updateData.name}</p><p className="cs-count">{updateData.usage_count}</p></span></a>
										))}
									</div>
								</div>
                    		</aside>
						</>
					)}
				</div>
			</section>
		</>
	);
};

export default memo(UpdatesContent);
