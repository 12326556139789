import { memo, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

import { getMyCollections } from '../../../services/user';
import routes from '../../../routes';
import CollectionItem from './CollectionItem';
import {getSitewideLink} from "../../../utils/sitewideLinks";

const CollectionsContent = () => {
	const navigate = useNavigate();
	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [initialLoad, setInitialLoad] = useState(true);
	const [selectedTab, setSelectedTab] = useState(routes.spaces());
	const [activeTab, setActiveTab] = useState('collections');
	const [collections, setCollections] = useState(null);
	const [collectionsCount, setCollectionsCount] = useState(0);
	const [walletsCount, setWalletsCount] = useState(0);
	const [customsCount, setCustomsCount] = useState(0);

	const renderCollectionViewUrl = (space) => {
		const url = routes.viewCollection();
		return url.replace(':space_username', space.username);
	};

	const getCollections = useCallback(async () => {
		try {
			const params = {
				user_id: authUserInfo.id
			};

			const response = await getMyCollections(params);
			if (response && response.result) {
				setCollectionsCount(response.collections_count);
				setWalletsCount(response.wallets_count);
				setCustomsCount(response.custom_count);
				setCollections(response.collections);
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo]);

	useEffect(() => {
		if (authUserInfo && initialLoad) {
			getCollections().then((r) => {});
			setInitialLoad(false);
		}

		setSelectedTab(currentLocation.pathname);
		document.title = 'Commonspace Spaces';
	}, [authUserInfo, currentLocation.pathname, initialLoad]);

	return (
		<>
			<div className="cs-dash-card block-full card">
				<hgroup className="heading">
					<h2>Your Collections</h2>
					<p>Collections contain your Memberships and Items organized by Space or as a Custom Collection. {getSitewideLink('learn_more_collections')}.</p>
				</hgroup>
				<div id="cs-dash-card-nav-spaces" className="cs-nav-tabs">
					<nav className="cs-nav-buttons-row block-full">
						<div className="stack-row">
							<a
								onClick={() => {
									setActiveTab('collections');
								}}
								className={"button secondary gradient rounded-corners"
									+ (activeTab === 'collections' ? " active" : "")}
							>
								<span><p>{collectionsCount} Total</p></span>
							</a>
							<a
								onClick={() => {
									setActiveTab('wallets');
								}}
								className={"button secondary gradient rounded-corners"
									+ (activeTab === 'wallets' ? " active" : "")}
							>
								<span><p>{walletsCount} Space</p></span>
							</a>
							<a
								onClick={() => {
									setActiveTab('custom');
								}}
								className={"button secondary gradient rounded-corners" 
									+ (activeTab === 'custom' ? " active" : "")}
							>
								<span><p>{customsCount} Custom</p></span>
							</a>
						</div>
					</nav>
				</div>
			</div>
			{authUserInfo && (
				<>
					{activeTab === 'collections' && (
						<>
							{collections &&
								collections.map((data, index) => (
									<CollectionItem
										key={index}
										data={data}
										type={'active'}
										urlRedirect={renderCollectionViewUrl(data)}
										currentUser={authUserInfo}
									></CollectionItem>
								))}
						</>
					)}
					{/*{activeTab === 'expired' && (*/}
					{/*	<>*/}
					{/*		{expiredSpaces &&*/}
					{/*			expiredSpaces.map((data, index) => (*/}
					{/*				<SpaceItem*/}
					{/*					key={index}*/}
					{/*					data={data}*/}
					{/*					type={'expired'}*/}
					{/*					currentUser={authUserInfo}*/}
					{/*				></SpaceItem>*/}
					{/*			))}*/}
					{/*	</>*/}
					{/*)}*/}
					{/*{activeTab === 'manage' && (*/}
					{/*	<>*/}
					{/*		{ownSpaces &&*/}
					{/*			ownSpaces.map((data, index) => (*/}
					{/*				<SpaceItem*/}
					{/*					key={index}*/}
					{/*					data={data}*/}
					{/*					type={'manage'}*/}
					{/*					currentUser={authUserInfo}*/}
					{/*				></SpaceItem>*/}
					{/*			))}*/}
					{/*	</>*/}
					{/*)}*/}
				</>
			)}

			{/*<div className="cs-pagination block-full">*/}
			{/*	<nav className="pagination-gradient" aria-label="Pagination">*/}
			{/*		<a href="#pprevious" className="pagination-previous">*/}
			{/*			<span className="sr-only">Previous</span>*/}
			{/*			<i className="fa-regular fa-angle-left"></i>*/}
			{/*		</a>*/}
			{/*		<a href="#p1">1</a>*/}
			{/*		<a href="#p2">2</a>*/}
			{/*		<a href="#p3" className="pagination-extra">3</a>*/}
			{/*		<span className="pagination-ellipsis">...</span>*/}
			{/*		<a href="#p8" className="pagination-extra">8</a>*/}
			{/*		<a href="#p9">9</a>*/}
			{/*		<a href="#10">10</a>*/}
			{/*		<a href="pnext" className="pagination-next">*/}
			{/*			<span className="sr-only">Next</span>*/}
			{/*			<i className="fa-regular fa-angle-right"></i>*/}
			{/*		</a>*/}
			{/*	</nav>*/}
			{/*</div>*/}
		</>



		// <div id="cscxl-dashboard" className="collections-content-container">
		// 	<div className="dashboard-wrapper block-full card">
		// 		<div className="grid grid-cols-12  md:gap-8">
		// 			<div className="col-span-10 flex items-center h-[75px] md:h-[48px]">
		// 				<h3 className="header-medium">Your Collections</h3>
		// 			</div>
		// 			<div className="col-span-2  w-[42px] md:w-[80px] grid  items-center justify-self-end ">
		// 				<a className="btn btn-secondary btn-md">
		// 					<i className="fa-light fa-plus"></i>
		//
		// 				</a>
		// 			</div>
		// 		</div>
		// 		<p className="pt-3 body-txtitalic--small">
		// 			Collections are groups of your Items and Membership Keys. You can create your
		// 			own custom Collections and you also have automatically created Collections for
		// 			your Spaces and any Wallets you have connected.{' '}
		// 			{getSitewideLink('learn_more_collections')}.
		// 			{/*<a className="gradient-color-txt" href="">*/}
		// 			{/*	Learn more about Collections, Items, and Membership Keys.*/}
		// 			{/*</a>*/}
		// 		</p>
		// 		<ul className="nav nav-pills mt-5 grid grid-flow-row grid-rows-3 grid-cols-1 md:grid-cols-3 md:grid-rows-1">
		// 			<li className="nav-item !p-0 col-span-1" role="presentation">
		// 				<Button
		// 					className={'!w-full nav-link ' + (activeTab === 'collections' ? 'active' : '')}
		// 					onClick={() => {
		// 						setActiveTab('collections');
		// 					}}
		// 				>
		// 					{collectionsCount} Collections
		// 				</Button>
		// 			</li>
		// 			<li className="nav-item !p-0 col-span-1" role="presentation">
		// 				<Button
		// 					className={'!w-full nav-link ' + (activeTab === 'wallets' ? 'active' : '')}
		// 					onClick={() => {
		// 						setActiveTab('wallets');
		// 					}}
		// 				>
		// 					{walletsCount} Spaces & Wallets Only
		// 				</Button>
		// 			</li>
		// 			<li className="nav-item !p-0 col-span-1" role="presentation">
		// 				<Button
		// 					className={'!w-full nav-link ' + (activeTab === 'custom' ? 'active' : '')}
		// 					onClick={() => {
		// 						setActiveTab('custom');
		// 					}}
		// 				>
		// 					{customsCount} Custom Only
		// 				</Button>
		// 			</li>
		// 		</ul>
		// 	</div>
		// 	<div>
		// 		{authUserInfo && (
		// 			<>
		// 				{activeTab === 'collections' && (
		// 					<>
		// 						{collections &&
		// 							collections.map((data, index) => (
		// 								<CollectionItem
		// 									key={index}
		// 									data={data}
		// 									type={'active'}
		// 									urlRedirect={renderCollectionViewUrl(data)}
		// 									currentUser={authUserInfo}
		// 								></CollectionItem>
		// 							))}
		// 					</>
		// 				)}
		// 				{/*{activeTab === 'expired' && (*/}
		// 				{/*	<>*/}
		// 				{/*		{expiredSpaces &&*/}
		// 				{/*			expiredSpaces.map((data, index) => (*/}
		// 				{/*				<SpaceItem*/}
		// 				{/*					key={index}*/}
		// 				{/*					data={data}*/}
		// 				{/*					type={'expired'}*/}
		// 				{/*					currentUser={authUserInfo}*/}
		// 				{/*				></SpaceItem>*/}
		// 				{/*			))}*/}
		// 				{/*	</>*/}
		// 				{/*)}*/}
		// 				{/*{activeTab === 'manage' && (*/}
		// 				{/*	<>*/}
		// 				{/*		{ownSpaces &&*/}
		// 				{/*			ownSpaces.map((data, index) => (*/}
		// 				{/*				<SpaceItem*/}
		// 				{/*					key={index}*/}
		// 				{/*					data={data}*/}
		// 				{/*					type={'manage'}*/}
		// 				{/*					currentUser={authUserInfo}*/}
		// 				{/*				></SpaceItem>*/}
		// 				{/*			))}*/}
		// 				{/*	</>*/}
		// 				{/*)}*/}
		// 			</>
		// 		)}
		// 	</div>
		// </div>
	);
};

export default memo(CollectionsContent);
