import { memo, useCallback, useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSpaceItemData} from "../../services/space";
import {checkMembershipItemStatus} from "../../services/payment_method";
import routes from "../../routes";
import {getSpaceInfo} from "../../states/spaceGlobal/actions";
import {MembershipItemUtils} from "../../utils/MembershipItemUtils";

const SpaceItemActivated = (props) => {
    const { verifyEmailSpaceUsername, verifyEmailSpaceItemId } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isSubmitted, setSubmitted] = useState(false);
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [itemDetails, setItemDetails] = useState(null);
    const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);

    const getItem = useCallback(async (space_id, item_id) => {
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }
        const response = await getSpaceItemData(data);
        if (response && response.result) {
            const itemData = response.item;
            setItemDetails(response.item);
            if (itemData.photo || itemData.video) {
                if (itemData.photo) {
                    setCollectionAssetType('image');
                }
                if (itemData.video) {
                    setCollectionAssetType('video');
                }
            } else {
                setCollectionAssetType('image');
            }
        }
    }, []);

    const checkStatus = useCallback(async (space_id, item_id) => {
        if (!item_id) return;
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await checkMembershipItemStatus(data);
        if (response) {
            if (response.result) {
                const transaction = response.stripe_transaction;
                if (transaction) {
                    if (transaction.status !== 'inactive') {
                        const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
                        const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
                        // navigate(fin_url);
                    }
                } else {
                    const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
                    const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
                    // navigate(fin_url);
                }
            }
            setCheckStatusLoaded(true);
        }
    },[authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceItemId]);

    useEffect(() => {
        if (verifyEmailSpaceUsername) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getItem(spaceInfo.id, verifyEmailSpaceItemId).then((r) => {});
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(verifyEmailSpaceUsername));
                }
            }
        }

        if (spaceInfo && verifyEmailSpaceItemId && authUserInfo) {
            checkStatus(spaceInfo.id, verifyEmailSpaceItemId).then(r => {});
        }
        // console.log('verifyEmailSpaceItemId: '+verifyEmailSpaceItemId);
        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);

    }, [
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        initialLoad,
        verifyEmailSpaceUsername,
        verifyEmailSpaceItemId,
        // itemDetails,
        // isSubmitted,
        isCheckStatusLoaded
    ]);

    return (
        <>
            <main id="cs-space-main" className="cs-main main-theme">
                <section id="cs-product-membership-activate-c1" className="p-section-safeview theme-transparent">
                    <div className="centerframe-grid gap-2gutter">
                        <div id="cs-product-activate-main" className="block-lg order-1">
                            <hgroup className="heading">
                                <h1>Item Activated</h1>
                                <p className="text-p2">Your new{' '}
                                    <a href={`/@${spaceInfo?.username ?? ''}`} target="_blank">
                                        <i className="fa-regular fa-solar-system"/>
                                        {spaceInfo?.name ?? ''}
                                    </a>

                                </p>
                            </hgroup>

                            <div className="cs-stack-title-preview">
                                <h4>New Item Active</h4>
                                <div className="cs-product-preview-row">
                                    <div className="avatar-square-gradient-md">
                                        <img src={itemDetails?.photo_medium} alt="Image of Friends & Mike Membership"/>
                                    </div>
                                    <hgroup>
                                        <h5>{itemDetails && itemDetails.name ? itemDetails.name : ''}</h5>
                                        <p>{itemDetails && itemDetails.summary ? itemDetails.summary : ''}</p>
                                    </hgroup>
                                </div>
                            </div>

                            <div className="cs-stack-title-preview">
                                <h4>Current Membership</h4>
                                <div className="cs-product-preview-row">
                                    <div className="avatar-square-gradient-md">
                                        <img
                                            // src={itemDetails?.photo_medium}
                                            alt="Image of Friends & Mike Membership"/>
                                    </div>
                                    <hgroup>
                                        <h5>Friends & Mike Founding Membership</h5>
                                        <p>The Official #FAM Membership unlocks global benefits and community + exclusive perks.</p>
                                    </hgroup>
                                </div>
                            </div>

                            <p className="text-p2">You can always manage your Memberships and Items in your Dashboard.</p>

                            <div className="stack-buttons col">
                                <a href="/dashboard" type="button" className="button-primary-gradient">
                                    <span><p>View in Dashboard</p></span>
                                </a>
                                <a href={spaceInfo && spaceInfo.username ? (`/@${spaceInfo.username}/member`) : ''} type="button" className="button-secondary-gradient">
                                    <span><p>Go to Members</p></span>
                                </a>
                            </div>
                        </div>
                        <aside id="cs-profile-create-aside" className="block-lg order-2 hidden lg:block">
                            <div className="cs-profile-preview card sticky top-2gutter-xl"
                                 style={{backgroundImage: 'url(' + authUserInfo?.profile?.background_photo + ')'}}
                            >

                                {authUserInfo && authUserInfo?.profile?.background_video && (
                                    <div className="video-profile-background-container">
                                        <video
                                            autoPlay
                                            muted
                                            loop
                                            className="video-profile-avatar"
                                        >
                                            <source
                                                src={authUserInfo?.profile?.background_video}
                                                type="video/mp4"
                                            />
                                        </video>
                                    </div>
                                )}

                                <div className="cs-stack-profile-preview rounded-card stack-col shader">
                                    <div className="form-input-media lg circle air nohover"
                                         style={{backgroundImage: 'url(' + authUserInfo?.profile?.photo_medium + ')'}}
                                    >
                                        <a href="#">
                                        </a>
                                    </div>
                                    <hgroup>
                                        <p className="text-p1 active">@{(authUserInfo?.profile?.username ? authUserInfo?.profile?.username : '')}</p>
                                        <h1>{(authUserInfo?.profile?.full_name ? authUserInfo?.profile?.full_name : '')}</h1>
                                        <p className="text-p2 italic">{authUserInfo?.profile?.gender != null ? (authUserInfo?.profile?.gender === 'male' ? 'He/Him' : 'She/Her') : ''} </p>
                                    </hgroup>
                                    <p className="italic">
                                        {(authUserInfo?.profile?.intro ? authUserInfo?.profile?.intro : '')}
                                    </p>
                                    <div className="cs-stack-icons stack-row-wrap">
                                        <i className="fa-brands fa-x-twitter"></i>
                                        <i className="fa-brands fa-instagram"></i>
                                        <i className="fa-brands fa-facebook-f"></i>
                                        <i className="fa-brands fa-tiktok"></i>
                                        <i className="fa-brands fa-linkedin-in"></i>
                                        <i className="fa-brands fa-twitch"></i>
                                        <i className="fa-regular fa-globe"></i>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </section>
            </main>

        </>

    );
};

export default memo(SpaceItemActivated);