import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import { Alchemy, Network } from 'alchemy-sdk';
import Web3 from 'web3';
import './../SpaceClaimMembership/SpaceClaimMembership.scss';
import { setLocalStorage } from '../../utils/storage';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import { getSpaceItemData } from '../../services/space';
import {
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_KEY,
	NODE_ENV,
	MAGIC_CONNECT_NETWORK
} from '../../constants/common';
import { getUserSpaceItemClaim } from '../../services/user';
import routes from '../../routes';

import default_items_img from '../../assets/images/form_background.png';
import '../SpaceView/SpaceView.scss';
import {getCommunityBtnText, getDetailsGift, getDetailsMonthlyPrice, getDetailsPrice} from "../../components/commons/helpers/MembershipItemHelpers";
import {MembershipItemUtils} from "../../utils/MembershipItemUtils";
import {checkMembershipItemStatus} from "../../services/payment_method";
import {MEMBERSHIP_TYPES} from "../../constants/space";

const SpaceClaimItem = (props) => {
	const { hasGradient, url } = props;
	const currentLocation = useLocation();
	let space_username = null;
	const [searchParams, setSearchParams] = useSearchParams();
	const itemId = searchParams.get('id');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [submitted, setSubmitted] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [item, setItem] = useState(null);
	const [isItemLoaded, setIsItemLoaded] = useState(false);
	const [isClaimed, setClaimed] = useState(false);
	const [fetchingCollectionData, setFetchingCollectionData] = useState(false);
	const [collectionData, setCollectionData] = useState(null);
	const [collectionAssetType, setCollectionAssetType] = useState('image');
	const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
	const [spaceName, setSpaceName] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [loadItemOnce, setLoadItemOnce] = useState(false);
	const [status, setStatus] = useState('');

	// For testing purpose only //
	const test_public_address = '0x452d40db156034223e8865f93d6a532ae62c4a99';
	const test_contract_address = '0xb0bbbc0f43d611351b3115bd5e338f4c402ae8c2';
	// End testing data //

	const settings = {
		apiKey: ALCHEMY_API_KEY,
		network: Network.ETH_MAINNET
	};

	const alchemy = new Alchemy(settings);

	const scrollToClick = () => {
		const element = document.getElementById('cs-space-product-item-c2');
		element.scrollIntoView({ behavior: 'smooth' });
	}

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const web3 = new Web3(magic.rpcProvider);

	const path = currentLocation.pathname;
	if (path && path.startsWith('/@') && path.length > 3) {
		const clean_path = path.replace('/@', '');
		const path_exp = clean_path.split('/');
		space_username = path_exp[0];
	}

	const getItem = useCallback(async (space_id, item_id) => {
		if (!item_id) {
			return;
		}

		const data = {
			space_id: space_id,
			item_id: item_id
		};

		if (authUserInfo) {
			data['viewing_user_id'] = authUserInfo.id;
		}

		const response = await getSpaceItemData(data);

		if (response && response.result) {
			const itemData = response.item;
			setItem(itemData);
			setIsItemLoaded(true);

			if (itemData.photo || itemData.video) {
				if (itemData.photo) {
					const bg = {
						backgroundImage: 'url(' + itemData.photo + ')',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					};
					setCollectionAssetBGImage(bg);
					setCollectionAssetType('image');
				}
				if (itemData.video) {
					setCollectionAssetType('video');
				}
			} else {
				const bg = {
					backgroundImage: 'url(' + default_items_img + ')',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover'
				};
				setCollectionAssetBGImage(bg);
				setCollectionAssetType('image');
			}

			if (itemData) {
				//TODO-MJ: Bug will show error when the contract address is not valid
				getNFTCollectionData(itemData).then();
			}
		}
	}, []);

	const getItemClaim = useCallback(async () => {
		if (!itemId) {
			return;
		}
		const response = await getUserSpaceItemClaim({
			user_id: authUserInfo.id,
			space_id: spaceInfo.id,
			item_id: itemId
		});
		if (response && response.result) {
			setClaimed(true);
		}
	}, [authUserInfo, spaceInfo, itemId]);

	const checkOwnerIfInCollection = useCallback(
		async (owner_contract_address, owner_email = null) => {
			let collection_contract_address = item.collection_contract_address;

			//WE use test data on dev environment
			if (NODE_ENV === 'development') collection_contract_address = test_contract_address;

			alchemy.nft.getNftsForOwner(owner_contract_address).then((response) => {
				if (response) {
					checkAddressIfInCollection(
						owner_contract_address,
						collection_contract_address,
						response.ownedNfts,
						owner_email
					).then();
				}
			});
		},
		[authUserInfo, spaceInfo, itemId, item]
	);

	const checkAddressIfInCollection = useCallback(
		async (owner_contract_address, collection_address, nfts, owner_email = null) => {
			let found = false;
			nfts.forEach((nft) => {
				if (nft.contract && nft.contract.address) {
					const nftContractAddressLowerCase = nft.contract.address.toLowerCase();
					const collectionAddressLowerCase = collection_address.toLowerCase();
					if (nftContractAddressLowerCase === collectionAddressLowerCase) {
						found = true;
					}
				}
			});

			setLocalStorage('temp_item_nft_is_owned', found);
			setLocalStorage('temp_item_space_username', space_username);

			if (found) {
				setLocalStorage('temp_item_wallet_owner_contract_address', owner_contract_address);
				setLocalStorage('temp_item_wallet_owner_email', owner_email);

				const isSubscription = item.enable_expiration_and_subscription
				if (isSubscription) {
					//Goes to Payment Method layout if subscription is enabled
					let paymentMethodUrl = routes.spaceItemPaymentMethod();
					const payment_fin_url =
						paymentMethodUrl.replace(':space_username', '@' + space_username) + '?id=' + itemId;
					navigate(payment_fin_url);
				} else {
					//Goes to Payment Wallet connect layout
					// const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
					let url = routes.spaceItemWalletConnect();
					//redirect to wallet connect for item when the collection is NFT and no subscription
					// if (itemDetails.collection_type === 'item') {
					// 	if (isSpaceMember) {
					// 		// Membership Ready
					// 		url = routes.spaceItemActivate();
					// 		//TODO-MJ add the error layout when connecting wallet failed
					// 	} else {
					// 		// EXISTING USER/NEW TO SPACE CLAIM NOW FLOW where User
					// 		// must own an existing NFT to claim Membership/Item NO SUBSCRIPTION
					// 		url = routes.spaceItemWalletConnect();
					// 	}
					// }
					const fin_url =
						url.replace(':space_username', '@' + space_username) + '?id=' + itemId;
					navigate(fin_url);
				}
			} else {
				const url = routes.spaceItemErrorConnect();
				const fin_url =
					url.replace(':space_username', '@' + space_username) + '?id=' + itemId;
				window.location.replace(fin_url);
			}
		},
		[space_username, itemId, item]
	);

	const claimItem = useCallback(async () => {
		// if (MembershipItemUtils.isOAuth(loading, authUserInfo)){
			if (spaceInfo) {
				setSubmitted(true);
				if (authUserInfo) {
					await web3Login(true);
				} else {
					await web3Login(false);
				}
			}
		// } else {
		// 	// window.location.replace(routes.login());
		// }

	}, [authUserInfo, spaceInfo, itemId, item]);

	const web3Login = async (loggedIn) => {
		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});
		setSubmitted(true);
		web3.eth
			.getAccounts()
			.then((accounts) => {
				magic.connect
					.requestUserInfo({ isResponseRequired: false })
					.then((user_account) => {
						let values = {
							email: user_account.email ?? 'no-email',
							user_public_address: accounts?.[0]
						};

						//WE use test data on dev environment
						if (NODE_ENV === 'development') {
							checkOwnerIfInCollection(test_public_address, values.email).then();
						} else {
							checkOwnerIfInCollection(
								values.user_public_address,
								values.email
							).then();
						}
					});
			})
			.catch((error) => {
				setSubmitted(false);
			});
	};

	const getNFTCollectionData = useCallback(async (itemData) => {
		if (itemData) {
			setFetchingCollectionData(true);
			alchemy.nft
				.getContractMetadata(itemData.collection_contract_address)
				.then((response) => {
					if (response) {
						setCollectionData(response);
						setFetchingCollectionData(false);
					}
				});
		}
	}, []);

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + space_username;
		window.location.replace(url);
		// navigate(url);
	}, [space_username]);

	const checkStatus = useCallback(async (space_id, item_id) => {
		if (!item_id) return;
		const data = {
			space_id: space_id,
			item_id: item_id
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const response = await checkMembershipItemStatus(data);
		if (response && response.result) {
			const transaction = response.stripe_transaction;
			if (transaction) {
				setStatus(transaction.status);
				setIsDisabled(transaction.status === 'active' || transaction.status === 'expired');
			}
		}
	},[authUserInfo]);

	useEffect(() => {
		if (space_username && !isItemLoaded) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					getItem(spaceInfo.id, itemId).then((r) => {});
					setSpaceName(spaceInfo.name);
					if (authUserInfo) {
						getItemClaim().then();
					}
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(space_username));
				}
				if (spaceInfoLoading === 'failed') {
					navigate(routes.createSpace());
				}
			}
		}

		if (item != null) {
			if (authUserInfo != null && !loadItemOnce) {
				setLoadItemOnce(true);
				getItem(spaceInfo.id, itemId).then((r) => {});
				checkStatus(spaceInfo.id, itemId).then(r =>{} );
			}
			if (authUserInfo != null && !isDisabled) {
				if (item.creator === authUserInfo.id) {
					setIsDisabled(true);
				}
			}
		}

		// if (itemDetails != null && authUserInfo != null && !isClaimed) {
		// 	if (itemDetails.creator === authUserInfo.id) {
		// 		setClaimed(true);
		// 	}
		// }

		// console.log('itemId: ' + itemId);
	}, [
		currentLocation.pathname,
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		isItemLoaded,
		item,
		itemId,
		loadItemOnce,
		isClaimed,
		status,
		isDisabled
	]);

	return (
		<>
           <div id="cs-space-main" className="cs-main main-theme">
                {fetchingCollectionData ? (
						<div className="loading-items w-full">
							<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
						</div>
					) : (
                        <section id="cs-space-product-item-c1" className="p-section-safeview theme">
                        
                            <div className="centerframe-grid gap-2gutter">
                                <div id="cs-product-cover-media" className="block-lg rounded-card" style={collectionAssetBGImage}>
                                   
                                    
                                {collectionAssetType &&
                                    collectionAssetType === 'video' && (
                                        <video
                                            autoPlay
                                            muted
                                            loop
                                            className="membership-video"
                                        >
                                            <source
                                                src={item.video}
                                                type="video/mp4"
                                            />
                                        </video>
                                    )}
                                  
                                </div>
                                {!collectionData ? (
                                    <div id="cs-product-info" className="block-lg">
                                        <hgroup className="stack-col">
                                            {item && item.contract_address ? (
                                                <>
                                                    <span className="collection-display-error">Collection not found.</span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="collection-display-error">No collection contract address set.</span>
                                                </>
                                            )}  
                                        </hgroup>
                                    </div>
                                ) : (
                                    <div id="cs-product-info" className="block-lg">
                                        <hgroup className="stack-col">
                                        <h2>{item ? item.name : ''}</h2>
                                            <p className="cs-product-info-summary">{item ? item.summary : ''}</p>
                                            <p className="cs-product-info-listing">
                                                {item
                                                ? item.listing_text
                                                : ''}</p>                             
                                        </hgroup>
                                        <div className="cs-product-pricing-details stack-row">
                                            {item && item.type === MEMBERSHIP_TYPES.NFT_COLLECTION ? (
                                                <div className="stack-col-base">
                                                                                                    
                                                    <h2>Invite Only</h2>
                                                    <p>NFT Required</p>
                                                </div>
                                            ) : (
                                                <div className="stack-col-base">
                                                                                                    
                                                    <h2>Free</h2>
                                                    <p>Invite Only</p>
                                                </div>
                                            )}
                                            <div className="stack-col-base">
                                                <h4><i className="fa-regular fa-square-user"></i>Membership</h4>
                                                <h4><i className="fa-regular fa-gift"></i>{item.benefits_count} Benefit{item.benefits_count > 1? 's': ''}</h4>
                                            </div>
                                        </div>
                                        <div id="cs-stack-product-actions" className="stack-row">
                                            {item && (
                                                <a href="#purchaseProduct" onClick={claimItem} className={`button-primary-gradient ${submitted || isDisabled ? 'pointer-events-none' : ''}`}><span><p>{getCommunityBtnText(item, true, false, status)}</p></span></a>
                                            )}
                                            
                                            <a href="#purchaseProduct" className="button-secondary-gradient" onClick={scrollToClick}><span><p>Details</p></span></a>
                                        </div>
                                    </div>
                                    
                                )}

                            </div>
                        </section>
                    )}
            </div>
			<section id="cs-space-product-item-c2" className="p-section-safeview theme-transparent">
                <div className="centerframe-grid gap-2gutter">

                    <div className="block-full">
                        <div className="cs-rte-output space-y-card">
                            <p>{collectionData && collectionData.openSea
										? collectionData.openSea.description
										: ''}</p>
                        </div>
                    </div>
                    <div className="block-full">
                        <div id="cs-product-benefits" className="stack-col">
                            <div className="datarow title">
                                <h3>Benefits</h3>
                                <p>This Membership provides Benefits for {' '}<a href={routes.viewUserSpace() + space_username} target="_blank"><i className="fa-solid fa-solar-system"></i> {spaceName}</a></p>
                            </div>
                            <div className="datarow">
                                <p>Monthly Access</p>
                                <p>The {spaceName} Membership is a lifetime membership. <span>No Expiry</span></p>
                            </div>
                            <div className="datarow">
                                <p>100,000 Community Points</p>
                                <p>Add 100k community points to your account and use them for unlockable benefits and limited items! <span>1 redemption</span></p>
                            </div>
                        </div>

                        <div id="cs-product-traits" className="stack-col">
                            <div className="datarow title">
                                <h3>Traits</h3>
                                <p>This Item has 3 traits</p>
                            </div>
                            <div className="datarow">
                                <p>Hidden Content</p>
                                <p>Viewable by Owner</p>
                            </div>
                            <div className="datarow">
                                <p>Properties</p>
                                <p>Status: VIP</p>
                            </div>
                            <div className="datarow">
                                <p>Boosts</p>
                                <p>+100k Community Points</p>
                            </div>
                        </div>

                        <div id="cs-product-technicals" className="stack-row-wrap">
                            <div className="datarow title">
                                <h3>Additional Details</h3>
                                <p>Space and technical details</p>
                            </div>
                            <div className="datarow">
                                <p>Connected Space</p>
                                <p>{spaceName}</p>
                            </div>
                            <div className="datarow">
                                <p>Space URL</p>
                                <p><a href={url} target="_blank">{url}</a></p>
                            </div>
                            <div className="datarow">
                                <p>Account Limit</p>
                                {item && (
                                <p>{item.max_per_account}</p>
                                )}
                            </div>
                            <div className="datarow">
                                <p>Transferable</p>
                                <p>No, By NFT Ownership</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			{/* <div className="container join-space">
				<>
					{fetchingCollectionData ? (
						<div className="loading-items w-full">
							<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
						</div>
					) : (
						<>
							{!collectionData ? (
								<div className="text-center pt-5">
									{itemDetails && itemDetails.contract_address ? (
										<>
											<span className="collection-display-error">Collection not found.</span>
										</>
									) : (
										<>
											<span className="collection-display-error">No collection contract address set.</span>
										</>
									)}
								</div>
							) : (
								<>
									<div className="grid lg:grid-cols-12 gap-4 py-5">
										<div className="col-span-12 lg:col-span-6 flex justify-center lg:items-start pt-5">
											<div
												className="asset-preview"
												style={collectionAssetBGImage}
											>
												{collectionAssetType &&
													collectionAssetType === 'video' && (
														<video
															autoPlay
															muted
															loop
															className="membership-video"
														>
															<source
																src={itemDetails.video}
																type="video/mp4"
															/>
														</video>
													)}
											</div>
										</div>
										<div className="membership-details col-span-12 lg:col-span-6 grid items-center content-center">
											<div className="mt-4 lg:mt-0 w-full">
												<h2 className="header-large">
													{itemDetails ? itemDetails.name : ''}
												</h2>
											</div>
											<div className="mt-4 lg:mt-0 w-full">
												<p className="body-text--big">
													{itemDetails ? itemDetails.listing_text : ''}
												</p>
											</div>
											<div className="mt-4 lg:mt-0 w-full">
												<i className="body-text--big">
													{itemDetails ? itemDetails.summary : ''}
												</i>
											</div>
											<div className="mt-4 lg:mt-0 w-full">
												<div className="grid lg:grid-cols-6 md:grid-cols-1 gap-4">
													<div className="space-buy-details lg:col-span-3">
														<h5 className="header-small">{getDetailsPrice(itemDetails)}</h5>
														<p className="body-txtitalic--big color-light">
															{getDetailsMonthlyPrice(false, itemDetails)}
														</p>
														{itemDetails && (
															<>
																<Button
																	className="btn btn-md btn-primary mt-5"
																	disabled={submitted || isDisabled}
																	onClick={claimItem}
																>
																	{submitted && (
																		<i className="fa-solid fa-spinner fa-spin me-3"></i>
																	)}
																	{getCommunityBtnText(itemDetails, true, false, status)}
																</Button>
															</>
														)}
													</div>
													<div className="space-buy-details lg:col-span-3">
														<h5 className="header-small">
															<i className="fa-regular fa-cubes"/>{' '}
															{getDetailsGift(false, itemDetails)}
														</h5>

														{itemDetails.benefits_count > 0 &&
															<h5 className="header-small">
																<i className="fa-regular fa-gift"/>{' '}
																{itemDetails.benefits_count} Benefit{itemDetails.benefits_count > 1? 's': ''}
															</h5>
														}
														<a className="btn btn-md btn-secondary mt-5" onClick={scrollToClick}>
															Items Benefit
														</a>
													</div>
												</div>
												<ul className="list-socials pt-4">
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-twitter isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-instagram isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-facebook isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-tiktok isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-youtube isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-linkedin isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-discord isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-twitch isDisabled"></i>
														</li>
													)}
													{spaceInfo.website && (
														<li>
															<a href={spaceInfo.website} target="_blank">
																<i className="fa-regular fa-globe"></i>
															</a>
														</li>
													)}
												</ul>
											</div>


										</div>
									</div>
								</>
							)}
						</>
					)}
				</>
			</div> */}
			{/* <div className="w-full join-space-details my-5 pt-5">
				<div className="container">
					{!fetchingCollectionData && (
						<>
							<div id="details-description">
								<p className="body-text--reg">
									{collectionData && collectionData.openSea
										? collectionData.openSea.description
										: ''}
									
								</p>
							</div>
							
						</>
					)}
				</div>
			</div> */}
		</>
	);
};

export default memo(SpaceClaimItem);
