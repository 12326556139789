import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './states/store';
import './input.css'; // Import the generated Tailwind CSS file
import './assets/fontawesome/css/all.css';
import './scss/style.scss';
import App from './App';
import {ConfigProvider} from "antd";

const WrappedApp = () => {
	return (
		<Provider store={store}>
			<Router>
				<ConfigProvider theme={{ token: {
						fontSize: 'unset',
						fontFamily: 'unset',
						lineHeight: 'unset',
					}, hashed: false }}>
					<App />
				</ConfigProvider>
			</Router>
		</Provider>
	);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<WrappedApp />);
