import { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import routes from '../../../routes';
import { displayDataContent } from '../../../utils/spaceUpdateDataView';
import ReactPlayer from "react-player/lazy";
import default_bg from "../../../assets/images/form_background.png";


const LatestUpdates = (parentProps) => {
	const { data, user, updatesViewingUserIsMember, updateGlobalActiveTab, spaceData } =
		parentProps;
	const [spaceUsername, setSpaceUsername] = useState(null);

	const handleActiveTabChange = () => {
		updateGlobalActiveTab('update_content');
	};

	const redirectLink = (spaceUsername, data) => {
		return routes
			.spaceViewSpaceUpdate()
			.replace(':space_username', '@' + spaceUsername)
			.replace(':update_id', data.id);
	}

	const CategoryRow = (item) => {
		return (
			<>
				<p className="update-tag color-lightest">{item.category.name}</p>
			</>
		);
	};

	useEffect(() => {
		const path = window.location.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let directory = path.replace('/@', '');
			const split = directory.split('/');
			const username = split[0];
			setSpaceUsername(username);
		}
	}, [spaceUsername, data, updatesViewingUserIsMember]);

	return (
		<article className={`cs-excerpt-update block-md stack-y`}>
			{data && data.id ? (
				<>
					<a>
						{(data && data.cover_video) ?
							<ReactPlayer
								playing={true}
								loop={true}
								controls={false}
								muted={true}
								width="100%"
								height="100%"
								className="videoPreview"
								url={data.cover_video}
							/>
							:
							<img className="card " alt="Description of image" src={(data.cover_photo_medium ? data.cover_photo_medium : default_bg)} />
						}
						{/* </a> */}
					</a>

					<div className="cs-stack-subtitle-published stack-row ">
						<time>{moment(data.created_on).format('LL')}</time>
						{data.categories && data.categories.map((category, index) => (
							<a key={index} href="#" className="chip-gradient">{category.name}</a>
						))
						}
					</div>
					<hgroup>
						<h4>
							<a href="#" target="_self">
								{((data.title && data.title !== '') ? data.title : <br></br>)}
							</a>
						</h4>
						<p>
							{ (data && data.preview_text && data.preview_text !== '') && (
								parse(data.preview_text)
							)}
						</p>
					</hgroup>
					<div className="cs-stack-fill ">
						{!user ? (
							<a className="button-secondary-gradient" href={routes.login()}>
								<span>
									<p>
										<i className="fa-regular fa-lock"/>
										Login to Unlock
									</p>
								</span>
							</a>
						) : (
							<>
								{data.visibility === 'members' ? (
									<>
										{updatesViewingUserIsMember ? (
											<a
												className="button-secondary-gradient"
												onClick={() => {
													handleActiveTabChange();
												}}
												href={redirectLink(spaceUsername, data)}
											>
												<span>
													<p>
														Read More
													</p>
												</span>
											</a>
										) : (
											<a
												className="button-secondary-gradient disabled"
											>
												<span>
													<p>
														<i className="fa-solid fa-lock"/> Members Only
													</p>
												</span>
											</a>
										)}
									</>
								) : (
									<a
										className="button-secondary-gradient"
										onClick={() => {
											handleActiveTabChange();
										}}
										href={redirectLink(spaceUsername, data)}
									>
										<span>
											<p>
												Read More
											</p>
										</span>
									</a>
								)}
							</>
						)}
					</div>
				</>
				) : (
					<>
						{spaceData && spaceData.viewing_user_is_owner && (
							<div className="card-updates space-placeholder flex items-center justify-center">
								<h6 className="header-title text-center">
									Create Latest Updates <br />
									from your Dashboard
								</h6>
							</div>
						)}
					</>
				)
			}
		</article>
	);
};

export default memo(LatestUpdates);
