import React, { memo } from 'react';
const SocialLinks = (parentProps) => {
    const { socialLinks, parentClassName, childClassName, removeATag = false } = parentProps;

    const setSocialLinkIcon = (social_link_type) => {
        let iconClass = 'hide'

        if (social_link_type === 'website')
            iconClass = "fa-regular fa-globe";
        if (social_link_type === 'instagram')
            iconClass = "fa-brands fa-instagram";
        if (social_link_type === 'youtube')
            iconClass = "fa-brands fa-youtube";
        if (social_link_type === 'tiktok')
            iconClass = "fa-brands fa-tiktok";
        if (social_link_type === 'twitch')
            iconClass = "fa-brands fa-twitch";
        if (social_link_type === 'facebook')
            iconClass = "fa-brands fa-facebook-f";
        if (social_link_type === 'linkedin')
            iconClass = "fa-brands fa-linkedin-in";
        if (social_link_type === 'x')
            iconClass = "fa-brands fa-x-twitter";

        return iconClass
    };

    const checkSocialLink = (social_link_type) => {
        let active = ''
        if (socialLinks && socialLinks.length > 0) {
            socialLinks.map((socialLink)=>{
                if (socialLink.social_type === social_link_type && socialLink.social_link && socialLink.social_link?.trim() !== null)
                    active = ' active'
            })
        }
        return active
    };

    return (
        <div className={parentClassName}>
            {removeATag ? (
                <>
                    <i className={"fa-regular fa-globe" + checkSocialLink('website')}/>
                    <i className={"fa-brands fa-instagram" + checkSocialLink('instagram')}/>
                    <i className={"fa-brands fa-youtube" + checkSocialLink('youtube')}/>
                    <i className={"fa-brands fa-tiktok" + checkSocialLink('tiktok')}/>
                    <i className={"fa-brands fa-twitch" + checkSocialLink('twitch')}/>
                    <i className={"fa-brands fa-facebook-f" + checkSocialLink('facebook')}/>
                    <i className={"fa-brands fa-linkedin-in" + checkSocialLink('linkedin')}/>
                    <i className={"fa-brands fa-x-twitter" + checkSocialLink('x')}/>
                </>
            ) : (
                socialLinks.map((socialLink, index)=>
                    (
                        (socialLink.social_link && socialLink.social_link?.trim() !== null) && (
                            <a
                                key={index}
                                className={childClassName}
                                href={socialLink.social_link}
                            >
                                <span>
                                    <p>
                                        <i className={setSocialLinkIcon(socialLink.social_type)}/>
                                    </p>
                                </span>
                            </a>
                        )
                    )
                )
            )}
        </div>
    )
}

export default memo(SocialLinks);