import { memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import { SPACE_VISIBILITY, SPACE_SIGNUP_METHOD } from '../../../constants/space';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import { updateSpaceVisibility } from '../../../services/space';
import SpaceProfilePassword from '../../../components/commons/Modals/SpaceProfilePassword';
import SpaceProfileInvite from '../../../components/commons/Modals/SpaceProfileInvite';
import SpaceProfileOfflineMessage from '../../../components/commons/Modals/SpaceProfileOfflineMessage';
import { displayPageContentLoader, hidePageContentLoader } from '../../../states/pageContentLoader';
import { useDispatch } from 'react-redux';

const ManageSpace = (props) => {
	const { spaceInfo, authUserInfo } = props;
	const dispatch = useDispatch();
	const [selectedSpaceVisibility, setSelectedSpaceVisibility] = useState('public');
	const [selectedSignUp, setSelectedSignUp] = useState('all_sign');
	const [passwordModalOpen, setPasswordModalOpen] = useState(false);
	const [inviteModalOpen, setInviteModalOpen] = useState(false);
	const [offlineMessageModalOpen, setOfflineMessageModalOpen] = useState(false);

	const redirectToManageSpaceLink = useCallback(
		(route, section = null) => {
			if (spaceInfo) {
				if (!section) {
					return route.replace(':space_username', '@' + spaceInfo.username);
				}
				return route.replace(':space_username', '@' + spaceInfo.username) + '#' + section;
			}
			return '';
		},
		[spaceInfo]
	);

	const redirectToInformationPages = useCallback(() => {
		if (spaceInfo) {
			const route = routes.spaceManageInfoPages();
			return route.replace(':space_username', '@' + spaceInfo.username);
		}
		return '';
	}, [spaceInfo]);

	const handleSpaceVisibilityChange = useCallback(
		(value) => {
			setSelectedSpaceVisibility(value);
			updateSpace(value).then((r) => {});
		},
		[spaceInfo, authUserInfo]
	);

	const updateSpace = useCallback(
		async (value) => {
			if (spaceInfo && authUserInfo) {
				const data = {
					user_id: authUserInfo.id,
					space_id: spaceInfo.id,
					visibility: value
				};

				await updateSpaceVisibility(data);
			}
		},
		[spaceInfo, authUserInfo]
	);

	useEffect(() => {
		if (spaceInfo) {
			dispatch(hidePageContentLoader());
			setSelectedSpaceVisibility(spaceInfo.visibility);
		} else {
			dispatch(displayPageContentLoader());
		}
	}, [spaceInfo, authUserInfo]);

	return (
		<div className="block-full card">
			<div className="memberships">
				<div className="dashboard-wrapper">
					<div className="grid grid-cols-12">
						<div className="col-span-12">
							<h3 className="header-medium">Manage Space</h3>
						</div>
					</div>
					<div className="grid grid-cols-12 mt-5">
						<div className="col-span-12">
							<p className="body-txtitalic--small m-0">
								You can manage the features, options, content and style of your{' '}
								{/*<a className="gradient-color-txt">Space</a>*/}
								{getSitewideLink('space_manage_space')} here. For an overview on,
								you can browse the{' '}
								{/*<a className="gradient-color-txt">Space Creation Guide</a> */}
								{getSitewideLink('space_creation_guide')} or{' '}
								{/*<a className="gradient-color-txt">*/}
								{/*	learn more about managing your Space.*/}
								{/*</a>*/}
								{getSitewideLink('learn_more_manage_space')}
							</p>
						</div>
					</div>
				</div>
				<div className="dashboard-wrapper mt-5">
					<div className="grid grid-cols-2 gap-6 text-center md:text-end ">
						<div className="col-span-2 md:col-span-1 md:text-start">
							<h3 className="header-small">Space Basics</h3>
						</div>
						<div className="col-span-2 md:col-span-1">
							<p className="body-text--small  md:text-end m-0">
								Manage your homepage and general content.
							</p>
						</div>
					</div>
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className='flex-1'>
							<Link
								className="btn btn-secondary btn-md"
								to={redirectToManageSpaceLink(routes.spaceManageProfile())}
							>
								Manage Space Profile
							</Link>
						</div>
						<div  className='flex-1'>
							<p className="body-txtitalic--smallest md:text-start m-0">
								Edit and update your Space Profile Details here, including your
								Space Name, Username, Social Links, and Profile Image/Backgrounds.
							</p>
						</div>
					</div>
					<div className="mt-4 gap-6 flex flex-col-reverse md:flex-row text-center md:text-end">
						<div className='flex-1'>
							<Link
								className="btn btn-secondary btn-md"
								to={redirectToManageSpaceLink(routes.spaceManageHomePage())}
							>
								Manage Homepage
							</Link>
						</div>
						<div className='flex-1'>
							<p className="body-txtitalic--smallest md:text-start m-0">
								Manage and edit your Welcome Section, Links Section, and other
								Content Blocks on the homepage of your Space.
							</p>
						</div>
					</div>
					<div className="mt-4 gap-6 flex flex-col-reverse md:flex-row text-center md:text-start">
						<div className="flex-1">
							<Link
								className="btn btn-secondary btn-md"
								to={redirectToInformationPages(routes.spaceManageInfoPages())}
							>
								Edit Information Pages
							</Link>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Edit and update your Information Pages, such as your About, FAQ,
								Privacy, and Terms of Service pages.
							</p>
						</div>
					</div>
				</div>
				<div className="dashboard-wrapper mt-5">
					<div className="grid grid-cols-2 gap-6 text-center md:text-end">
						<div className="col-span-2 md:col-span-1 md:text-start">
							<h3 className="header-small">Community Features</h3>
						</div>
						<div className="col-span-2 md:col-span-1">
							<p className="body-text--small md:text-end m-0">
								Enable and customize your Community experience.
							</p>
						</div>
					</div>
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className="flex-1">
							{/*<Button className="btn btn-secondary btn-md">*/}
							{/*	<i className="fa-regular fa-comments me-2"></i> Channels*/}
							{/*</Button>*/}
							<Link
								className="btn btn-secondary btn-md"
								to={redirectToManageSpaceLink(routes.spaceManageChannels())}
							>
								<i className="fa-regular fa-comments me-2"/>
								Channels
							</Link>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Community Channels is a place where Members can post and chat with
								each other. It’s similar to a Facebook Group but with customizable
								topic channels like Discord or Slack.{' '}
								{/*<a className="gradient-color-txt">Learn more.</a>*/}
								{getSitewideLink('learn_more_channels_manage_space')}
							</p>
						</div>
					</div>
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className="flex-1">
							<Button className="btn btn-secondary btn-md">
								<i className="fa-regular fa-star me-2"></i> Points
							</Button>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Community Points reward Members based on their activity in your
								Space. Once earned, you can create ways for Members to spend them to
								unlock Benefits or get Items.{' '}
								{/*<a className="gradient-color-txt">Learn more.</a>*/}
								{getSitewideLink('learn_more_points_manage_space')}
							</p>
						</div>
					</div>
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className="flex-1">
							<Button className="btn btn-secondary btn-md">
								<i className="fa-regular fa-ranking-star me-2"></i> Leaderboards
							</Button>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Member Leaderboards are a Ranking of every Member based on their
								activated Memberships, Items, and lifetime earned Community Points.
								You can also use Rankings to create Unlockable Benefits.{' '}
								{/*<a className="gradient-color-txt">Learn more.</a>*/}
								{getSitewideLink('learn_more_leaderboards_manage_space')}
							</p>
						</div>
					</div>
				</div>
				{spaceInfo && spaceInfo.plan === 'pro' && (
					<div className="dashboard-wrapper mt-5">
						<div className="grid grid-cols-2 gap-6 text-center md:text-end">
							<div className="col-span-2 md:col-span-1 md:text-start">
								<h3 className="header-small">Pro Customization</h3>
							</div>
							<div className="col-span-2 md:col-span-1">
								<p className="body-txtitalic--small md:text-end m-0">
									{/*<a className="gradient-color-txt">Manage Pro Plan</a>*/}
									{getSitewideLink('manage_pro_plan')}
								</p>
							</div>
						</div>
						<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
							<div className="flex-1">
								<Link
									className="btn btn-secondary btn-md"
									to={redirectToManageSpaceLink(
										routes.spaceManageCustomizeSpace(),
										'logo-identity'
									)}
								>
									Logo & Identity
								</Link>
							</div>
							<div className="flex-1">
								<p className="body-txtitalic--smallest md:text-start m-0">
									Customize your Logo, Fonts, and Key Colors. This will apply to
									your entire Space.
								</p>
							</div>
						</div>
						<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
							<div className="flex-1">
								<Link
									className="btn btn-secondary btn-md"
									to={redirectToManageSpaceLink(
										routes.spaceManageCustomizeSpace(),
										'homepage'
									)}
								>
									Homepage
								</Link>
							</div>
							<div className="flex-1">
								<p className="body-txtitalic--smallest md:text-start m-0">
									Customize your Homepage layout, colors, backgrounds, and title
									content. This will only apply to your homepage.
								</p>
							</div>
						</div>
						<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
							<div className="flex-1">
								<Link
									className="btn btn-secondary btn-md"
									to={redirectToManageSpaceLink(
										routes.spaceManageCustomizeSpace(),
										'sections'
									)}
								>
									Sections
								</Link>
							</div>
							<div className="flex-1">
								<p className="body-txtitalic--smallest md:text-start m-0">
									Customize your Light and Dark Section colors and backgrounds.
									This will apply as the default for all pages.
								</p>
							</div>
						</div>
						<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
							<div className="flex-1">
								<Link
									className="btn btn-secondary btn-md"
									to={redirectToManageSpaceLink(
										routes.spaceManageCustomizeNav(),
										'navigations'
									)}
								>
									Navigation
								</Link>
							</div>
							<div className="flex-1">
								<p className="body-txtitalic--smallest md:text-start m-0">
									Customize your primary and footer navigation links.
								</p>
							</div>
						</div>
						<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
							<div className="flex-1">
								<Link
									className="btn btn-secondary btn-md"
									to={redirectToManageSpaceLink(
										routes.spaceManageCustomizeNav(),
										'domain'
									)}
								>
									Domain
								</Link>
							</div>
							<div className="flex-1">
								<p className="body-txtitalic--smallest md:text-start m-0">
									Set up and manage your domain settings. You can connect your
									Space to any domain or sub-domain.
								</p>
							</div>
						</div>
					</div>
				)}
				<div className="dashboard-wrapper mt-5">
					<div className="grid grid-cols-2 gap-6 text-center md:text-end">
						<div className="col-span-2 md:col-span-1 md:text-start">
							<h3 className="header-small">Space Settings</h3>
						</div>
						<div className="col-span-2 md:col-span-1">
							<p className="body-text--small md:text-end m-0">
								Manage your general Space settings.
							</p>
						</div>
					</div>
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className="flex-1">
							<DropdownWithItems
								title="Update Visibility"
								dropDownClass="dropdown-bg"
								customButtonClass="btn-md btn-secondary mt-2"
								displayError={selectedSpaceVisibility === 'default'}
								selectedValue={selectedSpaceVisibility}
								listItems={SPACE_VISIBILITY}
								onDropdownChange={(value) => handleSpaceVisibilityChange(value)}
							/>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Make your Space viewable to the Public, Password Protected, Invite
								Only, or Offline/Maintenance. Learn more about Space Visibility.
							</p>
						</div>
					</div>
					{selectedSpaceVisibility === 'password_protected' && (
						<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
							<div className="flex-1">
								<Link
									onClick={() => setPasswordModalOpen(true)}
									className="btn btn-secondary btn-md"
								>
									Create/Edit Password
								</Link>
								<SpaceProfilePassword
									spaceInfo={spaceInfo}
									authUserInfo={authUserInfo}
									modalOpen={passwordModalOpen}
									setModalOpen={setPasswordModalOpen}
								/>
							</div>
							<div className="flex-1">
								<p className="body-txtitalic--smallest md:text-start m-0">
									Set your Password for Visitors to access your Space. This will
									only apply to visitors who are not logged in. When accessing
									your Space, visitors will have the option to Log In or enter
									this password to view the Space.
								</p>
							</div>
						</div>
					)}
					{selectedSpaceVisibility === 'invite_only' && (
						<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
							<div className="flex-1">
								<Link
									onClick={() => setInviteModalOpen(true)}
									className="btn btn-secondary btn-md"
								>
									Manage Invite
								</Link>
								<SpaceProfileInvite
									modalOpen={inviteModalOpen}
									setModalOpen={setInviteModalOpen}
									spaceId={spaceInfo.id}
									userId={authUserInfo.id}
								/>
							</div>
							<div className="flex-1">
								<p className="body-txtitalic--smallest md:text-start m-0">
									You can set a message to display while your site is offline to
									explain to visitors the reason and share other details.
								</p>
							</div>
						</div>
					)}
					{selectedSpaceVisibility === 'offline_maintenance' && (
						<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
							<div className="flex-1">
								<Link
									onClick={() => setOfflineMessageModalOpen(true)}
									className="btn btn-secondary btn-md"
								>
									Manage Offline Message
								</Link>
								<SpaceProfileOfflineMessage
									spaceInfo={spaceInfo}
									authUserInfo={authUserInfo}
									modalOpen={offlineMessageModalOpen}
									setModalOpen={setOfflineMessageModalOpen}
								/>
							</div>
							<div className="flex-1">
								<p className="body-txtitalic--smallest md:text-start m-0">
									You can set a message to display while your site is offline to
									explain to visitors the reason and share other details.
								</p>
							</div>
						</div>
					)}
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className="flex-1">
							<DropdownWithItems
								title="Update Visibility"
								dropDownClass="dropdown-bg"
								customButtonClass="btn-md btn-secondary mt-2"
								displayError={selectedSignUp === 'default'}
								selectedValue={selectedSignUp}
								listItems={SPACE_SIGNUP_METHOD}
								onDropdownChange={(value) => setSelectedSignUp(value)}
							/>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Members can sign up and login using email, wallets, SSO, or all
								methods. {/*<a className="gradient-color-txt">*/}
								{/*	{' '}*/}
								{/*	Learn more about Sign-On Methods.*/}
								{/*</a>*/}
								{getSitewideLink('all_sign_on_methods')}
							</p>
						</div>
					</div>
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className="flex-1">
							<Button className="btn btn-secondary btn-md">Security</Button>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Set up and manage your domain settings. You can connect your Space
								to any domain or sub-domain.
							</p>
						</div>
					</div>
				</div>
				<div className="dashboard-wrapper mt-5">
					<div className="grid grid-cols-2 gap-6 text-center md:text-end">
						<div className="col-span-2 md:col-span-1 md:text-start">
							<h3 className="header-small">Communications</h3>
						</div>
						<div className="col-span-2 md:col-span-1 md:text-start">
							<p className="body-txtitalic--small md:text-end m-0">
								Edit your communication styles and settings.
							</p>
						</div>
					</div>
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className="flex-1">
							<Button className="btn btn-secondary btn-md">Email Styles</Button>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Customize your Email styles for Updates, Member, and all other
								notification emails.
							</p>
						</div>
					</div>
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className="flex-1">
							<Button className="btn btn-secondary btn-md">Email Settings</Button>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Customize your settings for what events generate emails to Members.
							</p>
						</div>
					</div>
					<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">
						<div className="flex-1">
							<Button className="btn btn-secondary btn-md">Notifications</Button>
						</div>
						<div className="flex-1">
							<p className="body-txtitalic--smallest md:text-start m-0">
								Customize other notification settings, such as SMS and Activity
								events.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(ManageSpace);
