import { memo, useEffect } from 'react';
import DashboardContent from './DashboardContent';

import './SpaceDashboard.scss';
import {useSelector} from "react-redux";

const SpaceDashboard = () => {
	const { authUserInfo, loading } = useSelector((state) => state.general);

	useEffect(() => {
		document.title = 'Commonspace Space Dashboard';
	}, []);

	return (
		<main id="cs-space-main" className="cs-main main-theme">
			<section id="cs-profile-dash-dashboard-c1" className="px-section-safeview pb-section-safeview pt-gutter theme-transparent">
				{authUserInfo && (
					<DashboardContent />
				)}
			</section>
		</main>
	);
};

export default memo(SpaceDashboard);
