import { memo, useEffect, useCallback, useState, useRef } from 'react';
import { Button, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { updateSpaceProNavigation } from '../../../services/space';
import { hideTooltip, showTooltip } from '../../../utils/input';
import { CURRENT_LINK_OPTIONS } from '../../../constants/space';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import { getSitewideLink } from '../../../utils/sitewideLinks';

const CustomizeProSpace = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [initialLoad, setInitialLoad] = useState(true);
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [headerNavigationLink1Id, setHeaderNavigationLink1Id] = useState(null);
	const [headerNavigationLink2Id, setHeaderNavigationLink2Id] = useState(null);
	const [headerNavigationLink3Id, setHeaderNavigationLink3Id] = useState(null);
	const [headerNavigationLink4Id, setHeaderNavigationLink4Id] = useState(null);
	const [headerNavigationLink1, setHeaderNavigationLink1] = useState('');
	const [headerNavigationLink2, setHeaderNavigationLink2] = useState('');
	const [headerNavigationLink3, setHeaderNavigationLink3] = useState('');
	const [headerNavigationLink4, setHeaderNavigationLink4] = useState('');
	const [headerNavigationLink1Url, setHeaderNavigationLink1Url] = useState('');
	const [headerNavigationLink2Url, setHeaderNavigationLink2Url] = useState('');
	const [headerNavigationLink3Url, setHeaderNavigationLink3Url] = useState('');
	const [headerNavigationLink4Url, setHeaderNavigationLink4Url] = useState('');
	const [footerNavigationListTitle, setFooterNavigationListTitle] = useState('');
	const [footerNavigationLink1Id, setFooterNavigationLink1Id] = useState(null);
	const [footerNavigationLink2Id, setFooterNavigationLink2Id] = useState(null);
	const [footerNavigationLink3Id, setFooterNavigationLink3Id] = useState(null);
	const [footerNavigationLink4Id, setFooterNavigationLink4Id] = useState(null);
	const [footerNavigationLink5Id, setFooterNavigationLink5Id] = useState(null);
	const [footerNavigationLink6Id, setFooterNavigationLink6Id] = useState(null);
	const [footerNavigationLink1, setFooterNavigationLink1] = useState('');
	const [footerNavigationLink2, setFooterNavigationLink2] = useState('');
	const [footerNavigationLink3, setFooterNavigationLink3] = useState('');
	const [footerNavigationLink4, setFooterNavigationLink4] = useState('');
	const [footerNavigationLink5, setFooterNavigationLink5] = useState('');
	const [footerNavigationLink6, setFooterNavigationLink6] = useState('');
	const [footerNavigationLink1Url, setFooterNavigationLink1Url] = useState('');
	const [footerNavigationLink2Url, setFooterNavigationLink2Url] = useState('');
	const [footerNavigationLink3Url, setFooterNavigationLink3Url] = useState('');
	const [footerNavigationLink4Url, setFooterNavigationLink4Url] = useState('');
	const [footerNavigationLink5Url, setFooterNavigationLink5Url] = useState('');
	const [footerNavigationLink6Url, setFooterNavigationLink6Url] = useState('');

	const headerNavigationLink1Ref = useRef(null);
	const headerNavigationLink2Ref = useRef(null);
	const headerNavigationLink3Ref = useRef(null);
	const headerNavigationLink4Ref = useRef(null);
	const headerNavigationLink1UrlRef = useRef(null);
	const headerNavigationLink2UrlRef = useRef(null);
	const headerNavigationLink3UrlRef = useRef(null);
	const headerNavigationLink4UrlRef = useRef(null);
	const footerNavigationListTitleRef = useRef(null);
	const footerNavigationLink1Ref = useRef(null);
	const footerNavigationLink2Ref = useRef(null);
	const footerNavigationLink3Ref = useRef(null);
	const footerNavigationLink4Ref = useRef(null);
	const footerNavigationLink5Ref = useRef(null);
	const footerNavigationLink6Ref = useRef(null);
	const footerNavigationLink1UrlRef = useRef(null);
	const footerNavigationLink2UrlRef = useRef(null);
	const footerNavigationLink3UrlRef = useRef(null);
	const footerNavigationLink4UrlRef = useRef(null);
	const footerNavigationLink5UrlRef = useRef(null);
	const footerNavigationLink6UrlRef = useRef(null);

	const [selectedHeaderLink1OpenType, setSelectedHeaderLink1OpenType] =
		useState('open_new_window');
	const [selectedHeaderLink2OpenType, setSelectedHeaderLink2OpenType] =
		useState('open_new_window');
	const [selectedHeaderLink3OpenType, setSelectedHeaderLink3OpenType] =
		useState('open_new_window');
	const [selectedHeaderLink4OpenType, setSelectedHeaderLink4OpenType] =
		useState('open_new_window');
	const [selectedFooterLink1OpenType, setSelectedFooterLink1OpenType] =
		useState('open_new_window');
	const [selectedFooterLink2OpenType, setSelectedFooterLink2OpenType] =
		useState('open_new_window');
	const [selectedFooterLink3OpenType, setSelectedFooterLink3OpenType] =
		useState('open_new_window');
	const [selectedFooterLink4OpenType, setSelectedFooterLink4OpenType] =
		useState('open_new_window');
	const [selectedFooterLink5OpenType, setSelectedFooterLink5OpenType] =
		useState('open_new_window');
	const [selectedFooterLink6OpenType, setSelectedFooterLink6OpenType] =
		useState('open_new_window');

	const saveContinue = () => {
		const url = routes.createSpaceLaunch();
		navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createCustomizeProSpace();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		if (createdSpace) {
			if (createdSpace.plan === 'pro') {
				if (createdSpace.pro_style) {
					setFooterNavigationListTitle(createdSpace.pro_style.navigation_list_title);
				}
				if (createdSpace.pro_navigations) {
					const navs = createdSpace.pro_navigations;
					const header_navs = [];
					const footer_navs = [];

					navs.forEach((nav) => {
						if (nav['section'] === 'header') {
							header_navs.push(nav);
						}
						if (nav['section'] === 'footer') {
							footer_navs.push(nav);
						}
					});

					header_navs.forEach((nav, index) => {
						if (index === 0) {
							setHeaderNavigationLink1Id(nav.id);
							setHeaderNavigationLink1(nav.label);
							setHeaderNavigationLink1Url(nav.url);
							setSelectedHeaderLink1OpenType(nav.open_type);
						}
						if (index === 1) {
							setHeaderNavigationLink2Id(nav.id);
							setHeaderNavigationLink2(nav.label);
							setHeaderNavigationLink2Url(nav.url);
							setSelectedHeaderLink2OpenType(nav.open_type);
						}
						if (index === 2) {
							setHeaderNavigationLink3Id(nav.id);
							setHeaderNavigationLink3(nav.label);
							setHeaderNavigationLink3Url(nav.url);
							setSelectedHeaderLink3OpenType(nav.open_type);
						}
						if (index === 3) {
							setHeaderNavigationLink4Id(nav.id);
							setHeaderNavigationLink4(nav.label);
							setHeaderNavigationLink4Url(nav.url);
							setSelectedHeaderLink4OpenType(nav.open_type);
						}
					});

					footer_navs.forEach((nav, index) => {
						if (index === 0) {
							setFooterNavigationLink1Id(nav.id);
							setFooterNavigationLink1(nav.label);
							setFooterNavigationLink1Url(nav.url);
							setSelectedFooterLink1OpenType(nav.open_type);
						}
						if (index === 1) {
							setFooterNavigationLink2Id(nav.id);
							setFooterNavigationLink2(nav.label);
							setFooterNavigationLink2Url(nav.url);
							setSelectedFooterLink2OpenType(nav.open_type);
						}
						if (index === 2) {
							setFooterNavigationLink3Id(nav.id);
							setFooterNavigationLink3(nav.label);
							setFooterNavigationLink3Url(nav.url);
							setSelectedFooterLink3OpenType(nav.open_type);
						}
						if (index === 3) {
							setFooterNavigationLink4Id(nav.id);
							setFooterNavigationLink4(nav.label);
							setFooterNavigationLink4Url(nav.url);
							setSelectedFooterLink4OpenType(nav.open_type);
						}
						if (index === 4) {
							setFooterNavigationLink5Id(nav.id);
							setFooterNavigationLink5(nav.label);
							setFooterNavigationLink5Url(nav.url);
							setSelectedFooterLink5OpenType(nav.open_type);
						}
						if (index === 5) {
							setFooterNavigationLink6Id(nav.id);
							setFooterNavigationLink6(nav.label);
							setFooterNavigationLink6Url(nav.url);
							setSelectedFooterLink6OpenType(nav.open_type);
						}
					});
				}
			} else {
				const url = routes.createSpaceLaunch();
				navigate(url.replace(':space_username', space_username));
			}
		}
	};

	const setNavObj = (space_id, label, url, type, open_type, nav_id = null) => {
		const obj = {
			space_id: space_id,
			label: label,
			url: url,
			section: type,
			open_type: open_type
		};
		if (nav_id) {
			obj['id'] = nav_id;
		}
		return obj;
	};

	const submitProNav = useCallback(
		async (isSaveAndGoBack) => {
			const navigations = [];

			if (headerNavigationLink1 && headerNavigationLink1Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						headerNavigationLink1,
						headerNavigationLink1Url,
						'header',

						selectedHeaderLink1OpenType,
						headerNavigationLink1Id
					)
				);
			}

			if (headerNavigationLink2 && headerNavigationLink2Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						headerNavigationLink2,
						headerNavigationLink2Url,
						'header',
						selectedHeaderLink2OpenType,
						headerNavigationLink2Id
					)
				);
			}

			if (headerNavigationLink3 && headerNavigationLink3Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						headerNavigationLink3,
						headerNavigationLink3Url,
						'header',
						selectedHeaderLink3OpenType,
						headerNavigationLink3Id
					)
				);
			}

			if (headerNavigationLink4 && headerNavigationLink4Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						headerNavigationLink4,
						headerNavigationLink4Url,
						'header',
						selectedHeaderLink4OpenType,
						headerNavigationLink4Id
					)
				);
			}

			if (footerNavigationLink1 && footerNavigationLink1Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink1,
						footerNavigationLink1Url,
						'footer',
						selectedFooterLink1OpenType,
						footerNavigationLink1Id
					)
				);
			}

			if (footerNavigationLink2 && footerNavigationLink2Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink2,
						footerNavigationLink2Url,
						'footer',
						selectedFooterLink2OpenType,
						footerNavigationLink2Id
					)
				);
			}

			if (footerNavigationLink3 && footerNavigationLink3Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink3,
						footerNavigationLink3Url,
						'footer',
						selectedFooterLink3OpenType,
						footerNavigationLink3Id
					)
				);
			}

			if (footerNavigationLink4 && footerNavigationLink4Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink4,
						footerNavigationLink4Url,
						'footer',
						selectedFooterLink4OpenType,
						footerNavigationLink4Id
					)
				);
			}

			if (footerNavigationLink5 && footerNavigationLink5Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink5,
						footerNavigationLink5Url,
						'footer',
						selectedFooterLink5OpenType,
						footerNavigationLink5Id
					)
				);
			}

			if (footerNavigationLink6 && footerNavigationLink6Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink6,
						footerNavigationLink6Url,
						'footer',
						selectedFooterLink6OpenType,
						footerNavigationLink6Id
					)
				);
			}

			const data = {
				space_id: spaceInfo.id,
				user_id: authUserInfo.id,
				navigations: JSON.stringify(navigations),
				navigation_list_title: footerNavigationListTitle
			};

			try {
				setSubmitted(true);
				const response = await updateSpaceProNavigation(data);
				if (response && response.result) {
					dispatch(getSpaceInfo(space_username));

					if (isSaveAndGoBack) {
						const url = routes.createSpaceLaunch();
						navigate(url.replace(':space_username', space_username));
					} else {
						const url = routes.createCustomizeProSpace();
						navigate(url.replace(':space_username', space_username));
					}
				} else {
					setAlert({
						variant: 'danger',
						message: response.message
					});
					window.scrollTo(0, 0);
				}
				setSubmitted(false);
			} catch (error) {
				console.log(error);
			}
		},
		[
			space_username,
			authUserInfo,
			spaceInfo,
			headerNavigationLink1Id,
			headerNavigationLink2Id,
			headerNavigationLink3Id,
			selectedHeaderLink1OpenType,
			selectedHeaderLink2OpenType,
			selectedHeaderLink3OpenType,
			selectedHeaderLink4OpenType,
			selectedFooterLink1OpenType,
			selectedFooterLink2OpenType,
			selectedFooterLink3OpenType,
			selectedFooterLink4OpenType,
			selectedFooterLink5OpenType,
			selectedFooterLink6OpenType,
			headerNavigationLink4Id,
			headerNavigationLink1,
			headerNavigationLink2,
			headerNavigationLink3,
			headerNavigationLink4,
			footerNavigationLink1Id,
			footerNavigationLink2Id,
			footerNavigationLink3Id,
			footerNavigationLink4Id,
			footerNavigationLink5Id,
			footerNavigationLink6Id,
			footerNavigationLink1,
			footerNavigationLink2,
			footerNavigationLink3,
			footerNavigationLink4,
			footerNavigationLink5,
			footerNavigationLink6,
			headerNavigationLink1Url,
			headerNavigationLink2Url,
			headerNavigationLink3Url,
			headerNavigationLink4Url,
			footerNavigationLink1Url,
			footerNavigationLink2Url,
			footerNavigationLink3Url,
			footerNavigationLink4Url,
			footerNavigationLink5Url,
			footerNavigationLink6Url,
			footerNavigationListTitle
		]
	);

	const tooltipFocused = (input) => {
		if (input === 'header_navigation_link1') {
			headerNavigationLink1Ref.current.focus();
		}
		if (input === 'header_navigation_link2') {
			headerNavigationLink2Ref.current.focus();
		}
		if (input === 'header_navigation_link3') {
			headerNavigationLink3Ref.current.focus();
		}
		if (input === 'header_navigation_link4') {
			headerNavigationLink4Ref.current.focus();
		}
		if (input === 'header_navigation_link1_url') {
			headerNavigationLink1UrlRef.current.focus();
		}
		if (input === 'header_navigation_link2_url') {
			headerNavigationLink2UrlRef.current.focus();
		}
		if (input === 'header_navigation_link3_url') {
			headerNavigationLink3UrlRef.current.focus();
		}
		if (input === 'header_navigation_link4_url') {
			headerNavigationLink4UrlRef.current.focus();
		}
		if (input === 'footer_navigation_list_title') {
			footerNavigationListTitleRef.current.focus();
		}
		if (input === 'footer_navigation_link1') {
			footerNavigationLink1Ref.current.focus();
		}
		if (input === 'footer_navigation_link2') {
			footerNavigationLink2Ref.current.focus();
		}
		if (input === 'footer_navigation_link3') {
			footerNavigationLink3Ref.current.focus();
		}
		if (input === 'footer_navigation_link4') {
			footerNavigationLink4Ref.current.focus();
		}
		if (input === 'footer_navigation_link5') {
			footerNavigationLink5Ref.current.focus();
		}
		if (input === 'footer_navigation_link6') {
			footerNavigationLink6Ref.current.focus();
		}
		if (input === 'footer_navigation_link1_url') {
			footerNavigationLink1UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link2_url') {
			footerNavigationLink2UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link3_url') {
			footerNavigationLink3UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link4_url') {
			footerNavigationLink4UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link5_url') {
			footerNavigationLink5UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link6_url') {
			footerNavigationLink6UrlRef.current.focus();
		}
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main id="cs-platform-main" className="cs-main main-theme">
				<section
					id="cs-platform-create-basics-c1"
					className="p-section-safeview theme-transparent"
				>
					<div className="centerframe-grid gap-2 gutter">
						<div className="block-full">
							<div className="cs-stack-heading">
								<div className="stack-row split">
									<h1>Customize Your Domain & Navigation</h1>
									<h3>Step 2 of 2</h3>
								</div>
								<p>
									Your{' '}
									<a href="link-via-admin" target="_blank">
										Space
									</a>{' '}
									supports a custom domain and navigation links on your Header and
									Footer. You can add up to 4 extra links on your Header
									Navigation and up to 6 extra links on your Footer Navigation.
									You can also edit the title for the link list on your Footer
									Navigation. Note that default links in both areas cannot be
									changed.{' '}
								</p>
							</div>
							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>Use your own Domain or Sub-Domain</h2>
									</div>
									<a href="#link-via-admin" className="button-secondary-gradient">
										<span>
											<p>
												View Guide{' '}
												<i className="fa-regular fa-arrow-up-right-from-square i-r"></i>
											</p>
										</span>
									</a>
								</div>
								<div className="block-full">
									<p>
										Pro Spaces are able to use a custom Domain or Sub-Domain URL
										instead of the default common.space/@username URL. You can
										use any domain name you own or also use a sub-domain for a
										domain you own such as members.domain.com. You must have
										access to the DNS Records of the domain name, which is
										usually accessible at the registrar or hosting company where
										your domain name was purchased or first set up.{' '}
										<a href="link-via-admin" target="_blank">
											Learn more about Custom Domains.
										</a>
									</p>
								</div>

								<h3>
									Custom Domain Setup is coming soon. Until then, you can complete
									a Support Request and we will help you set up your custom domain
									name with your Space.
								</h3>
							</div>
							<div className="grid grid-cols-12 gap-4 p-6 ">
								<div className="flex block-lg items-center justify-center  border-2 border-gray-600 rounded-full text-gray-600">
									Add Custom Domain in Dashboard - Coming Soon
								</div>

								<a
									href="#link-via-admin"
									className="button-secondary-gradient block-lg"
								>
									<span>
										<p>
											Request Custom Domain Setup{' '}
											<i className="fa-regular fa-arrow-up-right-from-square i-r"></i>
										</p>
									</span>
								</a>
							</div>
							<div className="formrow title">
								<div>
									<h2>Customize Your Navigation</h2>
								</div>
							</div>

							<div className="block-full">
								<p>
									Your main Header Navigation has Home, Updates, Benefits, and
									Members as the first 4 links. These cannot be changed, but you
									can add up to 4 more additional links which can lead anywhere
									you specify. Your Footer Navigation has a primary link list of
									Home, Updates, Benefits, Members, Privacy Policy, and Terms of
									Use which cannot be changed, but you can create a secondary link
									list of up to 6 additional links.{' '}
									<a href="link-via-admin" target="_blank">
										Learn more about Custom Navigation.
									</a>
								</p>
							</div>
							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h4>Header Navigation Additional Links</h4>
									</div>

									<div className="cs-formrow-helper-end">
										You can add up to 4 additional links. Be sure to keep them
										short and check how it looks before launching your Space. To
										skip, leave fields blank.
									</div>
								</div>
							</div>

							<div className="block-full gap-4 ">
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											placeholder="Up to 16 characters, including spaces."
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											onChange={(e) =>
												setHeaderNavigationLink1(e.target.value)
											}
											onClick={() =>
												tooltipFocused('header_navigation_link1')
											}
											value={headerNavigationLink1}
											ref={headerNavigationLink1Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											placeholder="Enter full URL with https://"
											className="input-lg body-text--small z-0"
											type="text"
											onChange={(e) =>
												setHeaderNavigationLink1Url(e.target.value)
											}
											onClick={() =>
												tooltipFocused('header_navigation_link1_url')
											}
											value={headerNavigationLink1Url}
											ref={headerNavigationLink1UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											// customButtonClass="btn-md secondary-gradient whitespace-pre-wrap"
											// customButtonClass="button gradient secondary distribute w-full"
											customButtonClass="button-dropdown-gradient-alt secondary"
											title="Open Link Type"
											selectedValue={selectedHeaderLink1OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) => {
												setSelectedHeaderLink1OpenType(value);
											}}
										/>
									</div>
								</div>
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											placeholder="Up to 16 characters, including spaces."
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											onChange={(e) =>
												setHeaderNavigationLink2(e.target.value)
											}
											onClick={() =>
												tooltipFocused('header_navigation_link2')
											}
											value={headerNavigationLink2}
											ref={headerNavigationLink2Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											placeholder="Enter full URL with https://"
											className="input-lg body-text--small z-0"
											type="text"
											onChange={(e) =>
												setHeaderNavigationLink2Url(e.target.value)
											}
											onClick={() =>
												tooltipFocused('header_navigation_link2_url')
											}
											value={headerNavigationLink2Url}
											ref={headerNavigationLink2UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											customButtonClass="button-dropdown-gradient-alt secondary"
											title="Open Link Type"
											selectedValue={selectedHeaderLink2OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) =>
												setSelectedHeaderLink2OpenType(value)
											}
										/>
									</div>
								</div>
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											placeholder="Up to 16 characters, including spaces."
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											onChange={(e) =>
												setHeaderNavigationLink3(e.target.value)
											}
											onClick={() =>
												tooltipFocused('header_navigation_link3')
											}
											value={headerNavigationLink3}
											ref={headerNavigationLink3Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											placeholder="Enter full URL with https://"
											className="input-lg body-text--small z-0"
											type="text"
											onChange={(e) =>
												setHeaderNavigationLink3Url(e.target.value)
											}
											onClick={() =>
												tooltipFocused('header_navigation_link3_url')
											}
											value={headerNavigationLink3Url}
											ref={headerNavigationLink3UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											customButtonClass="button-dropdown-gradient-alt secondary"
											title="Open Link Type"
											selectedValue={selectedHeaderLink3OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) =>
												setSelectedHeaderLink3OpenType(value)
											}
										/>
									</div>
								</div>
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											placeholder="Up to 16 characters, including spaces."
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											onChange={(e) =>
												setHeaderNavigationLink4(e.target.value)
											}
											onClick={() =>
												tooltipFocused('header_navigation_link4')
											}
											value={headerNavigationLink4}
											ref={headerNavigationLink4Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											placeholder="Enter full URL with https://"
											className="input-lg body-text--small z-0"
											type="text"
											onChange={(e) =>
												setHeaderNavigationLink4Url(e.target.value)
											}
											onClick={() =>
												tooltipFocused('header_navigation_link4_url')
											}
											value={headerNavigationLink4Url}
											ref={headerNavigationLink4UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											customButtonClass="button-dropdown-gradient-alt secondary"
											title="Open Link Type"
											selectedValue={selectedHeaderLink4OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) =>
												setSelectedHeaderLink4OpenType(value)
											}
										/>
									</div>
								</div>
							</div>
							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h4>Footer Navigation Additional Links</h4>
									</div>

									<div className="cs-formrow-helper-end">
										You can choose a link list title and add up to 6 additional
										links. Be sure to keep them short and check how it looks
										before launching your Space. To skip, leave fields blank.
									</div>
								</div>
							</div>
							<div className="block-full gap-4 ">
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0 border-2"
											onClick={() =>
												tooltipFocused('footer_navigation_link1')
											}
											type="text"
											placeholder="Up to 16 characters, including spaces."
											onChange={(e) =>
												setFooterNavigationLink1(e.target.value)
											}
											value={footerNavigationLink1}
											ref={footerNavigationLink1Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											placeholder="Enter full URL with https://"
											onClick={() =>
												tooltipFocused('footer_navigation_link1_url')
											}
											onChange={(e) =>
												setFooterNavigationLink1Url(e.target.value)
											}
											value={footerNavigationLink1Url}
											ref={footerNavigationLink1UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											title="Open Link Type"
											dropDownClass="dropdown-bg"
											customButtonClass="button-dropdown-gradient-alt secondary"
											selectedValue={selectedFooterLink1OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) =>
												setSelectedFooterLink1OpenType(value)
											}
										/>
									</div>
								</div>
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0 border-2"
											onClick={() =>
												tooltipFocused('footer_navigation_link2')
											}
											type="text"
											placeholder="Up to 16 characters, including spaces."
											onChange={(e) =>
												setFooterNavigationLink2(e.target.value)
											}
											value={footerNavigationLink2}
											ref={footerNavigationLink2Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											placeholder="Enter full URL with https://"
											onClick={() =>
												tooltipFocused('footer_navigation_link2_url')
											}
											onChange={(e) =>
												setFooterNavigationLink2Url(e.target.value)
											}
											value={footerNavigationLink2Url}
											ref={footerNavigationLink2UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											title="Open Link Type"
											dropDownClass="dropdown-bg"
											// customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"
											customButtonClass="button-dropdown-gradient-alt secondary"
											selectedValue={selectedFooterLink2OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) =>
												setSelectedFooterLink2OpenType(value)
											}
										/>
									</div>
								</div>
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0 border-2"
											onClick={() =>
												tooltipFocused('footer_navigation_link3')
											}
											type="text"
											placeholder="Up to 16 characters, including spaces."
											onChange={(e) =>
												setFooterNavigationLink3(e.target.value)
											}
											value={footerNavigationLink3}
											ref={footerNavigationLink3Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											placeholder="Enter full URL with https://"
											onClick={() =>
												tooltipFocused('footer_navigation_link3_url')
											}
											onChange={(e) =>
												setFooterNavigationLink3Url(e.target.value)
											}
											value={footerNavigationLink3Url}
											ref={footerNavigationLink3UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											title="Open Link Type"
											dropDownClass="dropdown-bg"
											customButtonClass="button-dropdown-gradient-alt secondary"
											selectedValue={selectedFooterLink3OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) =>
												setSelectedFooterLink3OpenType(value)
											}
										/>
									</div>
								</div>
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0 border-2"
											onClick={() =>
												tooltipFocused('footer_navigation_link4')
											}
											type="text"
											placeholder="Up to 16 characters, including spaces."
											onChange={(e) =>
												setFooterNavigationLink4(e.target.value)
											}
											value={footerNavigationLink4}
											ref={footerNavigationLink4Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											placeholder="Enter full URL with https://"
											onClick={() =>
												tooltipFocused('footer_navigation_link4_url')
											}
											onChange={(e) =>
												setFooterNavigationLink4Url(e.target.value)
											}
											value={footerNavigationLink4Url}
											ref={footerNavigationLink4UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											title="Open Link Type"
											dropDownClass="dropdown-bg"
											customButtonClass="button-dropdown-gradient-alt secondary"
											selectedValue={selectedFooterLink4OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) =>
												setSelectedFooterLink4OpenType(value)
											}
										/>
									</div>
								</div>
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0 border-2"
											onClick={() =>
												tooltipFocused('footer_navigation_link5')
											}
											type="text"
											placeholder="Up to 16 characters, including spaces."
											onChange={(e) =>
												setFooterNavigationLink5(e.target.value)
											}
											value={footerNavigationLink5}
											ref={footerNavigationLink5Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											placeholder="Enter full URL with https://"
											onClick={() =>
												tooltipFocused('footer_navigation_link5_url')
											}
											onChange={(e) =>
												setFooterNavigationLink5Url(e.target.value)
											}
											value={footerNavigationLink5Url}
											ref={footerNavigationLink5UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											title="Open Link Type"
											dropDownClass="dropdown-bg"
											customButtonClass="button-dropdown-gradient-alt secondary"
											selectedValue={selectedFooterLink5OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) =>
												setSelectedFooterLink5OpenType(value)
											}
										/>
									</div>
								</div>
								<div className="formrow">
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="name" className=" py-[10px]">
											Name
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0 border-2"
											onClick={() =>
												tooltipFocused('footer_navigation_link6')
											}
											type="text"
											placeholder="Up to 16 characters, including spaces."
											onChange={(e) =>
												setFooterNavigationLink6(e.target.value)
											}
											value={footerNavigationLink6}
											ref={footerNavigationLink6Ref}
											maxLength={16}
										/>
									</div>
									<div className="form-input rounded-full col-span-4">
										<label htmlFor="url" className=" py-[10px]">
											URL
										</label>
										<input
											onFocus={hideTooltip}
											onBlur={showTooltip}
											className="input-lg body-text--small z-0"
											type="text"
											placeholder="Enter full URL with https://"
											onClick={() =>
												tooltipFocused('footer_navigation_link6_url')
											}
											onChange={(e) =>
												setFooterNavigationLink6Url(e.target.value)
											}
											value={footerNavigationLink6Url}
											ref={footerNavigationLink6UrlRef}
										/>
									</div>

									<div className="  col-span-4">
										<DropdownWithItems
											title="Open Link Type"
											dropDownClass="dropdown-bg"
											customButtonClass="button-dropdown-gradient-alt secondary"
											selectedValue={selectedFooterLink6OpenType}
											listItems={CURRENT_LINK_OPTIONS}
											onDropdownChange={(value) =>
												setSelectedFooterLink6OpenType(value)
											}
										/>
									</div>
								</div>
								{/* <div className="grid grid-cols-12 gap-4  p-6"> */}
								{/* <button
										type="submit"
										className="primary-gradient col-span-6"					
										onClick={() => submitProNav(true)}
										disabled={submitted}
									>
										<span>
											<p>										
												<i className="fa-solid fa-spinner fa-spin"></i>{' '}
												<p>Save & Continue</p>
											</p>
										</span>
									</button> */}
								<div className="formrow ">
									<div className=" col-span-12 flex justify-center">
										<button
											className="primary-gradient w-full "
											type="submit"
											onClick={() => submitProNav(true)}
											disabled={submitted}
										>
											<span>
												<p>
													{submitted && (
														<i className="fa-solid fa-spinner fa-spin"></i>
													)}
													Save & Continue
												</p>
											</span>
										</button>
										<div class="cs-formrow-helper-none">
											<p></p>
										</div>
									</div>
									<div className=" col-span-12 flex justify-center">
										<button
											className="secondary-gradient w-full"
											onClick={() => submitProNav(false)}
											type="submit"
											disabled={submitted}
										>
											<span>
												<p>
													{submitted && (
														<i className="fa-solid fa-spinner fa-spin"></i>
													)}
													Save & Go Back
												</p>
											</span>
										</button>
										<div class="cs-formrow-helper-none">
											<p></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default memo(CustomizeProSpace);
