import { memo, useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Register from '../Register';
import { getEmailVerification } from '../../../services/user';
import './EmailVerification.scss';
import {getDataByUsername} from "../../../services/general";
import {getLocalStorage, setLocalStorage} from '../../../utils/storage';
import routes from "../../../routes";
import {setAccessToken} from "../../../utils/common";

const EmailVerification = (props) => {
	const { verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId, username, spaceViewData} = props;
	const [params] = useSearchParams();
	const token = params.get('token');
	const redirect_link = params.get('redirect_link');
	const [resultMessage, setResultMessage] = useState('');
	const [email, setEmail] = useState(null);
	const [verifying, setVerifying] = useState(true);
	const [verified, setVerified] = useState(false);
	const [isSpaceLoaded, setIsSpaceLoaded] = useState(false);
	const fetchViewData = useCallback(
		async (username) => {
			try {
				setIsSpaceLoaded(false);
				const data = {
					username: username
				};
				const response = await getDataByUsername(data);
				if (response.result) {
					spaceViewData(response.space);
				} else {

				}
				setIsSpaceLoaded(true);
			} catch (error) {
				console.log(error);
			}
		},
		[username]
	);

	useEffect(()=>{
		if (!isSpaceLoaded) {
			fetchViewData(username).then(() => {});
		}
	},[isSpaceLoaded])

	const verifyEmail = useCallback(
		async (token) => {
			try {
                
                if (redirect_link) {
                    setLocalStorage('redirect_link', redirect_link);
                } else {
                    localStorage.removeItem('redirect_link');
                }
				const is_redirect = !!getLocalStorage('redirect_link');
				setVerified(false);
				await getEmailVerification({ token, is_redirect }).then((result) => {
					if (result) {
						if (result.result) {
							setVerified(true);
							setEmail(result.email);
							if (result.access_token) {
								setAccessToken(result);
							}
							// After verification check if redirect link is not null to proceed the new account payment
							if (is_redirect) {
								window.location.replace(getLocalStorage('redirect_link'))
							}
						}
						setResultMessage(result.message);
						setTimeout(() => {
							setVerifying(false);
						}, 200);
					}
				},[is_redirect]);
			} catch (error) {
				console.log(error);
			}
		},
		[setEmail]
	);

	useEffect(() => {
		document.title = 'Commonspace Registration';
		if (verifying && !email) {
			if (token) {
				setTimeout(() => {
					// added timeout delay to allow grecaptcha to render first
					verifyEmail(token).then();
				}, 1000);
			} else {
				setResultMessage('Email verification token is required.');
				setVerifying(false);
			}
		}
	}, [token, email, verifying, verified]);

	return (
		<Register
			verifyEmailSpaceUsername={verifyEmailSpaceUsername}
			verifyEmailSpaceMembershipId={verifyEmailSpaceMembershipId}
			verifyEmailSpaceItemId={verifyEmailSpaceItemId}
			emailVerificationToken={token}
			verified={verified}
			email={email}
			verificationMessage={resultMessage}
			verifying={verifying}
		/>
	);
};

export default memo(EmailVerification);
