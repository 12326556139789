import { memo, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import space_ex1 from "../../../assets/images/space_ex1.png";
import space_ex3 from "../../../assets/images/space_ex3.png";
import space_ex2 from "../../../assets/images/space_ex2.png";
import space_ex4 from "../../../assets/images/space_ex4.png";

const CreateFirstItem = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);

	const saveContinue = () => {
		const url = routes.createSpacePostUpdate();
		navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createSpaceBasicCompletePage();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main  id="cs-platform-main" className="cs-main main-theme">
				<section id="cs-platform-create-basic-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<article className="block-full">
							<div className="cs-stack-heading">
								<div className="stack-row split">
									<h1>Create Your First Item</h1>
									<h3>Step 1 of 5</h3>
								</div>
								<p>
									{getSitewideLink(
										'first_items',
										' '
									)}{' '}
									are exactly the same as{' '}
									{getSitewideLink(
										'memberships_item',
										' '
									)}
									, except that they don’t provide the Member Access Benefit so they
									require a Membership to use and a Member can activate as many they
									own. Items can be free or paid, and provide extra Benefits Member.
									You can use Items to upsell, give special access or status to
									Members, offer Redemptions, and much more by adding{' '}
									{getSitewideLink(
										'custom_benefits',
										' '
									)}{' '}
									to them.{' '}
									{getSitewideLink(
										'learn_more_items',
										' '
									)}
									.
								</p>
							</div>
							<h3>
								Items are coming soon and will be available to add to your Space
								from your Dashboard. All Space Owners will be updated by email when
								it becomes available!
							</h3>
							<div className="centerframe-grid gap-2gutter">
								<a className="button-primary-gradient block-xl" onClick={saveContinue}><span><p>Continue to Next Step</p></span></a>
								<a className="button-secondary-gradient block-md" onClick={saveBack}><span><p>Go Back</p></span></a>
							</div>
						</article>
					</div>
				</section>
			</main>


			{/*<main id="member-profile" className="flex">*/}
			{/*	<div className="container !py-20">*/}
			{/*		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*			<div className="col-span-12 md:col-span-6 flex items-center">*/}
			{/*				<h1 className="mb-4 mt-5 header-large">Create Your First Item</h1>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">*/}
			{/*				<p className="body-txtitalic--large ">Step 1 of 5</p>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-12">*/}
			{/*				<p className="body-text--reg">*/}
			{/*					/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*					/!*	Items*!/*/}
			{/*					/!*</Link>*!/*/}
			{/*					{getSitewideLink(*/}
			{/*						'first_items',*/}
			{/*						'gradient-color-txt body-txtitalic--reg'*/}
			{/*					)}{' '}*/}
			{/*					are exactly the same as{' '}*/}
			{/*					/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*					/!*	Memberships*!/*/}
			{/*					/!*</Link>*!/*/}
			{/*					{getSitewideLink(*/}
			{/*						'memberships_item',*/}
			{/*						'gradient-color-txt body-txtitalic--reg'*/}
			{/*					)}*/}
			{/*					, except that they don’t provide the Member Access Benefit so they*/}
			{/*					require a Membership to use and a Member can activate as many they*/}
			{/*					own. Items can be free or paid, and provide extra Benefits Member.*/}
			{/*					You can use Items to upsell, give special access or status to*/}
			{/*					Members, offer Redemptions, and much more by adding{' '}*/}
			{/*					/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*					/!*	Custom Benefits*!/*/}
			{/*					/!*</Link>*!/*/}
			{/*					{getSitewideLink(*/}
			{/*						'custom_benefits',*/}
			{/*						'gradient-color-txt body-txtitalic--reg'*/}
			{/*					)}{' '}*/}
			{/*					to them.{' '}*/}
			{/*					/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*					/!*	Learn more about Items*!/*/}
			{/*					/!*</Link>*!/*/}
			{/*					{getSitewideLink(*/}
			{/*						'learn_more_items',*/}
			{/*						'gradient-color-txt body-txtitalic--reg'*/}
			{/*					)}*/}
			{/*					.*/}
			{/*				</p>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="grid grid-cols-12 mt-4">*/}
			{/*			<div className="col-span-12">*/}
			{/*				<h5 className="header-small">*/}
			{/*					Items are coming soon and will be available to add to your Space*/}
			{/*					from your Dashboard. All Space Owners will be updated by email when*/}
			{/*					it becomes available!*/}
			{/*				</h5>*/}
			{/*			</div>*/}
			{/*		</div>*/}

			{/*		<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">*/}
			{/*			<div className="md:col-span-8">*/}
			{/*				<Button*/}
			{/*					onClick={saveContinue}*/}
			{/*					className="btn btn-lg btn-primary"*/}
			{/*					type="submit"*/}
			{/*				>*/}
			{/*					Continue to Next Step*/}
			{/*				</Button>*/}
			{/*			</div>*/}
			{/*			<div className="md:col-span-4 flex items-end">*/}
			{/*				<Button*/}
			{/*					onClick={saveBack}*/}
			{/*					className="btn btn-lg btn-warning bg-none"*/}
			{/*					type="submit"*/}
			{/*				>*/}
			{/*					Go Back*/}
			{/*				</Button>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</main>*/}
		</>
	);
};

export default memo(CreateFirstItem);
