import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import { OAuthExtension } from '@magic-ext/oauth';
import Alert from 'react-bootstrap/Alert';
import Web3 from 'web3';
import { getAuthUserInfo } from '../../states/general/actions';

import {
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_KEY,
	MAGIC_OAUTH_KEY,
	MAGIC_CONNECT_NETWORK
} from '../../constants/common';
import { setAccessToken } from '../../utils/common';
import { checkMfaEnabled, checkSmsEnabled, requestSmsOtp, dualCheck2FA } from '../../services/user';
import { login, magic_login } from '../../services/auth';
import routes from '../../routes';

import './Login.scss';
import dj_bg_img from '../../assets/images/cs-splash.jpg';
import {getLocalStorage, removeLocalStorage} from "../../utils/storage";

const LoginForm = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [isMagicLink, setIsMagicLink] = useState(false);
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [isLoginBtn, setLoginBtn] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(true);
	const [formHasError, setFormHasError] = useState(true);
	const [hasMfaEnabled, setHasMfaEnabled] = useState(false);
	const { size } = useSelector((state) => state.classSize);
	const [margin, setMargin] = useState();
	const [hasEmailSupplied, setHasEmailSupplied] = useState(false);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new OAuthExtension()]
	});
	window.magic = magic;
	const web3 = new Web3(magic.rpcProvider);

	const handleFormOnBlur = useCallback(async () => {
		setAlert(null);

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setAlert(null);

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(
		async (values) => {
			try {
				setLoginBtn(true);
				console.log(hasMfaEnabled);
				if (hasMfaEnabled) {
					console.log('MFA Enabled');
					values.user_id = authUserInfo.id;
					await handleOtp(values);
				} else {
					console.log('MFA Not Enabled');
					await loginUser(values);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[hasMfaEnabled]
	);

	const supplyEmail = () => {
		const fields = form.getFieldsValue();
		if (!formHasError) {
			setHasEmailSupplied(true);
			form.resetFields();
			form.setFieldsValue({ email: String(fields.email) });
		}
	};
	const checkHas2FA = async () => {
		try {
			let values = {
				user_id: authUserInfo.id
			};
			const mfa_async = checkMfaEnabled(values);
			const sms_async = checkSmsEnabled(values);
			const mfa_res = await mfa_async;
			const sms_res = await sms_async;
			if (mfa_res.result || sms_res.result) {
				setHasMfaEnabled(true);
			} else {
				setHasMfaEnabled(false);
				window.location.replace(routes.dashboard());
			}
			console.log(mfa_res.message);
			console.log(sms_res.message);
		} catch (error) {
			console.log(error);
		}
	};

	const requestSMS = async () => {
		let values = {
			user_id: authUserInfo.id
		};
		const result = await requestSmsOtp(values);
		if (result.result) {
			setAlert({
				variant: 'success',
				message: 'OTP sent to your number.'
			});
		} else {
			setAlert({
				variant: 'danger',
				message: 'OTP send failed.'
			});
		}
	};

	const handleOtp = async (values) => {
		try {
			// let values = {
			//     user_id: authUserInfo.id
			// };
			let result = await dualCheck2FA(values);
			if (result.result) {
				form.resetFields();
				setAlert({
					variant: 'success',
					message: 'Log in successful!'
				});
				setLoginBtn(false);
				window.location.replace(routes.dashboard());
			} else {
				setAlert({
					variant: 'danger',
					message: 'OTP Code is incorrect.'
				});
				setLoginBtn(false);
			}
		} catch (error) {
			setLoginBtn(false);
			console.log(error);
		}
	};

	// Web 3 Login
	const web3Login = async () => {
		setSubmitted(true);
		setIsMagicLink(true);

		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});

		web3.eth
			.getAccounts()
			.then((accounts) => {
				loginMagic({ user_public_address: accounts?.[0] });
			})
			.catch((error) => {
				setSubmitted(false);
				setIsMagicLink(false);
			});
	};

	const loginUser = async (values) => {
		const result = await login(values);
		await processLogin(result);
	};

	const loginMagic = async (values) => {
		const result = await magic_login(values);
		await processLogin(result);
	};

	const processLogin = async (result) => {
		// console.log(result);
		if (result) {
			if (result.result) {
				if (result.access_token) {
					setAccessToken(result);
				}
				dispatch(getAuthUserInfo());
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'User not found.Please try again.'
					});
				}
			}
		}
		setLoginBtn(false);
		setSubmitted(false);
		setIsMagicLink(false);
	};

	useEffect(() => {
		document.title = 'Commonspace Login';

		setMargin(() => {
			if (size !== 'lg') {
				return '4';
			} else {
				return '5';
			}
		});
		if (loading && loading === 'done' && authUserInfo) {
			// window.location.replace(routes.dashboard());
			setSubmitted(false);
			checkHas2FA().then(() => {});
			if (getLocalStorage('redirect_link')) {
				removeLocalStorage('redirect_link')
			}
		}
	}, [authUserInfo, loading, hasMfaEnabled, isMagicLink, size, margin]);

	return (
		<main id="cs-platform-main" className="cs-main main-theme">
			<section id="cs-space-auth-c1" className="py-0 px-0 sm:py-section-safeview theme-transparent">
				<div className="cs-centerframe-split">
					<div className="cs-centerframe-split-left order-2 sm:order-1">
						<div className="p-section-safeview sm:pr-gutter">
							<div className="cs-stack-auth-form stack-col sm:max-w-half-breakpoint-less-gutter">
								<hgroup className="heading">
									{hasMfaEnabled && (
										<h2>Secure Authentication Enabled</h2>
									)}
									{!hasMfaEnabled && !hasEmailSupplied &&  (
										<h2>Login</h2>
									)}
									{!hasMfaEnabled && hasEmailSupplied &&(
										<h2>Login with Email</h2>
									)}

									{hasMfaEnabled && (
										<p>
											Enter the 2FA code associated with your account from your app. This
											may be from Google Authenticator, Authy, or whichever app you set
											up.
										</p>
									)}

									{!hasMfaEnabled && !hasEmailSupplied && (
										<p className='text-p1'>
											Enter your email or username to login.
											{/*Enter your email/username or use your wallet to login. If you have 2 Factor Authentication enabled, be sure to have your method available.*/}
										</p>
									)}
									{!hasMfaEnabled && hasEmailSupplied && (
										<p className='text-p1' >
											Enter your password associated with your email. If you have 2 Factor Authentication enabled, be sure to have your method available.

										</p>
									)}

								</hgroup>
								<Form
									form={form}
									name="loginForm"
									validateMessages={VALIDATE_MESSAGES}
									onFocus={() => {
										setFormIsOnFocus(true);
									}}
									onBlur={() => {
										setFormIsOnFocus(false);
										handleFormOnBlur().then(() => {});
									}}
									autoComplete="off"
									onChange={handleFormChanges}
									onFinish={handleSubmit}
                                    className='w-full stack-form-col'
								>
									{!hasMfaEnabled && (
                                        <>
											<Form.Item
												name="email"
												validateTrigger="onBlur"
												rules={[{ required: true}]}
                                                className='w-full stack-form-col'
											>
												<div className="form-input unlabeled">
                                                    <label htmlFor="email">Email or Username</label>
                                                    <input id="email" name="email" type="text" autoComplete="email" required placeholder="Email or Username"/>
                                                </div>

											</Form.Item>
											{(form.getFieldsError()[0]?.errors.length > 0 || (alert && alert.message)) && (
												<div className="alert callout warning !m-0">
													<div className="action">
														<i className="fa-solid fa-triangle-exclamation"></i>
													</div>
													<div className="content">
														<div>
															<h6>{form.getFieldsError()[0]?.errors.length > 0 ? form.getFieldsError()[0]?.errors : (alert && alert.message) ? alert.message : ''}</h6>
														</div>
													</div>

												</div>
											)}
                                        </>
									)}
									{!hasMfaEnabled && (
										<>
											<Form.Item
												name="password"
												validateTrigger="onBlur"
												rules={[{ required: true }]}
											>
												<div className="form-input-combo">
													<label htmlFor="password">Password</label>
													<input id="password" name="password" type="password" autoComplete="current-password" required placeholder="Password"/>
													<button  disabled={submitted || formHasError} type="submit" className="secondary-gradient">
                                                        <span><p>{isLoginBtn && (
															<i className="fa-solid fa-spinner fa-spin z-0 mr-2"></i>
														)}
															Login</p></span>
													</button>
												</div>

											</Form.Item>

											{(form.getFieldsError()[1]?.errors.length > 0) && (
												<div className="alert callout warning !m-0">
													<div className="action">
														<i className="fa-solid fa-triangle-exclamation"></i>
													</div>
													<div className="content">
														<div>
															<h6>{form.getFieldsError()[1]?.errors}</h6>
														</div>
													</div>

												</div>
											)}
										</>
									)}
									{hasMfaEnabled && (
										<div>
											<Form.Item
												name="otp"
												rules={[{ required: true }]}
											>
												<div className="form-input unlabeled">
													<label htmlFor="otp">Email or Username</label>
													<input id="otp" name="otp" type="number" required placeholder="Enter 2FA App Code"/>
												</div>
											</Form.Item>
										</div>
									)}
									{hasMfaEnabled && (                                        
                                        <button type="submit"  disabled={submitted} className="secondary-gradient">
											<span><p>{submitted && (
												<i className="fa-solid fa-spinner fa-spin"></i>
												)}
												Confirm & Login</p></span>
                                        </button>
									)}
									{!hasMfaEnabled && (
                                        <button onClick={web3Login} disabled={submitted} type="submit" className="secondary-gradient !hidden">
                                            <i
												className={
													isMagicLink
														? 'fa-solid fa-spinner fa-spin mr-2'
														: 'fa-solid fa-wallet mr-2'
												}
											></i>
											Login with wallet
                                        </button>
									)}
								</Form>
								<p>
									If you have 2 Factor Authentication enabled, have your method
									available.
								</p>
								<p>
									Trouble logging in?{' '}
									<a href={routes.forgotPassword()} >Reset password here.</a>
								</p>
							</div>
						</div>
					</div>

					<div className="cs-centerframe-split-right order-1 sm:order-2">
						<div className="lg:pl-half-gutter">
							<img
								className="sm:max-h-element"
								src={dj_bg_img}
							/>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

const Login = () => {
	return <LoginForm />;
};
export default memo(Login);
