import React, { memo, useCallback, useEffect, useState } from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import Alert from 'react-bootstrap/Alert';
import { useSearchParams, useLocation, useNavigate, Link } from 'react-router-dom';
import CustomPagination from '../../components/commons/CustomPagination';

import Communities from './Communities';
import FeaturedLinks from './FeaturedLinks';
import LatestUpdates from './LatestUpdates';
import MemberBenefits from './MemberBenefits';
import Benefits from './Tabs/Benefits';
import SpaceClaimMembership from '../SpaceClaimMembership';
import Channel from '../Channel';
import SpaceClaimItem from '../SpaceClaimItem';
import { VALIDATE_MESSAGES } from '../../constants/common';
import { hideTooltip, showTooltip } from '../../utils/input';
import { subscribeNewsletter, getSpaceUpdates } from '../../services/space';

import './SpaceView.scss';
import default_profile_card_bg from '../../assets/images/profile_header.png';
import UpdatesContent from './LatestUpdates/UpdatesContent';
import SpaceSupport from './SpaceSupport';
import SpaceAbout from './SpaceAbout';
import SpaceFAQ from './SpaceFAQ';
import SpaceTermsServices from './SpaceTermsServices';
import SpacePrivacyPolicy from './SpacePrivacyPolicy';
import SpaceContact from './CommonLayouts/SpaceContact/SpaceContact';
import SpaceBuyMembership from '../SpaceBuyMembership';
import SpaceBuyItem from '../SpaceBuyItem';
import SpaceInformationPages from './SpaceInformationPages';
import routes from '../../routes';
import { CustomValidators } from '../../utils/CustomValidators';
import { getListDisplay } from '../../utils/list';
import { PRONOUNS } from '../../constants/profile';
import {shortenNumber} from "../../utils/number";
import parse from "html-react-parser";
import Top8 from "../Profile/UserView/Top8";
import Spaces from "../Profile/UserView/Spaces";
import Collections from "../Profile/UserView/Collections";
import Activity from "../Profile/UserView/Activity";
import Following from "../Profile/UserView/Following";
import Followers from "../Profile/UserView/Followers";
import Pagination from "react-bootstrap/Pagination";
import channels_cover_image from "../../assets/images/fam-access-1.jpg";
import default_user_profile from "../../assets/images/default_user_profile.png";
import zoom_cover_image from "../../assets/images/fam-access-2.jpg";
import shop_cover_image from "../../assets/images/fam-access-3.jpg";

const SpaceView = (props) => {
	const { spaceData, globalActiveTab, updateGlobalActiveTab, url } = props;
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const urlTab = searchParams.get('tab');
	const [subscriptionForm] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [submittedSubscription, setSubmittedSubscription] = useState(false);
	const [alert, setAlert] = useState(null);
	const [spaceUpdates, setSpaceUpdates] = useState(null);
	const [spaceUpdatesIsFetching, setSpaceUpdatesIsFetching] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [spaceUsername, setSpaceUsername] = useState(null);
	const [updatesViewingUserIsMember, setUpdatesViewingUserIsMember] = useState(false);
	const [playVideo, setPlayVideo] = useState(false);
	const [pageSize, setPageSize] = useState(1);
	const [isViewAllMemberships, setIsViewAllMemberships] = useState(false);
	const organizedSpaceMembership = [];
	const organizedSpaceUpdates = [];
	const organizedSpaceBenefits = [];

	let profileAssetType = 'image';

	if (spaceData.video) {profileAssetType = 'video';}

	const [currentPage, setCurrentPage] = useState(0);

	const onPageChange = (page) => {
		setCurrentPage(page);
		const section = document.getElementById('cs-space-updates-browse');
		if (section) {
			section.scrollIntoView({ behavior: 'smooth' });
		}
	};

	let space_bg = null;
	let cardBackgroundType = 'image';
	if (spaceData.background_photo || spaceData.background_video) {
		if (spaceData.background_photo) {
			space_bg = {
				backgroundImage: 'url(' + spaceData.background_photo + ')'
			};
			cardBackgroundType = 'image';
		}
		if (spaceData.background_video) {
			cardBackgroundType = 'video';
		}
	} else {
		space_bg = {
			backgroundImage: 'url(' + default_profile_card_bg + ')'
		};
		cardBackgroundType = 'image';
	}

	let selectedWelcomeAssetType = 'image';
	if (spaceData.welcome_youtube_url) {
		selectedWelcomeAssetType = 'youtube';
	} else {
		if (spaceData.welcome_video) {
			selectedWelcomeAssetType = 'video';
		}
	}

	const getUpdates = useCallback(
		async (data) => {
			if (!spaceUpdates || (spaceUpdates && spaceUpdates.length === 0)) {
				setSpaceUpdatesIsFetching(true);
			}
			try {
				const publish_statuses = ['immediately', 'published', 'publishing'];
				const params = {
					space_id: data.id,
					in_space_view: true,
					publish_statuses: JSON.stringify(publish_statuses)
				};
				if (authUserInfo) {
					params.viewing_user_id = authUserInfo.id;
				}
				const result = await getSpaceUpdates(params);
				if (result && result.result) {
					if (result.data) {
						setSpaceUpdates(result.data);
						setUpdatesViewingUserIsMember(result.viewing_user_is_member);
						const chunkSize = 6;
						let data = result.data;
						for (let i = 0; i < data.length; i += chunkSize) {
							const chunk = data.slice(i, i + chunkSize);
							organizedSpaceUpdates.push(chunk);
						}
					} else {
						setSpaceUpdates(null);
					}
				}
				setSpaceUpdatesIsFetching(false);
			} catch (error) {
				console.log(error);
				setSpaceUpdatesIsFetching(false);
			}
		},
		[authUserInfo, spaceUpdates]
	);

	let latestUpdatesCount = 0;
	const latestUpdates = [];
	if (spaceData.updates) {
		latestUpdatesCount = spaceData.updates.length;
		spaceData.updates.forEach((item, index) => {
			if (index <= 2) {
				latestUpdates.push(item);
			}
		});
	}

	let featuredLinksCount = 0;
	const featuredLinks = [];
	if (spaceData.links) {
		featuredLinksCount = spaceData.links.length;
		spaceData.links.forEach((item, index) => {
			if (index <= 2) {
				featuredLinks.push(item);
			}
		});
	}

	let firstMembership = null;
	const spaceMembershipsAndItems = [];
	const spaceMemberships = [];

	if (spaceData.memberships) {
		spaceData.memberships.forEach((item, index) => {
			// Don't add subscription item/membership if benefits doesn't exist
			{
				item.enable_expiration_and_subscription && item.benefits.length < 1
					? null
					: spaceMembershipsAndItems.push(item);
			}
			if (index === 0) {
				firstMembership = item;
			}
		});
		const chunkSize = 6;
		let data = spaceMembershipsAndItems;
		for (let i = 0; i < data.length; i += chunkSize) {
			const chunk = data.slice(i, i + chunkSize);
			organizedSpaceMembership.push(chunk);
		}
	}
	if (spaceData.items) {
		spaceData.items.forEach((item, index) => {
			// Don't add subscription item/membership if benefits doesn't exist
			{
				item.enable_expiration_and_subscription && item.benefits.length < 1
					? null
					: spaceMembershipsAndItems.push(item);
			}
		});
		const chunkSize = 6;
		let data = spaceData.items;

		for (let i = 0; i < data.length; i += chunkSize) {
			const chunk = data.slice(i, i + chunkSize);
			organizedSpaceMembership.push(chunk);
		}
	}
	if (spaceData && spaceMembershipsAndItems && spaceMembershipsAndItems.length < 3) {
		const membershipCount = spaceMembershipsAndItems.length;
		let remainingMembershipCount = 3 - membershipCount;
		while (remainingMembershipCount > 0) {
			spaceMembershipsAndItems.push({});
			remainingMembershipCount--;
		}
	}
	const onlyThreeSpaceMembershipsAndItems = [];
	if (spaceMembershipsAndItems.length > 0) {
		spaceMembershipsAndItems.forEach((item, index) => {
			if (index <= 2) {
				// Don't add subscription item/membership if benefits doesn't exist
				{
					item.enable_expiration_and_subscription && item.benefits.length < 1 ?
						null :
						onlyThreeSpaceMembershipsAndItems.push(item);
				}
			}
		});
	}
	let benefitsCount = 0;
	const benefits = [];
	if (spaceData.benefits) {
		benefitsCount = spaceData.benefits.length;
		spaceData.benefits.forEach((item, index) => {
			if (index <= 2) {
				benefits.push(item);
			}
		});
		const chunkSize = 6;
		let data = spaceData.benefits;
		for (let i = 0; i < data.length; i += chunkSize) {
			const chunk = data.slice(i, i + chunkSize);
			organizedSpaceBenefits.push(chunk);
		}
	}

	const handleSubscriptionFormSubmit = useCallback(async (values) => {
		if (values.email) {
			setSubmittedSubscription(true);
			setAlert(null);
			const data = {
				space_id: spaceData.id,
				email: values.email
			};
			const response = await subscribeNewsletter(data);
			if (response && response.result) {
				setAlert({
					variant: 'success',
					message: response.message
				});
			} else {
				setAlert({
					variant: 'danger',
					message: response.message
				});
			}
			subscriptionForm.resetFields();
			setSubmittedSubscription(false);
		}
	}, []);

	const setActiveTabChange = (item) => {
		updateGlobalActiveTab(item);
		setCurrentPage(0);
		setIsViewAllMemberships(false);
		if (item === 'home' || item === 'members') {
			// force page url navigate to reset location path when going back to home
			navigate(routes.viewUserSpace() + spaceData.username, { replace: true });
		}
	};

	const setVideoPlay = () => {
		if (globalActiveTab !== 'home') {
			setPlayVideo(false);
		}
	};

	const tabs = [
		{ id: 'home', active: ['home'] },
		{ id: 'updates', active: ['updates'] },
		{ id: 'benefits', active: ['benefits'] },
		{ id: 'members', active: ['members'] },
		{ id: 'support', active: ['support'] },
		{ id: 'about', active: ['about', 'about#contact'] }
	];

	useEffect(() => {}, [pageSize]);

	useEffect(() => {}, [
		organizedSpaceUpdates.length,
		currentPage,
		organizedSpaceBenefits.length,
		organizedSpaceMembership.length
	]);

	useEffect(() => {
		const path = window.location.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let directory = path.replace('/@', '');
			const split = directory.split('/');
			const username = split[0];
			setSpaceUsername(username);
		}

		if (spaceData) {
			setUpdatesViewingUserIsMember(spaceData.viewing_user_is_member);
		}

		if (spaceUsername != null) {
			if (globalActiveTab === 'updates') {
				getUpdates(spaceData).then();

				if (!path.includes('update')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/update');
				}
			} else if (globalActiveTab === 'home') {
				if (!path.includes('home') && !path.includes('verify-email')) {
					window.history.replaceState(null, null, '/@' + spaceUsername);
				}
			} else if (globalActiveTab === 'benefits') {
				if (!path.includes('benefit')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/benefit');
				}
			} else if (globalActiveTab === 'members') {
				if (!path.includes('member')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/member');
				}
			} else if (globalActiveTab === 'support') {
				if (!path.includes('support')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/support');
				}
			} else if (globalActiveTab === 'about') {
				if (!path.includes('about')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/about');
				}
			} else if (globalActiveTab === 'contact') {
				if (!path.includes('contact')) {
					window.history.replaceState(
						null,
						null,
						'/@' + spaceUsername + '/contact'
					);
				}
			}
		}

		const availableTabs = ['home', 'updates', 'benefits', 'members'];
		if (initialLoad) {
			setInitialLoad(false);

			if (availableTabs.includes(urlTab)) {
				setActiveTabChange(urlTab);
				searchParams.delete('tab');
				setSearchParams(searchParams);
			}

			// if (urlTab != null) {
			// 	urlTab.map((tab) => console.log('tab: ' + tab));
			// }
		}

		if (globalActiveTab === 'update_content') {
			if (availableTabs.includes(globalActiveTab)) {
				let baseUrl = '/@' + spaceUsername;
				// if (globalActiveTab === 'home') {
				//  baseUrl = baseUrl;
				// }
				if (globalActiveTab === 'updates') {
					baseUrl = baseUrl + '/update';
				}
				if (globalActiveTab === 'benefits') {
					baseUrl = baseUrl + '/benefit';
				}
				if (globalActiveTab === 'members') {
					baseUrl = baseUrl + '/member';
				}
				if (globalActiveTab === 'support') {
					baseUrl = baseUrl + '/support';
				}
				if (globalActiveTab === 'about') {
					baseUrl = baseUrl + '/about';
				}
				if (globalActiveTab === 'contact') {
					baseUrl = baseUrl + '/contact';
				}
				window.history.replaceState(null, null, baseUrl);
				navigate(baseUrl);
			}
		}

		// if (document.location.hash === '#contact') {
		//  setTimeout(() => {
		//      document
		//          .querySelector('#contact')
		//          .scrollIntoView({ behavior: 'smooth', block: 'start' });
		//  }, 300);
		// }

		setVideoPlay();
	}, [spaceData, initialLoad, urlTab, globalActiveTab, spaceUsername, playVideo]);

	useEffect(()=>{

	},[tabs])

	return (
		<>
			<main id="cs-space-main" className="cs-main main-theme" >
				<section id="cs-profile-banner" className={`${globalActiveTab !== 'channel'? '' : 'h-3row'} p-0 bg-cover-center relative`} style={{backgroundImage: `url(${spaceData.background_photo_medium})`}}>
					{cardBackgroundType && cardBackgroundType === 'video' && (
						<ReactPlayer
							playing={true}
							loop={true}
							muted={true}
							controls={false}
							width="100%"
							height="100%"
							className={`videoPreview absolute`}
							url={spaceData.background_video}
						/>
					)}

					{(globalActiveTab !== 'channel') &&
						<div className={`p-section-safeview bg-primary-shader-light backdrop-blur-lg relative`}>
							<div className={`centerframe-grid lg:gap-x-2gutter`}>
								<div id="cs-profile-banner-main" className="block-xl order-1">
									<div className="stack-col lg:stack-row">
										<div id="cs-profile-banner-avatar">
											<div className={`cs-profile-avatar`}>
												{profileAssetType && profileAssetType === 'video' ?
													<ReactPlayer
														playing={true}
														loop={true}
														muted={true}
														controls={false}
														width="100%"
														height="100%"
														className={`profile-image videoPreview `}
														url={spaceData?.video}
													/>
													:
													<img className="profile-image " src={spaceData?.photo_medium}/>
												}
												<i className="fa-solid fa-badge-check"/>
											</div>
										</div>
										<div id="cs-profile-banner-info" className="stack-col">
											<p className="cs-profile-username">@{spaceData?.username}</p>
											<div className="cs-stack-profile-displayname-pronouns stack-row-wrap">
												<h1>{spaceData?.name}</h1>
												<p>{spaceData?.location}</p>
											</div>
											<p className="cs-profile-bio">{spaceData?.about}</p>
											<div className="cs-stack-link-icons stack-row-wrap">
												{/*{(viewUser?.profile?.social_link || viewUser?.profile?.url) && (*/}
												<>
													{/*{viewUser?.profile?.social_link && (*/}
													<a className="button-icon-round-gradient-alt">
														<span><p><i className="fa-brands fa-x-twitter"></i></p></span>
													</a>
													{/*)}*/}
													{/*{viewUser?.profile?.social_link && (*/}
													<a className="button-icon-round-gradient-alt">
														<span><p><i className="fa-brands fa-instagram"></i></p></span>
													</a>
													{/*)}*/}
													{/*{viewUser?.profile?.social_link && (*/}
													<a className="button-icon-round-gradient-alt">
														<span><p><i className="fa-brands fa-facebook-f"></i></p></span>
													</a>
													{/*)}*/}
													{/*{viewUser?.profile?.social_link && (*/}
													<a className="button-icon-round-gradient-alt">
														<span><p><i className="fa-brands fa-tiktok"></i></p></span>
													</a>
													{/*)}*/}
													{/*{viewUser?.profile?.social_link && (*/}
													<a className="button-icon-round-gradient-alt">
														<span><p><i className="fa-brands fa-linkedin-in"></i></p></span>
													</a>
													{/*)}*/}
													{/*{viewUser?.profile?.social_link && (*/}
													<a className="button-icon-round-gradient-alt">
														<span><p><i className="fa-brands fa-twitch"></i></p></span>
													</a>
													{/*)}*/}
													{/*{viewUser?.profile?.url && (*/}
													<a target="_blank" className="button-icon-round-gradient-alt">
														<span><p><i className="fa-regular fa-globe"></i></p></span>
													</a>
													{/*)}*/}
												</>
												{/*)}*/}
											</div>
											<div className="cs-stack-profile-stats stack-row">
												<div className="cs-stack-profile-stats-item stack-col-base">
													<p>{spaceData ? spaceData.members_count : 0}</p>
													<p>Members</p>
												</div>
												<div className="cs-stack-profile-stats-item stack-col-base">
													<p>{spaceData ? spaceData.updates_count : 0}</p>
													<p>Updates</p>
												</div>
												<div className="cs-stack-profile-stats-item stack-col-base">
													<p>{spaceData ? spaceData.benefits_count : 0}</p>
													<p>Benefits</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="cs-profile-banner-actions" className="block-md order-2">
									<div className="cs-stack-buttons stack-col">
										<Link className="button-secondary-gradient-alt">
										<span>
											<p>
												Become a Member
											</p>
										</span>
										</Link>
										<Link className="button-secondary-gradient-alt">
										<span>
											<p>
												Learn More
											</p>
										</span>
										</Link>
										<Link onClick={(event) => {setActiveTabChange('contact');}} className="button-secondary-gradient-alt">
										<span>
											<p>
												Contact
											</p>
										</span>
										</Link>

										<Link className="button-secondary-gradient-alt">
										<span>
											<p>
												Share
											</p>
										</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					}
				</section>

				{(globalActiveTab !== 'channel') &&
					<div id="cs-profile-nav" className="centerframe-grid pt-gutter pb-gutter">
						<nav className="cs-nav-buttons-row block-full">
							<div className="stack-row-wrap lg:stack-row">
								{tabs.map((tab, index) => (
									<a key={index} onClick={() => {
										updateGlobalActiveTab(tab.id);
										setIsViewAllMemberships(false);
									}}
									   className={'button-secondary-gradient squared '
										   + ((tab.active ? tab.active.includes(globalActiveTab) : globalActiveTab === tab.id)
											   ? 'active'
											   : '')
									   }
									>
									<span>
										<p>
											{tab.id.charAt(0).toUpperCase() + tab.id.slice(1)}
										</p>
									</span>
									</a>
								))}
							</div>
						</nav>
					</div>
				}

				{/* Start of Welcome Home Section*/}
				<section id="cs-space-home-c1" className={`p-section-safeview theme-transparent ${globalActiveTab === 'home' ? 'show active' : 'hidden'}`}>
					<div className="centerframe-grid gap-2gutter">
						<div id="cs-space-home-welcome" className="grid-base col-span-full order-1">
							<div id="cs-space-home-welcome-image"
								className={`block-lgx card img-vid-container w-full ${selectedWelcomeAssetType === 'image' ? '' : '!p-0'} `}
								style={ {backgroundImage: 'url(' + (selectedWelcomeAssetType === 'image' ? spaceData?.welcome_photo : '') + ')'} }
							>
								{selectedWelcomeAssetType === 'video' && (
									<ReactPlayer
										onPlay={() => setPlayVideo(true)}
										onStart={() => setPlayVideo(true)}
										playing={playVideo}
										controls={true}
										width="100%"
										height="100%"
										className="w-full h-full"
										url={spaceData.welcome_video}
									/>
								)}
								{selectedWelcomeAssetType === 'youtube' && (
									<ReactPlayer
										onPlay={() => setPlayVideo(true)}
										onStart={() => setPlayVideo(true)}
										playing={playVideo}
										controls={true}
										width="100%"
										height="100%"
										className="youtubeVideoPreview"
										url={spaceData.welcome_youtube_url}
									/>
								)}
							</div>

							<div
								id="cs-space-home-welcome-intro"
								className="block-mdx"
							>
								<hgroup>
									<h2>{spaceData.welcome_title}</h2>
									<p>{spaceData.welcome_message}</p>
								</hgroup>
								<div className="stack-col">
									{(spaceData && !spaceData.viewing_user_is_member) &&
										<button type="button"
												className="primary-gradient"
												onClick={(event) =>
													setActiveTabChange(
														'members'
													)
												}
										>
											<span><p>Join the Community</p></span>
										</button>
									}
									{spaceData.has_newsletters_signup && (
										<>
											<Form
												form={subscriptionForm}
												name="subscriptionForm"
												id="subscriptionForm"
												validateMessages={
													VALIDATE_MESSAGES
												}
												onFinish={
													handleSubscriptionFormSubmit
												}
												onFocus={() => {
													setAlert(null);
												}}
												onBlur={() => {
													setAlert(null);
												}}
												className="form-input-combo"
											>
												<>
													{alert && (
														<div className="mt-4">
															<div className="col-span-8">
																<Alert
																	key={
																		alert.variant
																	}
																	variant={
																		alert.variant
																	}
																	className={
																		'custom-alert ' +
																		(alert.variant ===
																		'danger'
																			? 'custom-alert-danger'
																			: 'custom-alert-success')
																	}
																>
																	{
																		alert.message
																	}
																</Alert>
															</div>
														</div>
													)}
												</>
												<Form.Item
													label="Email"
													name="email"
													className="mb-0 hidden-label email-input !w-full"
													rules={[
														{ required: false },
														{
															validator:
															CustomValidators.email
														}
													]}
												>
													<input disabled={submittedSubscription} id="email-address" name="email" type="email" autoComplete="email" required placeholder="Enter your email"/>
												</Form.Item>
												<Form.Item className="mb-0 hidden lg:flex !justify-end">

													<button disabled={
														submittedSubscription
													} type="submit" className="secondary-gradient"><span><p>
                                                                                        {submittedSubscription && (
																							<i className="fa-solid fa-spinner fa-spin me-2"></i>
																						)}
														Subscribe
                                                                                    </p></span></button>
												</Form.Item>
											</Form>
										</>
									)}
									<div className="cs-stack-link-icons stack-row">
										<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>
										<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>
										<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>
										<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-tiktok"></i></p></span></a>
										<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-youtube"></i></p></span></a>
										<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-linkedin"></i></p></span></a>
										<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-discord"></i></p></span></a>
										<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-twitch"></i></p></span></a>
										<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-regular fa-globe"></i></p></span></a>
									</div>
								</div>
							</div>
						</div>

						{spaceData && onlyThreeSpaceMembershipsAndItems && onlyThreeSpaceMembershipsAndItems.length > 0 && (
							<div id="cs-space-products-join" className="grid-base col-span-full order-2">
								<div className="block-full">
									<div className="cs-stack-title stack-row">
										<h2><span>Join</span> Community</h2>
										<p><a onClick={() =>
											setActiveTabChange('members')
										}
										>view all</a></p>
									</div>

								</div>
								{onlyThreeSpaceMembershipsAndItems.map((data, index) => (
									<article key={index} className="cs-product-membership card block-md" >
										<Communities
											data={data}
											user={authUserInfo}
											spaceData={spaceData}
											updateGlobalActiveTab={
												updateGlobalActiveTab
											}
										></Communities>
									</article>
								))}
							</div>
						)}
					</div>
				</section>
				{/* End of Welcome Home Section*/}

				{/* Start of Latest Updates Section */}
				<section id="cs-space-home-c2" className={`p-section-safeview theme-transparent ${globalActiveTab === 'home' ? 'show active' : 'hidden'}`}>
					<div className="centerframe-grid gap-2gutter">
						{spaceData && latestUpdates.length > 0 && (
							<div
								id="cs-space-updates-latest"
								className="grid-base col-span-full order-1"
							>
								<div className="block-full">
									<div className="cs-stack-title stack-row">
										<h2>
											<span>Newest</span> Updates
										</h2>
										<p>
											<a
												onClick={() =>
													setActiveTabChange('updates')
												}
											>
												view all
											</a>
										</p>
									</div>
								</div>
								<div className="grid grid-cols lg:gap-gutter gap-gutterx col-span-full">
									{latestUpdates.slice(0, 2).map((data, index) => (
										<div
											key={index}
											className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline"
										>
											<LatestUpdates
												key={index}
												data={data}
												user={authUserInfo}
												spaceData={spaceData}
												updatesViewingUserIsMember={
													updatesViewingUserIsMember
												}
												updateGlobalActiveTab={
													updateGlobalActiveTab
												}
											/>
										</div>
									))}
									{latestUpdates.map((data, index) => (
										<div
											key={index}
											className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0"
										>
											<LatestUpdates
												key={index}
												data={data}
												user={authUserInfo}
												spaceData={spaceData}
												updatesViewingUserIsMember={
													updatesViewingUserIsMember
												}
												updateGlobalActiveTab={
													updateGlobalActiveTab
												}
											/>
										</div>
									))}
								</div>
							</div>
						)}

						{spaceData && latestUpdates.length > 0 && (
							<div
								id="cs-space-benefits-latest"
								className="grid-base col-span-full order-2"
							>
								<div className="block-full">
									<div className="cs-stack-title stack-row">
										<h2>
											<span>Member</span> Benefits
										</h2>
										{spaceData && benefitsCount > 3 && (
											<p>
												<a
													onClick={() =>
														setActiveTabChange('benefits')
													}
												>
													view all
												</a>
											</p>
										)}
									</div>
								</div>
								<div className="grid grid-cols lg:gap-gutter gap-gutterx col-span-full">
									{benefits.slice(0, 2).map((data, index) => (
										<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
											<MemberBenefits
												key={index}
												data={data}
												user={authUserInfo}
												spaceData={spaceData}
											/>
										</div>
									))}
									{benefits.map((data, index) => (
										<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0">
											<MemberBenefits
												key={index}
												data={data}
												user={authUserInfo}
												spaceData={spaceData}
											/>
										</div>
									))}
								</div>
							</div>
						)}
						{spaceData && featuredLinks.length > 0 && (
							<div
								id="cs-space-links-latest"
								className="grid-base col-span-full order-3"
							>
								<div className="block-full">
									<div className="cs-stack-title stack-row">
										{spaceData && spaceData.link_title_first ? (
											<h2>
												<span>{spaceData.link_title_first}</span>
												&nbsp;{spaceData.link_title_second}
											</h2>
										) : (
											<h2>
												<span>Featured</span> Links
											</h2>
										)}
										{spaceData && benefitsCount > 3 && (
											<p>
												<a
													onClick={() =>
														setActiveTabChange('updates')
													}
												>
													view all
												</a>
											</p>
										)}
									</div>
								</div>

								{featuredLinks.slice(0, 3).map((data, index) => (
									<FeaturedLinks key={index} data={data} index={index} />
								))}
								{/* {featuredLinks.map((data, index) =>
                                                    <article className="cs-featured-link block-md card border-2" >
                                                    <FeaturedLinks
                                                            key={index}
                                                            data={data}
                                                            index={index}
                                                        />
                                                    </article>
                                                )} */}
								{/*ANCHOR LINK: Mobile View All*/}
								<p className="cs-mobile-link-viewall">
									<a href="fam-space-links.html">view all</a>
								</p>
							</div>
						)}
					</div>
				</section>
				{/* End of Latest Updates Section */}

				{/* Start of Newest Updates */}
				<section id="cs-space-updates-c1" className={`p-section-safeview theme-transparent ${globalActiveTab === 'updates' ? 'show active' : 'hidden'}`}>
					<div className="centerframe-grid gap-2gutter">
						<div id="cs-space-updates-browse" className="grid-base col-span-full order-1">
							<div className="block-full">
								<div className="cs-stack-title-filters stack-row-wrap">
									<h2>
										<span>Newest</span> Updates
									</h2>
									<div className="cs-stack-filters">
										<button
											className="filter-dropdown-gradient"
											id="menu-button"
											aria-expanded="true"
											aria-haspopup="true"
										>
											<p>All</p>
											<i className="fa-regular fa-angle-down"></i>
										</button>
										<button
											className="filter-dropdown-gradient"
											id="menu-button"
											aria-expanded="true"
											aria-haspopup="true"
										>
											<p>Newest</p>
											<i className="fa-regular fa-angle-down"></i>
										</button>
									</div>
								</div>
							</div>
							<div className="cs-stack-excerpts grid grid-cols">
								{spaceUpdatesIsFetching ? (
									<div className="loading-items">
										<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
									</div>
								) : (
									spaceData &&
									spaceUpdates &&
									organizedSpaceUpdates &&
									organizedSpaceUpdates[currentPage] &&
									organizedSpaceUpdates[currentPage].length > 0 &&
									organizedSpaceUpdates[currentPage].map(
										(data, index) => (
											<div
												key={index}
												className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 p-0"
											>
												<LatestUpdates
													data={data}
													user={authUserInfo}
													updatesViewingUserIsMember={
														updatesViewingUserIsMember
													}
													updateGlobalActiveTab={
														updateGlobalActiveTab
													}
												></LatestUpdates>
											</div>
										)
									)
								)}
								{spaceData && spaceUpdates && spaceUpdates.length > 6 && (
									<div className="cs-pagination block-full">
										<CustomPagination
											items={spaceUpdates.length} // 100
											currentPage={currentPage} // 1
											pageSize={6} // 10
											onPageChange={onPageChange}
										></CustomPagination>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
				{/* End of Newest Updates */}

				{/* Start of Latest Benefits */}
				<div id="cs-space-benefits-post-c1" className={`p-section-safeview theme-transparent ${globalActiveTab === 'benefits' ? 'show active' : 'hidden'}`}>
					<div className="centerframe-grid gap-2gutter">
						<div id="cs-space-benefits-browse" className="grid-base col-span-full order-1">
							<div className="block-full">
								<div className="cs-stack-title-filters stack-row-wrap">
									<h2>
										<span>Newest</span> Benefits
									</h2>
									<div className="cs-stack-filters">
										<button
											className="filter-dropdown-gradient"
											id="menu-button"
											aria-expanded="true"
											aria-haspopup="true"
										>
											<p>All</p>
											<i className="fa-regular fa-angle-down"></i>
										</button>
										<button
											className="filter-dropdown-gradient"
											id="menu-button"
											aria-expanded="true"
											aria-haspopup="true"
										>
											<p>Newest</p>
											<i className="fa-regular fa-angle-down"></i>
										</button>
									</div>
								</div>
							</div>
							<div className="cs-stack-excerpts grid grid-cols">
								{spaceData &&
									organizedSpaceBenefits[currentPage] &&
									organizedSpaceBenefits[currentPage].length > 0 && (
										<>
											{organizedSpaceBenefits.map((data, index) => (
												<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 p-0">
													<MemberBenefits
														key={index}
														data={data}
														user={authUserInfo}
														spaceData={spaceData}
													></MemberBenefits>
												</div>
											))}
										</>
									)}
								{spaceData && benefits && benefits.length > 6 && (
									<div className="cs-pagination block-full">
										<CustomPagination
											items={benefits.length} // 100
											currentPage={currentPage} // 1
											pageSize={6} // 10
											onPageChange={onPageChange}
										></CustomPagination>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{/* End of Latest Benefits */}

				{/* Start of Members Tab */}
				<section id="cs-space-members-c1" className={`theme-transparent p-section-safeview ${globalActiveTab === 'members' ? 'show active' : 'hidden'}`}>
					<div className="centerframe-grid gap-2gutter">

						{/*SECTION COMPONENT: ACCESS - LATEST*/}
						{/* CSJS FOR FUTURE: The "order-1" class is the current non-editable default based on future*/}
						{/* {space_members_c1_component_order} array values and should be included for future customizable ordering features. */}
						<div id="cs-space-access-latest" className={`grid-base col-span-full order-1 ${isViewAllMemberships ? 'hidden' : ''}`}>
							<div className="block-full">
								{/*ANCHOR TITLE: Member Access*/}
								<div className="cs-stack-title stack-row">
									{/*CSJS <span> text is {space_access_latest_title_part1} */}
									{/*and non-span text is {space_access_latest_title_part2}. */}
									<h2><span>Member</span> Access</h2>
									{/*CSJS Links to fam-space-access.html*/}
									<p><a>view all</a></p>
								</div>
							</div>

							{/*ANCHOR LINKBOX: Featured Access Block*/}
							{/*CSJS Inline background-image URL is {space_access_image} for specific Access Module.*/}
							<article className="cs-featured-access block-md card" style={ {backgroundImage: 'url(' + channels_cover_image + ')'} }>
								{/*CSJS Links to fam-space-channels-channelname.html*/}
								<a href={routes.viewUserSpace() + spaceData?.username + '/channel'} target="_self" className="rounded-card p-card">
									<div className="spacer-element-min-row"></div>
									<hgroup>
										{/* CSJS <i> is {space_access_icon} for specific Access Module. */}
										<p><i className="fa-solid fa-messages"></i></p>
										{/* CSJS <i>> is {space_access_name} for specific Access Module. */}
										<h4>Channels</h4>
									</hgroup>
									<div className="cs-stack-access-split stack-row">
										<div className="stack-row-min">
											{/*CSJS Display up to 5 most recent active Member profile avatars, based on activity in past 24 hours across*/}
											{/*all channels. Image is {cs_profile_image} and alt text is "Image of {cs_profile_username}".*/}
											<div className="stack-avatar-min">
												{spaceData && spaceData.members && spaceData.members.length > 0 && spaceData.members.slice(0, 10).map((member, index) => (
													<div key={index} className="avatar-gradient-min" style={{zIndex: (spaceData.members.length > 10 ? 10 : spaceData.members.length ) - (index + 1)}}
														// className={`z-${(spaceData.memberships.length > 10 ? 10 : spaceData.memberships.length ) - (index + 1)}`}
													>
														<img src={member.profile.photo_thumb ? member.profile.photo_thumb : default_user_profile} alt="Image of cs_profile_username"/>
													</div>
												))}
											</div>
											{/*CSJS If more than 5 active, then display additional count of recent active Member profile avatars,*/}
											{/*based on activity in past 24 hours across all channels, excluding the 5 displayed as avatars.*/}
											<p>+{spaceData.members.length} active</p>
										</div>
										{/*CSJS Display count based on new recent posts (new posts and comments combined)*/}
										{/*in past 24 hours across all channels.*/}
										<p>52 Recent Posts</p>
									</div>
								</a>
							</article>

							{/*ANCHOR LINKBOX: Featured Access Block*/}
							{/*CSJS Inline background-image URL is {space_access_image} for specific Access Module.*/}
							<article className="cs-featured-access block-md card" style={ {backgroundImage: 'url(' + zoom_cover_image + ')'} }>
								{/*CSJS Links to fam-space-access-zoom.html*/}
								<a target="_self" className="rounded-card p-card">
									<div className="spacer-element-min-row"></div>
									<hgroup>
										{/*CSJS <i> is {space_access_icon} for specific Access Module.*/}
										<p><i className="fa-solid fa-video"></i></p>
										{/*CSJS <i>> is {space_access_name} for specific Access Module.*/}
										<h4>FAM Hour on Zoom</h4>
									</hgroup>
									<div className="cs-stack-access stack-row">
										{/*CSJS Next scheduled Zoom date for Zoom Access Module*/}
										<p>Upcoming on July 1, 6pm PT</p>
									</div>
								</a>
							</article>

							{/*ANCHOR LINKBOX: Featured Access Block*/}
							{/*CSJS Inline background-image URL is {space_access_image} for specific Access Module.*/}
							<article className="cs-featured-access block-md card" style={ {backgroundImage: 'url(' + shop_cover_image + ')'} }>
								{/*CSJS Links to {space_access_url} for Link Access Module.*/}
								<a target="_blank" className="rounded-card p-card">
									<div className="spacer-element-min-row"></div>
									<hgroup>
										{/*CSJS <i> is {space_access_icon} for specific Access Module.*/}
										<p><i className="fa-solid fa-store"></i></p>
										{/*CSJS <i>> is {space_access_name} for specific Access Module.*/}
										<h4>FAM Shop</h4>
									</hgroup>
									<div className="cs-stack-access stack-row">
										{/*CSJS Text is {space_access_description} for Link Access Module.*/}
										<p>FAM Members Only Gear</p>
									</div>
								</a>
							</article>

							{/*ANCHOR LINK: Mobile View All*/}
							{/*CSJS Links to fam-space-access.html*/}
							<p className="cs-mobile-link-viewall"><a>view all</a></p>

						</div>

						<div id="cs-space-products-featured" className="grid-base col-span-full order-2">
							<div className="block-full">
								<div className="cs-stack-title stack-row">
									<h2><span>{isViewAllMemberships ? 'Newest' : 'Featured'}</span> Memberships & Items</h2>
									{ isViewAllMemberships ?
										<div className="cs-stack-filters">
											<button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>All</p><i className="fa-regular fa-angle-down"></i></button>
											<button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>Newest</p><i className="fa-regular fa-angle-down"></i></button>
										</div>
										:
										<p><a onClick={()=>{
											setIsViewAllMemberships(!isViewAllMemberships)
										}}>view all</a></p>
									}
								</div>
							</div>

							{!isViewAllMemberships && onlyThreeSpaceMembershipsAndItems.map((data, index) => (
								<article key={index} className="cs-product-membership card block-md" >
									<Communities
										data={data}
										user={authUserInfo}
										spaceData={spaceData}
										updateGlobalActiveTab={
											updateGlobalActiveTab
										}
									/>
								</article>
							))}
							{isViewAllMemberships && spaceData && organizedSpaceMembership[currentPage] && organizedSpaceMembership[currentPage].length > 0 && (
								<>
									{organizedSpaceMembership[currentPage].map(
										(data, index) => (
											<article key={index} className="cs-product-membership card block-md">
												<Communities
													data={data}
													user={authUserInfo}
													spaceData={spaceData}
													updateGlobalActiveTab={
														updateGlobalActiveTab
													}
												/>
											</article>
										)
									)}
								</>
							)}
							{isViewAllMemberships && spaceData && spaceMembershipsAndItems && spaceMembershipsAndItems.length > 6 && (
								<div className="cs-pagination block-full">
									<CustomPagination
										items={spaceMembershipsAndItems.length} // 100
										currentPage={currentPage} // 1
										pageSize={pageSize} // 10
										onPageChange={onPageChange}
									></CustomPagination>
								</div>
							)}
						</div>
					</div>

				</section>
				{/* End of Members Tab */}

				<div className={'tab-pane ' + (globalActiveTab === 'channel' ? 'show active' : 'hidden')}>
					{globalActiveTab === 'channel' && (
						<div className="bg-transparent">
							<Channel spaceData={spaceData} />
						</div>
					)}
				</div>

				<div
					className={
						'tab-pane ' +
						(globalActiveTab === 'faq' ||
						globalActiveTab === 'about' ||
						globalActiveTab === 'contact' ||
						globalActiveTab === 'support' ||
						globalActiveTab === 'privacy-policy' ||
						globalActiveTab === 'terms-services'
							? 'show active'
							: '')
					}
				>
					{(globalActiveTab === 'faq' ||
						globalActiveTab === 'about' ||
						globalActiveTab === 'contact' ||
						globalActiveTab === 'support' ||
						globalActiveTab === 'privacy-policy' ||
						globalActiveTab === 'terms-services') && (
						<SpaceInformationPages
							spaceData={spaceData}
							isPro={false}
							activeTab={globalActiveTab}
						/>
					)}
				</div>

				{globalActiveTab === 'claim_membership' && (
					<SpaceClaimMembership hasGradient={false}/>
				)}
				{globalActiveTab === 'buy_membership' && (
					<SpaceBuyMembership hasGradient={false}/>
				)}
				{globalActiveTab === 'buy_item' && (
					<SpaceBuyItem hasGradient={false}/>
				)}
				{globalActiveTab === 'claim_item' &&
					<SpaceClaimItem hasGradient={false} />
				}
				<div className={'tab-pane ' + (globalActiveTab === 'update_content' ? 'show active' : 'hidden')}>
					{globalActiveTab === 'update_content' && (
						<UpdatesContent
							updatesViewingUserIsMember={updatesViewingUserIsMember}
						/>
					)}
				</div>

			</main>
		</>
	);
};
export default memo(SpaceView);
