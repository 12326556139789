import { memo, useCallback, useEffect, useState } from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';

import { requestSpaceEmailInvite, subscribeNewsletter } from '../../../services/space';
import { VALIDATE_MESSAGES } from '../../../constants/common';
import { hideTooltip, showTooltip } from '../../../utils/input';
import routes from '../../../routes';

import './../SpaceView.scss';
import default_profile_card_bg from '../../../assets/images/profile_header.png';

const SpaceEmailInvitesOnly = (props) => {
	const navigate = useNavigate();
	const { spaceData } = props;
	const [emailRequestForm, subscriptionForm] = Form.useForm();
	const [alertInvite, setAlertInvite] = useState(null);
	const [alert, setAlert] = useState(null);
	const [submittedEmailRequest, setSubmittedEmailRequest] = useState(false);
	const [submittedSubscription, setSubmittedSubscription] = useState(false);

	let space_bg = null;
	let cardBackgroundType = 'image';
	if (spaceData.background_photo || spaceData.background_video) {
		if (spaceData.background_photo) {
			space_bg = {
				backgroundImage: 'url(' + spaceData.background_photo + ')'
			};
			cardBackgroundType = 'image';
		}
		if (spaceData.background_video) {
			cardBackgroundType = 'video';
		}
	} else {
		space_bg = {
			backgroundImage: 'url(' + default_profile_card_bg + ')'
		};
		cardBackgroundType = 'image';
	}

	const handleEmailRequestFormSubmit = useCallback(async (values) => {
		setSubmittedEmailRequest(true);
		setAlertInvite(null);

		const data = {
			space_id: spaceData.id,
			email: values.email
		};

		const response = await requestSpaceEmailInvite(data);
		if (response && response.result) {
			setAlertInvite({
				variant: 'success',
				message: response.message
			});
		} else {
			setAlertInvite({
				variant: 'danger',
				message: response.message
			});
		}

		setSubmittedEmailRequest(false);
	}, []);

	const handleSubscriptionFormSubmit = useCallback(async (values) => {
		setSubmittedSubscription(true);
		setAlert(null);

		const data = {
			space_id: spaceData.id,
			email: values.email
		};

		const response = await subscribeNewsletter(data);
		if (response && response.result) {
			setAlert({
				variant: 'success',
				message: response.message
			});
		} else {
			setAlert({
				variant: 'danger',
				message: response.message
			});
		}

		setSubmittedSubscription(false);
	}, []);

	const redirectToLogin = useCallback(async () => {
		navigate(routes.login());
	}, []);

	return (
		<>
			<main id="profile">
				<div className="w-full profile-banner" style={space_bg}>
					{cardBackgroundType && cardBackgroundType === 'video' && (
						<video autoPlay muted loop className="user-view-video-profile-background">
							<source src={spaceData.background_video} type="video/mp4" />
						</video>
					)}
					<div
						className={
							'container ' + (cardBackgroundType === 'video' ? 'z-index-ontop' : '')
						}
					></div>
					<div className="bg-overlay"></div>
				</div>
				<div className="w-full ">
					<div className="container pt-3 pb-3 mb-5">
						<div className="grid grid-cols-12 text-center">
							<div className="col-span-12 nav-welcome">
								Welcome to the {spaceData.name}
							</div>
						</div>
					</div>

					<div className="tab-content space-profile-url" id="pills-tabContent">
						<div className="tab-pane show active">
							<div className="container">
								<div className="grid grid-cols-12 justify-center">
								<div className="col-span-6 col-start-4 text-center">
										<h1>Invite only</h1>
										<p className="body-text-black">
											Log in to continue or request an invite.
										</p>

										<Button
											className="btn btn-md btn-primary mt-4"
											onClick={redirectToLogin}
										>
											Login
										</Button>

										<Form
											form={emailRequestForm}
											name="emailRequestForm"
											id="emailRequestForm"
											validateMessages={VALIDATE_MESSAGES}
											onFinish={handleEmailRequestFormSubmit}
											className={'space-settings-error pt-3'}
											onFocus={() => {
												setAlert(null);
											}}
										>
											<>
												{alertInvite && (
													<div className="grid grid-cols-12 m-0 p-0">
														<div className="col-span-8 m-0 p-0">
															<Alert
																key={alertInvite.variant}
																variant={alertInvite.variant}
																className={
																	'custom-alert ' +
																	(alertInvite.variant ===
																	'danger'
																		? 'custom-alert-danger'
																		: 'custom-alert-success')
																}
															>
																{alertInvite.message}
															</Alert>
														</div>
													</div>
												)}
											</>
											<div className="input-group-com input-with-btn mt-4">
												<Form.Item
													label="Email"
													name="email"
													className="mb-0 hidden-label"
													rules={[{ required: true }]}
												>
													<Input
														onFocus={hideTooltip}
														onBlur={showTooltip}
														placeholder="Enter email to request an invite."
														className="input-md body-txtitalic--smallest color-light"
														disabled={submittedEmailRequest}
													/>
												</Form.Item>
												<Form.Item>
													<Button
														className="btn btn-primary btn-md btn-request"
														type="submit"
														disabled={submittedEmailRequest}
													>
														{submittedEmailRequest && (
															<i className="fa-solid fa-spinner fa-spin"></i>
														)}
														Request
													</Button>
												</Form.Item>
											</div>
										</Form>

										<Form
											form={subscriptionForm}
											name="subscriptionForm"
											id="subscriptionForm"
											validateMessages={VALIDATE_MESSAGES}
											onFinish={handleSubscriptionFormSubmit}
											className={'space-settings-error pt-3'}
											onFocus={() => {
												setAlert(null);
											}}
										>
											<>
												{alert && (
													<div className="grid grid-cols-12 m-0 p-0">
														<div className="col-span-8 m-0 p-0">
															<Alert
																key={alert.variant}
																variant={alert.variant}
																className={
																	'custom-alert ' +
																	(alert.variant === 'danger'
																		? 'custom-alert-danger'
																		: 'custom-alert-success')
																}
															>
																{alert.message}
															</Alert>
														</div>
													</div>
												)}
											</>
											<div className="input-group-com input-with-btn mt-4">
												<Form.Item
													label="Email"
													name="email"
													className="mb-0 hidden-label"
													rules={[{ required: true }]}
												>
													<Input
														onFocus={hideTooltip}
														onBlur={showTooltip}
														placeholder="Enter email to subscribe to newsletter."
														className="input-md body-txtitalic--smallest color-light"
														disabled={submittedSubscription}
													/>
												</Form.Item>
												<Form.Item>
													<Button
														className="btn btn-secondary-dd btn-md btn-subscribe"
														type="submit"
														disabled={submittedSubscription}
													>
														{submittedSubscription && (
															<i className="fa-solid fa-spinner fa-spin"></i>
														)}
														Subscribe
													</Button>
												</Form.Item>
											</div>
											<ul className="list-socials pt-2  ">
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-twitter isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-instagram isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-facebook isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-tiktok isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-youtube isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-linkedin isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-discord isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-twitch isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-regular fa-globe isDisabled"></i>
													{/* </a> */}
												</li>
											</ul>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(SpaceEmailInvitesOnly);
