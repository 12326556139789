import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Form, Input } from 'antd';
import DropdownWithItems from '../../DropdownWithItems';
import { LENGTH_OF_BLOCK } from '../../../../constants/space';
import { useSelector } from 'react-redux';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import { blockSpaceMember, unblockSpaceMember } from '../../../../services/space';
import Alert from 'react-bootstrap/Alert';
import { hideTooltip, showTooltip } from '../../../../utils/input';

ReactModal.setAppElement('#root');

const BanUserModal = (props) => {
	const { modalOpen, setModalOpen, member, space, getMemberDataRequest, toUpdateDataId } = props;
	const [banUserForm] = Form.useForm();
	const [alert, setAlert] = useState(null);
	const [selectedBlockLength, setSelectedBlockLength] = useState('default');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [reason, setReason] = useState();

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: 'white',
			width:'80%',
			borderRadius: 32
		},
		overlay: { background: 'rgba(0, 0, 0, 0.6)' }
	};

	const filterDropdownChange = useCallback(async (value) => {
		setSelectedBlockLength(value);
	});

	const handleFormOnBlur = useCallback(async () => {
		await banUserForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setReason(banUserForm.getFieldValue('reason'));
		await banUserForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(
		async (values) => {
			setDisplayDropDownError(true);

			if (member.blocked) {
				try {
					setSubmitted(true);

					const data = values;
					data.member_id = member.id;

					const response = await unblockSpaceMember(data);
					if (response && response.result) {
						setAlert({
							variant: 'success',
							message: response.message
						});
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						window.scrollTo(0, 0);
					}
					setSubmitted(false);
					setDisplayDropDownError(false);
					getMemberDataRequest(toUpdateDataId).then(() => {});
					banUserForm.setFieldValue('reason', '');
					setModalOpen(false);
					setReason(null);
				} catch (error) {
					console.log(error);
				}
				// console.log(values);
			} else {
				if (selectedBlockLength === 'default') {
					return;
				}

				try {
					setSubmitted(true);

					const data = values;
					data.member_id = member.id;
					data.days_duration = selectedBlockLength;

					const response = await blockSpaceMember(data);
					if (response && response.result) {
						setAlert({
							variant: 'success',
							message: response.message
						});
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						window.scrollTo(0, 0);
					}
					setSubmitted(false);
					setDisplayDropDownError(false);
					getMemberDataRequest(toUpdateDataId).then(() => {});
					banUserForm.setFieldValue('reason', '');
					setModalOpen(false);
					setReason(null);
				} catch (error) {
					console.log(error);
				}
				// console.log(values);
			}
		},
		[member, selectedBlockLength]
	);

	useEffect(() => {
		if (!modalOpen) {
			setAlert(null);
		}
	}, [member, space, getMemberDataRequest, modalOpen]);

	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={customStyles}
		>
			<button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
				<i className="fa-solid fa-xmark"></i>
			</button>
			<h3 className="header-medium mt-2">
				{member.blocked ? 'Unban ' : 'Ban '}
				{member.user.profile.username
					? '@' + member.user.profile.username
					: member.user.profile.full_name}
			</h3>
			<Form
				form={banUserForm}
				name="banUserForm"
				validateMessages={VALIDATE_MESSAGES}
				onFocus={() => {
					setFormIsOnFocus(true);
				}}
				onBlur={() => {
					setTimeout(() => {
						setFormIsOnFocus(false);
						handleFormOnBlur();
					}, 200);
				}}
				autoComplete="off"
				onChange={handleFormChanges}
				onFinish={handleSubmit}
				className={formIsOnFocus ? 'hide-antd-error-messages add-category' : 'add-category'}
			>
				<>
					{alert && (
						<div className="grid grid-cols-12 mt-5">
							<Alert
								key={alert.variant}
								variant={alert.variant}
								className={
									'custom-alert ' +
									(alert.variant === 'danger'
										? 'custom-alert-danger'
										: 'custom-alert-success')
								}
							>
								{alert.message}
							</Alert>
						</div>
					)}
				</>
				<div
					className={
						'input-group-com input-label-modal input-with-btn mb-5 ' +
						(alert ? 'mt-2' : ' mt-5')
					}
				>
					<label className="body-text--small">Reason*</label>
					<Form.Item
						name="reason"
						className="mb-0 hidden-label"
						validateTrigger="onBlur"
						rules={[{ required: true }]}
					>
						<Input
							type="text"
							className="input-md"
							validateTrigger="onBlur"
							// placeholder="Enter details on your reason for banning. This will be sent to the Member."
							disabled={submitted}
							onFocus={hideTooltip}
							onBlur={showTooltip}
						/>
					</Form.Item>
					{!reason && (
						<span className="tool-tip">
							Enter details on your reason for{' '}
							{member.blocked ? 'unbanning' : 'banning'}. This will be sent to the
							Member.
						</span>
					)}
				</div>
				{!member.blocked && (
					<div className="grid grid-cols-12 mt-5">
						<DropdownWithItems
							title="Ban Length"
							dropDownClass="dropdown-bg"
							customButtonClass="btn-md btn-secondary col-span-12"
							displayError={displayDropDownError && selectedBlockLength === 'default'}
							selectedValue={selectedBlockLength}
							listItems={LENGTH_OF_BLOCK}
							onDropdownChange={(value) => filterDropdownChange(value)}
						/>
					</div>
				)}
				{member.blocked ? (
					<p className="body-text--tiny mt-5 mb-5">
						@{member.user.profile.username} was banned on {member.blocked_on}
						{member.blocked_on} by @username for {member.blocked_until_on}. Their ban
						will expire on {member.expiration_on} {member.expiration_on}.
					</p>
				) : (
					<p className="body-text--small mt-5 mb-5">
						You can ban a Member from accessing your Space for 1 day, 1 Week, 1 Month, 3
						Months, or Indefinitely. This will force deactivate their Membership,
						prevent them from accessing any private areas of Space, prevent them from
						receiving notifications, and hide their Profile within your Space. It will
						not remove their ownership of any Memberships or Items.
					</p>
				)}
				<Button
					className="btn btn-md btn-primary mb-2"
					type="submit"
					disabled={submitted || formHasError}
				>
					<i
						className={submitted ? 'fa-solid fa-spinner fa-spin me-2' : 'fa-solid me-2'}
					></i>
					{member.blocked ? 'Unban ' : 'Ban '}
					{member.user.profile.username
						? '@' + member.user.profile.username
						: member.user.profile.full_name}
				</Button>
			</Form>
		</ReactModal>
	);
};

export default BanUserModal;
