import React, {memo, useEffect, useState} from 'react';
import Card from '../../ProfileContent/ManageFollowers/Card';
import {followUser, unfollowUser} from "../../../../services/follow";
import Top8 from "../Top8";
import routes from "../../../../routes";

const Followers = (props) => {
	const { data, authUserInfo, viewUserId, refetch, top8 } = props;

	const handleFollowUnfollow = async (list, event, id) => {
		if (authUserInfo) {
			const data = {
				user_id: authUserInfo.id,
				follow_id: id
			};

			if (event === 'follow') {
				await followUser(data);
			} else {
				await unfollowUser(data);
			}

			refetch();
		}
	};

	return (
		<>
			<div className="cs-profile-main block-xl order-1">
				<div className="block-full">
					<div className="cs-stack-title-filters stack-row-wrap">
						<h2>Followers</h2>
						<div className="cs-stack-filters">
							<button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true"
									aria-haspopup="true"><p>Latest</p><i className="fa-regular fa-angle-down"></i>
							</button>
						</div>
					</div>
				</div>

				<div className="cs-stack-follows">
					{data && data.length > 0 ? (
						<>
							{data.map((item, index) => (
								<div className="cs-datarow-follow" key={index}>
									<div className="datarow">
										<div className="data-item stack-row">
											<div className="igroup stack-row">
												<a href={routes.viewUserSpace() + item.user.profile.username} className="avatar-gradient">
													<img src={item.user.profile.photo_thumb}
														 alt="Image of cs_profile_username"
													/>
												</a>
												<h4><a href={routes.viewUserSpace() + item.user.profile.username}>{'@' + item.user.profile.username}</a>
												</h4>
											</div>
										</div>
										<div className="data-item-2 stack-row">
											<div className="igroup stack-row">
												<i className="fa-solid fa-user-group"></i>
												<p>{item.user.followers_count}</p>
											</div>
											<div className="igroup stack-row">
												<i className="fa-light fa-user-group"></i>
												<p>{item.user.followings_count}</p>
											</div>
										</div>
										<div className="data-item">
											{authUserInfo.id !== item.user.id && (
												<button
													type="button"
													className={(item.user.is_following ? 'secondary ' : '') + " button gradient sm rounded-corners"}
													onClick={() => {
														handleFollowUnfollow(
															'followers',
															item.user.is_following ? 'unfollow' : 'follow',
															item.user.id).then(r => {})
													}}
												><span><p>{item.user.is_following ? 'Unfollow' : 'Follow'}</p></span>
												</button>
											)}
										</div>
									</div>
								</div>
							))}
						</>
					) : (
						<></>
					)}
				</div>

				{/*<div className="cs-pagination block-full">*/}
				{/*	<nav className="pagination-gradient" aria-label="Pagination">*/}
				{/*		<a href="#pprevious" className="pagination-previous">*/}
				{/*			<span className="sr-only">Previous</span>*/}
				{/*			<i className="fa-regular fa-angle-left"></i>*/}
				{/*		</a>*/}
				{/*		<a href="#p1">1</a>*/}
				{/*		<a href="#p2">2</a>*/}
				{/*		<a href="#p3" className="pagination-extra">3</a>*/}
				{/*		<span className="pagination-ellipsis">...</span>*/}
				{/*		<a href="#p8" className="pagination-extra">8</a>*/}
				{/*		<a href="#p9">9</a>*/}
				{/*		<a href="#10">10</a>*/}
				{/*		<a href="pnext" className="pagination-next">*/}
				{/*			<span className="sr-only">Next</span>*/}
				{/*			<i className="fa-regular fa-angle-right"></i>*/}
				{/*		</a>*/}
				{/*	</nav>*/}
				{/*</div>*/}
			</div>

			<aside className="cs-profile-aside block-md order-2">
				<div id="cs-top8" className="stack-col order-1">
					<h2>Top 8</h2>
					<div className="cs-stack-top8 stack-row-wrap lg:stack-col">
						{ top8 && top8.map((data, index) => (
							<Top8
								key={index}
								index={index}
								data={data}
							></Top8>
						))}
					</div>
				</div>
			</aside>


			{/*<div className="user-view col-span-12">*/}
			{/*	<div className="grid grid-cols-12 gap-8 mt-4  card-view">*/}
			{/*		<div className="col-span-12 mt-4">*/}
			{/*			<div className="grid grid-cols-12 gap-4">*/}
			{/*				{data && data.length > 0 ? (*/}
			{/*					<>*/}
			{/*						{data.map((item, index) => (*/}
			{/*							<Card*/}
			{/*								col="col-span-12 sm:col-span-6 lg:col-span-4"*/}
			{/*								key={index}*/}
			{/*								type="followers"*/}
			{/*								data={item}*/}
			{/*								onEventClick={handleFollowUnfollow}*/}
			{/*								displayButtons={false}*/}
			{/*							></Card>*/}
			{/*						))}*/}
			{/*					</>*/}
			{/*				) : (*/}
			{/*					<>*/}
			{/*						{!data && authUserInfo && authUserInfo.id === viewUserId ? (*/}
			{/*							<div className='col-span-12'>*/}
			{/*								<h3 className="header-medium text-center mt-5">*/}
			{/*									No Followers Yet.*/}
			{/*								</h3>*/}
			{/*								<p className="header-small color-reg mt-5 text-center">*/}
			{/*									Profiles that follow you will show here.*/}
			{/*								</p>*/}
			{/*							</div>*/}
			{/*						) : (*/}
			{/*							<div className='col-span-12'>*/}
			{/*								<h3 className="header-medium text-center mt-5">*/}
			{/*									This profile has no followers.*/}
			{/*								</h3>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</>*/}
			{/*				)}*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</>
	);
};

export default memo(Followers);
