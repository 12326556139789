import React, { memo, useEffect, useCallback, useState } from 'react';
import {Button, Form} from 'antd';
import {useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import routes from '../../../routes';
import {createUpdateMembershipDetails, getMembershipDetails, getSpaceMembership, getSpacePayoutMethods} from '../../../services/space';
import '../../SpaceBuyMembership/SpaceBuyMembership.scss'
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {checkActiveMembershipStatus, checkMembershipItemStatus, updateMembershipItemStatus} from "../../../services/payment_method";
import {getSitewideLink} from "../../../utils/sitewideLinks";
import ActivationMembershipModal from "../../../components/commons/Modals/ActivationMembership/ActivationMembershipModal";
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";
import {getListDisplay} from "../../../utils/list";
import {PRONOUNS} from "../../../constants/profile";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import {updateSpacePost} from "../../../services/post";
import FormFieldStacked from "../../../components/commons/FormFieldStacked";
import {register} from "../../../services/user";
import {setAccessToken} from "../../../utils/common";
import CreateMemberProfile from "../../../components/commons/CreateMemberProfile";
import {getLocalStorage} from "../../../utils/storage";

const MembershipActivateWalletConnect = (props) => {
	const { verifyEmailSpaceUsername, verifyEmailSpaceMembershipId } = props;
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [membership, setMembership] = useState(null);
	const [isSubmitted, setSubmitted] = useState(false);
	const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);
	const [isActivationOpen, setActivationOpen] = useState(false);
	const [collectionAssetType, setCollectionAssetType] = useState('image');
	const [isNewAccountMembership, setIsNewAccountMembership] = useState(false);
	const [membershipDetails, setMembershipDetails] = useState(false);
	const [isMembershipDetailsLoaded, setMembershipDetailsLoaded] = useState(false);
	const [isKeepActiveMembership, setIsKeepActiveMembership] = useState(false);
	const [isSwitchMembership, setIsSwitchMembership] = useState(false);

	const skipActivation = useCallback(async () => {
		const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
		const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
		navigate(fin_url);
	}, [verifyEmailSpaceUsername, verifyEmailSpaceMembershipId]);


	// Get the Membership Details of the user
	const getMembershipDetailsQuery = useCallback(async () => {
		try {
			if (!spaceInfo) {
				return;
			}
			if (!authUserInfo) {
				return;
			}
			const params = {};
			params.space_id = spaceInfo.id;
			params.user_id = authUserInfo.id;
			params.is_include_active_membership = true;
			const response = await getMembershipDetails(params);
			if (response && response.result) {
				const membershipDetails = response.membership_details;
				setMembershipDetails(membershipDetails);
				setIsNewAccountMembership(membershipDetails === null && getLocalStorage('redirect_link'));
			}
		} catch (error) {
			console.log(error);
		}
	}, [spaceInfo, authUserInfo, membership]);

	const getMembership = useCallback(async (space_id, membership_id) => {
		const data = {
			space_id: space_id,
			membership_id: membership_id,
			is_get_payment_transaction : true
		}
		if (authUserInfo) {
			data['viewing_user_id'] = authUserInfo.id;
		}
		const response = await getSpaceMembership(data);
		if (response && response.result) {
			const membershipData = response.membership;
			setMembership(response.membership);
			if (membershipData.photo || membershipData.video) {
				if (membershipData.photo) {
					setCollectionAssetType('image');
				}
				if (membershipData.video) {
					setCollectionAssetType('video');
				}
			} else {
				setCollectionAssetType('image');
			}
		}
	}, []);

	const checkStatus = useCallback(async (space_id, membership_id) => {
		if (!membership_id) return;
		const data = {
			space_id: space_id,
			membership_id: membership_id,
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const response = await checkMembershipItemStatus(data);
		if (response) {
			setCheckStatusLoaded(true);
			if (response.result) {
				setCheckStatusLoaded(true);
				const transaction = response.stripe_transaction;
				if (transaction) {
					if (transaction.status !== 'inactive') {
						const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
						const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
						navigate(fin_url);
					}
				} else {
					const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
					const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
					navigate(fin_url);
				}
			}
			setCheckStatusLoaded(true);
		}
	},[authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId])


	const activateMembershipStatus = useCallback(async (isSwitchMembership = false) => {
		setSubmitted(true);
		if (!verifyEmailSpaceMembershipId) return;
		if (!spaceInfo) return;
		const data = {
			space_id: spaceInfo.id,
			membership_id: verifyEmailSpaceMembershipId,
			status: 'active'
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const createStripeResponse = await updateMembershipItemStatus(data);
		if (createStripeResponse && createStripeResponse.result) {
			setTimeout(() => {
				if (!isSwitchMembership) {
					const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
					const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
					navigate(fin_url);
				}
			}, 500);

			setSubmitted(false);
		} else {
			setSubmitted(false);
		}
	},[authUserInfo, spaceInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId])

	const keepActiveMembership = useCallback(async () => {
		setIsKeepActiveMembership(true);
	}, [])

	const switchNewMembership = useCallback(async () => {
		setIsSwitchMembership(true);
		activateMembershipStatus(true).then(r => {});
	}, [])

	const redirectToMembers = useCallback(async () => {
		const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
		const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '/member';
		navigate(fin_url);
	}, [verifyEmailSpaceUsername])

	const redirectToDashboard = useCallback(async () => {
		const url = routes.dashboard();
		navigate(url);
	}, [])

	const checkActivateSubscription = useCallback(async () => {
		const data = {
			space_id: spaceInfo.id
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const checkActiveStatusResponse = await checkActiveMembershipStatus(data);
		if (checkActiveStatusResponse && checkActiveStatusResponse.result) {
			if (checkActiveStatusResponse.is_active_exist) {
				setActivationOpen(true);
			} else {
				await activateMembershipStatus();
			}
		} else {
			await activateMembershipStatus();
		}
	},[spaceInfo, authUserInfo])

	const handleFormChanges = useCallback(async () => {
		setUsername(form.getFieldValue('username'));
		setDisplayName(form.getFieldValue('display_name'));
		setLocation(form.getFieldValue('location'));
		setBio(form.getFieldValue('bio'));
		setWebsite(form.getFieldValue('website'));

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(async (values) => {
		setSubmitted(true);
		const params = {};
		params.space_id = spaceInfo.id;
		params.user_id = authUserInfo.id;
		params.membership_id = membership.id;
		params.community_bio = values.community_bio;
		params.favorite_food = values.favorite_food;
		params.favorite_city = values.favorite_city;
		params.favorite_spirit = values.favorite_spirit;
		params.food_allergies_restrictions = values.food_allergies_restrictions;
		try {
			const response = await createUpdateMembershipDetails(params);
			if (response && response.result) {
				await activateMembershipStatus();
			} else {

			}
		} catch (error) {
			console.log(error);
		}
		},[spaceInfo, authUserInfo, membership]
	)

	// Check and make a request for the getMembershipDetails
	useEffect(()=>{
		if (spaceInfo && authUserInfo && membership && !isMembershipDetailsLoaded) {
			setMembershipDetailsLoaded(true);
			getMembershipDetailsQuery().then(r => {});
		}
	},[spaceInfo, authUserInfo, membership, isMembershipDetailsLoaded])

	useEffect(() => {
		if (verifyEmailSpaceUsername) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					getMembership(spaceInfo.id, verifyEmailSpaceMembershipId).then((r) => {});
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(verifyEmailSpaceUsername));
				}
			}
		}

		if (spaceInfo && verifyEmailSpaceMembershipId && authUserInfo) {
			checkStatus(spaceInfo.id, verifyEmailSpaceMembershipId).then(r => {});
		}
		//Redirect user to logged in page if not authenticated
		MembershipItemUtils.notAuthRedirect(loading, authUserInfo);
		console.log(authUserInfo);
	}, [
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		initialLoad,
		verifyEmailSpaceUsername,
		verifyEmailSpaceMembershipId,
	]);

	useEffect(()=> {
		if (membershipDetails) {
			if ((membershipDetails && membership && membership?.payment_transaction?.status === 'inactive') && !membershipDetails?.active_membership) {
				form.setFieldsValue({
					community_bio: membershipDetails.community_bio ?? '',
					favorite_food: membershipDetails.favorite_food ?? '',
					favorite_city: membershipDetails.favorite_city ?? '',
					favorite_spirit: membershipDetails.favorite_spirit ?? '',
					food_allergies_restrictions: membershipDetails.favorite_spirit ?? ''
				});
			}
		}
	},[membershipDetails])

	useEffect(()=> {
	},[
		membership,
		membershipDetails,
		isKeepActiveMembership,
		isSwitchMembership,
		isSubmitted,
		isCheckStatusLoaded
	])


	return (
		<>
            <main id="cs-space-main" className="cs-main main-theme">
                {(!spaceInfo || !authUserInfo) ? (
					<section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
						<div className="centerframe-flex frame-modal">
							<div className="block-lg modal-block">
								<i className="fa-regular fa-solar-system"/>
							</div>
						</div>
					</section>
				) : (
					<>
						{/*  Create your member profile here for the newly created account from signup buy */}
						{isNewAccountMembership ? (
							<CreateMemberProfile
								isNewAccountFromPurchase={true}
								authUserInfo={authUserInfo}
								spaceInfo={spaceInfo}
								membership={membership}
							/>
						) : (
							<section id="cs-product-membership-activate-c1" className="p-section-safeview theme-transparent">
								<div className="centerframe-grid gap-2gutter">
									{/*
										fam-space-product-membership-activate-new
										Note: Add the flagging here to detect the newly activated membership
									*/}

									{/*
										fam-space-product-membership-activate-existing-inactive
										Note: Add the flagging here
									*/}

									<>
										{ (!membershipDetails || (membershipDetails && membership && membership?.payment_transaction?.status === 'inactive') && !membershipDetails?.active_membership) && (
											<>
												<div id="cs-product-activate-main" className="block-lg order-1">
													<hgroup className="heading">
														{ (membership && !membership?.payment_transaction && !membershipDetails?.active_membership) && (
															<>
																{/*CSJS "Membership" or "Item" based on the product type */}
																<h1>Welcome to {membership?.name ?? ''}!</h1>
																{/* CSJS "Membership" or "Item" based on the product type */}
																<p className="text-p2">Complete your Member Details below to activate your membership.</p>
															</>
														)}

														{ (membership && membership?.payment_transaction?.status === 'inactive') && (
															<>
																{/*CSJS "Membership" or "Item" based on the product type */}
																<h1>Welcome to {membership?.name ?? ''}!</h1>
																{/* CSJS "Membership" or "Item" based on the product type */}
																<p className="text-p2">Review and update your Member Details below to activate your membership.</p>
															</>
														)}

													</hgroup>

													<div className="cs-product-preview-row">
														<div className="avatar-square-sm"><img src={membership?.photo ?? ''} alt="Image of Friends & Mike Membership"/></div>
														<hgroup>
															{/*CSJS Product Name*/}
															<h5>{membership?.name ?? ''}</h5>
															{/*CSJS Product Info Summary*/}
															<p>{membership?.summary ?? ''}</p>
														</hgroup>
													</div>

													<Form className="stack-col"
														  form={form}
														  name="form"
														  validateMessages={VALIDATE_MESSAGES}
														  onFocus={() => {
															  // setFormIsOnFocus(true);
														  }}
														  onBlur={() => {
															  // setFormIsOnFocus(false);
															  // handleSignUpFormOnBlur().then(() =>{});
														  }}
														  autoComplete="off"
														// onChange={handleSignUpFormChanges}
														  onFinish={handleSubmit}
													>

														<div className="cs-stack-space-create-community-profile stack-col">
															<hgroup className="title">
																{/*CSJS "Friends & Mike" is space_name*/}
																<h3>{membership?.name ?? ''}</h3>
																<p>Share additional details on your Profile, visible to Members only.</p>
															</hgroup>

															<div className="form-textarea air">
																<label htmlFor="community-bio">Community Bio</label>
																{/*CSJS Default Community Field (Always Present), Friends & Mike is space_name. Values are filled with Member's existing data if not new.*/}
																{/*<textarea id="community-bio" name="community-bio" placeholder="Share details about yourself with Friends & Mike members." rows="3"/>*/}
																<Form.Item
																	name="community_bio"
																	validateTrigger="onBlur"
																	className="w-full"
																	rules={[
																		// {
																		// 	max: 32,
																		// 	message: 'Must not exceed 32 characters.'
																		// }
																	]}
																>
																	<FormFieldStacked
																		formType='textarea'
																		name="community_bio"
																		// id="community-bio"
																		type="text"
																		// maxLength={32}
																		disabled={isSubmitted}
																		placeholder="Share details about yourself with Friends & Mike members."
																	/>
																</Form.Item>
															</div>
															<div className="form-input air">
																<label htmlFor="favorite-food">Favorite Food</label>
																{/*<input type="text" name="favorite-food" id="favorite-food" required placeholder="What is your favorite food of all time?"/>*/}
																<Form.Item
																	name="favorite_food"
																	validateTrigger="onBlur"
																	className="w-full"
																	rules={[
																		// {
																		// 	max: 32,
																		// 	message: 'Must not exceed 32 characters.'
																		// }
																	]}
																>
																	<FormFieldStacked
																		formType='input'
																		name="favorite_food"
																		// id="favorite-food"
																		type="text"
																		// maxLength={32}
																		disabled={isSubmitted}
																		placeholder="What is your favorite food of all time?"
																	/>
																</Form.Item>
															</div>
															<div className="form-input air">
																<label htmlFor="favorite-city">Favorite City</label>
																{/*<input type="text" name="favorite-city" id="favorite-city" required placeholder="What is your favorite city to visit?"/>*/}
																<Form.Item
																	name="favorite_city"
																	validateTrigger="onBlur"
																	className="w-full"
																	rules={[
																		// {
																		// 	max: 32,
																		// 	message: 'Must not exceed 32 characters.'
																		// }
																	]}
																>
																	<FormFieldStacked
																		formType='input'
																		name="favorite_city"
																		// id="favorite-city"
																		type="text"
																		// maxLength={32}
																		disabled={isSubmitted}
																		placeholder="What is your favorite city to visit?"
																	/>
																</Form.Item>
															</div>
															<div className="form-input air">
																<label htmlFor="favorite-spirit">Favorite Spirit</label>
																{/*<input type="text" name="favorite_spirit" id="favorite-spirit" required placeholder="What is your favorite type of alcoholic spirit? (Optional)"/>*/}
																<Form.Item
																	name="favorite_spirit"
																	validateTrigger="onBlur"
																	className="w-full"
																	rules={[
																		// {
																		// 	max: 32,
																		// 	message: 'Must not exceed 32 characters.'
																		// }
																	]}
																>
																	<FormFieldStacked
																		formType='input'
																		name="favorite_spirit"
																		// id="favorite-spirit"
																		type="text"
																		// maxLength={32}
																		disabled={isSubmitted}
																		placeholder="What is your favorite type of alcoholic spirit? (Optional)"
																	/>
																</Form.Item>
															</div>
														</div>

														<div className="cs-stack-space-create-community-private-info stack-col">
															<hgroup className="title">
																{/*CSJS "Friends & Mike" is space_name*/}
																<h3>{membership?.name ?? ''} Member Information</h3>
																{/*CSJS "Friends & Mike" is space_name*/}
																<p>Complete the following member information fields. This information is kept private and only visible to {membership?.name ?? ''} staff.</p>
															</hgroup>

															<div className="form-input air">
																<label htmlFor="food-allergies-restrictions">Food Allergies & Restrictions</label>
																{/*<input type="text" name="food-allergies-restrictions" id="food-allergies-restrictions" required placeholder="Let us know if you have any allergies or dietary restrictions."/>*/}
																<Form.Item
																	name="food_allergies_restrictions"
																	validateTrigger="onBlur"
																	className="w-full"
																	rules={[
																		// {
																		// 	max: 32,
																		// 	message: 'Must not exceed 32 characters.'
																		// }
																	]}
																>
																	<FormFieldStacked
																		formType='input'
																		name="food_allergies_restrictions"
																		// id="food-allergies-restrictions"
																		type="text"
																		// maxLength={32}
																		disabled={isSubmitted}
																		placeholder="Let us know if you have any allergies or dietary restrictions."
																	/>
																</Form.Item>
															</div>
														</div>

														<Form.Item>
															<div className="stack-buttons col">
																{/*CSJS Can use a button instead of an A element by changing element from "a" to "button" and removing "button-" from class.*/}
																<button
																	// disabled={submitted}
																	type="submit"
																	className="primary-gradient">
																	<span><p>Save & Activate</p></span>
																</button>
															</div>
														</Form.Item>
													</Form>
												</div>
											</>
										)}
									</>
									{/* END of fam-space-product-membership-activate-new */}

									{/*
										fam-space-product-membership-activate-existing-confirmation-switch
										Note: Add the flagging here
									*/}
									{ (membership && membershipDetails && membershipDetails?.active_membership && !isKeepActiveMembership && !isSwitchMembership && membership.id !== membershipDetails?.active_membership?.id ) && (
										<>
											<div id="cs-product-activate-main" className="block-lg order-1">
												<hgroup className="heading">
													{/* CSJS "Membership" or "Item" based on the product type*/}
													<h1>Existing Active Membership</h1>
													{/*CSJS "Membership" or "Item" based on the product type*/}
													<p className="text-p2">Your currently have another <a href={`@${authUserInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a>
														{' '}Membership active. You can only have one Membership active at a time.
													</p>
												</hgroup>

												<div className="cs-stack-title-preview">
													<h4>Active Membership</h4>
													<div className="cs-product-preview-row">
														<div className="avatar-square-gradient-md"><img src={membershipDetails?.active_membership?.photo ?? ''}  alt={membershipDetails?.active_membership?.name ?? ''}/></div>
														<hgroup>
															{/*CSJS Product Name*/}
															<h5>{membershipDetails?.active_membership?.name ?? ''} Membership</h5>
															{/*CSJS Product Info Summary*/}
															<p>{membershipDetails?.active_membership?.summary ?? ''}</p>
														</hgroup>
													</div>
												</div>

												<div className="cs-stack-title-preview">
													<h4>New Membership</h4>
													<div className="cs-product-preview-row">
														<div className="avatar-square-gradient-md"><img src={membership?.photo ?? ''}  alt={membership?.name ?? ''}/></div>
														<hgroup>
															{/*CSJS Product Name*/}
															<h5>{membership?.name ?? ''} Membership</h5>
															{/*CSJS Product Info Summary*/}
															<p>{membership?.summary ?? ''}</p>
														</hgroup>
													</div>
												</div>

												<div className="stack-col">
													<div className="cs-stack-space-create-community-profile stack-col">
														<hgroup className="title">
															<h3>Select Membership</h3>
															<p>Please select which Membership you would like to use as as your active Membership. If you choose to change Memberships, your existing Membership will be deactivated and your selected Membership will be activated. Deactivated Memberships are not lost and can always be reactivated from your Dashboard.</p>
														</hgroup>

													</div>
													<div className="stack-buttons col">
														<button onClick={keepActiveMembership} className="secondary-gradient"><span><p>Keep Active Membership</p></span></button>
														<button onClick={switchNewMembership} className="secondary-gradient"><span><p>Switch to New Membership</p></span></button>
													</div>
												</div>
											</div>
										</>
									)}
									{/*
										END of fam-space-product-membership-activate-existing-confirmation-switch
									*/}
									{/*
										Start of fam-space-product-membership-activate-existing-confirmation-keep
									*/}
									{ isKeepActiveMembership && (
										<>
											<div id="cs-product-activate-main" className="block-lg order-1">
												<hgroup className="heading">
													{/* CSJS "Membership" or "Item" based on the product type*/}
													<h1>No Changes Made</h1>
													{/*CSJS "Membership" or "Item" based on the product type*/}
													<p className="text-p2">Your current <a href={`@${authUserInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"></i> {spaceInfo?.name ?? ''}</a>
														{' '}Membership has been kept active. No changes have been made to your Items.
													</p>
												</hgroup>

												<div className="cs-stack-title-preview">
													<h4>Active Membership</h4>
													<div className="cs-product-preview-row">
														<div className="avatar-square-gradient-md"><img src={membershipDetails?.active_membership?.photo ?? ''}  alt={membershipDetails?.active_membership?.name ?? ''}/></div>
														<hgroup>
															{/*CSJS Product Name*/}
															<h5>{membershipDetails?.active_membership?.name ?? ''} Membership</h5>
															{/*CSJS Product Info Summary*/}
															<p>{membershipDetails?.active_membership?.summary ?? ''}</p>
														</hgroup>
													</div>
												</div>

												<div className="cs-stack-title-preview">
													<h4>New Membership <span>Inactive</span></h4>
													<div className="cs-product-preview-row">
														<div className="avatar-square-gradient-md"><img src={membership?.photo ?? ''}  alt={membership?.name ?? ''}/></div>
														<hgroup>
															{/*CSJS Product Name*/}
															<h5>{membership?.name ?? ''} Membership</h5>
															{/*CSJS Product Info Summary*/}
															<p>{membership?.summary ?? ''}</p>
														</hgroup>
													</div>
												</div>

												<p className="text-p2">You can always manage your Memberships and Items in your Dashboard.</p>

												<button onClick={redirectToMembers} className="primary-gradient"><span><p>Go to Members</p></span></button>

											</div>
										</>
									)}

									{/*
										Start of fam-space-product-membership-activate-existing-confirmation-switch
									*/}
									{ isSwitchMembership && (
										<>
											<div id="cs-product-activate-main" className="block-lg order-1">
												<hgroup className="heading">
													<h1>New Membership Activated</h1>
													<p className="text-p2">Your new <a href={`@${authUserInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a>
														{' '}Membership is now active and your previous Membership has been deactivated. No changes have been made to your Items.
													</p>
												</hgroup>

												<div className="cs-stack-title-preview">
													<h4>New Membership Active</h4>
													<div className="cs-product-preview-row">
														<div className="avatar-square-gradient-md"><img src={membership?.photo ?? ''}  alt={membership?.name ?? ''}/></div>
														<hgroup>
															{/*CSJS Product Name*/}
															<h5>{membership?.name ?? ''} Membership</h5>
															{/*CSJS Product Info Summary*/}
															<p>{membership?.summary ?? ''}</p>
														</hgroup>
													</div>
												</div>

												<div className="cs-stack-title-preview">
													<h4>Existing Membership <span>Inactive</span></h4>
													<div className="cs-product-preview-row">
														<div className="avatar-square-gradient-md"><img src={membershipDetails?.active_membership?.photo ?? ''}  alt={membershipDetails?.active_membership?.name ?? ''}/></div>
														<hgroup>
															{/*CSJS Product Name*/}
															<h5>{membershipDetails?.active_membership?.name ?? ''} Membership</h5>
															{/*CSJS Product Info Summary*/}
															<p>{membershipDetails?.active_membership?.summary ?? ''}</p>
														</hgroup>
													</div>
												</div>

												<p className="text-p2">You can always manage your Memberships and Items in your Dashboard.</p>

												<div className="stack-buttons col">
													<button onClick={redirectToDashboard} className="primary-gradient"><span><p>View in Dashboard</p></span></button>
													<button onClick={redirectToMembers} className="secondary-gradient"><span><p>Go to Members</p></span></button>
												</div>

											</div>
										</>
									)}


									<aside id="cs-profile-create-aside" className="block-lg order-2 hidden lg:block">
										{/*CSJS BG Image as inline style background.*/}
										<div className="cs-profile-preview card sticky top-2gutter-xl"
											 style={{backgroundImage: `url("${authUserInfo?.profile?.background_photo ?? ''}")`}}
										>
											{/*CSJS Add class "shader" if background-image is applied in parent element cs-profile-preview, i.e when a user has uploaded a background.*/}
											<div className="cs-stack-profile-preview rounded-card stack-col shader">
												{/*CSJS Image as inline style background.*/}
												<div className="form-input-media lg circle air nohover"
													 style={{backgroundImage: `url("${authUserInfo?.profile?.photo_thumb ?? ''}")`}}
												>
													<a href="#">
													</a>
												</div>
												<hgroup>
													{/*CSJS Add "active" class when content present.*/}
													<p className="text-p1 active">@{authUserInfo?.profile?.username ?? 'username'}</p>
													<h1>{authUserInfo?.profile?.display_name ?? 'Display Name'}</h1>
													<p className="text-p2 italic">
														{authUserInfo?.profile?.pronouns ?? 'Pronouns'}
													</p>
												</hgroup>
												<p className="italic">{authUserInfo?.profile?.intro ?? 'Short Bio goes here'}</p>
												<div className="cs-stack-icons stack-row-wrap">
													{/*CSJS Only show if exists for User Profile.*/}
													<i className="fa-brands fa-x-twitter"/>
													{/*CSJS Only show if exists for User Profile.*/}
													<i className="fa-brands fa-instagram"/>
													{/*CSJS Only show if exists for User Profile.*/}
													<i className="fa-brands fa-facebook-f"/>
													{/*CSJS Only show if exists for User Profile.*/}
													<i className="fa-brands fa-tiktok"/>
													{/*CSJS Only show if exists for User Profile.*/}
													<i className="fa-brands fa-linkedin-in"/>
													{/*CSJS Only show if exists for User Profile.*/}
													<i className="fa-brands fa-twitch"/>
													{/*CSJS Only show if exists for User Profile.*/}
													<i className="fa-regular fa-globe"/>
												</div>
											</div>
										</div>
									</aside>
								</div>







								{/*<div className="cs-centerframe-split-lg">*/}
								{/*    <div className="cs-centerframe-split-left-lg order-2 lg:order-1">*/}
								{/*        <div className="p-section-safeview lg:pr-gutter">*/}
								{/*            <div className="cs-stack-checkout-form stack-col-base gap-gutterxx lg:max-w-half-breakpoint-less-gutter">*/}
								{/*                <div className="cs-stack-checkout-confirmation stack-col">*/}
								{/*                    <hgroup className="heading">*/}
								{/*                        <h2>Your Membership is ready!</h2>*/}
								{/*                    </hgroup>*/}
								{/*                </div>*/}
								{/*                <div className="cs-stack-checkout-activate stack-col">*/}
								{/*                    <hgroup className="heading">*/}
								{/*                        <p>You’re ready to access{' '}*/}
								{/*							{membership ? membership.name : ''}! You can activate*/}
								{/*							your Membership on your Account now or you can activate it later.*/}
								{/*							You won’t get access to the Membership Benefits until you do.</p>*/}
								{/*                    </hgroup>*/}

								{/*                    <div className="cs-product-preview-row">*/}
								{/*                        <div className="avatar-square-sm">*/}
								{/*                        <img src={membership?.photo} alt="Image of Friends & Mike Membership"/>*/}
								{/*                        </div>*/}
								{/*                        <hgroup>*/}
								{/*                            <h5>{' '}{spaceInfo ? spaceInfo.name : ''} Membership</h5>*/}
								{/*                            <p>The Official {' '}{spaceInfo ? spaceInfo.name : ''} Membership unlocks global benefits and community.</p>*/}
								{/*                        </hgroup>*/}
								{/*                    </div>*/}

								{/*                    <div className="stack-buttons col">*/}
								{/*                        <a onClick={checkActivateSubscription} type="button" className="button-primary-gradient"><span><p>Continue to Activate</p></span></a>*/}
								{/*                        <a onClick={skipActivation} type="button" className="button-secondary-gradient"><span><p>Skip Activation</p></span></a>    */}
								{/*                    </div>*/}
								{/*                </div>*/}
								{/*            </div>*/}
								{/*        </div>*/}
								{/*    </div>*/}
								{/*    <div className="cs-centerframe-split-right-lg order-1 lg:order-2 lg:pt-gutter lg:pb-gutter">*/}
								{/*    */}
								{/*        <div className="cs-checkout-product-hero">*/}
								{/*            */}
								{/*            {membershipItemImage(collectionAssetType, membership)}*/}
								{/*        </div>*/}
								{/*    </div>*/}
								{/*</div>*/}
								{/*{ membershipDetails &&*/}
								{/*	<ActivationMembershipModal*/}
								{/*		modalOpen={isActivationOpen}*/}
								{/*		setModalOpen={setActivationOpen}*/}
								{/*		membership={membershipDetails}*/}
								{/*		space={spaceInfo}*/}
								{/*		user={authUserInfo}*/}
								{/*		activationStatus={'active'}*/}
								{/*		isPaymentMethod={true}*/}
								{/*		redirectionPath={redirectionPath}*/}
								{/*	/>*/}
								{/*}*/}
							</section>

						)}
					</>
                )}
            </main>
		</>
	);
};

export default memo(MembershipActivateWalletConnect);
