import React, {memo} from "react";

const SpaceCollection = (parentsProps) => {
    const { space, goToSpaceCollections, handleClick } = parentsProps;

    return (
        <>
            <div className="cs-dash-card block-full card">
                <div className="formrow title">
                    <h3>{space.name + ' Collection'}</h3>
                    <p>Items & Memberships you've collected</p>
                </div>

                <div className="cs-stack-controlrows stack-form-col">
                    {space.items ? (
                        <div className="formrow">
                            <div className="data-item">
                                <div className="stack-avatar md">
                                    {space.items && space.items.length > 0 ? (
                                        space.items.slice(0, 4).map((key, index) => (
                                            <div
                                                className="avatar gradient md cs-avatar-item"
                                                key={index}
                                            >
                                                <img src={key.photo} alt=""/>
                                            </div>
                                        ))
                                    ) : (
                                        <h3>
                                            0 Items Owned
                                        </h3>
                                    )}
                                </div>
                                {space.items.length > 4 && (
                                    <p className="data-count">+{space.items.length - 4}</p>
                                )}
                            </div>
                            {space.items && space.items.length > 0 && (
                                <a
                                    onClick={() => {goToSpaceCollections().then(r => {})}}
                                    className="button gradient secondary">
                                    <span><p>View Items</p></span>
                                </a>
                            )}
                        </div>
                    ) : (
                        <h3>
                            0 Items Owned
                        </h3>
                    )}

                    {space.memberships ? (
                        <div className="formrow">
                            <div className="data-item">
                                <div className="stack-avatar md">
                                    {space.memberships && space.memberships.length > 0 ? (
                                        space.memberships.slice(0, 4).map((key, index) => (
                                            <div
                                                className="avatar gradient md cs-avatar-membership"
                                                key={index}
                                            >
                                                <img src={key.photo} alt=""/>
                                            </div>
                                        ))
                                    ) : (
                                        <h3>
                                            0 Keys Owned
                                        </h3>
                                    )}
                                </div>
                                {space.memberships.length > 4 && (
                                    <p className="data-count">+{space.memberships.length - 4}</p>
                                )}
                            </div>
                            {space.memberships && space.memberships.length > 0 && (
                                <a
                                    onClick={()=>handleClick()}
                                    className="button gradient secondary">
                                    <span><p>View Memberships</p></span>
                                </a>
                            )}
                        </div>
                    ) : (
                        <h3>
                            0 Keys Owned
                        </h3>
                    )}
                </div>
            </div>
        </>
    )
}

export default memo(SpaceCollection);