import React, {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Autocomplete from 'react-google-autocomplete';
import {Button, Checkbox, DatePicker, Form, Input} from 'antd';

import routes from '../../../routes';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import {MEMBERSHIP_DATA_SHARING, MEMBERSHIP_TYPES} from '../../../constants/space';
import {GOOGLE_MAP_KEY, VALIDATE_MESSAGES} from '../../../constants/common';
import {getSpaceItemData, postUserSpaceItemClaimAndSetupSharing, postUserSpaceItemClaimAndSignup} from '../../../services/space';
import {getSpaceInfo} from '../../../states/spaceGlobal/actions';
import {getUserSpaceItemClaim} from '../../../services/user';
import {getLocalStorage, removeLocalStorage} from '../../../utils/storage';
import {setAccessToken} from '../../../utils/common';
import dayjs from 'dayjs';
import default_items_img from '../../../assets/images/dj_party.png';
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {createStripePaymentIntent} from "../../../services/payment_method";
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";

const ItemClaimWalletConnected = () => {
	const currentLocation = useLocation();
	let space_username = null;
	const [searchParams, setSearchParams] = useSearchParams();
	const itemId = searchParams.get('id');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [claimWalletConnectedForm] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [emptyEmail, setEmptyEmail] = useState(true);
	const [alert, setAlert] = useState(null);
	const [agreementChecked1, setAgreementChecked1] = useState(false);
	const [agreementChecked2, setAgreementChecked2] = useState(false);
	const [itemDetails, setItemDetails] = useState(null);
	const [itemClaim, setItemClaim] = useState(null);
	const [selectedFirstName, setSelectedFirstName] = useState('share');
	const [selectedBirthDate, setSelectedBirthDate] = useState('share');
	const [selectedCity, setSelectedCity] = useState('share');
	const [selectedEmail, setSelectedEmail] = useState('share');
	const [itemFound, setItemFound] = useState(false);
	const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
	const [collectionAssetType, setCollectionAssetType] = useState('image');
	const [emptyCity, setEmptyCity] = useState(true);
	const [inputElement, setInputElement] = useState();

	const path = currentLocation.pathname;
	if (path && path.startsWith('/@') && path.length > 3) {
		const clean_path = path.replace('/@', '');
		const path_exp = clean_path.split('/');
		space_username = path_exp[0];
	}

	const checkEmailField = useCallback(async (emailField) => {
		if (!emailField || emailField === '') {
			setEmptyEmail(true);
		} else {
			setEmptyEmail(false);
		}
	}, []);

	const handleFormOnBlur = useCallback(async () => {
		await claimWalletConnectedForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		checkEmailField(claimWalletConnectedForm.getFieldValue('email')).then();

		let emptyCity = true;
		if (claimWalletConnectedForm.getFieldValue('city')) {
			emptyCity = false;
		}
		setEmptyCity(emptyCity);

		await claimWalletConnectedForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const onChangeAgreement1 = (e) => {
		setAgreementChecked1(e.target.checked);
	};

	const onChangeAgreement2 = (e) => {
		setAgreementChecked2(e.target.checked);
	};

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setSubmitted(true);

			if (!selectedFirstName) {
				return;
			}

			if (!selectedBirthDate) {
				return;
			}

			if (!selectedCity) {
				return;
			}

			if (!selectedEmail) {
				return;
			}

			const data = values;
			data.space_id = spaceInfo.id;
			data.item_id = itemId;
			data.share_name = selectedFirstName === 'share';
			data.share_birthdate = selectedBirthDate === 'share';
			data.share_city = selectedCity === 'share';
			data.share_email = selectedEmail === 'share';

			if (values.birthdate) {
				data.birthdate = values.birthdate.format('YYYY-MM-DD');
			}

			data.wallet_contract_address = getLocalStorage(
				'temp_item_wallet_owner_contract_address'
			);

			if (authUserInfo) {
				data.user_id = authUserInfo.id;
				const response = await postUserSpaceItemClaimAndSetupSharing(data);
				if (response && response.result) {
					removeLocalStorage('temp_item_nft_is_owned');
					removeLocalStorage('temp_item_space_username');
					removeLocalStorage('temp_item_wallet_owner_contract_address');
					removeLocalStorage('temp_item_wallet_owner_email');

					let url = routes.spaceMembersProductsItem();
					const isSubscription = itemDetails.enable_expiration_and_subscription;

					const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
					// if (!isSpaceMember) {
					const data = {
						item_id: itemId,
						space_id: spaceInfo.id,
						user_id: authUserInfo.id,
						payment_type: 'claim'
					}

					const response = await createStripePaymentIntent(data);

					if (response && response.result) {
						if (itemDetails.collection_type === 'item') {
							if (itemDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION && !isSubscription) {
								url = routes.spaceItemActivate();
							}
						}
					}
					// }
					const fin_url = url.replace(':space_username', '@' + space_username) + '?id=' + itemId;
					navigate(fin_url);
				} else if (!response.result && response.message === "Already claimed item.") {
					let url = routes.spaceItemActivate();
					const fin_url = url.replace(':space_username', '@' + space_username) + '?id=' + itemId;
					navigate(fin_url);
				}
			} else {
				const response = await postUserSpaceItemClaimAndSignup(data);
				if (response && response.result) {
					removeLocalStorage('temp_item_nft_is_owned');
					removeLocalStorage('temp_item_space_username');
					removeLocalStorage('temp_item_wallet_owner_contract_address');
					removeLocalStorage('temp_item_wallet_owner_email');

					if (response.access_token) {
						setAccessToken(response);
					}

					let url = routes.spaceMembershipWalletSetAccountEmailSent();
					if (!values.email) {
						url = routes.spaceMembershipRegisterMemberProfile();
					}

					const fin_url =
						url.replace(':space_username', '@' + space_username) + '?id=' + itemId;
					navigate(fin_url);
				} else {
					setAlert({ variant: 'danger', message: response.message });
					window.scrollTo(0, 0);
				}
			}

			setSubmitted(false);
		},
		[
			authUserInfo,
			spaceInfo,
			selectedFirstName,
			selectedBirthDate,
			selectedCity,
			selectedEmail,
			itemId,
			itemDetails
		]
	);

	const getItem = useCallback(async (space_id, item_id) => {
		const data = {
			space_id: space_id,
			item_id: item_id
		}
		if (authUserInfo) {
			data['viewing_user_id'] = authUserInfo.id;
		}
		const response = await getSpaceItemData(data);
		if (response && response.result) {
			setItemFound(true);
			const itemData = response.item;
			setItemDetails(itemData);

			if (itemData.photo || itemData.video) {
				if (itemData.photo) {
					setCollectionAssetBGImage(itemData.photo);
					setCollectionAssetType('image');
				}
				if (itemData.video) {
					setCollectionAssetType('video');
				}
			} else {
				setCollectionAssetBGImage(default_items_img);
				setCollectionAssetType('image');
			}
		} else {
			setItemFound(false);
		}
	}, []);

	const getItemClaim = useCallback(async () => {
		const response = await getUserSpaceItemClaim({
			user_id: authUserInfo.id,
			space_id: spaceInfo.id,
			item_id: itemId
		});
		if (response && response.result) {
			if (response.item_claim) {
				const url = routes.spaceMembersProductsItem();
				const fin_url =
					url.replace(':space_username', '@' + spaceInfo.username) + '?id=' + itemId;
				navigate(fin_url);
			}
			setItemClaim(response.item_claim);
		}
	}, [authUserInfo, spaceInfo, itemId]);

	useEffect(() => {
		//Redirect user to logged in page if not authenticated
		MembershipItemUtils.notAuthRedirect(loading, authUserInfo);

		if (space_username) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					getItem(spaceInfo.id, itemId).then((r) => {});

					const temp_item_nft_is_owned = getLocalStorage('temp_item_nft_is_owned');
					const temp_item_space_username = getLocalStorage('temp_item_space_username');

					const parsed_temp_item_nft_is_owned = JSON.parse(temp_item_nft_is_owned);
					if (!parsed_temp_item_nft_is_owned) {
						const url = routes.spaceItemErrorConnect();
						const fin_url =
							url.replace(':space_username', '@' + space_username) + '?id=' + itemId;
						window.location.replace(fin_url);
					}

					const temp_item_wallet_owner_email = getLocalStorage(
						'temp_item_wallet_owner_email'
					);

					claimWalletConnectedForm.setFieldsValue({
						email: temp_item_wallet_owner_email
					});
					checkEmailField(temp_item_wallet_owner_email).then();

					if (authUserInfo) {
						getItemClaim().then();
					}
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(space_username));
				}
				if (spaceInfoLoading === 'failed') {
					navigate(routes.createSpace());
				}
			}

			if (spaceInfo && authUserInfo) {
				getItemClaim().then();
			}
		}
		const checkValue = (str, max) => {
			if (str.charAt(0) !== '0' || str === '00') {
				var num = parseInt(str);
				if (isNaN(num) || num <= 0 || num > max) num = 1;
				str =
					num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
						? '0' + num
						: num.toString();
			}
			return str;
		};

		const handleInput = (e) => {
			e.target.type = 'text';
			let input = e.target.value;
			if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
			const values = input.split('/').map((v) => v.replace(/\D/g, ''));
			if (values[0]) values[0] = checkValue(values[0], 12);
			if (values[1]) values[1] = checkValue(values[1], 31);
			const output = values
				.map((v, i) => (v.length === 2 && i < 2 ? v + ' / ' : v))
				.join('')
				.substr(0, 14);
			inputElement.value = output;

			const dateParts = output.split('/');
			const year = parseInt(dateParts[2], 10);
			const month = parseInt(dateParts[0], 10) - 1;
			const day = parseInt(dateParts[1], 10);
			// const newDate = new Date(year, month, day);

			const isFourDigits = (num) => {
				return /^\d{4}$/.test(num.toString());
			};

			// console.log('year: ' + year + ' month: ' + month + ' day:' + day);
			if (!isNaN(day) && !isNaN(month) && !isNaN(year) && isFourDigits(year)) {
				claimWalletConnectedForm.setFieldValue(
					'birthdate',
					dayjs(new Date(year, month, day))
				);
			}
		};

		setInputElement(document.getElementById('claimWalletConnectedForm_birthdate'));
		if (inputElement) {
			inputElement.addEventListener('input', handleInput);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('input', handleInput);
			}
		};
	}, [
		loading,
		authUserInfo,
		space_username,
		spaceInfo,
		initialLoad,
		itemId,
		itemClaim,
		inputElement
	]);

	return (
		<>
			<main id="sign-up" className="flex">
				{!spaceInfo || !authUserInfo ? (
					<div className="loading-items w-full items-center text-center mt-8">
						<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
					</div>
				) : (
					<>
						<div className="margin-left-15 xsm:mx-5 md:mx-20 lg:mr-0 lg:ml-40">
							<div className="grid grid-cols-12 justify-start margin-right-0">
								{!itemFound ? (
									<div className="col-span-12">Item not found.</div>
								) : (
									<div className="col-span-12 grid grid-cols-2 pr-0">
										<div className="col-span-2 grid grid-cols-2 ps-0 gap-4">
											<div className="lg:col-span-1 xsm:col-span-2">
												<h1 className="mb-4 mt-5 header-large">
													Wallet Connected &<br />
													Item Claimed
												</h1>
												<p className="body-text--reg">
													You’ve successfully claimed the{' '}
													<a href="" className="gradient-color-txt">
														{' '}
														{itemDetails ? itemDetails.name : ''}
													</a>{' '}
													for{' '}
													<a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
														<i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
													</a>
													. To use your Item, complete your signup below.
												</p>
											</div>
										</div>

										<div className="col-span-2 grid lg:grid-cols-2 md:grid-cols-1 xsm:gap-10 lg:gap-30 ">

											<div className="lg:hidden md:flex">
												{membershipItemImage(collectionAssetType, itemDetails)}
											</div>

											<div className="lg:col-span-1">
												<Form
													form={claimWalletConnectedForm}
													name="claimWalletConnectedForm"
													id="claimWalletConnectedForm"
													validateMessages={VALIDATE_MESSAGES}
													onFocus={() => {
														setFormIsOnFocus(true);
														setAlert(null);
													}}
													onBlur={() => {
														setTimeout(() => {
															setFormIsOnFocus(false);
															handleFormOnBlur();
														}, 200);
													}}
													autoComplete="off"
													onChange={handleFormChanges}
													onFinish={handleSubmit}
													className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
												>
													{!authUserInfo && (
														<>
															<h3 className="mb-4 mt-5 header-medium">
																Account Sign Up
															</h3>
															<p className="body-text--reg">
																Your Account Details are kept private unless you
																choose to share it with a Community Space, and
																are never publicly shared. Adding your Email is
																strongly recommended for Account Support and for
																to access your all Updates and Benefits.
															</p>

															<>
																{alert && (
																	<div className="grid grid-cols-12 my-2">
																		<div className="col-span-12">
																			<Alert
																				key={alert.variant}
																				variant={alert.variant}
																				className={
																					'custom-alert ' +
																					(alert.variant === 'danger'
																						? 'custom-alert-danger'
																						: 'custom-alert-success')
																				}
																			>
																				{alert.message}
																			</Alert>
																		</div>
																	</div>
																)}
															</>

															<div className={'grid xsm:grid-cols-1 lg:grid-cols-2 gap-4 ' + (alert ? 'my-3' : 'my-5')}>
																<div className="input-group-com input-border-radius confirm-email-error mx-0 mt-4">
																	<label className="body-text--small">
																		First Name*
																	</label>
																	<Form.Item
																		label="First Name"
																		name="first_name"
																		className="mb-0  hidden-label"
																		validateTrigger="onBlur"
																		rules={[{ required: true }]}
																	>
																		<Input
																			className="input-lg"
																			// placeholder="First Name*"
																			disabled={submitted}
																		/>
																	</Form.Item>
																</div>
																<div className="input-group-com input-border-radius confirm-email-error mx-0 mt-4">
																	<label className="body-text--small">
																		Last Name*
																	</label>
																	<Form.Item
																		label="Last Name"
																		name="last_name"
																		className="mb-0 hidden-label"
																		validateTrigger="onBlur"
																		rules={[{ required: true }]}
																	>
																		<Input
																			className="input-lg"
																			// placeholder="Last Name*"
																			disabled={submitted}
																		/>
																	</Form.Item>
																</div>
															</div>
															<div className="grid xsm:grid-cols-1 lg:grid-cols-2 items-center xsm:gap-8 lg:gap-4">
																<div className="input-group-com input-border-radius confirm-email-error mx-0">
																	<label className="body-text--small">
																		Birthdate*
																	</label>
																	<Form.Item
																		label="Birthdate"
																		name="birthdate"
																		className="mb-0 hidden-label"
																		validateTrigger="onBlur"
																		rules={[
																			{
																				type: 'object',
																				required: true
																			}
																		]}
																	>
																		<DatePicker
																			className="input-birthdate-not-flex"
																			placeholder="MM/DD/YYYY"
																			format="MM/DD/YYYY"
																		/>
																	</Form.Item>
																	{/*<span className="tool-tip-sm">*/}
																	{/*	MM/DD/YYYY*/}
																	{/*</span>*/}
																</div>
																<h5 className="gradient-color-txt fst-italic">
																	Why do we ask for this?
																</h5>
															</div>

															<div className="mt-5">
																<div className="input-group-com input-border-radius mx-0">
																	<label className="body-text--small city-email-width">
																		City*
																	</label>
																	<Form.Item
																		label="City"
																		name="city"
																		className="mb-0 hidden-label"
																		validateTrigger="onBlur"
																		rules={[{ required: true }]}
																	>
																		<Autocomplete
																			placeholder=""
																			apiKey={GOOGLE_MAP_KEY}
																			className="input-lg"
																			onPlaceSelected={(place) => {
																				claimWalletConnectedForm.setFieldValue(
																					'city',
																					place.formatted_address
																				);
																			}}
																		/>
																	</Form.Item>
																	{emptyCity && (
																		<>
																				<span className="tool-tip">
																					Start typing your City and
																					select from the list. This
																					is not publicly visible
																				</span>
																		</>
																	)}
																</div>
															</div>

															<div className="mt-5">
																<div className="input-group-com input-border-radius ">
																	<label className="body-text--small city-email-width">
																		Email*
																	</label>
																	<Form.Item
																		label="Email"
																		name="email"
																		className="mb-0 hidden-label"
																		validateTrigger="onBlur"
																		rules={[{ type: 'email', required: false }]}
																	>
																		<Input
																			className="input-lg"
																			// placeholder="Email"
																			disabled={submitted}
																		/>
																	</Form.Item>
																	{emptyEmail && (
																		<>
																		<span className="tool-tip">
																			OPTIONAL - Add your email for
																			account notifications and for
																			account recovery.
																		</span>
																		</>
																	)}
																</div>
															</div>

														</>
													)}
													<div className="grid padding-right-0">
														<div className="grid m-0">
															<h3 className="mb-4 mt-5 header-medium">
																Data Sharing with <i className="fa-solid fa-solar-system"/> {spaceInfo ? spaceInfo.name : ''}
															</h3>
															<p className="body-text--reg">
																Your item for{' '}
																<a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
																	<i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
																</a>{' '}
																includes access, updates, benefits, and more. To get
																full access to everything Sara Overshares offers, it’s
																recommended you share your Account Details with their
																management team. This information is never shared
																publicly.
															</p>
															<div className="grid grid-cols-12 gap-4 mt-5 items-center">
																<div className="xsm:col-span-12 md:col-span-4">
																	<p className="m-0 body-text--small gradient-color-txt">
																		First & Last Name
																	</p>
																</div>
																<div className="xsm:col-span-12 md:col-span-8">
																	<div className="input-group-com">
																		<DropdownWithItems
																			dropDownClass="w-full"
																			customButtonClass="btn-lg btn-secondary dropdown-primary"
																			title="Image Position"
																			selectedValue={selectedFirstName}
																			listItems={MEMBERSHIP_DATA_SHARING}
																			onDropdownChange={(value) =>
																				setSelectedFirstName(value)
																			}
																		/>
																	</div>
																</div>
															</div>
															<div className="grid grid-cols-12 gap-4 mt-5 items-center">
																<div className="xsm:col-span-12 md:col-span-4">
																	<p className="m-0 body-text--small gradient-color-txt">
																		Birthdate
																	</p>
																</div>
																<div className="xsm:col-span-12 md:col-span-8">
																	<div className="input-group-com">
																		<DropdownWithItems
																			dropDownClass="w-full"
																			customButtonClass="btn-lg btn-secondary dropdown-primary"
																			title="Image Position"
																			selectedValue={selectedBirthDate}
																			listItems={MEMBERSHIP_DATA_SHARING}
																			onDropdownChange={(value) =>
																				setSelectedBirthDate(value)
																			}
																		/>
																	</div>
																</div>
															</div>
															<div className="grid grid-cols-12 gap-4 mt-5 items-center">
																<div className="xsm:col-span-12 md:col-span-4">
																	<p className="m-0 body-text--small gradient-color-txt">
																		City
																	</p>
																</div>
																<div className="xsm:col-span-12 md:col-span-8">
																	<div className="input-group-com">
																		<DropdownWithItems
																			dropDownClass="w-full"
																			customButtonClass="btn-lg btn-secondary dropdown-primary"
																			title="Image Position"
																			selectedValue={selectedCity}
																			listItems={MEMBERSHIP_DATA_SHARING}
																			onDropdownChange={(value) =>
																				setSelectedCity(value)
																			}
																		/>
																	</div>
																</div>
															</div>
															<div className="grid grid-cols-12 gap-4 mt-5 items-center">
																<div className="xsm:col-span-12 md:col-span-4">
																	<p className="m-0 body-text--small gradient-color-txt">
																		Email Address
																	</p>
																</div>
																<div className="xsm:col-span-12 md:col-span-8">
																	<div className="input-group-com">
																		<DropdownWithItems
																			dropDownClass="w-full"
																			customButtonClass="btn-lg btn-secondary dropdown-primary"
																			title="Image Position"
																			selectedValue={selectedEmail}
																			listItems={MEMBERSHIP_DATA_SHARING}
																			onDropdownChange={(value) =>
																				setSelectedEmail(value)
																			}
																		/>
																	</div>
																</div>
															</div>
															<div className="form-check mt-5">
																<div className="form-check-label flex items-center">
																	<Checkbox
																		checked={agreementChecked1}
																		className="inline-flex  mr-4 items-center h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
																		onChange={onChangeAgreement1}
																	></Checkbox>
																	<label className="form-check-label body-text--small">
																		<p className="body-txtitalic--small xsm:ml-5 lg:ml-2">
																			By signing up, you agree to the Commonspace{' '}
																			<a className="gradient-color-txt">
																				Terms of Service
																			</a>
																			,{' '}
																			<a className="gradient-color-txt">
																				Privacy Policy
																			</a>
																			, and{' '}
																			<a className="gradient-color-txt">
																				Community Guidelines
																			</a>
																			.
																		</p>
																	</label>
																</div>
															</div>
															<div className="form-check mt-5">
																<div className="form-check-label flex items-center ">
																	<Checkbox
																		checked={agreementChecked2}
																		className="inline-flex  items-center mr-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
																		onChange={onChangeAgreement2}
																	></Checkbox>
																	<label className="form-check-label body-text--small">
																		<p className="body-txtitalic--small xsm:ml-5 lg:ml-2">
																			By joining <i className="fa-solid fa-solar-system"/>{' '} {spaceInfo ? spaceInfo.name : ''}
																			, you agree to their{' '}
																			<a className="gradient-color-txt">
																				Terms of Service
																			</a>{' '}
																			and{' '}
																			<a className="gradient-color-txt">
																				Privacy Policy
																			</a>
																			.
																		</p>
																	</label>
																</div>
															</div>
															<div className="input-group-com my-5 ps-0 pe-0">
																<Form.Item className="mb-0">
																	<Button
																		className="btn btn-primary btn-lg"
																		type="submit"
																		disabled={
																			submitted ||
																			formHasError ||
																			!agreementChecked1 ||
																			!agreementChecked2
																		}
																	>
																		{submitted && (
																			<i className="fa-solid fa-spinner fa-spin me-3"></i>
																		)}
																		{submitted ? 'Saving' : 'Save Account Details'}
																	</Button>
																</Form.Item>
															</div>
														</div>
													</div>
												</Form>
											</div>
											<div className="lg:inline xsm:hidden p-0">
												{membershipItemImage(collectionAssetType, itemDetails)}
											</div>
										</div>
									</div>
								)}
								<div className="col-6">
									<div className="left-image-holder"></div>
								</div>
							</div>
						</div>
					</>
					)
				}
			</main>
		</>
	);
};

export default memo(ItemClaimWalletConnected);
