import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import {
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_KEY,
	MAGIC_CONNECT_NETWORK,
	NODE_ENV,
	VALIDATE_MESSAGES
} from '../../../../constants/common';
import ReactQuill from 'react-quill';
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
	IMAGE_FILETYPES,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	VIDEO_FILETYPES,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH
} from '../../../../constants/file';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	MEMBERSHIP_TYPES,
	UPDATES_VISIBILITY
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { getListIcon } from '../../../../utils/list';
import TextareaAutosize from 'react-textarea-autosize';
import ExplicitContent from '../../../../components/commons/Modals/ExplicitContent';
import {
	checkMembershipShortCodeAvailability,
	createSpaceMembership
} from '../../../../services/space';
import { useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import routes from '../../../../routes';
import { CurrencyType } from '../../../../utils/MembershipItemUtils';
import { getSitewideLink } from '../../../../utils/sitewideLinks';
import { CustomValidators } from '../../../../utils/CustomValidators';

const CreateMembershipCommunity = () => {
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [createMembershipForm] = Form.useForm();
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [selectedVisibility, setSelectedVisibility] = useState('members');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [enableExpirationChecked, setExpirationChecked] = useState(false);
	const [enableGiftingChecked, setGiftingChecked] = useState(false);
	const [enableAddNumericChecked, setAddNumericChecked] = useState(false);
	const [membershipImageError, setMembershipImageError] = useState(null);
	const [membershipImage, setMembershipImage] = useState(null);
	const [membershipImageBlobPreview, setMembershipImageBlobPreview] = useState(null);
	const [membershipVideo, setMembershipVideo] = useState(null);
	const [membershipVideoBlobPreview, setMembershipVideoBlobPreview] = useState(null);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);

	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [formIsOnFocusMembership, setFormIsOnFocusMembership] = useState(false);
	const [formHasErrorMembership, setFormHasErrorMembership] = useState(true);

	useEffect(() => {
		createMembershipForm.setFieldValue('currency', 'USD');
	});

	const onChangeEnableExpiration = (e) => {
		setExpirationChecked(e.target.checked);
	};

	const onChangeEnableGifting = (e) => {
		setGiftingChecked(e.target.checked);
	};

	const onChangeEnableAddNumeric = (e) => {
		setAddNumericChecked(e.target.checked);
	};

	const handleMembershipImageUploadFileChange = (file, blobPreview) => {
		setMembershipImage(file);
		setMembershipImageBlobPreview(blobPreview);
	};

	const handleMembershipVideoUploadFileChange = (file, blobPreview) => {
		setMembershipVideo(file);
		setMembershipVideoBlobPreview(blobPreview);
	};

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	const handleSubmit = useCallback(
		async (values) => {
			setMembershipImageError(null);

			let hasError = false;

			if (selectedContentType === 'default' || selectedAccessType === 'default') {
				hasError = true;
			}

			if (!membershipImageBlobPreview) {
				setMembershipImageError('Membership Image is required.');
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			setSubmitted(true);
			const data = values;

			data.currency = 'USD';
			data.type = MEMBERSHIP_TYPES.COMMUNITY;
			data.expiration_subscription_enabled = enableExpirationChecked;
			data.enable_add_numeric_count = enableAddNumericChecked;
			data.enable_gifting = enableGiftingChecked;
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.visibility = selectedVisibility;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			if (membershipImage) {
				data.membership_image = membershipImage;
			}

			if (membershipVideo) {
				data.membership_video = membershipVideo;
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					const response = await createSpaceMembership(data);
					if (response && response.result) {
						setAlert({
							variant: 'success',
							message: response.message
						});
						setDisplayDropDownError(false);
						setSubmitted(false);

						setTimeout(() => {
							if (enableExpirationChecked) {
								const route = routes.addMemMonthBenefit();
								const url = route.replace(
									':space_username',
									'@' + spaceInfo.username
								);
								const fin_url = url.replace(
									':membership_id',
									response.membership.id
								);
								window.location.replace(fin_url);
							} else {
								const route = routes.manageMembership();
								const url = route.replace(
									':space_username',
									'@' + spaceInfo.username
								);
								const fin_url = url.replace(
									':membership_id',
									response.membership.id
								);
								window.location.replace(fin_url);
							}
						}, 1800);
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						setSubmitted(false);
					}

					setSubmitted(false);
				}
			} catch (error) {
				console.log(error);
				setSubmitted(false);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			enableExpirationChecked,
			enableAddNumericChecked,
			enableGiftingChecked,
			selectedContentType,
			selectedAccessType,
			selectedVisibility,
			membershipImage,
			membershipVideo,
			selectedExplicitAccess,
			selectedExplicitContent,
			membershipImageBlobPreview
		]
	);

	const handleMembershipFormOnBlur = useCallback(async () => {
		await createMembershipForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				setFormHasErrorMembership(true);
			});
	}, []);

	const handleFormMembershipChanges = useCallback(async () => {
		await createMembershipForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				setFormHasErrorMembership(true);
			});
	}, []);

	useEffect(() => {}, [authUserInfo, spaceInfo, spaceInfoLoading, membershipImageError]);

	return (
		<div className="block-full card">
			<div className="memberships">
				<div className="dashboard-wrapper">
					<div className="grid grid-cols-12">
						<div className="col-span-12">
							<h3 className="header-medium">Create New Community Membership</h3>
						</div>
					</div>
					<div className="grid grid-cols-12 mt-5">
						<div className="col-span-12">
							<p className="body-text--small">
								The Community Membership type is perfect for free or low-cost
								Memberships. They also have flexibility in changing details such as
								Max Supply and other options.{' '}
								<a className="body-txtitalic--small gradient-color-txt">
									Learn more about the Community Membership type.
								</a>
							</p>
						</div>
					</div>
				</div>
				<div className="dashboard-wrapper mt-5">
					<div className="grid grid-cols-12">
						<div className="col-span-6">
							<h3 className="header-small">Membership Details</h3>
						</div>
						<div className="col-span-6">
							<p className="body-text--small gradient-color-txt text-end">
								View Guide
							</p>
						</div>
					</div>
					<Form
						form={createMembershipForm}
						name="createMembershipForm"
						id="createMembershipForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusMembership(true);
						}}
						onBlur={() => {
							setTimeout(() => {
								setFormIsOnFocusMembership(false);
								handleMembershipFormOnBlur();
							}, 200);
						}}
						onChange={handleFormMembershipChanges}
						onFinish={handleSubmit}
						className={formIsOnFocusMembership ? 'hide-antd-error-messages' : ''}
					>
						<div className="grid grid-cols-12 md:gap-8 mt-5">
							<div className="col-span-12 md:col-span-4">
								<div className="upload-rec">
									<UploadDragger
										passedError={membershipImageError}
										cropperAspectRatio={4 / 3}
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={IMAGE_FILETYPES.toString()}
										uploadedFilePreview={membershipImageBlobPreview}
										onUploadFileChange={handleMembershipImageUploadFileChange}
										minHeight={IMAGE_STANDARD_MIN_HEIGHT}
										minWidth={IMAGE_STANDARD_MIN_WIDTH}
										maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
										maxWidth={IMAGE_STANDARD_MAX_WIDTH}
										uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
										disableCropper={true}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Membership Image*
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 3840px square or wide. <br />
									JPEG, PNG, or GIF
								</p>
							</div>
							<div className="col-span-12 md:col-span-4">
								<div className="upload-rec">
									<UploadDragger
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={VIDEO_FILETYPES.toString()}
										uploadedFilePreview={membershipVideoBlobPreview}
										onUploadFileChange={handleMembershipVideoUploadFileChange}
										minHeight={VIDEO_STANDARD_MIN_HEIGHT}
										minWidth={VIDEO_STANDARD_MIN_WIDTH}
										maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
										maxWidth={VIDEO_STANDARD_MAX_WIDTH}
										uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Membership Video
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 1920px/1080px, square or wide. <br />
									MOV or MP4
								</p>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Your Membership Image (up to 8MB) should be as high resolution
									as possible, up to 3840px height and width. This will also be
									used for any thumbnails and previews.
								</p>
								<p className="body-txtitalic--smallest">
									Your Membership Image (up to 8MB) should be as high resolution
									as possible, up to 3840px height and width. This will also be
									used for any thumbnails and previews.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 mt-4 md:gap-8 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Name
									</label>
									<Form.Item
										label="Name"
										name="name"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<Input
											placeholder="Up to 32 characters, including spaces."
											className="input-sm body-text--small color-reg"
											maxLength={32}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									This is the displayed Name of the Membership and is what people
									will see when browsing your Space and viewing their purchased
									Membership, as well as the Collection Name when viewing the
									artwork.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 mt-4 md:gap-8 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Sku Short Code
									</label>
									<Form.Item
										label="Sku Short Code"
										name="sku_shortcode"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												min: 2,
												message: 'Must be minimum 2 characters.'
											},
											{
												max: 8,
												message: 'Must not exceed 8 characters.'
											},
											({ getFieldValue }) => ({
												async validator(_, value) {
													if (value) {
														const data = {
															shortcode: value
														};

														if (spaceInfo) {
															data['space_id'] = spaceInfo.id;
														}

														let available = false;
														const response =
															await checkMembershipShortCodeAvailability(
																data
															);
														if (response && response.result) {
															available = response.availability;
														}

														if (!available) {
															return Promise.reject(
																new Error(
																	'Shortcode not available.'
																)
															);
														}
													}

													if (
														(value && value.match(/\ /g)) ||
														/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
															value
														)
													) {
														return Promise.reject(
															new Error('Invalid Input')
														);
													}

													return Promise.resolve();
												}
											})
										]}
									>
										<Input
											placeholder="2 to 8 characters. Letters and numbers only, no spaces."
											className="input-sm body-text--small color-reg"
											maxLength={8}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Choose a unique SKU Shortcode based the Membership Name. This is
									must be unique within your Space and is used in transactions,
									support, and other areas.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Max Supply
									</label>
									<Form.Item
										label="Max Supply"
										name="max_supply"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (typeof value !== 'string' && value) {
														value = value.toString();
													}
													if (
														isNaN(value) ||
														value < 0 ||
														value.match(/\./g)
													) {
														return Promise.reject(
															new Error('Invalid value.')
														);
													}

													return Promise.resolve();
												}
											})
										]}
									>
										<Input
											placeholder="Enter “0” for unlimited."
											className="input-sm body-text--small color-reg"
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Your Membership can have a limited or unlimited Supply. Enter
									“0” to set the Supply to unlimited, “1” to make it a unique
									1-of-1, or any larger number to set a higher limit.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-50">
										Max Per Account
									</label>
									<Form.Item
										label="Max Per Account"
										name="max_per_account"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{ validator: CustomValidators.maxPerCount }
										]}
									>
										<Input
											placeholder="Enter “0” for unlimited."
											className="input-sm body-text--small color-reg"
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									You can put a limit how many Memberships can be purchased by a
									single account. Enter “0” to set this to unlimited.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Currency
									</label>
									<Form.Item
										label="Currency"
										name="currency"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<Input
											placeholder="Start typing your currency and select from the list."
											className="input-sm body-text--small color-reg"
											disabled
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									You can offer your Membership for free or sell it at any price
									you choose. Denominated in your selected Currency. Enter “0” to
									set the Price as free.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Price
									</label>
									<Form.Item
										label="Price"
										name="price"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												validator: CustomValidators.priceMinmax(
													CurrencyType.USD
												)
											}
										]}
									>
										<Input
											placeholder="Enter “0” if free. "
											className="input-sm body-text--small color-reg"
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Set the Currency for your Membership Price. Any purchases in a
									different currency will be converted based on market pricing at
									the time of purchase.{' '}
									<a className="gradient-color-txt">
										{' '}
										See officially supported major currencies and
										cryptocurrencies.
									</a>
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4  flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="form-check">
									<label className="form-check-label body-text--small gradient-color-txt">
										<Checkbox
											className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											checked={enableExpirationChecked}
											onChange={onChangeEnableExpiration}
										/>
										Enable Expiration Date and add Monthly Subscription for
										continued access
									</label>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Memberships have a one-time upfront Price. This option adds an
									Expiration after a set number of months. After that, Members
									must pay a Monthly Subscription to continue access. Manage
									details in the Benefits Section.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Summary
									</label>
									<Form.Item
										label="Summary"
										name="summary"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										<TextareaAutosize
											minRows={1}
											placeholder="Up to 128 characters, including spaces."
											className="input-sm body-text--small color-reg"
											maxLength={128}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									This Summary shows when Members view their Membership. It should
									be 1 to 2 sentences summarizing the Membership and its details.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-5 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Listing Text
									</label>
									<Form.Item
										label="Listing Text"
										name="listing_text"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										<TextareaAutosize
											minRows={1}
											placeholder="Up to 128 characters, including spaces."
											className="input-sm body-text--small color-reg"
											maxLength={128}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									The Listing Text is used when listing your Membership for
									purchase on your Space. It should be 1 to 2 sentences
									summarizing the Membership and why people should join.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Individual Title
									</label>
									<Form.Item
										label="Individual Title"
										name="individual_title"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<Input
											maxLength={32}
											placeholder="Up to 32 characters, including spaces. Usually this is the same or similar to the Name."
											className="input-sm body-text--small color-reg"
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									This is the Title given to an individual Membership when
									purchased by someone. For example, if your Membership Name is
									“Club All Access Membership” then you could make this “Club
									Membership”.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4  flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="form-check">
									<label className="form-check-label body-text--small gradient-color-txt">
										<Checkbox
											className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											checked={enableAddNumericChecked}
											onChange={onChangeEnableAddNumeric}
										/>
										Add Numeric Count to the Individual Title of each purchased
										Membership
									</label>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									This adds a numeric count to the Individual Title of each
									Membership sold. For example, the first Membership sold would
									have the Title of “Club Membership #1”, and the second would be
									“Club Membership #2”, and so on.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4">
							<div className="col-span-12 md:col-span-8 flex items-center">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Description
									</label>
									<Form.Item
										label="Description"
										name="description"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 512,
												message: 'Must not exceed 512 characters.'
											}
										]}
									>
										<TextareaAutosize
											minRows={6}
											placeholder="Up to 512 characters, including spaces."
											className="input-sm body-text--small color-reg "
											maxLength={512}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4 flex align-top">
								<p className="body-txtitalic--smallest">
									This is a full Description of your Membership. You can use{' '}
									{getSitewideLink('markdown_syntax')} to add basic formatting.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 md:gap-8 mt-4  flex items-center">
							<div className="col-span-12 md:col-span-8">
								<div className="form-check ">
									<label className="form-check-label body-text--small gradient-color-txt">
										<Checkbox
											className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											checked={enableGiftingChecked}
											onChange={onChangeEnableGifting}
										></Checkbox>
										Enable gifting to accounts and external transfers on
										marketplaces
									</label>
								</div>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Enabling gifting and transfers allows Members to gift a
									purchased Membership to another account. Free gifting is
									supported within your Space. External transfers and sales can be
									done on marketplaces like OpenSea.{' '}
									<a className="gradient-color-txt">Learn more.</a>
								</p>
							</div>
						</div>

						{enableGiftingChecked && (
							<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
								<div className="col-span-12 md:col-span-8">
									<div className="input-group-com w-full mt-2">
										<label className="body-text--small gradient-color-txt w-50">
											Royalty Percentage
										</label>
										<Form.Item
											label="Royalty Percentage"
											name="royalty_percentage"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{ validator: CustomValidators.royaltyPercentage }
											]}
										>
											<Input
												placeholder="Whole numbers only, 0% to 20%."
												className="input-sm body-text--small color-reg"
											/>
										</Form.Item>
									</div>
								</div>
								<div className="col-span-12 md:col-span-4">
									<p className="body-txtitalic--smallest">
										With Gifting and Transfers enabled, when a Membership is sold on
										an external marketplace, you can specify that a percentage of
										the sale price be paid to you as a royalty.
										<a className="gradient-color-txt"> Learn more.</a>
									</p>
								</div>
							</div>
						)}

						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<DropdownWithItems
									title="Select Content Type"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2"
									displayError={
										displayDropDownError && selectedContentType === 'default'
									}
									icon={getListIcon(
										CONTENT_TYPES_LIST_WITH_DEFAULT,
										selectedContentType
									)}
									selectedValue={selectedContentType}
									listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openContentTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitContent}
									setModalOpen={setModalOpenExplicitContent}
									selectedItems={selectedExplicitContent}
									isExplicitContent={true}
									onSelectedItems={(value) => setSelectedExplicitContent(value)}
								/>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Change this setting if your Membership Details, Image, or Video
									contains explicit or sensitive content. If it does, viewers may
									need to confirm their age before accessing content. View our
									Content Guidelines.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<DropdownWithItems
									title="Select Access Type"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2"
									displayError={
										displayDropDownError && selectedAccessType === 'default'
									}
									icon={getListIcon(
										ACCESS_TYPES_LIST_WITH_DEFAULT,
										selectedAccessType
									)}
									selectedValue={selectedAccessType}
									listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openAccessTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitAccess}
									setModalOpen={setModalOpenExplicitAccess}
									selectedItems={selectedExplicitAccess}
									isExplicitContent={false}
									onSelectedItems={(value) => setSelectedExplicitAccess(value)}
								/>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									Change this setting if this Membership will provide Members with
									access to explicit or sensitive content, either directly on your
									Space or via an external site or other source. View our Content
									Guidelines.
								</p>
							</div>
						</div>
						<ExplicitContent modalOpen={modalOpen} setModalOpen={setModalOpen} />
						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12 md:col-span-8">
								<DropdownWithItems
									title="Update Visibility"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2"
									selectedValue={selectedVisibility}
									listItems={UPDATES_VISIBILITY}
									onDropdownChange={(value) => setSelectedVisibility(value)}
								/>
							</div>
							<div className="col-span-12 md:col-span-4">
								<p className="body-txtitalic--smallest">
									You can set the Visibility of this Membership to viewable to the
									Public, Members Only (any membership), or Custom Access (based
									on owning a specific Membership or Item).
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<>
								{alert && (
									<div className="col-span-12">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								)}
							</>
						</div>
						<div className="grid grid-cols-12 md:gap-8 mt-4 flex items-center">
							<div className="col-span-12">
								<Form.Item className="mb-0">
									<button
										className="btn btn-primary btn-md"
										type="submit"
										disabled={submitted || formHasErrorMembership}
									>
										{submitted ? (
											<>
												<i className="fa-solid fa-spinner fa-spin me-2"></i>
												Saving
											</>
										) : (
											<>Save</>
										)}
									</button>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default memo(CreateMembershipCommunity);
