import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	VISIBILITY_LIST_WITH_DEFAULT,
	VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT, VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT
} from '../../../../constants/profile';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { updateProfileVisibility } from '../../../../services/user';
import { getAuthUserInfo } from '../../../../states/general/actions';
import { getListIcon } from '../../../../utils/list';
import {getSitewideLink} from "../../../../utils/sitewideLinks";

const ManageVisibility = () => {
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [selectedVisibilitySettings, setSelectedVisibilitySettings] = useState({
		privacy: 'default',
		basics: 'default',
		follower_lists: 'default',
		spaces_list: 'default',
		collections: 'default',
		activity: 'default',
		about_me: 'default',
		city: 'default',
		badges: 'default',
		top_8: 'default'
	});
	const [classSize, setClassSize] = useState('md');
	const { screenSize } = useSelector((state) => state.classSize);

	const handleChanges = useCallback(
		(field, value) => {
			setSelectedVisibilitySettings((prev) => ({ ...prev, [field]: value }));

			if (authUserInfo && authUserInfo.profile.visibility) {
				const data = {
					user_id: authUserInfo.id,
					field: field,
					value: value
				};

				updateRequest(data).then((r) => {});
			}
		},
		[authUserInfo]
	);

	const updateRequest = async (data) => {
		try {
			const result = await updateProfileVisibility(data);
			if (result && result.result) {
				dispatch(getAuthUserInfo());
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			if (authUserInfo.profile.visibility) {
				setSelectedVisibilitySettings((prev) => ({
					...prev,
					privacy: authUserInfo.profile.visibility.privacy,
					basics: authUserInfo.profile.visibility.basics,
					follower_lists: authUserInfo.profile.visibility.follower_lists,
					spaces_list: authUserInfo.profile.visibility.spaces_list,
					collections: authUserInfo.profile.visibility.collections,
					activity: authUserInfo.profile.visibility.activity,
					about_me: authUserInfo.profile.visibility.about_me,
					city: authUserInfo.profile.visibility.city,
					badges: authUserInfo.profile.visibility.badges,
					top_8: authUserInfo.profile.visibility.top_8
				}));
			}
		}

		setClassSize(screenSize < 1300 ? 'sm' : 'md');

		document.title = 'Commonspace Profile Manage Visibility';
	}, [loading, authUserInfo, screenSize, classSize]);

	useEffect(() => {
		// console.log(selectedVisibilitySettings);
	}, [selectedVisibilitySettings]);

	return (
		<>
			<div className="cs-dash-card block-full card">

				<hgroup className="heading">
					<h2>Profile Visibility Settings</h2>
					<p>You can control who sees your profile, spaces, collections and more here. Note that your
						@username, profile image, and follower/following counts are always visible.</p>
				</hgroup>

				<div className="cs-stack-controlrows stack-form-col">

					<div className="formrow">
						<p>Profile Visibility</p>
						<p>Your overall Profile Visibility setting. Changes here may change settings below.</p>
						<DropdownWithItems
							title="Profile Privacy"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								VISIBILITY_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.privacy
							)}
							selectedValue={selectedVisibilitySettings.privacy}
							listItems={VISIBILITY_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('privacy', value)}
						/>
					</div>

					<div className="formrow">
						<p>Profile Basics</p>
						<p>Includes your display name, pronouns, bio, social links, and website link.</p>
						<DropdownWithItems
							title="Profile Basics"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.basics
							)}
							selectedValue={selectedVisibilitySettings.basics}
							listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('basics', value)}
						/>
					</div>

					<div className="formrow">
						<p>Follower Lists</p>
						<p>Your list of who you follow and who follow you.</p>
						<DropdownWithItems
							title="Follower Lists"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.follower_lists
							)}
							selectedValue={selectedVisibilitySettings.follower_lists}
							listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('follower_lists', value)}
						/>
					</div>

					<div className="formrow">
						<p>Spaces List</p>
						<p>Spaces you have joined, own, or manage.</p>
						<DropdownWithItems
							title="Spaces List"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.spaces_list
							)}
							selectedValue={selectedVisibilitySettings.spaces_list}
							listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('spaces_list', value)}
						/>
					</div>

					<div className="formrow">
						<p>Items & Collections</p>
						<p>Digital Items and Collections from your Spaces and Memberships.</p>
						<DropdownWithItems
							title="Items & Collections"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.collections
							)}
							selectedValue={selectedVisibilitySettings.collections}
							listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('collections', value)}
						/>
					</div>

					<div className="formrow">
						<p>Activity</p>
						<p>Recently joined spaces, items acquired, badge activity, public posts, new followers and
							following.</p>
						<DropdownWithItems
							title="Activity"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.activity
							)}
							selectedValue={selectedVisibilitySettings.activity}
							listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('activity', value)}
						/>
					</div>

					<div className="formrow">
						<p>About Me</p>
						<p>Your About Me information, as shown on your About section.</p>
						<DropdownWithItems
							title="About Me"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.about_me
							)}
							selectedValue={selectedVisibilitySettings.about_me}
							listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('about_me', value)}
						/>
					</div>

					<div className="formrow">
						<p>Display Location</p>
						<p>Your Profile Location, as shown on your About section. This is separate from your Account
							City, which is always private.</p>
						<DropdownWithItems
							title="City"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.city
							)}
							selectedValue={selectedVisibilitySettings.city}
							listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('city', value)}
						/>
					</div>

					<div className="formrow">
						<p>Badges</p>
						<p>Your earned badges, as shown on your About section.</p>
						<DropdownWithItems
							title="Badges"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.badges
							)}
							selectedValue={selectedVisibilitySettings.badges}
							listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('badges', value)}
						/>
					</div>

					<div className="formrow">
						<p>Top 8</p>
						<p>Your featured profiles and spaces that you love the most, as shown on your About section.</p>
						<DropdownWithItems
							title="Top 8"
							customButtonClass="button gradient secondary distribute w-full"
							icon={getListIcon(
								authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
								selectedVisibilitySettings.top_8
							)}
							selectedValue={selectedVisibilitySettings.top_8}
							listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
							onDropdownChange={(value) => handleChanges('top_8', value)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(ManageVisibility);
