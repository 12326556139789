import React, {memo, useCallback, useEffect, useState} from "react";
import {Form} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {checkMembershipItemStatus, createJoinMembershipItem, createMembershipItemSubscription, createStripeMembershipOnetimePayment} from "../../../services/payment_method";
import {getAuthUserInfo} from "../../../states/general/actions";
import {getSpaceMembership} from "../../../services/space";
import default_items_img from "../../../assets/images/form_background.png";
import routes from "../../../routes";
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import {checkMfaEnabled, checkSmsEnabled, dualCheck2FA, emailSignUp, registerMagic} from '../../../services/user';
import { login, magic_login } from '../../../services/auth';
import {removeAccessToken, setAccessToken} from '../../../utils/common';
import {
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_KEY,
	MAGIC_CONNECT_NETWORK
} from '../../../constants/common';
import {MEMBERSHIP_TYPES} from "../../../constants/space";
import {autoRenewParser, DateDayCondition, getButtonPaymentText, getCheckoutTypeText, getExpirationDate, getExpirationDateData, getExpirationMonthlyStart, getIncludedMonths, getMembershipItemPrice, getRecurringPrice, getSelectedMonth, membershipItemImage, Recurring} from "../../../components/commons/helpers/MembershipItemHelpers";
import '../SpaceBuyMembership.scss'
import {getPaymentType, MembershipItemUtils, PaymentType, RenewalOptions} from "../../../utils/MembershipItemUtils";
import {getSitewideLink} from "../../../utils/sitewideLinks";
import Alert from "react-bootstrap/Alert";
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';
import {getLocalStorage, removeLocalStorage} from "../../../utils/storage";

const MembershipPaymentMethod = () => {
    const [membershipPaymentMethodForm] = Form.useForm();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [searchParams] = useSearchParams();
    const membershipId = searchParams.get('id');
    const [hasMfaEnabled, setHasMfaEnabled] = useState(false);
    const [form] = Form.useForm();
    const [loginForm] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);
    const [formHasError, setFormHasError] = useState(true);
    const [agreementChecked1, setAgreementChecked1] = useState(false);
    const [agreementChecked2, setAgreementChecked2] = useState(false);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [alert, setAlert] = useState(null);
    const [alert2, setAlert2] = useState(null);
    const currentLocation = useLocation();
    const [isMembershipLoaded, setMembershipLoaded] = useState(false);
    const [membership, setMembership] = useState(null);
    const [expirationDate, setExpirationDate] = useState('No Expiration');
    const [expirationDateDeductOneDay, setExpirationDateDeductOneDay] = useState('');
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
    const [spaceName, setSpaceName] = useState(null);
    const [isSubscription, setSubscription] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [loadMembershipOnce, setLoadMembershipOnce] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [displayDropDownError, setDisplayDropDownError] = useState(false);
    const [submittedAddCardForm, setSubmittedAddCardForm] = useState(false);
    const [showAddCardForm, setShowAddCardForm] = useState(false);
    const [autoMonthlyPrice, setAutoMonthlyPrice] = useState([{}]);
    const [selectedMonthlyPrice, setSelectedMonthlyPrice] = useState(RenewalOptions.renewal_none);
    const [isParseOnlyOnce, setParseOnlyOnce] = useState(false);
    const [hidePaymentMethod, setHidePaymentMethod] = useState(false);
    const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);
    const [isSignedUp, setIsSignedUp] = useState(false);
    const [isMagicLink, setIsMagicLink] = useState(false);
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
    let spaceUsername = null;
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
    const web3 = new Web3(magic.rpcProvider);
    const path = currentLocation.pathname;

    // Magic Link Web 3
    if (path && path.startsWith('/@') && path.length > 3) {
        const clean_path = path.replace('/@', '');
        const path_exp = clean_path.split('/');
        spaceUsername = path_exp[0];
    }

    const handleOptionChange = (event) => {
        setSelectedMonthlyPrice(event.target.value);
    };

    const handleSignUpFormChanges = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSignUpFormOnBlur = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleFormChanges = useCallback(async () => {
        await membershipPaymentMethodForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleLoginFormChanges = useCallback(async () => {
        await loginForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleLoginFormOnBlur = useCallback(async () => {
        await loginForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    useEffect(() => {
        // console.log(isSignedUp)
    }, [isSignedUp])

    const handleFormOnBlur = useCallback(async () => {
        await membershipPaymentMethodForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const onChangeAgreement1 = (e) => {
        setAgreementChecked1(e.target.checked);
    };

    const onChangeAgreement2 = (e) => {
        setAgreementChecked2(e.target.checked);
    };

    const handleSignup = useCallback(async (values) => {
		try {
			if (isMagicLink) {
				await web3Login(values);
			} else {
				await registerUser(values);
			}
		} catch (error) {
			setAlert({ variant: 'danger', message: error.message });
		}
    },)

    const registerUser = async (values) => {
		// setSuccessSignUp(false);
		// setSubmitted(true);
		values.email = values.email.toLowerCase();
        values.space_id = spaceInfo.id;
        values.space_path = path + '?id=' + membershipId;
		const result = await emailSignUp(values);
		if (result) {
            // console.log(result)
			if (result.result) {
				form.resetFields();
                if(result.message !== "Email already verified."){
                    setIsSignedUp(true);
                    setAlert({
                        variant: 'success',
                        message: 'Registered successfully!'
                    });
                }else{
                    setAlert({
						variant: 'danger',
						message: result.message
					});
                }
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'Something went wrong'
					});
				}
			}
			setSubmitted(false);
		}
	};

    const web3Login = async () => {
		web3.eth
			.getAccounts()
			.then((accounts) => {
				magic.connect
					.requestUserInfo({ isResponseRequired: false })
					.then((user_account) => {
						let values = {
							email: user_account.email ?? 'no-email',
							user_public_address: accounts?.[0]
						};
						getWalletInfo(values);
					});
			})
			.catch((error) => {
				// setSubmitted(false);
				setIsMagicLink(false);
				// console.log(error);
			});
	};

	const magicLogin = async () => {
		// setSubmitted(true);
		setIsMagicLink(true);
		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});
		await web3Login();
	};
    const loginUser = async (values) => {
        // console.log(values)
		const result = await login(values);
		await processLogin(result);
	};

    useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			// window.location.replace(routes.dashboard());
			setSubmitted(false);
			checkHas2FA().then(() => {});
		}
	}, [authUserInfo, loading, hasMfaEnabled, isMagicLink]);

    const checkHas2FA = async () => {
		try {
			let values = {
				user_id: authUserInfo.id
			};
			const mfa_async = checkMfaEnabled(values);
			const sms_async = checkSmsEnabled(values);
			const mfa_res = await mfa_async;
			const sms_res = await sms_async;
			if (mfa_res.result || sms_res.result) {
				setHasMfaEnabled(true);
			} else {
				setHasMfaEnabled(false);
				// window.location.replace(routes.dashboard());
			}
			console.log(mfa_res.message);
			console.log(sms_res.message);
		} catch (error) {
			console.log(error);
		}
	};

    const processLogin = async (result) => {
		// console.log(result);
		if (result) {
			if (result.result) {
				if (result.access_token) {
					setAccessToken(result);
				}
				dispatch(getAuthUserInfo());
			} else {
				if (result.message) {
					setAlert2({ variant: 'danger', message: result.message });
				} else {
					setAlert2({
						variant: 'danger',
						message: 'User not found.Please try again.'
					});
				}
			}
		}
		// setLoginBtn(false);
		// setSubmitted(false);
		// setIsMagicLink(false);
	};
    const handleLogin = useCallback(
		async (values) => {
			try {
				// setLoginBtn(true);
				// console.log(values);
				if (hasMfaEnabled) {
					// console.log('MFA Enabled');
					values.user_id = authUserInfo.id;
					await handleOtp(values);
				} else {
					// console.log('MFA Not Enabled');
					await loginUser(values);
				}
			} catch (error) {
				console.log(error);
			}
		},[])
    

    const handleOtp = async (values) => {
		try {
			// let values = {
			//     user_id: authUserInfo.id
			// };
			let result = await dualCheck2FA(values);
			if (result.result) {
				form.resetFields();
				setAlert({
					variant: 'success',
					message: 'Log in successful!'
				});
				// setLoginBtn(false);
				window.location.replace(routes.dashboard());
			} else {
				setAlert({
					variant: 'danger',
					message: 'OTP Code is incorrect.'
				});
				// setLoginBtn(false);
			}
		} catch (error) {
			// setLoginBtn(false);
			console.log(error);
		}
	};

    const handleSubmit = useCallback(async (values) => {
            setSubmitted(true);
            if (!authUserInfo) {
                setSubmitted(false);
                return;
            }
            if (!spaceInfo) {
                setSubmitted(false);
                return;
            }
            if (!membership) {
                setSubmitted(false);
                return;
            }
            const data = values;
            data.membership_id = membershipId;
            data.space_id = spaceInfo.id;
            data.user_id = authUserInfo.id;
            data.payment_type = 'free';
            data.space_owner_id = spaceInfo.owner;
            // const response = await createStripePaymentIntent(data);

            // if (membershipDetails != null) {
            if (hidePaymentMethod) {
                const response = await createJoinMembershipItem(data);
                if (response && response.result) {
                    setSubmitted(false);
                    await proceedPaymentCompleted();
                } else {
                    setSubmitted(false);
                }
            } else {
                try {
                    setDisplayDropDownError(true);
                    data.is_subscription_enable = membership.enable_expiration_and_subscription;
                    /*
                    * If payment is subscription
                    * */
                    if (membership.enable_expiration_and_subscription && membership.first_benefit) {
                        data.amount = (selectedMonthlyPrice === RenewalOptions.renewal_none ? 0 : selectedMonthlyPrice);
                        data.is_recurring = selectedMonthlyPrice !== RenewalOptions.renewal_none;
                        data.membership_fee = parseFloat((membership.price === null ? 0 : membership.price));
                        data.currency = membership.first_benefit.currency.toLowerCase();
                        data.included_months = membership.first_benefit.included_months;
                        data.recurring_months = getSelectedMonth(selectedMonthlyPrice, autoMonthlyPrice);
                        data.start_date = getExpirationDateData(data.included_months, true);

                        const response = await createMembershipItemSubscription(data);
                        if (response && response.payout && response.payout.url) {
                            // setAlert({
                            //     variant: 'success',
                            //     message: response.message
                            // });
                            window.location.href = response.payout.url;

                           /* if (getLocalStorage('redirect_link')) {
                                removeLocalStorage('redirect_link')
                            }*/

                        } else {
                            setAlert({
                                variant: 'danger',
                                message: response.message
                            });
                            setSubmitted(false);
                            setFormHasError(false);
                        }
                    } else {
                        /*
                        * If payment is One-time only
                        */
                        data.amount = parseFloat(membership.price);
                        data.currency = membership.currency.toLowerCase();
                        const response = await createStripeMembershipOnetimePayment(data);
                        if (response && response.payout) {
                            if (response.payout.url && response.payout.status === 'open') {
                                window.location.href = response.payout.url;
                            } else if (response.payout.status === 'complete') {
                                let url = routes.spaceMembershipPaymentCompleted();
                                const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
                                navigate(fin_url);
                            }
                        } else {
                            setSubmitted(false);
                            setFormHasError(false);
                            setAlert({
                                variant: 'danger',
                                message: response.message
                            });
                        }
                    }


                    // if (selectedCard.value !== 'default' && selectedCard.value !== 'add') {
                    //     if (authUserInfo) {
                    //         data.user_id = authUserInfo.id;
                    //         data.stripe_id = selectedCard.cardObject.id;
                    //
                    //         const response = await createStripePaymentIntent(data);
                    //         if (response && response.result) {
                    //             setSubmitted(false);
                    //             await proceedPaymentCompleted();
                    //         } else {
                    //             setSubmitted(false);
                    //         }
                    //     } else {
                    //         setSubmitted(false);
                    //     }
                    // } else {
                    //     if (!selectedCountry || selectedCountry === '') {
                    //         setSubmitted(false);
                    //         return;
                    //     }
                    //
                    //     //TODO: change package this is only a temporary fix
                    //     let countryCode = getCode(selectedCountry);
                    //     if (!countryCode) {
                    //         if (selectedCountry === 'United States') {
                    //             countryCode = getCode('United States of America');
                    //         }
                    //     }
                    //
                    //     data.country = countryCode;
                    //     data.state = selectedRegion;
                    //     data.expiry_mm = values.expiry.format('MM');
                    //     data.expiry_yy = values.expiry.format('YYYY');
                    //
                    //     delete data.expiry;
                    //
                    //     if (authUserInfo) {
                    //         setSubmittedAddCardForm(true);
                    //         data.user_id = authUserInfo.id;
                    //
                    //         const response = await createPaymentMethodAndPay(data);
                    //         if (response && response.result) {
                    //             setShowAddCardForm(false);
                    //             dispatch(getAuthUserInfo());
                    //             setSubmitted(false);
                    //             await proceedPaymentCompleted();
                    //         } else {
                    //             setSubmitted(false);
                    //         }
                    //         setSubmittedAddCardForm(false);
                    //     } else {
                    //         setSubmitted(false);
                    //     }
                    // }
                } catch (error) {
                    setSubmitted(false);
                    console.log(error);
                }
            }
            // }
        },
        [
            authUserInfo,
            selectedCountry,
            selectedRegion,
            // selectedCard,
            membership,
            hidePaymentMethod,
            spaceInfo,
            selectedMonthlyPrice,
            autoMonthlyPrice]
    );

    const checkStatus = useCallback(async (space_id, membership_id) => {
        if (!membership_id) return;
        const data = {
            space_id: space_id,
            membership_id: membership_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await checkMembershipItemStatus(data);
        if (response && response.result) {
            const transaction = response.stripe_transaction;
            const sessionStatus = response.session_status;
            if (transaction) {
                if (transaction.status === 'inactive') {
                    if (sessionStatus === 'complete') {
                        let url = routes.spaceMembershipPaymentCompleted();
                        const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
                        navigate(fin_url);
                    }
                } else if (transaction.status === 'active' || transaction.status === 'expired') {
                    let url = routes.spaceMembershipBuy();
                    const fin_url =
                        url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
                    navigate(fin_url);
                }
            }
            setCheckStatusLoaded(true);
        } else if (response.message === "Space membership already exist.") {
            let url = routes.spaceMembershipPaymentCompleted();
            const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
            navigate(fin_url);
        }
    },[authUserInfo])

    const getMembership = useCallback(async (space_id, membership_id) => {
        if (!membership_id) return;
        const data = {
            space_id: space_id,
            membership_id: membership_id
        }

        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }

        const response = await getSpaceMembership(data);
        if (response && response.result) {
            const membershipData = response.membership;
            setIsPaid(membershipData.is_paid);
            setMembership(membershipData);
            setMembershipLoaded(true);
            if (membershipData.photo || membershipData.video) {
                if (membershipData.photo) {
                    const bg = {
                        backgroundImage: 'url(' + membershipData.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (membershipData.video) {
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, [authUserInfo]);

    const proceedPaymentCompleted = useCallback(async () => {
        // const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
        if (spaceUsername) {
            let url = routes.spaceMembershipPaymentCompleted();
            // let url = routes.spaceMembershipPaymentCompleted();
            // if (membershipDetails.collection_type === 'membership') {
            //     if (isSubscription) {
            //         // const isSubscription = membershipDetails.enable_expiration_and_subscription;
            //         // if (!isSpaceMember) {
            //             if (membershipDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
            //                 url = routes.spaceMembershipWalletConnect();
            //             }
            //         // }
            //
            //     } else {
            //         if (isSpaceMember) {
            //             // Membership Ready
            //             //to
            //             //Wallet Connect
            //         } else {
            //             // EXISTING USER/NEW TO SPACE CLAIM NOW FLOW where User
            //             // must own an existing NFT to claim Membership/Item NO SUBSCRIPTION
            //             if (membershipDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
            //                 url = routes.spaceMembershipWalletConnect();
            //             }
            //         }
            //     }
            // }
            const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
            navigate(fin_url);
        }
    }, [spaceInfo, membership, isSubscription, spaceUsername, membershipId]);


    useEffect(() => {
        // dispatch(getSpaceInfo(spaceUsername));

        if (membership == null) {
            setMembershipLoaded(false);
        }
        if (authUserInfo===null) {
            dispatch(getAuthUserInfo());
        }

        if (spaceUsername && !isMembershipLoaded) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getMembership(spaceInfo.id, membershipId).then((r) => {
                    });
                    setSpaceName(spaceInfo.name);
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(spaceUsername));
                }
                if (spaceInfoLoading === 'failed') {
                    navigate(routes.createSpace());
                }
            }
        }

        if (membership != null) {
            setHidePaymentMethod(
                !isSubscription && (membership.price == null || (parseFloat(membership.price) === 0)) ||
                isSubscription && selectedMonthlyPrice === RenewalOptions.renewal_none &&
                (membership.price == null || (parseFloat(membership.price) === 0))
            );
            if (!isParseOnlyOnce) {
                setParseOnlyOnce(true);
                setAutoMonthlyPrice(autoRenewParser(membership));
            }
            setSubscription(membership.enable_expiration_and_subscription);
            // console.log('selectedMonthlyPrice: -start: ' + selectedMonthlyPrice);
            if (autoMonthlyPrice.length > 0 && membership.enable_expiration_and_subscription && membership.first_benefit) {
                const included_months = membership.first_benefit.included_months;
                const date_created = membership.first_benefit.created_on;
                const selectedMonths = autoMonthlyPrice.filter(monthly => monthly.value === selectedMonthlyPrice);
                let redemptionCount = included_months;
                if (selectedMonths.length > 0 ) {
                    redemptionCount = included_months + selectedMonths[0].months
                }
                // console.log('selectedMonthlyPrice: -setExpirationDate: ' + selectedMonthlyPrice);
                setExpirationDate(getExpirationDate(date_created, included_months, DateDayCondition.default));
                setExpirationDateDeductOneDay(getExpirationDate(date_created, redemptionCount, DateDayCondition.deductOneDay));
            }

            if (authUserInfo != null && !loadMembershipOnce) {
                setLoadMembershipOnce(true);
                checkStatus(spaceInfo.id, membershipId).then(r =>{} );
                getMembership(spaceInfo.id, membershipId).then((r) => {});
            }
        }
        // setAddCard(selectedCard.value === 'add');
        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);

    }, [
        currentLocation.pathname,
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        spaceUsername,
        membershipId,
        membership,
        loadMembershipOnce,
        hidePaymentMethod,
        isSubscription,
        isPaid,
        isCheckStatusLoaded,
        selectedMonthlyPrice
    ]);

    useEffect(() => {
    },[
        expirationDateDeductOneDay,
        expirationDate,
        isSubscription,
        autoMonthlyPrice,
        spaceInfo]
    );

    return (
        <>
            <main id="cs-space-main" className="cs-main main-theme">
                {(!spaceInfo || !membership) ? (
                    <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                        <div className="centerframe-flex frame-modal">
                            <div className="block-lg modal-block">
                                <i className="fa-regular fa-solar-system"></i>
                            </div>
                        </div>
                    </section>
                ) : (                    
                    <section id="cs-space-checkout-c1" className="py-0 px-0 lg:py-section-safeview theme-transparent">
                        <div className="cs-centerframe-split-lg">
                            <div className="cs-centerframe-split-left-lg order-2 lg:order-1">
                                <div className="p-section-safeview lg:pr-gutter">
                                    <div className="cs-stack-checkout-form stack-col-base gap-gutterxx lg:max-w-half-breakpoint-less-gutter">
                                        
                                        
                                            {isEmailConfirmed ? (
                                                <div className="cs-checkout-start stack-col">
                                                <hgroup className="heading">
                                                    <h2>
                                                        Email Confirmed!
                                                    </h2>
                                                    {authUserInfo && (
                                                        <p>Review and continue below to purchase this membership and join the community.</p>
                                                    )}
                                                </hgroup>
                                                <hgroup className="title">
                                                {authUserInfo ? (
                                                    <h3>{membership ? membership.name : ''}</h3>
                                                ) : (
                                                    <h3>{membership ? membership.name : ''} Membership</h3>
                                                )}
                                                <p>Your membership for {' '}
                                                    <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                        <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                    </a>
                                                    {' '}includes access, updates, benefits, and more.
                                                    {authUserInfo && (
                                                        <>{' '}Memberships can be a one-time price or monthly subscription</>
                                                    )}
                                                </p>
                                            </hgroup>
                                                </div>
                                            ) : (!isEmailConfirmed && membership && membership.type === MEMBERSHIP_TYPES.NFT_COLLECTION) ? (
                                                <div className="cs-checkout-start stack-col">
                                                <hgroup className="heading">
                                                    <h2>
                                                        Wallet Connected! <br/>
                                                        Review Membership Details.
                                                    </h2>
                                                    {authUserInfo && (
                                                        <p>Review and continue below to purchase this membership and join the community.</p>
                                                    )}
                                                </hgroup>
                                                <hgroup className="title">
                                                {authUserInfo ? (
                                                    <h3>{membership ? membership.name : ''}</h3>
                                                ) : (
                                                    <h3>{membership ? membership.name : ''} Membership</h3>
                                                )}
                                                <p>Your membership for {' '}
                                                    <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                        <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                    </a>
                                                    {' '}includes access, updates, benefits, and more.
                                                    {authUserInfo && (
                                                        <>{' '}Memberships can be a one-time price or monthly subscription</>
                                                    )}
                                                </p>
                                            </hgroup>
                                            <div className="cs-stack-checkout-product-info">
                                                <div className="stack-row">
                                                    <p>
                                                        {getPaymentType(membership) !== PaymentType.subscription ? (
                                                            'Membership Price'
                                                        ) : (
                                                            'Membership'
                                                        )}
                                                    </p>

                                                    {membership &&
                                                        <p>{getMembershipItemPrice(isSubscription, membership.type, membership)}</p>
                                                    }
                                                    {/*<p>${(membership?.price != null ? parseFloat(membership?.price).toFixed(2) : 0) + ' ' + (membership?.currency != null ? membership?.currency : 'USD') }</p>*/}
                                                </div>
                                                { membership && (
                                                    <>
                                                        {isSubscription &&
                                                            <div className="stack-row">
                                                                <p>Included</p>
                                                                <p>{getIncludedMonths(membership.first_benefit.included_months)}</p>
                                                            </div>
                                                        }
                                                        {expirationDateDeductOneDay &&
                                                            <div className="stack-row">
                                                                <p>Expiration</p>
                                                                <p>{expirationDateDeductOneDay.trim() !== ''  ? expirationDateDeductOneDay : 'No Expiration'}</p>
                                                            </div>
                                                        }
                                                        {getPaymentType(membership) !== PaymentType.subscription &&
                                                            <div className="stack-row">
                                                                <p>Expiration Date</p>
                                                                <p>No Expiration</p>
                                                            </div>
                                                        }
                                                        {/*{isSubscription &&*/}
                                                        {/*    <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                        {/*        <div className="col-span-4">*/}
                                                        {/*            <p className="m-0 body-text--small gradient-color-txt">*/}
                                                        {/*                Renewal*/}
                                                        {/*            </p>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="col-span-8">*/}
                                                        {/*            <div className="input-group-com">*/}
                                                        {/*                <DropdownWithItems*/}
                                                        {/*                    dropDownClass="w-full"*/}
                                                        {/*                    customButtonClass="btn-lg btn-secondary dropdown-primary"*/}
                                                        {/*                    title="Credit Card"*/}
                                                        {/*                    selectedValue={selectedMonthlyPrice}*/}
                                                        {/*                    listItems={autoMonthlyPrice}*/}
                                                        {/*                    onDropdownChange={(value) =>*/}
                                                        {/*                        setSelectedMonthlyPrice(value)*/}
                                                        {/*                    }*/}
                                                        {/*                />*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*}*/}
                                                    </>
                                                )}

                                                
                                            </div>
                                            </div>
                                            ) : (authUserInfo) && (
                                                <div class="cs-checkout-review-product stack-col">
                                                <hgroup className="heading">
                                                    <h2>
                                                        Review Membership
                                                    </h2>
                                                    {authUserInfo && (
                                                        <p>Review and continue below to purchase this membership and join the community.</p>
                                                    )}
                                                </hgroup>
                                                <hgroup className="title">
                                                {authUserInfo ? (
                                                    <h3>{membership ? membership.name : ''}</h3>
                                                ) : (
                                                    <h3>{membership ? membership.name : ''} Membership</h3>
                                                )}
                                                <p>Your membership for {' '}
                                                    <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                        <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                    </a>
                                                    {' '}includes access, updates, benefits, and more.
                                                    {authUserInfo && (
                                                        <>{' '}Memberships can be a one-time price or monthly subscription</>
                                                    )}
                                                </p>
                                            </hgroup>
                                            <div className="cs-stack-checkout-product-info">
                                                <div className="stack-row">
                                                    <p>
                                                        {getPaymentType(membership) !== PaymentType.subscription ? (
                                                            'Membership Price'
                                                        ) : (
                                                            'Membership'
                                                        )}
                                                    </p>

                                                    {membership &&
                                                        <p>{getMembershipItemPrice(isSubscription, membership.type, membership)}</p>
                                                    }
                                                    {/*<p>${(membership?.price != null ? parseFloat(membership?.price).toFixed(2) : 0) + ' ' + (membership?.currency != null ? membership?.currency : 'USD') }</p>*/}
                                                </div>
                                                { membership && (
                                                    <>
                                                        {isSubscription &&
                                                            <div className="stack-row">
                                                                <p>Included</p>
                                                                <p>{getIncludedMonths(membership.first_benefit.included_months)}</p>
                                                            </div>
                                                        }
                                                        {expirationDateDeductOneDay &&
                                                            <div className="stack-row">
                                                                <p>Expiration</p>
                                                                <p>{expirationDateDeductOneDay.trim() !== ''  ? expirationDateDeductOneDay : 'No Expiration'}</p>
                                                            </div>
                                                        }
                                                        {getPaymentType(membership) !== PaymentType.subscription &&
                                                            <div className="stack-row">
                                                                <p>Expiration Date</p>
                                                                <p>No Expiration</p>
                                                            </div>
                                                        }
                                                        {/*{isSubscription &&*/}
                                                        {/*    <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                        {/*        <div className="col-span-4">*/}
                                                        {/*            <p className="m-0 body-text--small gradient-color-txt">*/}
                                                        {/*                Renewal*/}
                                                        {/*            </p>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="col-span-8">*/}
                                                        {/*            <div className="input-group-com">*/}
                                                        {/*                <DropdownWithItems*/}
                                                        {/*                    dropDownClass="w-full"*/}
                                                        {/*                    customButtonClass="btn-lg btn-secondary dropdown-primary"*/}
                                                        {/*                    title="Credit Card"*/}
                                                        {/*                    selectedValue={selectedMonthlyPrice}*/}
                                                        {/*                    listItems={autoMonthlyPrice}*/}
                                                        {/*                    onDropdownChange={(value) =>*/}
                                                        {/*                        setSelectedMonthlyPrice(value)*/}
                                                        {/*                    }*/}
                                                        {/*                />*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*}*/}
                                                    </>
                                                )}

                                                
                                            </div>
                                                </div>
                                            )}

                                            
                                            
                                            {!authUserInfo && (
                                                    <>
                                                        <hgroup className="heading">

                                                            <h3>{isSignedUp ? 'Confirm your Account' : 'Sign Up or Log In to continue'}</h3>
                                                            {!isSignedUp ? (
                                                                <p>Enter your email to create a free account or continue below if you already have an account. By signing up, you agree to our <a href="fam-space-terms.html" target="_blank">Terms of Service</a>, <a href="fam-space-terms.html" target="_blank">Privacy Policy</a>, and  <a href="fam-space-terms.html" target="_blank">Community Guidelines</a>.</p>
                                                            ) : (
                                                                <p>Check your email to continue.</p>
                                                            )}
                                                        </hgroup>
                                                        {!isSignedUp ? (
                                                            <>
                                                                <div className="w-full">
                                                                    {alert && (
                                                                        <div className="alert callout error">
                                                                                <div className="action">
                                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                                </div>
                                                                                <div className="content">
                                                                                    <div>
                                                                                        <h6>{alert.message}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    )}
                                                                    <Form
                                                                        form={form}
                                                                        name="signupForm"
                                                                        validateMessages={VALIDATE_MESSAGES}
                                                                        onFocus={() => {
                                                                            setFormIsOnFocus(true);
                                                                        }}
                                                                        onBlur={() => {
                                                                            setFormIsOnFocus(false);
                                                                            handleSignUpFormOnBlur().then(() =>{});
                                                                        }}
                                                                        autoComplete="off"
                                                                        onChange={handleSignUpFormChanges}
                                                                        onFinish={handleSignup}
                                                                        className={formIsOnFocus ? 'hide-antd-error-messages' : 'relative z-0'}
                                                                    >
                                                                        <Form.Item
                                                                            name="email"
                                                                            validateTrigger="onBlur"
                                                                            rules={[{ required: true, type: 'email' }]}
                                                                        >
                                                                            <div className="form-input-combo">
                                                                                <label htmlFor="email">Email</label>
                                                                                <input id="email" name="email" type="email" autoComplete="email" required placeholder="Email"/>
                                                                                <button disabled={submitted || formHasError} type="submit" className="secondary-gradient">
                                                                                        <span>
                                                                                            <p>
                                                                                                {submitted && (
                                                                                                    <i className="fa-solid fa-spinner fa-spin  mr-2"></i>
                                                                                                )}
                                                                                                Sign Up
                                                                                            </p>
                                                                                        </span>
                                                                                </button>
                                                                            </div>
                                                                        </Form.Item>
                                                                        {form.getFieldsError()[0]?.errors.length > 0 && (
                                                                            <div className="alert callout warning">
                                                                                <div className="action">
                                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                                </div>
                                                                                <div className="content">
                                                                                    <div>
                                                                                        <h6>{form.getFieldsError()[0]?.errors}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Form>
                                                                </div>

                                                                <hgroup className="heading">
                                                                    <h4>Already have an Account?</h4>
                                                                    <p>Log in to continue.</p>
                                                                </hgroup>

                                                                {/* <form className="w-full stack-form-col">

                                                                    <div className="form-input-combo">
                                                                        <label htmlFor="email">Email or Username</label>
                                                                        <input id="email" name="email" type="email" autoComplete="email" required placeholder="Email or Username"/>
                                                                    </div>
                                                                    <div className="form-input-combo">
                                                                        <label htmlFor="password">Password</label>
                                                                        <input id="password" name="password" type="password" autoComplete="current-password" required placeholder="Password"/>
                                                                        <a href="fam-space-checkout-review.html" type="submit" className="button-secondary-gradient"><span><p>Login</p></span></a>
                                                                    </div>
                                                                </form>  */}
                                                                <Form
                                                                    form={loginForm}
                                                                    name="loginForm"
                                                                    validateMessages={VALIDATE_MESSAGES}
                                                                    onFocus={() => {
                                                                        setFormIsOnFocus(true);
                                                                    }}
                                                                    onBlur={() => {
                                                                        setFormIsOnFocus(false);
                                                                        handleLoginFormOnBlur().then(() => {});
                                                                    }}
                                                                    autoComplete="off"
                                                                    onChange={handleLoginFormChanges}
                                                                    onFinish={handleLogin}
                                                                    className={(formIsOnFocus? 'hide-antd-error-messages z-0': 'otp relative z-0') +' w-full stack-form-col !p-0 !m-0'}
                                                                >
                                                                {!hasMfaEnabled && (
                                                                    <>
                                                                    {alert2 && (
                                                                        <div className="alert callout error">
                                                                                <div className="action">
                                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                                </div>
                                                                                <div className="content">
                                                                                    <div>
                                                                                        <h6>{alert2.message}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    )}
                                                                        <Form.Item
                                                                            name="email"
                                                                            className={`w-full stack-form-col !p-0 !m-0`}
                                                                            validateTrigger="onBlur"
                                                                            rules={[{ required: true }]}
                                                                        >
                                                                            <div className="form-input unlabeled !p-0 !rounded-full">
                                                                                <input id="email" name="email" type="text" autoComplete="email" required placeholder="Email or Username"/>
                                                                            </div>

                                                                        </Form.Item>
                                                                        {(loginForm.getFieldsError()[0]?.errors.length > 0 && (alert && alert.message)) && (
                                                                            <div className="alert callout warning !m-0">
                                                                                <div className="action">
                                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                                </div>
                                                                                <div className="content">
                                                                                    <div>
                                                                                        <h6>{loginForm.getFieldsError()[0]?.errors.length > 0 ? loginForm.getFieldsError()[0]?.errors : (alert && alert.message) ? alert.message : ''}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {!hasMfaEnabled && (
                                                                    <>
                                                                    <Form.Item
                                                                        name="password"
                                                                        className={`w-full stack-form-col !m-0`}
                                                                        validateTrigger="onBlur"
                                                                        rules={[{ required: true }]}
                                                                    >
                                                                        <div className="form-input-combo">
                                                                            <label htmlFor="password">Password</label>
                                                                            <input id="password" name="password" type="password" autoComplete="current-password" required placeholder="Password"/>
                                                                            <button  disabled={submitted || formHasError} type="submit" className="secondary-gradient">
                                                                                <span><p>
                                                                                    Login</p></span>
                                                                            </button>
                                                                        </div>

                                                                    </Form.Item>

                                                                    {(loginForm.getFieldsError()[1]?.errors.length > 0) && (
                                                                        <div className="alert callout warning !m-0">
                                                                            <div className="action">
                                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                                            </div>
                                                                            <div className="content">
                                                                                <div>
                                                                                    <h6>{loginForm.getFieldsError()[1]?.errors}</h6>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    )}
                                                                    </>
                                                                )}
                                                                </Form>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <a>
                                                                    <div className="block-callout card stack-col">
                                                                        <hgroup className="heading">
                                                                            <h3>Thank you for signing up!</h3>
                                                                            <p>We’ve sent an email with a link to confirm your account. Please click it to continue. If you do not see it within 5 minutes, please check your spam folder.</p>
                                                                        </hgroup>
                                                                    </div>
                                                                </a>

                                                                <p>No email? <a onClick={()=>{
                                                                        removeAccessToken('user');
                                                                        // let url = routes.spaceMembershipPaymentCompleted();
                                                                        // const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
                                                                        // navigate(fin_url);
                                                                        window.location.reload(true);
                                                                    }}>Re-enter your email here.</a>
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            {(authUserInfo && isSubscription) &&
                                                <>
                                                    <h4>Select an Auto-Renew Option</h4>
                                                    <fieldset className="form-radio-set card-block gradient secondary set-input-hidden" aria-label="Options">
                                                        <div className="form-stack-radio-options">

                                                            <label htmlFor="renewal_monthly" className="form-radio-option">
                                                                <span>
                                                                    <input id="renewal_monthly"
                                                                           type="radio"
                                                                           name="renewal-options"
                                                                           value={getRecurringPrice(membership, RenewalOptions.monthly).discounted_price}
                                                                           className="hidden"
                                                                           onChange={handleOptionChange}
                                                                    />
                                                                    {/*   CSJS User/Space Owner set price for Monthly / 1 Month Count.   */}
                                                                    <p aria-label="renewal_monthly" aria-description="Renew Subscription Monthly">{getRecurringPrice(membership, RenewalOptions.monthly).label}</p>
                                                                </span>
                                                                <span>
                                                                    {/*CSJS Month Count is preset. Use as basis to calculate percentage off for multi-month options. */}
                                                                    <p>1 Month</p>
                                                                </span>
                                                            </label>

                                                            <label htmlFor="renewal_quarterly" className="form-radio-option">
                                                                <span>
                                                                    <input id="renewal_quarterly"
                                                                           type="radio"
                                                                           name="renewal-options"
                                                                           value={getRecurringPrice(membership, RenewalOptions.quarterly).discounted_price}
                                                                           className="hidden"
                                                                           onChange={handleOptionChange}
                                                                    />
                                                                    {/*   CSJS User/Space Owner set price for Quarterly / 3 Month Count.   */}
                                                                    <p aria-label="renewal_quarterly" aria-description="Renew Subscription Quarterly">{getRecurringPrice(membership, RenewalOptions.quarterly).label}</p>
                                                                </span>
                                                                <span>
                                                                    {/*   CSJS Month Count is preset. Percentage should be calculated based on User/Space Owner set price.   */}
                                                                    <p>3 Months <i>10% off</i></p>
                                                                </span>
                                                            </label>

                                                            <label htmlFor="renewal_biannually" className="form-radio-option">
                                                                <span>
                                                                    <input id="renewal_biannually"
                                                                           type="radio"
                                                                           name="renewal-options"
                                                                           value={getRecurringPrice(membership, RenewalOptions.biannually).discounted_price}
                                                                           className="hidden"
                                                                           onChange={handleOptionChange}
                                                                    />
                                                                    {/*   CSJS User/Space Owner set price for Biannual / 6 Month Count.   */}
                                                                    <p aria-label="renewal_biannually" aria-description="Renew Subscription Biannually">{getRecurringPrice(membership, RenewalOptions.biannually).label}</p>
                                                                </span>
                                                                <span>
                                                                    {/*   CSJS Month Count is preset. Percentage should be calculated based on User/Space Owner set price.   */}
                                                                    <p>6 Months <i>15% off</i></p>
                                                                </span>
                                                            </label>

                                                            <label htmlFor="renewal_annually" className="form-radio-option">
                                                                <span>
                                                                    <input id="renewal_annually"
                                                                           type="radio"
                                                                           name="renewal-options"
                                                                           value={getRecurringPrice(membership, RenewalOptions.annually).discounted_price}
                                                                           className="hidden"
                                                                           onChange={handleOptionChange}
                                                                    />
                                                                    {/*    CSJS User/Space Owner set price for Annual / 12 Month Count.    */}
                                                                    <p aria-label="renewal_annually" aria-description="Renew Subscription Annually">{getRecurringPrice(membership, RenewalOptions.annually).label}</p>
                                                                </span>
                                                                <span>
                                                                    {/*    CSJS Month Count is preset. Percentage should be calculated based on User/Space Owner set price.   */}
                                                                    <p>12 Months <i>25% off</i></p>
                                                                </span>
                                                            </label>

                                                            <label htmlFor="renewal_none" className="form-radio-option">
                                                                <span>
                                                                    <input id="renewal_none"
                                                                           type="radio"
                                                                           name="renewal-options"
                                                                           value="renewal_none"
                                                                           className="hidden"
                                                                           onChange={handleOptionChange}
                                                                    />
                                                                    <p aria-label="renewal_none" aria-description="No Renewal">Auto-Renew Off</p>
                                                                </span>
                                                                <span>
                                                                    <p>Do not renew</p>
                                                                </span>
                                                            </label>

                                                        </div>
                                                    </fieldset>
                                                </>
                                            }

                                        {authUserInfo && (
                                            
                                                <div className="cs-checkout-review-pricing stack-col">
                                                    <hgroup className="heading">
                                                        <h2>Pricing & Terms</h2>
                                                        <p>By continuing below, you are authorizing <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                        <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                        </a> to charge your Payment Method for</p>
                                                    </hgroup>
                                                    <div className="cs-checkout-total">
                                                        <div className="cs-checkout-total-upfront">
                                                            {(membership != null && isSubscription) ? (
                                                                <>
                                                                    <h3>${(membership?.first_benefit?.monthly_price != null ? parseFloat(membership?.first_benefit?.monthly_price ?? 0).toFixed(2) : 0) }</h3>
                                                                    {/*<h3>${(membership.price != null ? parseFloat(membership.price).toFixed(2) : 0) + ' ' + (membership.currency != null ? membership.currency : 'USD') }</h3>*/}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <h3>${(membership?.price != null ? parseFloat(membership?.price ?? 0).toFixed(2) : 0) }</h3>
                                                                    {/*<h3>${(membership.price != null ? parseFloat(membership.price).toFixed(2) : 0) + ' ' + (membership.currency != null ? membership.currency : 'USD') }</h3>*/}
                                                                </>
                                                            )}
                                                            {/* getCheckoutTypeText will handle the type of payment for the membership */}
                                                            <p>{getCheckoutTypeText(membership)}</p>
                                                        </div>
                                                        {isSubscription &&
                                                            <>
                                                                {/*CSJS Once renew option has been selected, replace with "+ $[price] every [count] month(s) auto-renewal charge starting [date] until canceled." or*/}
                                                                {/*"No auto-renewal, expiring [date].". For example, "+ $20 every month auto-renewal starting August 26, 2024 until canceled.*/}
                                                                {/*" or "+ $102 every 6 months auto-renewal charge starting August 26, 2024 until canceled." or "No auto-renewal, expiring August 25, 2024.*/}
                                                                <div className="cs-checkout-total-renewal">
                                                                    <p>+ {getExpirationMonthlyStart(selectedMonthlyPrice, autoMonthlyPrice, membership?.first_benefit?.currency, expirationDate, membership?.first_benefit?.included_months)}</p>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <form className="cs-checkout-form-promo stack-col">
                                                        <div className="form-input-combo">
                                                            <label htmlFor="promo">Promo Code</label>
                                                            <input id="promo" name="promo" type="text" placeholder="Promo Code (optional)"/>
                                                            <a href="#" type="submit" className="button-secondary-gradient"><span><p>Redeem</p></span></a>
                                                        </div>
                                                    </form>
                                                    <hgroup className="title">
                                                            <h3>Payment Details</h3>
                                                            <p>All major debit cards, credit cards, Apple Pay, Google Pay, and wallets are accepted. Your full card number is never accessible and only the last 4 digits are used for identification.</p>
                                                    </hgroup>
                                                    <Form
                                                        form={membershipPaymentMethodForm}
                                                        name="membershipPaymentMethod"
                                                        id="membershipPaymentMethod"
                                                        validateMessages={VALIDATE_MESSAGES}
                                                        onFocus={() => {
                                                            setFormIsOnFocus(true);
                                                            setAlert(null);
                                                        }}
                                                        onBlur={() => {
                                                            setTimeout(() => {
                                                                setFormIsOnFocus(false);
                                                                handleFormOnBlur().then(r => {});
                                                            }, 200);
                                                        }}
                                                        autoComplete="off"
                                                        onChange={handleFormChanges}
                                                        onFinish={handleSubmit}
                                                        className={formIsOnFocus ? 'hide-antd-error-messages cs-checkout-terms stack-col' : ' cs-checkout-terms stack-col'}
                                                    >
                                                        <fieldset>
                                                            <legend className="sr-only">Terms & Agreement</legend>
                                                            <div className="form-input-checkbox gradient md rounded row">
                                                                <div className="checkbox">
                                                                    <input id="checkbox-space-agreement" onChange={onChangeAgreement1} aria-describedby="checkbox-space-agreement-description" name="checkbox-space-agreement" type="checkbox"/>
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="checkbox-space-agreement" className="sr-only">Space Terms</label>
                                                                    <p id="checkbox-space-agreement-description">By joining, you agree to {spaceInfo ? spaceInfo.name : ''}  <a href={routes.viewUserSpace() + spaceInfo?.username + '/terms-services'} target="_blank">Terms of Service</a>, <a href={routes.viewUserSpace() + spaceInfo?.username + '/privacy-policy'} target="_blank">Privacy Policy</a>, and <a href={getSitewideLink('community_guidelines_space_create')} target="_blank">Community Guidelines.</a></p>
                                                                </div>
                                                            </div>

                                                            <div className="form-input-checkbox gradient md rounded row">
                                                                <div className="checkbox">
                                                                <input id="checkbox-cs-agreement" onChange={onChangeAgreement2} aria-describedby="checkbox-cs-agreement-description" name="checkbox-cs-agreement" type="checkbox"/>
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="checkbox-cs-agreement" className="sr-only">Space Terms</label>
                                                                    <p id="checkbox-cs-agreement-description">By continuing, you agree to the Commonspace Platforms <a href={getSitewideLink('terms_of_service_space_create')} target="_blank">Terms of Service</a> and <a href={getSitewideLink('privacy_policy_space_create')} target="_blank">Privacy Policy</a>.</p>
                                                                </div>

                                                            </div>
                                                        </fieldset>

                                                        {hidePaymentMethod && membership != null &&
                                                                <Form.Item>
                                                                {/* <button type="submit" class="secondary-gradient w-full"><span><p>Post</p></span></button> */}
                                                                    <button
                                                                        className="primary-gradient w-full"
                                                                        type="submit"
                                                                        disabled={!agreementChecked1 || !agreementChecked2}
                                                                    ><span><p>
                                                                        {submitted && (
                                                                            <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                                        )}
                                                                        {getButtonPaymentText(isSubscription, membership.type, selectedMonthlyPrice)}
                                                                        </p></span>
                                                                    </button>
                                                                    {/* <a href="#" type="submit" className="button-secondary-gradient"><span><p>Redeem</p></span></a> */}
                                                                </Form.Item>
                                                        }

                                                        {!hidePaymentMethod && membership != null &&
                                                            <Form.Item >
                                                            {/* <button type="submit" class="primary-gradient w-full"><span><p>Post</p></span></button> */}
                                                                <button
                                                                    className="primary-gradient w-full"
                                                                    type="submit"
                                                                    disabled={
                                                                            submitted ||
                                                                            formHasError ||
                                                                            !agreementChecked1 ||
                                                                            !agreementChecked2

                                                                    }
                                                                ><span><p>
                                                                    {submitted && (
                                                                        <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                                    )}
                                                                    <i className="fa-regular fa-wallet"></i>
                                                                    {getButtonPaymentText(isSubscription, membership.type, selectedMonthlyPrice)}
                                                                    </p></span>
                                                                </button>
                                                            </Form.Item>
                                                        }

                                                        {alert && (
                                                            <div className="alert callout warning !m-0">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <div>
                                                                        <h6>{alert?.message ?? 'Payment Error!'}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="stack-button-info">
                                                            <p><span>AES 256-Encrypted</span> All Payment Information is securely managed and processed by Stripe.</p>
                                                        </div>
                                                    </Form>
                                                </div>
                                            
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="cs-centerframe-split-right-lg order-1 lg:order-2 lg:pt-gutter lg:pb-gutter">
                                <div className="cs-checkout-product-hero">
                                    {membershipItemImage(collectionAssetType, membership)}
                                </div>
                            </div>
                        </div>
                    </section>
                )}

            </main>
        </>
    );
}

export default memo(MembershipPaymentMethod);