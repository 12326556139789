import { memo, useCallback, useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';
import Alert from 'react-bootstrap/Alert';

import {
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_KEY,
	MAGIC_CONNECT_NETWORK
} from '../../constants/common';
import { setAccessToken } from '../../utils/common';
import { emailSignUp, registerMagic } from '../../services/user';
import routes from '../../routes';
import dj_bg_img from '../../assets/images/cs-splash.jpg';
import './SignUp.scss';
import { getSpaceMembership, getSpaceItemData } from '../../services/space';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import { getSitewideLink } from '../../utils/sitewideLinks';

const SignUpForm = () => {
	const [form] = Form.useForm();
	const [params] = useSearchParams();
	const currentLocation = useLocation();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [isMagicLink, setIsMagicLink] = useState(false);
	const [alert, setAlert] = useState(null);
	const [successSignUp, setSuccessSignUp] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(true);
	const [formHasError, setFormHasError] = useState(true);
	const [initialLoad, setInitialLoad] = useState(true);
	const [verifyEmailSpaceUsername, setVerifyEmailSpaceUsername] = useState(null);
	const [verifyEmailSpaceMembershipId, setVerifyEmailSpaceMembershipId] = useState(null);
	const [membershipDetails, setMembershipDetails] = useState(null);
	const [verifyEmailSpaceItemId, setVerifyEmailSpaceItemId] = useState(null);
	const [itemDetails, setItemDetails] = useState(null);
	const { size } = useSelector((state) => state.classSize);
	const [margin, setMargin] = useState();

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const web3 = new Web3(magic.rpcProvider);

	const handleFormOnBlur = useCallback(async () => {
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setAlert(null);

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(async (values) => {
		setAlert(null);

		try {
			if (isMagicLink) {
				await web3Login(values);
			} else {
				await registerUser(values);
			}
		} catch (error) {
			setAlert({ variant: 'danger', message: error.message });
		}
	}, []);

	const registerUser = async (values) => {
		setSuccessSignUp(false);
		setSubmitted(true);
		values.email = values.email.toLowerCase();
		const result = await emailSignUp(values);
		if (result) {
			if (result.result) {
				form.resetFields();
				setAlert({
					variant: 'success',
					message: 'Registered successfully!'
				});
				setSuccessSignUp(true);
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'Something went wrong'
					});
				}
			}
			setSubmitted(false);
		}
	};

	const registerMagicUser = async (values) => {
		setSubmitted(true);

		const result = await registerMagic(values);

		if (result) {
			if (result.result) {
				if (result.access_token) {
					setAccessToken(result);
				}

				form.resetFields();
				setAlert({
					variant: 'success',
					message: 'Registered successfully!'
				});

				window.location.replace(routes.walletSetAccount());
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'Something went wrong'
					});
				}
				setIsMagicLink(false);
				setSubmitted(false);
			}
		}
	};

	const getWalletInfo = (values) => {
		magic.connect.getWalletInfo().then((wallet_info) => {
			values.type = wallet_info.walletType;
			registerMagicUser(values);
		});
	};

	// Web 3 Login
	const web3Login = async () => {
		web3.eth
			.getAccounts()
			.then((accounts) => {
				magic.connect
					.requestUserInfo({ isResponseRequired: false })
					.then((user_account) => {
						let values = {
							email: user_account.email ?? 'no-email',
							user_public_address: accounts?.[0]
						};
						getWalletInfo(values);
					});
			})
			.catch((error) => {
				// setSubmitted(false);
				setIsMagicLink(false);
				// console.log(error);
			});
	};

	const magicLogin = async () => {
		// setSubmitted(true);
		setIsMagicLink(true);
		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});
		await web3Login();
	};

	const getMembership = useCallback(async (space_id, membership_id) => {
		const response = await getSpaceMembership({
			space_id: space_id,
			membership_id: membership_id
		});
		if (response && response.result) {
			const membershipData = response.membership;
			setMembershipDetails(membershipData);
		}
	}, []);

	const getItem = useCallback(async (space_id, item_id) => {
		const response = await getSpaceItemData({
			space_id: space_id,
			item_id: item_id
		});
		if (response && response.result) {
			const itemData = response.item;
			setItemDetails(itemData);
		}
	}, []);

	useEffect(() => {
		setMargin(() => {
			if (size !== 'lg') {
				return '4';
			} else {
				return '5';
			}
		});
		document.title = 'Commonspace Registration';

		if (loading && loading === 'done' && authUserInfo) {
			if (
				location.pathname === routes.walletSetAccountEmailSent() ||
				(verifyEmailSpaceUsername &&
					(verifyEmailSpaceMembershipId || verifyEmailSpaceItemId))
			) {
				setSuccessSignUp(true);
			} else {
				window.location.replace(routes.dashboard());
			}
		}

		const path = currentLocation.pathname;
		if (path.includes('membership/wallet/set-account/email-sent')) {
			const pathArr = path.match(/^\/([^/]*)\//);
			if (pathArr && pathArr.length > 0 && pathArr[1]) {
				const id = params.get('id');
				if (id) {
					const username = path.match(/^\/([^/]*)\//)[1];
					const space_username = username.replace('@', '');
					setVerifyEmailSpaceUsername(space_username);
					setVerifyEmailSpaceMembershipId(parseInt(id));

					if (initialLoad) {
						setInitialLoad(false);
						dispatch(getSpaceInfo(space_username));
					}
				}
			}
		}
		if (path.includes('item/wallet/set-account/email-sent')) {
			const pathArr = path.match(/^\/([^/]*)\//);
			if (pathArr && pathArr.length > 0 && pathArr[1]) {
				const id = params.get('id');
				if (id) {
					const username = path.match(/^\/([^/]*)\//)[1];
					const space_username = username.replace('@', '');
					setVerifyEmailSpaceUsername(space_username);
					setVerifyEmailSpaceItemId(parseInt(id));

					if (initialLoad) {
						setInitialLoad(false);
						dispatch(getSpaceInfo(space_username));
					}
				}
			}
		}

		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				if (verifyEmailSpaceMembershipId) {
					getMembership(spaceInfo.id, verifyEmailSpaceMembershipId).then((r) => {});
				}
				if (verifyEmailSpaceItemId) {
					getItem(spaceInfo.id, verifyEmailSpaceItemId).then((r) => {});
				}
			}
		}
	}, [
		currentLocation.pathname,
		loading,
		authUserInfo,
		verifyEmailSpaceUsername,
		verifyEmailSpaceMembershipId,
		verifyEmailSpaceItemId,
		spaceInfoLoading,
		spaceInfo,
		size,
		margin
	]);

	return (
        <div id="cs-platform-main" className="cs-main main-theme">
            <section id="cs-space-auth-c1" className="py-0 px-0 sm:py-section-safeview theme-transparent">
                <div className="cs-centerframe-split">
                    <div className="cs-centerframe-split-left order-2 sm:order-1">
                        <div className="p-section-safeview sm:pr-gutter">
                            <div className="cs-stack-auth-form stack-col sm:max-w-half-breakpoint-less-gutter">
                                <hgroup className="heading">
                                    <h2>
                                        {location.pathname === routes.walletSetAccountEmailSent() ||
                                            (verifyEmailSpaceUsername &&
                                                (verifyEmailSpaceMembershipId || verifyEmailSpaceItemId)) ? (
                                                <>Confirm Email</>
                                            ) : (
                                                <>Sign Up</>
                                        )}
                                    </h2>
                                    <p className="text-p1">
                                        {location.pathname === routes.walletSetAccountEmailSent() ||
                                        (verifyEmailSpaceUsername &&
                                            (verifyEmailSpaceMembershipId || verifyEmailSpaceItemId)) ? (
                                            <>
                                                Your account has been saved and an email has been added. Please
                                                check your email to confirm.
                                            </>
                                        ) : (
                                            <>
                                            Create your member account for free.
                                            </>
                                        )}
                                    </p>
                                </hgroup>
                            {successSignUp && (
                                <a href={routes.profile}>
                                <div className="block-callout card stack-col">
                                    <hgroup className="heading">
                                        <h3>
                                        {location.pathname === routes.walletSetAccountEmailSent() ||
		 								(verifyEmailSpaceUsername &&
		 									(verifyEmailSpaceMembershipId ||
		 										verifyEmailSpaceItemId)) ? (
		 									<>Please check and confirm your email.</>
		 								) : (
		 									<>Thank you for signing up!</>
		 								)}
                                        </h3>
                                        <p>We’ve sent an email with a link to confirm your account. Please click it to continue. If you do not see it within 5 minutes, please check your spam folder.</p>
                                    </hgroup>
                                </div>
                                </a>
		 					)}
                            {!successSignUp && (
                                <div className='w-full'>
                                <Form
                                    form={form}
                                    name="signupForm"
                                    validateMessages={VALIDATE_MESSAGES}
                                    onFocus={() => {
                                        setFormIsOnFocus(true);
                                    }}
                                    onBlur={() => {
                                        setFormIsOnFocus(false);
                                        handleFormOnBlur().then(() =>{});
                                    }}
                                    autoComplete="off"
                                    onChange={handleFormChanges}
                                    onFinish={handleSubmit}
                                    className={formIsOnFocus ? 'hide-antd-error-messages' : 'relative z-0'}
                                >
									<Form.Item
										name="email"
										className=""
										validateTrigger="onBlur"
										rules={[{ required: true, type: 'email' }]}
									>
										<div className="form-input-combo">
											<label htmlFor="email">Email</label>
											<input id="email" name="email" type="email" autoComplete="email" required placeholder="Email"/>
											<button  disabled={submitted || formHasError} type="submit" className="secondary-gradient">
                                                    <span>
                                                        <p>
                                                            {submitted && (
																<i className="fa-solid fa-spinner fa-spin  mr-2"></i>
															)}
															Sign Up
                                                        </p>
                                                    </span>
											</button>
										</div>
									</Form.Item>
                                    {form.getFieldsError()[0]?.errors.length > 0 && (
                                        <div className="alert callout warning">
                                            <div className="action">
                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                            </div>
                                            <div className="content">
                                                <div>
                                                    <h6>{form.getFieldsError()[0]?.errors}</h6>
                                                </div>  
                                            </div>
                                        </div>
                                        )}
                                </Form>
                            </div>
                            )}
                            {!successSignUp && (
                                <>
                                    <p>By signing up, you agree to our <a target="_blank">Terms of Service</a>, <a  target="_blank">Privacy Policy</a>, and  <a target="_blank">Community Guidelines</a>.</p>
                                    <p>Already have an account? <a href={routes.login()}>Login here.</a></p>
                                </>
                            )}
                            {successSignUp && (
                                <p>No email? <a href='#' onClick={() => setSuccessSignUp(false)}>Re-enter your email here.</a></p>
                            )}
                            </div>
                        </div>
                    </div>
                    <div className="cs-centerframe-split-right order-1 sm:order-2">
                    
                        <div className="lg:pl-half-gutter">
                        	{membershipDetails && membershipDetails.video === 'video' ? (
		 					<video autoPlay muted loop className="membership-video">
		 						<source src={membershipDetails.video} type="video/mp4" />
		 					</video>
                            ) : (
                                <img
									className="sm:max-h-element"
                                    src={membershipDetails ? membershipDetails.photo : dj_bg_img}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </section>
          
        </div>	
		
	);
};

const SignUp = () => {
	return <SignUpForm />;
};
export default memo(SignUp);
