import React, { memo, useEffect, useCallback, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import { Alchemy, Network } from 'alchemy-sdk';
import Web3 from 'web3';
import './SpaceClaimMembership.scss';

import {MEMBERSHIP_TYPES} from "../../constants/space";
import { setLocalStorage } from '../../utils/storage';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import {
	getSpaceMembership,
} from '../../services/space';
import {
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_KEY,
	NODE_ENV,
	MAGIC_CONNECT_NETWORK
} from '../../constants/common';
import { getUserSpaceMembershipClaim } from '../../services/user';
import routes from '../../routes';

import default_items_img from '../../assets/images/form_background.png';
import '../SpaceView/SpaceView.scss';
import {getCommunityBtnText, getDetailsGift, getDetailsMonthlyPrice, getDetailsPrice} from "../../components/commons/helpers/MembershipItemHelpers";
import {MembershipItemUtils} from "../../utils/MembershipItemUtils";
import {checkMembershipItemStatus, createStripePaymentIntent} from "../../services/payment_method";
import ReactPlayer from "react-player/lazy";

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }

const SpaceClaimMembership = (props) => {
	const { hasGradient, url } = props;
	const currentLocation = useLocation();
	let space_username = null;
	const [searchParams, setSearchParams] = useSearchParams();
	const membershipId = searchParams.get('id');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [submitted, setSubmitted] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [membership, setMembership] = useState(null);
	const [isClaimed, setClaimed] = useState(false);
	const [fetchingCollectionData, setFetchingCollectionData] = useState(false);
	const [collectionData, setCollectionData] = useState(null);
	const [collectionAssetType, setCollectionAssetType] = useState('image');
	const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
	const [spaceName, setSpaceName] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [loadMembershipOnce, setLoadMembershipOnce] = useState(false);
	const [status, setStatus] = useState('');
	const [isMembershipLoaded, setMembershipLoaded] = useState(false);

	// For testing purpose only //
	const test_public_address = '0x452d40db156034223e8865f93d6a532ae62c4a99';
	const test_contract_address = '0xb0bbbc0f43d611351b3115bd5e338f4c402ae8c2';
	// End testing data //
    


	const settings = {
		apiKey: ALCHEMY_API_KEY,
		network: Network.ETH_MAINNET
	};

	const alchemy = new Alchemy(settings);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const web3 = new Web3(magic.rpcProvider);

	const path = currentLocation.pathname;
	if (path && path.startsWith('/@') && path.length > 3) {
		const clean_path = path.replace('/@', '');
		const path_exp = clean_path.split('/');
		space_username = path_exp[0];
	}

	const scrollToClick = () => {
		const element = document.getElementById('cs-space-product-item-c2');
		element.scrollIntoView({ behavior: 'smooth' });
	}

	const getMembership = useCallback(async (space_id, membership_id) => {
		if (!membership_id) {
			return;
		}
		const data = {
			space_id: space_id,
			membership_id: membership_id
		};

		if (authUserInfo) {
			data['viewing_user_id'] = authUserInfo.id;
		}

		const response = await getSpaceMembership(data);
		if (response && response.result) {
			const membershipData = response.membership;
			setMembership(membershipData);
			setMembershipLoaded(true);

			if (membershipData.photo || membershipData.video) {
				if (membershipData.photo) {
					const bg = {
						backgroundImage: 'url(' + membershipData.photo + ')',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					};
					setCollectionAssetBGImage(bg);
					setCollectionAssetType('image');
				}
				if (membershipData.video) {
					setCollectionAssetType('video');
				}
			} else {
				const bg = {
					backgroundImage: 'url(' + default_items_img + ')',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover'
				};
				setCollectionAssetBGImage(bg);
				setCollectionAssetType('image');
			}

			if (membershipData) {
				//TODO-MJ: Bug will show error when the contract address is not valid
				getNFTCollectionData(membershipData).then();
			}
		}
	}, []);

    // const getUrl = (url) => {
    //     if (url.startsWith('https://')) {
    //         return url.slice(8); // Remove the first 8 characters (https://)
    //     }
    //     if (url.startsWith('http://')) {
    //         return url.slice(7); // Remove the first 7 characters (http://)
    //     }
    //     return url;
    // }
    
    

	const getMembershipClaim = useCallback(async () => {
		if (!membershipId) {
			return;
		}

		const response = await getUserSpaceMembershipClaim({
			user_id: authUserInfo.id,
			space_id: spaceInfo.id,
			membership_id: membershipId
		});
		if (response && response.result) {
			setClaimed(true);
			// setMembershipClaim(response.membership_claim);
		}
	}, [authUserInfo, spaceInfo, membershipId]);

	const checkOwnerIfInCollection = useCallback(
		async (owner_contract_address, owner_email = null) => {
			let collection_contract_address = membership.collection_contract_address;

			//WE use test data on dev environment
			if (NODE_ENV === 'development') collection_contract_address = test_contract_address;

			alchemy.nft.getNftsForOwner(owner_contract_address).then((response) => {
				if (response) {
					checkAddressIfInCollection(
						owner_contract_address,
						collection_contract_address,
						response.ownedNfts,
						owner_email
					).then();
				}
			});
		},
		[authUserInfo, spaceInfo, membershipId, membership]
	);

	const checkAddressIfInCollection = useCallback(
		async (owner_contract_address, collection_address, nfts, owner_email = null) => {
			let found = false;
			nfts.forEach((nft) => {
				if (nft.contract && nft.contract.address) {
					const nftContractAddressLowerCase = nft.contract.address.toLowerCase();
					const collectionAddressLowerCase = collection_address.toLowerCase();
					if (nftContractAddressLowerCase === collectionAddressLowerCase) {
						found = true;
					}
				}
			});

			// console.log('found: '+found);

			setLocalStorage('temp_membership_nft_is_owned', found);
			setLocalStorage('temp_membership_space_username', space_username);

			if (found) {
				setLocalStorage(
					'temp_membership_wallet_owner_contract_address',
					owner_contract_address
				);
				setLocalStorage('temp_membership_wallet_owner_email', owner_email);

				const isSubscription = membership.enable_expiration_and_subscription
				if (isSubscription) {
					//Goes to Payment Method layout if subscription is enabled
					let paymentMethodUrl = routes.spaceMembershipPaymentMethod();
					const payment_fin_url =
						paymentMethodUrl.replace(':space_username', '@' + space_username) + '?id=' + membershipId;
					navigate(payment_fin_url);
				} else {
					console.log('spaceMembershipWalletConnect: ');
					//Goes to Payment Wallet connect layout
					// const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
					let url = routes.spaceMembershipWalletConnect();
					//redirect to wallet connect for item when the collection is NFT and no subscription
					// if (membershipDetails.collection_type === 'membership') {
					// 	if (isSpaceMember) {
					// 		// Membership Ready
					// let url = routes.spaceMembershipActivate()
					// 		//TODO-MJ add the error layout when connecting wallet failed
					// 	} else {
					// 		// EXISTING USER/NEW TO SPACE CLAIM NOW FLOW where User
					// 		// must own an existing NFT to claim Membership/Item NO SUBSCRIPTION
					// 		url = routes.spaceMembershipWalletConnect();
					// 	}
					// }
					const fin_url = url.replace(':space_username', '@' + space_username) + '?id=' + membershipId;

					console.log(fin_url)
					navigate(fin_url);

				}
			} else {
				const url = routes.spaceMembershipErrorConnect();
				const fin_url =
					url.replace(':space_username', '@' + space_username) + '?id=' + membershipId;
				window.location.replace(fin_url);
			}
		},
		[space_username, membershipId, membership, spaceInfo, authUserInfo]
	);

	const claimMembership = useCallback(async () => {
		// if (MembershipItemUtils.isOAuth(loading, authUserInfo)){
			if (spaceInfo) {
				setSubmitted(true);

				if (authUserInfo) {
					await web3Login(true);
				} else {
					await web3Login(false);
				}
			}
		// } else {
		// 	window.location.replace(routes.login());
		// }

	}, [authUserInfo, spaceInfo, membershipId, membership]);

	const web3Login = async (loggedIn) => {
		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});
		setSubmitted(true);
		web3.eth
			.getAccounts()
			.then((accounts) => {
				magic.connect
					.requestUserInfo({ isResponseRequired: false })
					.then((user_account) => {
						let values = {
							email: user_account.email ?? 'no-email',
							user_public_address: accounts?.[0]
						};

						//WE use test data on dev environment
						if (NODE_ENV === 'development') {
							checkOwnerIfInCollection(test_public_address, values.email).then();
						} else {
							checkOwnerIfInCollection(
								values.user_public_address,
								values.email
							).then();
						}
					});
			})
			.catch((error) => {
				setSubmitted(false);
			});
	};

	// const joinMembership = useCallback(async () => {
	// 	if (spaceInfo) {
	// 		setSubmitted(true);
	//
	// 		if (authUserInfo) {
	// 			// TODO: Do code for join payment here
	//
	// 			if (authUserInfo.user_public_address) {
	// 				const response = await joinSpaceMembership({
	// 					user_id: authUserInfo.id,
	// 					membership_id: membershipId
	// 				});
	// 				if (response && response.result) {
	// 					getMembershipClaim().then();
	// 				}
	// 			} else {
	// 				await web3Login();
	// 			}
	//
	// 			setSubmitted(false);
	// 		} else {
	// 			await web3Login();
	// 		}
	// 	}
	// }, [authUserInfo, spaceInfo, membershipId, membershipDetails]);

	const getNFTCollectionData = useCallback(async (membershipData) => {
		if (membershipData && membershipData.collection_contract_address !== null) {
			setFetchingCollectionData(true);
			alchemy.nft
				.getContractMetadata(membershipData.collection_contract_address)
				.then((response) => {
					if (response) {
						setCollectionData(response);
						setFetchingCollectionData(false);
					}
				});
		}
	}, []);

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + space_username;
		window.location.replace(url);
		// navigate(url);
	}, [space_username]);

	const checkStatus = useCallback(async (space_id, membership_id) => {
		if (!membership_id) return;
		const data = {
			space_id: space_id,
			membership_id: membership_id
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const response = await checkMembershipItemStatus(data);
		if (response && response.result) {
			const transaction = response.stripe_transaction;
			if (transaction) {
				setStatus(transaction.status);
				setIsDisabled(transaction.status === 'active' || transaction.status === 'expired');
			}
		}
	},[authUserInfo]);

	useEffect(() => {
		if (space_username && !isMembershipLoaded) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					getMembership(spaceInfo.id, membershipId).then((r) => {});
					setSpaceName(spaceInfo.name);
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(space_username));
				}
				if (spaceInfoLoading === 'failed') {
					navigate(routes.createSpace());
				}
			}
		}

		// if (authUserInfo) {
		// 	getMembershipClaim().then();
		// }

		if (membership != null) {
			if (authUserInfo != null && !loadMembershipOnce) {
				setLoadMembershipOnce(true);
				getMembership(spaceInfo.id, membershipId).then((r) => {});
				checkStatus(spaceInfo.id, membershipId).then(r =>{} );
			}
			if (authUserInfo != null && !isDisabled) {
				if (membership.creator === authUserInfo.id) {
					setIsDisabled(true);
				}
			}
		}

		// console.log('hasGradient: '+ hasGradient);

	}, [
		currentLocation.pathname,
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		membershipId,
		membership,
		isMembershipLoaded,
		loadMembershipOnce,
		isClaimed,
		status,
		isDisabled
	]);

	return (
		<>
			{fetchingCollectionData ? (
				<div className="loading-items w-full">
					<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
				</div>
			) : (
				<section id="cs-space-product-membership-c1" className="p-section-safeview theme">
					<div className="centerframe-grid gap-2gutter">
						<div id="cs-product-cover-media" className="block-lg rounded-card">
							<a>
								{membership && membership.video ? (
									<ReactPlayer
										playing={true}
										loop={true}
										muted={true}
										controls={false}
										width="100%"
										height="592px"
										className={`videoPreview max-h-element`}
										url={membership.video}
									/>
								) : (
									<img className="max-h-element rounded-card" src={membership?.photo ?? ''}/>
								)}
							</a>
						</div>
						{!collectionData ? (
							<div id="cs-product-info" className="block-lg">
								<hgroup className="stack-col">
									{membership && membership.contract_address ? (
										<h2>Collection not found.</h2>
									) : (
										<h2>No collection contract address set..</h2>
									)}
								</hgroup>
							</div>
						) : (
							<div id="cs-product-info" className="block-lg">
								<hgroup className="stack-col">
									<h2>{membership ? membership.name : ''}</h2>
									<p className="cs-product-info-summary">{membership ? membership.summary : ''}</p>
									<p className="cs-product-info-listing">
										{membership
											? membership.listing_text
											: ''}</p>
								</hgroup>
								<div className="cs-product-pricing-details stack-row">
									{membership && membership.type === MEMBERSHIP_TYPES.NFT_COLLECTION ? (
										<div className="stack-col-base">
											<h2>Claimable</h2>
											<p className="cs-product-renewal-summary-cost">No Expiry</p>
											<p>NFT Required</p>
										</div>
									) : (
										<div className="stack-col-base">
											<h2>Free</h2>
											<p>Invite Only</p>
										</div>
									)}
									<div className="stack-col-base">
										<h4><i className="fa-regular fa-square-user"></i>Membership</h4>
										<h4><i className="fa-regular fa-infinity"></i>Lifetime</h4>
										<h4><i className="fa-regular fa-gift"></i>{membership.benefits_count} Benefit{membership.benefits_count > 1? 's': ''}</h4>
									</div>
								</div>
								<div id="cs-stack-product-actions" className="stack-row">
									{membership && (
										<a href="#purchaseProduct" onClick={claimMembership} className={classNames("button-primary-gradient", submitted || isDisabled ? 'pointer-events-none' : '')}><span><p>{getCommunityBtnText(membership, true, false, status)}</p></span></a>
									)}
									<a href="#purchaseProduct" className="button-secondary-gradient" onClick={scrollToClick}><span><p>Details</p></span></a>
								</div>
							</div>

						)}

					</div>
				</section>
			)}
			<section id="cs-space-product-item-c2" className="p-section-safeview theme-transparent">
                <div className="centerframe-grid gap-2gutter">

                    <div className="block-full">
                        <div className="cs-rte-output space-y-card">
                            <p>{collectionData && collectionData.openSea
										? collectionData.openSea.description
										: ''}</p>
                        </div>
                    </div>
                    <div className="block-full">
                        <div id="cs-product-benefits" className="stack-col">
                            <div className="datarow title">
                                <h3>Benefits</h3>
                                <p>This Membership provides Benefits for {' '}<a href={routes.viewUserSpace() + space_username} target="_blank"><i className="fa-solid fa-solar-system"></i> {spaceName}</a></p>
                            </div>
                            <div className="datarow">
                                <p>Monthly Access</p>
                                <p>The {spaceName} Membership is a lifetime membership. <span>No Expiry</span></p>
                            </div>
                            <div className="datarow">
                                <p>100,000 Community Points</p>
                                <p>Add 100k community points to your account and use them for unlockable benefits and limited items! <span>1 redemption</span></p>
                            </div>
                        </div>

                        <div id="cs-product-traits" className="stack-col">
                            <div className="datarow title">
                                <h3>Traits</h3>
                                <p>This Item has 3 traits</p>
                            </div>
                            <div className="datarow">
                                <p>Hidden Content</p>
                                <p>Viewable by Owner</p>
                            </div>
                            <div className="datarow">
                                <p>Properties</p>
                                <p>Status: VIP</p>
                            </div>
                            <div className="datarow">
                                <p>Boosts</p>
                                <p>+100k Community Points</p>
                            </div>
                        </div>

                        <div id="cs-product-technicals" className="stack-row-wrap">
                            <div className="datarow title">
                                <h3>Additional Details</h3>
                                <p>Space and technical details</p>
                            </div>
                            <div className="datarow">
                                <p>Connected Space</p>
                                <p>{spaceName}</p>
                            </div>
                            <div className="datarow">
                                <p>Space URL</p>
                                <p><a href={url} target="_blank">{url}</a></p>
                            </div>
                            <div className="datarow">
                                <p>Account Limit</p>
                                {membership && (
                                <p>{membership.max_per_account}</p>
                                )}
                            </div>
                            <div className="datarow">
                                <p>Transferable</p>
                                <p>No, By NFT Ownership</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			
		</>
	);
};

export default memo(SpaceClaimMembership);
