import React, {memo, useCallback, useEffect, useState} from "react";
import UploadDraggerV2 from "../UploadDraggerV2";
import {IMAGE_FILETYPES, IMAGE_LARGE_FILE_UPLOAD_SIZE, IMAGE_LARGE_MAX_HEIGHT, IMAGE_LARGE_MAX_WIDTH, IMAGE_LARGE_MIN_HEIGHT, IMAGE_LARGE_MIN_WIDTH, IMAGE_STANDARD_FILE_UPLOAD_SIZE, IMAGE_STANDARD_MAX_HEIGHT, IMAGE_STANDARD_MAX_WIDTH, IMAGE_STANDARD_MIN_HEIGHT, IMAGE_STANDARD_MIN_WIDTH, VIDEO_FILETYPES, VIDEO_STANDARD_FILE_UPLOAD_SIZE, VIDEO_STANDARD_MAX_HEIGHT, VIDEO_STANDARD_MAX_WIDTH, VIDEO_STANDARD_MIN_HEIGHT, VIDEO_STANDARD_MIN_WIDTH} from "../../../constants/file";
import {Form} from "antd";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import {checkUsernameAvailability} from "../../../services/general";
import FormFieldStacked from "../FormFieldStacked";
import {CUSTOM_REGEX} from "../../../constants/custom_regex";
import DropdownWithItems from "../DropdownWithItems";
import {GENDER_LIST_WITH_DEFAULT, PRONOUNS, PRONOUNS_LIST_WITH_DEFAULT, VISIBILITY_LIST_WITH_DEFAULT} from "../../../constants/profile";
import Alert from "react-bootstrap/Alert";
import {showTooltip} from "../../../utils/input";
import {createUpdateMembershipDetails} from "../../../services/space";
import routes from "../../../routes";
import default_profile_card_bg from "../../../assets/images/profile_header.png";
import {getListDisplay} from "../../../utils/list";
import ReactPlayer from "react-player/lazy";
import {updateProfile} from "../../../services/user";
import {getAuthUserInfo} from "../../../states/general/actions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {updateMembershipItemStatus} from "../../../services/payment_method";
import {getLocalStorage, removeLocalStorage} from "../../../utils/storage";


const CreateMemberProfile = (props) => {
    const { isNewAccountFromPurchase = true, membership, authUserInfo, spaceInfo } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [formHasError, setFormHasError] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [alert, setAlert] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [profilePhotoBlobPreview, setProfilePhotoBlobPreview] = useState(null);
    const [profileVideo, setProfileVideo] = useState(null);
    const [profileVideoBlobPreview, setProfileVideoBlobPreview] = useState(null);
    const [profileBackgroundPhoto, setProfileBackgroundPhoto] = useState(null);
    const [profileBackgroundPhotoBlobPreview, setProfileBackgroundPhotoBlobPreview] = useState(null);
    const [profileBackgroundVideo, setProfileBackgroundVideo] = useState(null);
    const [profileBackgroundVideoBlobPreview, setProfileBackgroundVideoBlobPreview] = useState(null);
    const [profileAssetType, setProfileAssetType] = useState(null);
    const [cardBackground, setCardBackground] = useState(null);
    const [cardBackgroundType, setCardBackgroundType] = useState(null);
    const [username, setUsername] = useState(null);
    const [displayName, setDisplayName] = useState(null);
    const [location, setLocation] = useState(null);
    const [bio, setBio] = useState(null);
    const [website, setWebsite] = useState(null);
    const [selectedGender, setSelectedGender] = useState('default');
    const [selectedPronouns, setSelectedPronouns] = useState('default');
    const [selectedVisibility, setSelectedVisibility] = useState('default');
    const [displayDropDownError, setDisplayDropDownError] = useState(false);
    const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
    const [videoIsRemoved, setVideoIsRemoved] = useState(false);
    const [photoBGIsRemoved, setPhotoBGIsRemoved] = useState(false);
    const [videoBGIsRemoved, setVideoBGIsRemoved] = useState(false);
    const [profileImageHasError, setProfileImageHasError] = useState(null);
    const [profileVideoHasError, setProfileVideoHasError] = useState(null);
    const [profileBGImageHasError, setProfileBGImageHasError] = useState(null);
    const [profileBGVideoHasError, setProfileBGVideoHasError] = useState(null);

    const setProfileImageUploadErrors = (error) => {
        setProfileImageHasError(error?.message)
    }
    const setProfileVideoUploadErrors = (error) => {
        setProfileVideoHasError(error?.message)
    }
    const setProfileBGImageUploadErrors = (error) => {
        setProfileBGImageHasError(error?.message)
    }
    const setProfileBGVideoUploadErrors = (error) => {
        setProfileBGVideoHasError(error?.message)
    }

    const handleProfilePhotoUploadFileChange = (file, blobPreview) => {
        if (!file) {
            setPhotoIsRemoved(true);
        } else {
            setPhotoIsRemoved(false);
        }
        setProfilePhoto(file);
        setProfilePhotoBlobPreview(blobPreview);
    };

    const handleProfileVideoUploadFileChange = useCallback(
        async (file, blobPreview) => {
            if (!file) {
                setVideoIsRemoved(true);
            } else {
                setVideoIsRemoved(false);
            }
            if (blobPreview && profileVideoBlobPreview && blobPreview !== profileVideoBlobPreview) {
                setProfileVideo(null);
                setProfileVideoBlobPreview(null);
                setTimeout(() => {
                    setProfileVideo(file);
                    setProfileVideoBlobPreview(blobPreview);
                });
            } else {
                setProfileVideo(file);
                setProfileVideoBlobPreview(blobPreview);
            }
        },
        [profileVideoBlobPreview]
    );

    const handleProfileBackgroundPhotoUploadFileChange = (file, blobPreview) => {
        if (!file) {
            setPhotoBGIsRemoved(true);
        } else {
            setPhotoBGIsRemoved(false);
        }
        setProfileBackgroundPhoto(file);
        setProfileBackgroundPhotoBlobPreview(blobPreview);
    };

    const handleProfileBackgroundVideoUploadFileChange = useCallback(
        async (file, blobPreview) => {
            if (!file) {
                setVideoBGIsRemoved(true);
            } else {
                setVideoBGIsRemoved(false);
            }

            if (
                blobPreview &&
                profileBackgroundVideoBlobPreview &&
                blobPreview !== profileBackgroundVideoBlobPreview
            ) {
                setProfileBackgroundVideo(null);
                setProfileBackgroundVideoBlobPreview(null);

                setTimeout(() => {
                    setProfileBackgroundVideo(file);
                    setProfileBackgroundVideoBlobPreview(blobPreview);
                });
            } else {
                setProfileBackgroundVideo(file);
                setProfileBackgroundVideoBlobPreview(blobPreview);
            }
        },
        [profileBackgroundVideoBlobPreview]
    );

    const handleFormOnBlur = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const websiteOnBlur = (event) => {
        showTooltip(event);
        if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(event.target.value)) {
            event.target.value = 'https://' + event.target.value;
            form.setFieldValue('website', event.target.value);
        }
    };

    const handleFormChanges = useCallback(async () => {
        setUsername(form.getFieldValue('username'));
        setDisplayName(form.getFieldValue('display_name'));
        setLocation(form.getFieldValue('location'));
        setBio(form.getFieldValue('bio'));
        setWebsite(form.getFieldValue('website'));

        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                // console.log(errors)
                setFormHasError(true);
            });
    }, []);

    const handleSubmit = useCallback(async (values) => {
        // console.log(values)
        setSubmitted(true);
        const params = {};
        params.space_id = spaceInfo.id;
        params.user_id = authUserInfo.id;
        params.membership_id = membership.id;
        params.community_bio = values.community_bio;
        params.favorite_food = values.favorite_food;
        params.favorite_city = values.favorite_city;
        params.favorite_spirit = values.favorite_spirit;
        params.food_allergies_restrictions = values.food_allergies_restrictions;
        try {
            const response = await createUpdateMembershipDetails(params);
            if (response && response.result) {
                 /*Remove the redirect_link in the local storage to prevent the Create Member Profile
                 from loading after the submit is completed*/
                if (getLocalStorage('redirect_link')) {
                    removeLocalStorage('redirect_link')
                }
                await handleProfileSubmit(values);
            } else {
                setSubmitted(false);
            }
        } catch (error) {
            setSubmitted(false);
            console.log(error);
        }
        },[
            spaceInfo,
            authUserInfo,
            membership,
            selectedGender,
            selectedPronouns,
            selectedVisibility,
            profilePhoto,
            profileVideo,
            profileBackgroundPhoto,
            profileBackgroundVideo,
            photoIsRemoved,
            videoIsRemoved,
            photoBGIsRemoved,
            videoBGIsRemoved,
            alert
        ]
    )

    const handleProfileSubmit = useCallback(
        async (values) => {
            setDisplayDropDownError(true);

            const data = values;
            // console.log(data)

            //include url protocol when url protocol is missing
            // if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.website)) {
            //     data.website = 'https://' + data.website;
            // }

            if (selectedGender !== 'default') {
                data.gender = selectedGender;
            }
            if (selectedPronouns !== 'default') {
                data.pronouns = selectedPronouns;
            }
            if (selectedVisibility !== 'default') {
                data.visibility = selectedVisibility;
            }

            if (profilePhoto) {
                data.photo = profilePhoto;
            } else {
                if (photoIsRemoved) {
                    data.photo_is_empty = true;
                }
            }

            if (profileVideo) {
                data.video = profileVideo;
            } else {
                if (videoIsRemoved) {
                    data.video_is_empty = true;
                }
            }

            if (profileBackgroundPhoto) {
                data.background_photo = profileBackgroundPhoto;
            } else {
                if (photoBGIsRemoved) {
                    data.background_photo_is_empty = true;
                }
            }

            if (profileBackgroundVideo) {
                data.background_video = profileBackgroundVideo;
            } else {
                if (videoBGIsRemoved) {
                    data.background_video_is_empty = true;
                }
            }

            try {
                if (authUserInfo) {
                    data.user_id = authUserInfo.id;
                    const resultProfile = await updateProfile(data);
                    if (resultProfile) {
                        dispatch(getAuthUserInfo());
                        await activateMembership();
                        setSubmitted(false);
                        setAlert({
                            variant: 'success',
                            message: resultProfile.message.toString()
                        });
                        setTimeout(() => {
                            setAlert(null);
                        }, 5000);
                    } else {
                        setSubmitted(false);
                        setAlert({
                            variant: 'danger',
                            message: 'Failed to update profile'
                        });
                        setTimeout(() => {
                            setAlert(null);
                        }, 5000);
                    }
                }
            } catch (error) {
                setSubmitted(false);
                console.log(error);
                setAlert({
                    variant: 'danger',
                    message: 'Something went wrong when updating profile'
                });
                setTimeout(() => {
                    setAlert(null);
                }, 5000);
            }
        },
        [
            authUserInfo,
            selectedGender,
            selectedPronouns,
            selectedVisibility,
            profilePhoto,
            profileVideo,
            profileBackgroundPhoto,
            profileBackgroundVideo,
            photoIsRemoved,
            videoIsRemoved,
            photoBGIsRemoved,
            videoBGIsRemoved,
            alert
        ]
    );

    const activateMembership = useCallback(async () => {
        setSubmitted(true);
        if (!membership) {
            setSubmitted(false);
            return;
        }
        const data = {
            space_id: spaceInfo.id,
            membership_id: membership.id,
            status: 'active'
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const createStripeResponse = await updateMembershipItemStatus(data);
        if (createStripeResponse) {
            if (createStripeResponse && createStripeResponse.result) {
                const url = routes.spaceMembershipBuy();
                const fin_url = url.replace(':space_username', '@' + spaceInfo.username) + '?id=' + membership.id;
                navigate(fin_url);
            }
            setSubmitted(false);
        } else {
            setSubmitted(false);
        }
    },[authUserInfo, membership, spaceInfo]);


    const updateProfilePhotoAsset = async (photo, video, bg_photo, bg_video) => {
        if (!video && photo) {
            setProfileAssetType('image');
            setProfilePhotoBlobPreview(photo);
        }
        if (video) {
            setProfileAssetType('video');
            setProfileVideoBlobPreview(video);
        }
        if (!video && !photo) {
            setProfileAssetType('image');
            setProfilePhotoBlobPreview(null);
            setProfileVideoBlobPreview(null);
        }
        if (!bg_video && bg_photo) {
            setProfileBackgroundPhotoBlobPreview(bg_photo);
            const space_bg = {
                backgroundImage: 'url(' + bg_photo + ')'
            };
            setCardBackground(space_bg);
            setCardBackgroundType('image');
        }
        if (bg_video) {
            setProfileBackgroundVideoBlobPreview(bg_video);
            setCardBackgroundType('video');
        }
        if (!bg_video && !bg_photo) {
            setCardBackgroundType('image');
            setCardBackground({
                backgroundImage: 'url(' + default_profile_card_bg + ')'
            });
            setProfileBackgroundVideoBlobPreview(null);
        }
    };


    useEffect(() => {
        updateProfilePhotoAsset(
            profilePhotoBlobPreview,
            profileVideoBlobPreview,
            profileBackgroundPhotoBlobPreview,
            profileBackgroundVideoBlobPreview
        );
    }, [
        authUserInfo,
        profilePhotoBlobPreview,
        profileVideoBlobPreview,
        profileBackgroundPhotoBlobPreview,
        profileBackgroundVideoBlobPreview
    ]);


    return (
        <>
            <section id="cs-product-membership-activate-c1" className="p-section-safeview theme-transparent">
                <div className="centerframe-grid gap-2gutter">
                    {/*
						fam-space-product-membership-activate-new
						Note: Add the flagging here to detect the newly activated membership
					*/}
                    <div id="cs-product-activate-main" className="block-lg order-1">
                        <hgroup className="heading">
                            {/*CSJS "Membership" or "Item" based on the product type */}
                            <h1>Welcome to {membership?.name ?? ''}!</h1>
                            {/* CSJS "Membership" or "Item" based on the product type */}
                            <p className="text-p2">Complete your Member Details below to activate your membership.</p>
                        </hgroup>

                        <Form
                            form={form}
                            name="memberProfileForm"
                            validateMessages={VALIDATE_MESSAGES}
                            onFocus={() => {
                                setFormIsOnFocus(true);
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    setFormIsOnFocus(false);
                                    handleFormOnBlur().then(r => {
                                    });
                                }, 100);
                            }}
                            onChange={handleFormChanges}
                            onFinish={handleSubmit}
                            className={'stack-col'}
                        >
                            <div className={'cs-stack-space-create-cs-profile stack-col'}>
                                <hgroup className={`${isNewAccountFromPurchase ? "title" : "heading"}`}>
                                    <h3>Create your Profile</h3>
                                    <p className="text-p2">Your profile will be visible to friends, community members, and the public, based on your preferences. Images and videos are optional.</p>
                                </hgroup>

                                <div className="cs-stack-form-media stack-row">
                                    <div className="cs-stack-form-media-item">
                                        <div className="form-input-media circle md air">
                                            <UploadDraggerV2
                                                cropperAspectRatio={1 / 1}
                                                shape="circle"
                                                hideDragIcon="true"
                                                onError={setProfileImageUploadErrors}
                                                hideText="true"
                                                uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                uploadedFilePreview={profilePhotoBlobPreview}
                                                onUploadFileChange={handleProfilePhotoUploadFileChange}
                                                minHeight={IMAGE_STANDARD_MIN_HEIGHT}
                                                minWidth={IMAGE_STANDARD_MIN_WIDTH}
                                                maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
                                                maxWidth={IMAGE_STANDARD_MAX_WIDTH}
                                                uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
                                                disableCropper={true}
                                            />
                                        </div>
                                        <p className="cs-label">Profile Image</p>
                                        <p className="cs-helper">Up to 3840px square or wide JPEG, PNG, or GIF</p>
                                        {profileImageHasError && (
                                            <div className="alert callout error">
                                                <div className="action">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <p>{profileImageHasError}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="cs-stack-form-media-item">
                                        <div className="form-input-media circle md air">
                                            <UploadDraggerV2
                                                shape="circle"
                                                hideDragIcon="true"
                                                hideText="true"
                                                onError={setProfileVideoUploadErrors}
                                                uploadFileTypes={VIDEO_FILETYPES.toString()}
                                                uploadedFilePreview={profileVideoBlobPreview}
                                                onUploadFileChange={handleProfileVideoUploadFileChange}
                                                minHeight={VIDEO_STANDARD_MIN_HEIGHT}
                                                minWidth={VIDEO_STANDARD_MIN_WIDTH}
                                                maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
                                                maxWidth={VIDEO_STANDARD_MAX_WIDTH}
                                                uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
                                            />
                                        </div>
                                        <p className="cs-label">Profile Video</p>
                                        <p className="cs-helper">Up to 1920px/1080p square preferred MOV or MP4</p>
                                        {profileVideoHasError && (
                                            <div className="alert callout error">
                                                <div className="action">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <p>{profileVideoHasError}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="cs-stack-form-media stack-row">
                                    <div className="cs-stack-form-media-item">
                                        <div className="form-input-media md air aspect-cs-preview">
                                            <UploadDraggerV2
                                                cropperAspectRatio={16 / 9}
                                                hideDragIcon="true"
                                                hideText="true"
                                                onError={setProfileBGImageUploadErrors}
                                                uploadFileTypes={IMAGE_FILETYPES.toString()}
                                                uploadedFilePreview={profileBackgroundPhotoBlobPreview}
                                                onUploadFileChange={
                                                    handleProfileBackgroundPhotoUploadFileChange
                                                }
                                                minHeight={IMAGE_LARGE_MIN_HEIGHT}
                                                minWidth={IMAGE_LARGE_MIN_WIDTH}
                                                maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                                maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                                uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
                                                disableCropper={true}
                                                shape="video"
                                            />
                                        </div>
                                        <p className="cs-label">Background Image</p>
                                        <p className="cs-helper">Up to 3840px square or wide JPEG, PNG, or GIF</p>
                                        {profileBGImageHasError && (
                                            <div className="alert callout error">
                                                <div className="action">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <p>{profileBGImageHasError}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="cs-stack-form-media-item">
                                        <div className="form-input-media md air aspect-cs-preview">
                                            <UploadDraggerV2
                                                hideDragIcon="true"
                                                hideText="true"
                                                onError={setProfileBGVideoUploadErrors}
                                                uploadFileTypes={VIDEO_FILETYPES.toString()}
                                                uploadedFilePreview={profileBackgroundVideoBlobPreview}
                                                onUploadFileChange={
                                                    handleProfileBackgroundVideoUploadFileChange
                                                }
                                                minHeight={VIDEO_STANDARD_MIN_HEIGHT}
                                                minWidth={VIDEO_STANDARD_MIN_WIDTH}
                                                maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
                                                maxWidth={VIDEO_STANDARD_MAX_WIDTH}
                                                uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
                                                shape="video"
                                            />
                                        </div>
                                        <p className="cs-label">Background Video</p>
                                        <p className="cs-helper">Up to 1920px/1080p square or wide MOV or MP4</p>
                                        {profileBGVideoHasError && (
                                            <div className="alert callout error">
                                                <div className="action">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <p>{profileBGVideoHasError}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12 md:gap-8">
                                    <div className="col-span-12">
                                        <div className="mb-8">
                                            <div className="relative rounded-none ">
                                                <Form.Item
                                                    label="Username"
                                                    name="username"
                                                    className="mb-0 hidden-label"
                                                    validateTrigger="onBlur"
                                                    rules={[
                                                        {required: true},
                                                        {
                                                            min: 2,
                                                            message: 'Must be minimum 2 characters.'
                                                        },
                                                        {
                                                            max: 32,
                                                            message: 'Must not exceed 32 characters.'
                                                        },
                                                        ({}) => ({
                                                            async validator(_, value) {
                                                                // TODO: find way to trigger username API check on form submit to avoid flood requests
                                                                if (value) {
                                                                    const data = {
                                                                        current_user_id: authUserInfo.id,
                                                                        username: username
                                                                    };
                                                                    let available = false;
                                                                    const response = await checkUsernameAvailability(data);
                                                                    if (response && response.result) {
                                                                        available =
                                                                            response.availability;
                                                                    }
                                                                    if (!available) {
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                'Username not available.'
                                                                            )
                                                                        );
                                                                    }
                                                                }
                                                                if (value.match(/\ /g) || /[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(value)) {
                                                                    return Promise.reject(
                                                                        new Error('Invalid Input')
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            }
                                                        })
                                                    ]}
                                                >
                                                    <div className="form-input air">
                                                        <label htmlFor="username">Username</label>
                                                        <FormFieldStacked
                                                            formType='input'
                                                            type="text"
                                                            maxLength={32}
                                                            disabled={submitted}
                                                            // onChange={console.log(form.getFieldsError()[0])}
                                                            placeholder="2 to 32 characters. Letters, numbers, periods, and underscores only."
                                                        />
                                                        {/*<input id="email" name="email" type="email" autoComplete="email" required placeholder="Input Air"/>*/}
                                                    </div>
                                                </Form.Item>
                                                {form.getFieldsError()[0]?.errors.length > 0 && (
                                                    <div className="alert callout warning">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{form.getFieldsError()[0]?.errors}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        <div className="mb-8">
                                            <div className="relative  rounded-none ">
                                                <Form.Item
                                                    label="Display Name"
                                                    name="display_name"
                                                    className="mb-0 hidden-label"
                                                    validateTrigger="onBlur"
                                                    rules={[
                                                        {required: true},
                                                        {
                                                            max: 32,
                                                            message: 'Must not exceed 32 characters.'
                                                        }
                                                    ]}
                                                >
                                                    <div className="form-input air">
                                                        <label htmlFor="displayname">Display Name</label>
                                                        <FormFieldStacked
                                                            formType='input'
                                                            type="text"
                                                            maxLength={32}
                                                            disabled={submitted}
                                                            placeholder="Up to 32 characters."
                                                        />
                                                        {/*<input id="email" name="email" type="email" autoComplete="email" required placeholder="Input Air"/>*/}
                                                    </div>
                                                </Form.Item>
                                                {form.getFieldsError()[1]?.errors.length > 0 && (
                                                    <div className="alert callout warning">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{form.getFieldsError()[1]?.errors}</h6>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mb-8">
                                            <div className="relative  rounded-none ">
                                                <Form.Item
                                                    label="Location"
                                                    name="location"
                                                    className="mb-0 hidden-label"
                                                    validateTrigger="onBlur"
                                                    rules={[
                                                        // { required: true },
                                                        {
                                                            max: 32,
                                                            message: 'Must not exceed 32 characters.'
                                                        }
                                                    ]}
                                                >
                                                    <div className="form-input air">
                                                        <label htmlFor="location">Location</label>
                                                        <FormFieldStacked
                                                            formType='input'
                                                            type="text"
                                                            maxLength={32}
                                                            disabled={submitted}
                                                            placeholder="Up to 32 characters. This displays on your Profile and is separate from your private City location."
                                                        />
                                                        {/*<input id="email" name="email" type="email" autoComplete="email" required placeholder="Input Air"/>*/}
                                                    </div>
                                                </Form.Item>
                                                {form.getFieldsError()[2]?.errors.length > 0 && (
                                                    <div className="alert callout warning">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{form.getFieldsError()[2]?.errors}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mb-8">
                                            <div className="relative  rounded-none ">
                                                <Form.Item
                                                    name="bio"
                                                    className="mb-0 hidden-label"
                                                    validateTrigger="onBlur"
                                                    rules={[
                                                        // { required: true },
                                                        {
                                                            max: 255,
                                                            message: 'Must not exceed 255 characters.'
                                                        }
                                                    ]}
                                                >
                                                    <div className="form-textarea air">
                                                        <label htmlFor="shortbio">Short Bio</label>
                                                        <FormFieldStacked
                                                            formType='textarea'
                                                            type="text"
                                                            maxLength={255}
                                                            disabled={submitted}
                                                            rows="3"
                                                            placeholder="Up to 255 characters."
                                                        />
                                                        {/*<input id="email" name="email" type="email" autoComplete="email" required placeholder="Input Air"/>*/}
                                                    </div>
                                                </Form.Item>
                                                {form.getFieldsError()[3]?.errors.length > 0 && (
                                                    <div className="alert callout warning">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{form.getFieldsError()[3]?.errors}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {!isNewAccountFromPurchase &&
                                            <div className="mb-8">
                                                <div className="relative rounded-none ">
                                                    <Form.Item
                                                        label="Website"
                                                        name="website"
                                                        className="mb-0 hidden-label"
                                                        onBlur={websiteOnBlur}
                                                        validateTrigger="onBlur"
                                                        rules={[
                                                            // { required: true },
                                                            {
                                                                // type: 'url',
                                                                pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
                                                                message: 'Please Enter a valid URL'
                                                            }
                                                        ]}
                                                    >
                                                        <div className="form-input air">
                                                            <label htmlFor="website">Website</label>
                                                            <FormFieldStacked
                                                                formType='input'
                                                                type="text"
                                                                disabled={submitted}
                                                                placeholder="www.domain.com"
                                                            />
                                                            {/*<input id="email" name="email" type="email" autoComplete="email" required placeholder="Input Air"/>*/}
                                                        </div>
                                                    </Form.Item>
                                                    {form.getFieldsError()[4]?.errors.length > 0 && (
                                                        <div className="alert callout warning">
                                                            <div className="action">
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                            </div>
                                                            <div className="content">
                                                                <div>
                                                                    <h6>{form.getFieldsError()[4]?.errors}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        }

                                        <div className="cs-stack-form-link-icons mb-8">
                                            <p>Links</p>
                                            <div className="cs-stack-link-icons stack-row-wrap">
                                                <a href="https://x.com" target="_blank" className="button-icon-round-gradient-alt active"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>
                                                <a href="https://instagram.com" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>
                                                <a href="https://facebook.com" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>
                                                <a href="https://tiktok.com" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-tiktok"></i></p></span></a>
                                                <a href="https://linkedin.com" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-linkedin-in"></i></p></span></a>
                                                <a href="https://twitch.tv" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-brands fa-twitch"></i></p></span></a>
                                                <a href="" target="_blank" className="button-icon-round-gradient-alt"><span><p><i className="fa-regular fa-globe"></i></p></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cs-stack-form-buttons">
                                    <div className="col-span-6 xl:col-span-4 mb-8">
                                        <DropdownWithItems
                                            title="Gender"
                                            customButtonClass="icon-dropdown-gradient-alt secondary"
                                            icon="fa-light fa-person-half-dress"
                                            selectedValue={selectedGender}
                                            listItems={GENDER_LIST_WITH_DEFAULT}
                                            onDropdownChange={setSelectedGender}
                                        />
                                    </div>
                                    <div className="col-span-6 xl:col-span-4 mb-8">
                                        <DropdownWithItems
                                            title="Pronouns"
                                            customButtonClass="icon-dropdown-gradient-alt secondary"
                                            icon="fa-light fa-user"
                                            selectedValue={selectedPronouns}
                                            listItems={PRONOUNS_LIST_WITH_DEFAULT}
                                            onDropdownChange={setSelectedPronouns}
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-4 mb-8">
                                        <DropdownWithItems
                                            title="Visibility"
                                            customButtonClass="icon-dropdown-gradient-alt secondary"
                                            displayError={
                                                displayDropDownError &&
                                                selectedVisibility === 'default'
                                            }
                                            icon="fa-light fa-eye"
                                            selectedValue={selectedVisibility}
                                            listItems={VISIBILITY_LIST_WITH_DEFAULT}
                                            onDropdownChange={setSelectedVisibility}
                                        />
                                    </div>
                                </div>
                                <>
                                    {alert && (
                                        <div className="grid grid-cols-12 md:gap-8 my-2 mb-8">
                                            <div className="col-span-12">
                                                <Alert
                                                    key={alert.variant}
                                                    variant={alert.variant}
                                                    className={
                                                        'custom-alert ' +
                                                        (alert.variant === 'danger'
                                                            ? 'custom-alert-danger'
                                                            : 'custom-alert-success')
                                                    }
                                                >
                                                    {alert.message}
                                                </Alert>
                                            </div>
                                        </div>
                                    )}
                                </>

                                <div className="cs-stack-space-create-community-profile stack-col">
                                    <hgroup className="title">
                                        {/*CSJS "Friends & Mike" is space_name*/}
                                        <h3>{membership?.name ?? ''}</h3>
                                        <p>Share additional details on your Profile, visible to Members only.</p>
                                    </hgroup>

                                    <div className="form-textarea air">
                                        <label htmlFor="community-bio">Community Bio</label>
                                        {/*CSJS Default Community Field (Always Present), Friends & Mike is space_name. Values are filled with Member's existing data if not new.*/}
                                        {/*<textarea id="community-bio" name="community-bio" placeholder="Share details about yourself with Friends & Mike members." rows="3"/>*/}
                                        <Form.Item
                                            name="community_bio"
                                            validateTrigger="onBlur"
                                            className="w-full"
                                            rules={[
                                                // {
                                                // 	max: 32,
                                                // 	message: 'Must not exceed 32 characters.'
                                                // }
                                            ]}
                                        >
                                            <FormFieldStacked
                                                formType='textarea'
                                                name="community_bio"
                                                // id="community-bio"
                                                type="text"
                                                // maxLength={32}
                                                disabled={submitted}
                                                placeholder="Share details about yourself with Friends & Mike members."
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-input air">
                                        <label htmlFor="favorite-food">Favorite Food</label>
                                        {/*<input type="text" name="favorite-food" id="favorite-food" required placeholder="What is your favorite food of all time?"/>*/}
                                        <Form.Item
                                            name="favorite_food"
                                            validateTrigger="onBlur"
                                            className="w-full"
                                            rules={[
                                                // {
                                                // 	max: 32,
                                                // 	message: 'Must not exceed 32 characters.'
                                                // }
                                            ]}
                                        >
                                            <FormFieldStacked
                                                formType='input'
                                                name="favorite_food"
                                                // id="favorite-food"
                                                type="text"
                                                // maxLength={32}
                                                disabled={submitted}
                                                placeholder="What is your favorite food of all time?"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-input air">
                                        <label htmlFor="favorite-city">Favorite City</label>
                                        {/*<input type="text" name="favorite-city" id="favorite-city" required placeholder="What is your favorite city to visit?"/>*/}
                                        <Form.Item
                                            name="favorite_city"
                                            validateTrigger="onBlur"
                                            className="w-full"
                                            rules={[
                                                // {
                                                // 	max: 32,
                                                // 	message: 'Must not exceed 32 characters.'
                                                // }
                                            ]}
                                        >
                                            <FormFieldStacked
                                                formType='input'
                                                name="favorite_city"
                                                // id="favorite-city"
                                                type="text"
                                                // maxLength={32}
                                                disabled={submitted}
                                                placeholder="What is your favorite city to visit?"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-input air">
                                        <label htmlFor="favorite-spirit">Favorite Spirit</label>
                                        {/*<input type="text" name="favorite_spirit" id="favorite-spirit" required placeholder="What is your favorite type of alcoholic spirit? (Optional)"/>*/}
                                        <Form.Item
                                            name="favorite_spirit"
                                            validateTrigger="onBlur"
                                            className="w-full"
                                            rules={[
                                                // {
                                                // 	max: 32,
                                                // 	message: 'Must not exceed 32 characters.'
                                                // }
                                            ]}
                                        >
                                            <FormFieldStacked
                                                formType='input'
                                                name="favorite_spirit"
                                                // id="favorite-spirit"
                                                type="text"
                                                // maxLength={32}
                                                disabled={submitted}
                                                placeholder="What is your favorite type of alcoholic spirit? (Optional)"
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="cs-stack-space-create-community-private-info stack-col">
                                    <hgroup className="title">
                                        {/*CSJS "Friends & Mike" is space_name*/}
                                        <h3>{membership?.name ?? ''} Member Information</h3>
                                        {/*CSJS "Friends & Mike" is space_name*/}
                                        <p>Complete the following member information fields. This information is kept private and only visible to {membership?.name ?? ''} staff.</p>
                                    </hgroup>

                                    <div className="form-input air">
                                        <label htmlFor="food-allergies-restrictions">Food Allergies & Restrictions</label>
                                        {/*<input type="text" name="food-allergies-restrictions" id="food-allergies-restrictions" required placeholder="Let us know if you have any allergies or dietary restrictions."/>*/}
                                        <Form.Item
                                            name="food_allergies_restrictions"
                                            validateTrigger="onBlur"
                                            className="w-full"
                                            rules={[
                                                // {
                                                // 	max: 32,
                                                // 	message: 'Must not exceed 32 characters.'
                                                // }
                                            ]}
                                        >
                                            <FormFieldStacked
                                                formType='input'
                                                name="food_allergies_restrictions"
                                                // id="food-allergies-restrictions"
                                                type="text"
                                                // maxLength={32}
                                                disabled={submitted}
                                                placeholder="Let us know if you have any allergies or dietary restrictions."
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <Form.Item>
                                <div className="stack-buttons col">
                                    {/*CSJS Can use a button instead of an A element by changing element from "a" to "button" and removing "button-" from class.*/}
                                    <button
                                        // disabled={submitted}
                                        type="submit"
                                        className="primary-gradient">
                                        <span><p>Save & Activate</p></span>
                                    </button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>

                    <aside id="cs-profile-create-aside" className="block-lg order-2 hidden lg:block">
                        {/*CSJS BG Image as inline style background.*/}
                        <div className="cs-profile-preview card sticky top-2gutter-xl" style={cardBackground}>
                            {(cardBackgroundType && cardBackgroundType === 'video') && (
                                <ReactPlayer
                                    id="cscxl-create-member-profile"
                                    playing={true}
                                    loop={true}
                                    muted={true}
                                    controls={false}
                                    height="100%"
                                    width="100%"
                                    className=""
                                    url={profileBackgroundVideoBlobPreview}
                                    style={
                                        {
                                            position: 'absolute',
                                            // maxWidth: previewDimensions.width,
                                            // maxHeight: previewDimensions.height
                                        } }
                                />
                            )}
                            {/*CSJS Add class "shader" if background-image is applied in parent element cs-profile-preview, i.e when a user has uploaded a background.*/}
                            <div className="cs-stack-profile-preview rounded-card stack-col shader">
                                {/*CSJS Image as inline style background.*/}
                                <div
                                    className="form-input-media lg circle air nohover"
                                    style={{
                                        backgroundImage: 'url(' + profilePhotoBlobPreview + ')'
                                    }}
                                >
                                    {(profileAssetType && profileAssetType === 'video') &&
                                        <a>
                                            <ReactPlayer
                                                width="100%"
                                                height="100%"
                                                playing={true}
                                                loop={true}
                                                muted={true}
                                                className={``}
                                                controls={false}
                                                url={profileVideoBlobPreview}
                                                style={
                                                    {
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '9999px'
                                                    } }
                                            />
                                        </a>
                                    }
                                </div>
                                <hgroup>
                                    {/*CSJS Add "active" class when content present.*/}
                                    <p className="text-p1 active">@{username ? username : 'username'}</p>
                                    <h1>{displayName ? displayName : 'Display Name'}</h1>
                                    <p className="text-p2 italic">
                                        {selectedPronouns ? getListDisplay(PRONOUNS, selectedPronouns) : 'Pronouns'}
                                    </p>
                                </hgroup>
                                <p className="italic">{bio ? bio : 'Short Bio goes here'}</p>
                                <div className="cs-stack-icons stack-row-wrap">
                                    {/*CSJS Only show if exists for User Profile.*/}
                                    <i className="fa-brands fa-x-twitter"/>
                                    {/*CSJS Only show if exists for User Profile.*/}
                                    <i className="fa-brands fa-instagram"/>
                                    {/*CSJS Only show if exists for User Profile.*/}
                                    <i className="fa-brands fa-facebook-f"/>
                                    {/*CSJS Only show if exists for User Profile.*/}
                                    <i className="fa-brands fa-tiktok"/>
                                    {/*CSJS Only show if exists for User Profile.*/}
                                    <i className="fa-brands fa-linkedin-in"/>
                                    {/*CSJS Only show if exists for User Profile.*/}
                                    <i className="fa-brands fa-twitch"/>
                                    {/*CSJS Only show if exists for User Profile.*/}
                                    <i className="fa-regular fa-globe"/>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
            </section>
        </>
    );
}

export default memo(CreateMemberProfile);