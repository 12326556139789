export const CONTENT_TYPES = [
	{
		label: 'Does not contain Explicit Content',
		value: 'has_no_explicit'
	},
	{
		label: 'Does contain Explicit Content',
		value: 'has_explicit'
	}
];

export const ACCESS_TYPES = [
	{
		label: 'Does provide Explicit Access',
		value: 'has_explicit'
	},
	{
		label: 'Does not provide Explicit Access',
		value: 'has_no_explicit'
	}
];

export const LINKS_VISIBILITY = [
	{
		label: 'Show Links Section',
		value: 'show',
		icon: 'fa-light fa-earth-americas'
	},
	{
		label: 'Hide Links Section',
		value: 'hide',
		icon: 'fa-regular fa-lock'
	}
];

export const UPDATES_VISIBILITY = [
	{
		label: 'Public',
		value: 'public'
	},
	{
		label: 'Members Only',
		value: 'members'
	},
	{
		label: 'Custom',
		value: 'custom'
	}
];

export const UPDATE_AUTHORS = [
	{
		label: 'Space',
		value: 'space'
	},
	{
		label: 'Myself',
		value: 'me'
	}
];

export const UPDATE_COMMENTS_AVAILABILITY = [
	{
		label: 'Disable Comments',
		value: 'disabled'
	},
	{
		label: 'Enable Comments',
		value: 'enabled'
	}
];

export const UPDATE_PUBLISH_STATUSES = [
	{
		label: 'Publish Immediately',
		value: 'published'
	},
	{
		label: 'Schedule Publishing',
		value: 'publishing',
		disabled: true
	},
	{
		label: 'Unpublished Draft',
		value: 'draft'
	}
];

export const PUBLISH_STATUSES = [
	{
		label: 'Published',
		value: 'published'
	},
	{
		label: 'Scheduled',
		value: 'publishing',
		disabled: true
	},
	{
		label: 'Draft',
		value: 'draft'
	}
];

export const CUSTOM_SPACE_LIGHT_SECTION_IMAGE_POSITIONS = [
	{ label: 'Cover', value: 'cover' },
	{ label: 'Top', value: 'top' },
	{ label: 'Center', value: 'center' },
	{ label: 'Bottom', value: 'bottom' }
];

export const CUSTOM_SPACE_LIGHT_SECTION_VIDEO_POSITIONS = [
	{ label: 'Cover', value: 'cover' },
	{ label: 'Top', value: 'top' },
	{ label: 'Center', value: 'center' },
	{ label: 'Bottom', value: 'bottom' }
];

export const CUSTOM_SPACE_DARK_SECTION_IMAGE_POSITIONS = [
	{ label: 'Cover', value: 'cover' },
	{ label: 'Top', value: 'top' },
	{ label: 'Center', value: 'center' },
	{ label: 'Bottom', value: 'bottom' }
];

export const CUSTOM_SPACE_DARK_SECTION_VIDEO_POSITIONS = [
	{ label: 'Cover', value: 'cover' },
	{ label: 'Top', value: 'top' },
	{ label: 'Center', value: 'center' },
	{ label: 'Bottom', value: 'bottom' }
];

export const CUSTOM_SPACE_FOREGROUND_POSITIONS = [
	{ label: 'Cover', value: 'cover' },
	{ label: 'Top', value: 'top' },
	{ label: 'Center', value: 'center' },
	{ label: 'Bottom', value: 'bottom' }
];

export const CURRENT_LINK_OPTIONS = [
	{ label: 'Open link in current window', value: 'open_current_window' },
	{ label: 'Open link in new window', value: 'open_new_window' }
];

export const RENEW_PAYMENT_OPTIONS = [
	{ label: 'One-Time Charge', value: 'one-time' },
	{ label: 'Recurring Charge', value: 'recurring' }
];

export const RENEW_MANAGE_PAYMENT_OPTIONS = [
	{ label: 'One-Time Charge', value: 'one-time' },
	{ label: 'Enable Auto-Renew', value: 'recurring' }
];

export const ONE_TIME_RENEW_PAYMENT_OPTIONS = [
	{ label: 'One-Time Charge', value: 'one-time' },
	{ label: 'Enable Auto-Renew', value: 'recurring' }
];

export const RENEW_PAYMENT_TYPE_OPTIONS = {
	ONE_TIME: 'one-time',
	RECURRING: 'recurring'
};

export const MEMBERSHIP_TYPES = {
	COMMUNITY: 'community',
	NFT_COLLECTION: 'nft_collection'
};

export const LINKS_VISIBILITY_LIST_WITH_DEFAULT = LINKS_VISIBILITY;
if (!LINKS_VISIBILITY_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	LINKS_VISIBILITY_LIST_WITH_DEFAULT.unshift({
		label: 'Select Links Section Visibility',
		value: 'default',
		disabled: true
	});
}

export const CONTENT_TYPES_LIST_WITH_DEFAULT = CONTENT_TYPES;
if (!CONTENT_TYPES_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	CONTENT_TYPES_LIST_WITH_DEFAULT.unshift({
		label: 'Select Content Type',
		value: 'default',
		disabled: true
	});
}

export const ACCESS_TYPES_LIST_WITH_DEFAULT = ACCESS_TYPES;
if (!ACCESS_TYPES_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	ACCESS_TYPES_LIST_WITH_DEFAULT.unshift({
		label: 'Select Access Type',
		value: 'default',
		disabled: true
	});
}

export const UPDATE_AUTHORS_LIST_WITH_DEFAULT = UPDATE_AUTHORS;
if (!UPDATE_AUTHORS_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	UPDATE_AUTHORS_LIST_WITH_DEFAULT.unshift({
		label: 'Select Author',
		value: 'default',
		disabled: true
	});
}

export const MEMBERSHIP_DATA_SHARING = [
	{ label: 'Share', value: 'share' },
	{ label: 'Do not share', value: 'dont_share' }
];

export const SELECT_ADD_NEW_CARD = [
	{ label: 'Select', value: false },
	{ label: 'Add New', value: true }
];

export const SELECT_PAYMENT_METHOD = [
	{ label: 'Select', value: 'default', disabled: true },
	{ label: 'Credit Card', value: 'card' }
];

export const SELECT_COUNTRY = [{ label: 'United States', value: 'us' }];

export const SELECT_STATE = [{ label: 'Select State', value: 'default', disabled: true }];

export const SELECT_MONTHLY_PAYMENT = [{ label: '$5 USD for 1 MONTH', value: '5' }];

export const SELECT_SHARE = [{ label: 'Share', value: true }];

// in days number
export const LENGTH_OF_BLOCK = [
	{ label: 'Select Length of Ban*', value: 'default', disabled: true },
	{ label: '1 Day', value: 1 },
	{ label: '1 Week', value: 7 },
	{ label: '1 Month', value: 30 },
	{ label: '3 Months', value: 90 }
];

export const MANAGE_MEMBERS_FILTER = [
	{ label: 'Filter', value: 'default', disabled: true },
	{
		label: 'Membership',
		value: [
			{ label: 'Free', value: 'free' },
			{ label: 'Paid', value: 'paid' },
			{ label: 'Recurring', value: 'recurring' }
		],
		notClickable: true
	},
	{
		label: 'Account',
		value: [
			{ label: '< 30 days', value: '<30' },
			{ label: '> 30 days', value: '>30' },
			{ label: '1 Space', value: '1space' },
			{ label: '2+ Spaces', value: '2plusSpace' }
		]
	},
	{
		label: 'Activity',
		value: [
			{ label: 'Last Day', value: 'lastday' },
			{ label: 'Last 7 Days', value: 'last7days' },
			{ label: 'Last 30 Days', value: 'last30days' },
			{ label: 'Last 90 Days', value: 'last90days' },
			{ label: 'Last 180 Days', value: 'last180days' }
		]
	}
];

export const MANAGE_MEMBERS_SORT = [
	{ label: 'Sort', value: 'default', disabled: true },
	{ label: 'Join Date', value: 'join_date' },
	{ label: 'Follower Count', value: 'follower_count' },
	{ label: 'Following Count', value: 'following_count' },
	{ label: 'Memberships', value: 'memberships' },
	{ label: 'Items', value: 'items' },
	{ label: 'Total Spend', value: 'total_spend' },
	{ label: 'Last Active', value: 'last_active' }
];

export const ADD_CREATE_BENEFIT = [
	{ label: 'Add or Create Benefit', value: 'default', disabled: true },
	{ label: 'Add Benefit', value: 'add_benefit' },
	{ label: 'Create Benefit', value: 'create_benefit' }
];

export const UPDATES_FILTER = [
	{ label: 'Filter', value: 'default', disabled: true },
	{
		label: 'By Category',
		value: [{ label: 'List of Categories', value: 'category' }]
	},
	{
		label: 'By Status',
		value: [{ label: 'List of Status', value: 'status' }]
	},
	{
		label: 'By Visibility',
		value: [{ label: 'List of Types', value: 'type' }]
	},
	{
		label: 'By Author',
		value: [{ label: 'List of Authors', value: 'author' }]
	}
];

export const UPDATES_SORT = [
	{ label: 'Sort', value: 'default', disabled: true },
	{ label: 'By Date', value: 'date' },
	{ label: 'By Status', value: 'status' }
];

export const MEMBERSHIP_FILTER = [
	{ label: 'Filter', value: 'default', disabled: true },
	{
		label: 'By Price',
		value: [
			{ label: 'Free', value: 'free' },
			{ label: 'Paid', value: 'paid' },
			{ label: 'Subscription', value: 'subscription' }
		]
	},
	{
		label: 'By Visibility',
		value: [{ label: 'List of Types', value: 'list_type' }]
	}
];

export const MEMBERSHIP_SORT = [
	{ label: 'Sort', value: 'default', disabled: true },
	{ label: 'By Date', value: 'date' },
	{ label: 'By Price', value: 'price' },
	{ label: 'By Benefits', value: 'benefits' },
	{ label: 'By Sold', value: 'sold' }
];

export const SPACE_VISIBILITY = [
	{ label: 'Public', value: 'public' },
	{ label: 'Password Protected', value: 'password_protected' },
	{ label: 'Invite Only', value: 'invite_only' },
	{ label: 'Offline/Maintenance', value: 'offline_maintenance' }
];

export const SPACE_SIGNUP_METHOD = [
	{ label: 'All Sign-On Methods', value: 'all_sign' },
	{ label: 'Email', value: 'email' },
	{ label: 'Wallet', value: 'wallet' },
	{ label: 'SSO', value: 'sso' }
];

export const SPACE_PRIMARY_LANG = [
	{ label: 'English', value: 'en' },
	{ label: 'Japanese', value: 'jp' }
];

export const SPACE_PRIMARY_CURRENCY = [
	{ label: 'USD', value: 'usd' },
	{ label: 'JPY', value: 'jpy' },
	{ label: 'CAD', value: 'cad' },
	{ label: 'EUR', value: 'EUR' }
];

export const SPACE_BUSINESS_INDUSTRY = [
	{ label: 'Select an Industry', value: 'default', disabled: true },
	{ label: 'Telecommunication', value: 'telecom' },
	{ label: 'Information Technology', value: 'info_tech' },
	{ label: 'Food Industry', value: 'food_industry' },
	{ label: 'Automobile Manufacturing', value: 'automobile' },
	{ label: 'Oil and Gas', value: 'oil_gas' },
	{ label: 'Life and Health Insurance', value: 'life_health_insurance' },
	{ label: 'E-commerce', value: 'e_commerce' },
	{ label: 'Real Estate', value: 'real_estate' },
	{ label: 'Construction', value: 'construction' },
	{ label: 'Financial Services', value: 'Financial Services' }
];

export const SPACE_BUSINESS_TYPE = [
	{ label: 'Select a type of organization from the list', value: 'default', disabled: true },
	{ label: 'Sole Proprietorship', value: 'sole_proprietorship' },
	{ label: 'Partnership', value: 'partnership' },
	{ label: 'C-Corporation', value: 'c_corp' },
	{ label: 'S-Corporation', value: 's_corp' }
];

export const SPACE_BUSINESS_SIZE = [
	{ label: 'Select a range from the list', value: 'default', disabled: true },
	{ label: '0-10', value: '0_10' },
	{ label: '10-50', value: '10_50' },
	{ label: '50-100,', value: '50_100' },
	{ label: '100+,', value: '100_+' }
];

export const SPACE_PAYOUT_METHOD = [
	{ label: 'Select a Payout Method to Add', value: 'default', disabled: true },
	{ label: 'Bank Account Payouts via Stripe', value: 'stripe' },
];

export const SPACE_CHANNEL_VISIBILITY = [
	{ label: 'Visibility', value: 'default', disabled: true },
	{ label: 'Visible', value: 'visible' },
	{ label: 'Hidden', value: 'hidden' },
];

export const SPACE_CHANNEL_ACCESS = [
	{ label: 'Access', value: 'default', disabled: true },
	{ label: 'All Members', value: 'all_members' },
	{ label: 'Custom', value: 'custom' },
];

export const SPACE_CHANNEL_POSTING = [
	{ label: 'Posting', value: 'default', disabled: true },
	{ label: 'Allow', value: 'allow' },
	{ label: 'Disallow', value: 'disallow' },
];

export const SPACE_CHANNEL_COMMENTING = [
	{ label: 'Commenting', value: 'default', disabled: true },
	{ label: 'Allow', value: 'allow' },
	{ label: 'Disallow', value: 'disallow' },
];

export const SPACE_CHANNEL_STATUS = [
	{
		label: 'Disable Channels',
		value: 'disabled'
	},
	{
		label: 'Enable Channels',
		value: 'enabled'
	}
];

export const CUSTOM_SPACE_THEME = [
	{ label: 'Main Theme', value: 'main' },
	{ label: 'Alt Theme', value: 'alt' }
];

export const CUSTOM_SPACE_BACKGROUND_DIRECTION = [
	{ label: 'To Bottom', value: 'bottom', icon: 'fa-solid fa-arrow-down'},
	{ label: 'To Top Right', value: 'top-right', icon: 'fa-solid fa-arrow-up-right' },
	{ label: 'To Bottom Right', value: 'bottom-right', icon:'fa-solid fa-arrow-down-right' }
];

export const CUSTOM_SPACE_FONT_WEIGHT = [
	{ label: 'Bold', value: 'bold' },
	{ label: 'Normal', value: 'normal' }
];

export const CUSTOM_BANNER_VISIBILITY = [
    { label: 'Hide Banner', value: 'hide' },
	{ label: 'Show Banner', value: 'show' }
	
];

export const CUSTOM_THEME_TYPE = [
    { label: 'Single Theme', value: 'single' },
	{ label: 'Dual Theme', value: 'dual' }
	
];