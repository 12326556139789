import React, {memo} from "react";

const YourMembership = (parentsProps) => {
    const { containerRef, membership, status } = parentsProps;

    return (
        <>
            <div
                className="cs-dash-card block-full card"
                id='your-memberships'
                ref={containerRef}
            >
                <div className="formrow title">
                    <h3>Your Memberships</h3>
                    <p>Manage your Memberships</p>
                </div>
                <div className="cs-stack-controlrows stack-form-col">
                    <div className="formrow">
                        <div className={"data-item cs-stack-avatar-data" + (status !== 'active' ? ' inactive' : '')}>
                            <div className="avatar gradient md cs-avatar-membership">
                                <img src={membership.photo} alt="Image of space_product_name"/>
                            </div>
                            <hgroup>
                                <h4>{membership.name}</h4>
                                <p>{membership.listing_text}</p>
                                <p>{status.charAt(0).toUpperCase() + status.slice(1)}</p>
                            </hgroup>
                        </div>
                        <a href="#" className="button gradient secondary"><span><p>Manage</p></span></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(YourMembership);