import React, {memo, useCallback, useEffect, useState} from "react";
import ReactModal from "react-modal";
import { PlusIcon } from '@heroicons/react/20/solid'
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
    IMAGE_LARGE_MAX_HEIGHT,
    IMAGE_LARGE_MAX_WIDTH,
    IMAGE_STANDARD_MIN_HEIGHT,
    IMAGE_STANDARD_MIN_WIDTH,
    VIDEO_STANDARD_FILE_UPLOAD_SIZE,
    MIXED_FILETYPES, AUDIO_FILETYPES, UPLOAD_FILE_TYPE, IMAGE_FILETYPES, VIDEO_FILETYPES
} from '../../../../constants/file';
import { Button, Form } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import {createSpacePost, updateSpacePost} from '../../../../services/post'
import default_user_profile from "../../../../assets/images/default_user_profile.png";
import {updateSpaceModelComment} from "../../../../services/space";
import {useDispatch, useSelector} from 'react-redux';
import {
    setPost,
    setEditPost,
    setEditComment,
    setSubComment,
    setEditSubComment,
    setSubCommentReply,
    setEditSubCommentReply,
    setSubCommentSubReply,
    setEditSubCommentSubReply
} from '../../../../states/channelSlice';
import TextareaAutosize from "react-textarea-autosize";
import Dragger from "antd/es/upload/Dragger";
import {checkImageValidity, checkVideoValidity, convertUrlToFile, getFileExtension} from "../../../../utils/file";
import ReactPlayer from "react-player/lazy";
import TextArea from "antd/es/input/TextArea";

const ChannelPostForm = (props) => {
    const dispatch = useDispatch();

    const {
        itemElRef,
        dataToEdit, // if not null, The form is for editing
        setDataToEdit,
        isAudioUpload,
        setIsAudioUpload,
        modalOpen,
        setModalOpen,
        spaceChannel,
        spaceData,
        user,
        handleNewData
    } = props;

    const [submitted, setSubmitted] = useState(false);
    const [coverImageBlobPreview, setCoverImageBlobPreview] = useState(null);
    const [fileAttachment, setFileAttachment] = useState(null);
    const [isAttachmentRemoved, setAttachmentRemoved] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [attachmentType, setAttachmentType] = useState(null);
    const [comment, setComment] = useState('');
    const [isAttachmentExist, setIsAttachmentExist] = useState(false);
    const [isBtnPostEnabled, setIsBtnPostEnabled] = useState(false);
    const [fileType, setFileType] = useState(UPLOAD_FILE_TYPE.IMAGE);
    const [imageFiles, setImageFiles] = useState([]);
    const [videoFiles, setVideoFiles] = useState([]);
    const [removeAttachmentFiles, setRemoveAttachmentFiles] = useState([]);
    const [removedAttachmentType, setRemovedAttachmentType] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [alert, setAlert] = useState(null);
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const [editForm] = Form.useForm();

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            minWidth:'50%',
            maxWidth: '80%',
            borderRadius: 32
        },
        overlay: { background: 'rgba(0, 0, 0, 0.6)' }
    };


    const handleSubmit = useCallback(async (values) => {
        setIsSubmitting(true);
        if (dataToEdit) {
            if (spaceChannel.id && dataToEdit.id && dataToEdit.model_id == null ) {
                // Edit Post
                // console.log('Edit Post');
                handleSubmitEditPost(values).then(() => {});
            } else if (spaceChannel.id && dataToEdit.model_id && dataToEdit.id && dataToEdit.reply_to_id == null) {
                // Edit Comment
                // console.log('Edit Comment');
                handleSubmitEditComment(values).then(() => {});
            } else if (spaceChannel.id && dataToEdit.model_id && dataToEdit.id && dataToEdit.reply_to_id) {
                // Edit SubComment
                // console.log('Edit SubComment');
                handleSubmitEditSubComment(values).then(() => {});
            }
        } else {
            // Upload Attachments
            // console.log('Upload Attachments');
            const update = {};
            update.model = 'post';
            update.channel_id = spaceChannel.id;
            update.title = values.comment ? values.comment : '';
            update.content = '';
            const attachments = [];
            const attachment = {};
            if (fileAttachment){
                attachment.id = "null";
                attachments.push(attachment);

                if (attachmentType === UPLOAD_FILE_TYPE.IMAGE) {
                    update.attachments_type = 'image'
                } else if (attachmentType === UPLOAD_FILE_TYPE.VIDEO) {
                    update.attachments_type = 'video'
                } else if (attachmentType === UPLOAD_FILE_TYPE.AUDIO) {
                    update.attachments_type = 'audio'
                }

                update.attachments = JSON.stringify(attachments);
                update.file_0 = fileAttachment
            }

            try {
                if (user) {
                    update.user_id = user.id;
                    update.space_id = spaceData.id;
                    // const response = await createSpacePostUpdate(update);
                    const response = await updateSpacePost(update);
                    // console.log(response)
                    dispatch(setPost(response.post));
                    // handleNewData(response)
                    if (response && response.result) {
                        // setAlert({
                        // 	variant: 'success',
                        // 	message: response.message
                        // });
                        window.scrollTo(0, 0);
                        // setIsVideo(false);
                        setIsSubmitting(false);
                        editForm.resetFields();
                        setCoverImageBlobPreview(null);
                        setFileAttachment(null);
                        // setTimeout(() => {
                        // 	let urlRoute = routes.spaceUpdates();
                        // 	urlRoute = urlRoute.replace(':space_username', space_username);
                        // 	navigate(urlRoute);
                        // }, 1500);
                    } else {
                        window.scrollTo(0, 0);
                    }
                    setModalOpen(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
        }, [fileAttachment, spaceChannel, attachmentType, dataToEdit, isAttachmentRemoved, imageFiles, audioFile, videoFiles, fileType]
    );

    const handleSubmitEditPost = useCallback(async (values) => {
        setIsSubmitting(true);
        const update = {};
        update.model = 'post';
        update.post_id = dataToEdit.id
        update.channel_id = spaceChannel.id;
        update.title = values.post ? values.post : '';
        update.content = '';
        const attachments = [];
        const attachment = {};
        if (audioFile) {
            if (audioFile.file) {
                attachment.id = "null";
                attachments.push(attachment);
                update.attachments_type = 'audio';
                update.attachments = JSON.stringify(attachments);
                update.file_0 = audioFile.file;
            }
        }
        if (imageFiles.length > 0) {
            imageFiles.map((image, index) => {
                attachment.id = image.id;
                attachments.push(attachment);
                update.attachments_type = 'image';
                update.attachments = JSON.stringify(attachments);
                const key = `file_${index}`; // Create a dynamic key (e.g., file_0, file_1, ...)
                update[key] = image.file; // Assign the file object to the dynamic key
                // if (!isNullExist && image.file == null) {
                //     isNullExist = true;
                // }
                // if (image.file) {
                //     attachment.id = "null";
                //     attachments.push(attachment);
                //     update.attachments_type = 'image';
                //     update.attachments = JSON.stringify(attachments);
                //     const key = `file_${isNullExist ? index + 1 : index}`; // Create a dynamic key (e.g., file_0, file_1, ...)
                //     update[key] = image.file; // Assign the file object to the dynamic key
                // }
            })
        }
        if (videoFiles.length > 0) {
            videoFiles.map((video, index) => {
                if (video.file) {
                    attachment.id = "null";
                    attachments.push(attachment);
                    update.attachments_type = 'video';
                    update.attachments = JSON.stringify(attachments);
                    const key = `file_${index}`; // Create a dynamic key (e.g., file_0, file_1, ...)
                    update[key] = video.file; // Assign the file object to the dynamic key
                }
            })
        }
        if (removeAttachmentFiles.length > 0) {
            update.attachments_remove_ids = JSON.stringify(removeAttachmentFiles);
            update.attachments_remove_type = removedAttachmentType;
        }
        try {
            if (user) {
                update.user_id = user.id;
                update.space_id = spaceData.id;
                const response = await updateSpacePost(update);
                if (response && response.result) {
                    dispatch(setEditPost(response.post));
                    window.scrollTo(0, 0);
                    setIsSubmitting(false);
                    editForm.resetFields();
                    setImageFiles([]);
                    setVideoFiles([]);
                    setAudioFile(null);
                    setRemoveAttachmentFiles([]);
                    setRemovedAttachmentType(null);
                } else {
                    window.scrollTo(0, 0);
                }
                setIsSubmitting(false);
                setModalOpen(false);
            }
        } catch (error) {
            console.log(error);
        }
        },[user, spaceChannel, dataToEdit, isAttachmentRemoved, imageFiles, audioFile, videoFiles, fileType, removeAttachmentFiles, removedAttachmentType]
    );

    const handleSubmitEditComment = useCallback(async (values) => {
            setIsSubmitting(false)
            setSubmitted(true);
            const data = {
                space_id: spaceChannel.id,
                model: 'post',
                model_id: dataToEdit.model_id,
                comment_id: dataToEdit.id,
                comment: values.comment ? values.comment : ''
            };
            if (user) {
                data['user_id'] = user.id;
            }
            const response = await updateSpaceModelComment(data);
            if (response && response.result) {
                dispatch(setEditComment(response.comment));
                editForm.resetFields();
                setIsSubmitting(false);
            }
            setSubmitted(false);
            setModalOpen(false);
        },
        [user, spaceChannel, dataToEdit]
    );

    const handleSubmitEditSubComment = useCallback(async (values) => {
            setIsSubmitting(false)
            setSubmitted(true);
            const data = {
                space_id: spaceChannel.id,
                model: 'post',
                model_id: dataToEdit.model_id,
                comment_id: dataToEdit.id,
                comment: values.comment ? values.comment : ''
            };
            if (user) {
                data['user_id'] = user.id;
            }
            const response = await updateSpaceModelComment(data);
            if (response && response.result) {
                let comment = response.comment;
                if (comment.reply_to_sub_id) {
                    dispatch(setEditSubCommentReply(response.comment))
                } else {
                    dispatch(setEditSubComment(response.comment));
                }

                editForm.resetFields();
                setIsSubmitting(false);
            }
            setSubmitted(false);
            setModalOpen(false);
        },
        [user, spaceChannel, dataToEdit, itemElRef.current]
    );


    useEffect(()=>{
        if (dataToEdit) {
            if (dataToEdit.title) {
                editForm.setFieldValue('post', dataToEdit.title);
            } else if (dataToEdit.comment) {
                editForm.setFieldValue('post', dataToEdit.comment);
            }
            if (dataToEdit.attachments && dataToEdit.attachment_type === 'image' && dataToEdit.attachments.images.length > 0){
                let imageArray = [];
                dataToEdit.attachments.images.map((file)=>{
                    imageArray.push({file: null, uri: file.image_medium, id: file.id});
                    // imageArray.push({file: convertUrlToFile(image.image_large), uri: image.image_medium, id: image.id});
                })
                setImageFiles(imageArray);
                setAudioFile(null);
                setVideoFiles([]);
                setIsBtnPostEnabled(true);
            } else if(dataToEdit.attachments && dataToEdit.attachment_type === 'video' && dataToEdit.attachments.videos.length > 0){
                let videoArray = [];
                dataToEdit.attachments.videos.map((file)=>{
                    videoArray.push({file: null, uri: file.video, id: file.id});
                    // imageArray.push({file: convertUrlToFile(image.image_large), uri: image.image_medium, id: image.id});
                })
                setImageFiles([]);
                setAudioFile(null);
                setVideoFiles(videoArray);
                setIsBtnPostEnabled(true);
            } else if(dataToEdit.attachments && dataToEdit.attachment_type === 'audio' && dataToEdit.attachments.audios.length > 0){
                dataToEdit.attachments.audios.map((file, index)=>{
                    if (index === 0) {
                        setAudioFile({file: null, uri: file.audio, id: file.id});
                    }
                })
                setImageFiles([]);
                setVideoFiles([]);
                setIsBtnPostEnabled(true);
            }
        }
    }, [dataToEdit])

    const handleUploadFileChange = async (file) => {
        setAlert(null);
        const file_extension = getFileExtension(file);
        const objectUrl = URL.createObjectURL(file);

        if (VIDEO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
            const vidValid = await checkVideoValidity(file, alert);
            if (!vidValid) {
                return false;
            }
            // console.log('VIDEO_FILETYPES')
            setFileType(UPLOAD_FILE_TYPE.VIDEO);
            let videoArray = [];
            videoArray = videoArray.concat(videoFiles); // Combine arrays
            videoArray.push({file: file, uri: objectUrl});
            setVideoFiles(videoArray);
            imageFiles.map((file) => {
                removeAttachmentFiles.push(file.id);
                setRemoveAttachmentFiles([...removeAttachmentFiles]);
                setRemovedAttachmentType('image');
            })
            if (audioFile) {
                removeAttachmentFiles.push(audioFile.id);
                setRemovedAttachmentType('audio');
            }
            setAudioFile(null);
            setImageFiles([]);
            setIsBtnPostEnabled(true);
        }
        if (IMAGE_FILETYPES.includes('.' + file_extension.toLowerCase())) {
            const imgValid = await checkImageValidity(file, alert);
            if (!imgValid) {
                return false;
            }
            setFileType(UPLOAD_FILE_TYPE.IMAGE);
            let imageArray = [];
            imageArray = imageArray.concat(imageFiles); // Combine arrays
            imageArray.push({file: file, uri: objectUrl, id: 'null'});
            setImageFiles(imageArray);
            videoFiles.map((file) => {
                removeAttachmentFiles.push(file.id);
                setRemoveAttachmentFiles([...removeAttachmentFiles]);
                setRemovedAttachmentType('video');
            })
            if (audioFile) {
                removeAttachmentFiles.push(audioFile.id);
                setRemovedAttachmentType('audio');
            }
            setVideoFiles([]);
            setAudioFile(null);
            setIsBtnPostEnabled(true);
        }
        if (AUDIO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
            setFileType(UPLOAD_FILE_TYPE.AUDIO);
            setAudioFile({file: file, uri: objectUrl});
            // for removing the old image files
            imageFiles.map((file) => {
                removeAttachmentFiles.push(file.id);
                setRemoveAttachmentFiles([...removeAttachmentFiles]);
                setRemovedAttachmentType('image');
            })
            videoFiles.map((file) => {
                removeAttachmentFiles.push(file.id);
                setRemoveAttachmentFiles([...removeAttachmentFiles]);
                setRemovedAttachmentType('video');
            })
            setImageFiles([]);
            setVideoFiles([]);
            setIsBtnPostEnabled(true);
        }
    };

    useEffect(()=>{
    },[imageFiles, audioFile, videoFiles, removeAttachmentFiles, removedAttachmentType])


    useEffect(() => {
        if (!modalOpen) {
            setIsAttachmentExist(false);
            setIsSubmitting(false);
            setDataToEdit(null);
            setSubmitted(false);
            setCoverImageBlobPreview(null);
            setFileAttachment(null);
            setAttachmentRemoved(false);
            setAttachmentType(null);
            setComment(null);
            setIsAttachmentExist(false);
            setIsAudioUpload(false);
        }
    },[isAttachmentExist, modalOpen])

    useEffect(()=>{
    },[isAttachmentRemoved, isAudioUpload])


    const handlePostKeypress = (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            editForm.submit();
        }
    };

    return (
        <ReactModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={customStyles}
        >
            <Button className="modalClose fa-thin" onClick={() => {
                setDataToEdit(null);
                setAudioFile(null);
                setImageFiles([]);
                setVideoFiles([]);
                setRemoveAttachmentFiles([]);
                setModalOpen(false);
            }}>
                <i className="fa-solid fa-xmark"/>
            </Button>
            { user && (
                <>
                    <div className="grid grid-cols-12 mt-8 items-center gap-x-2">
                        <div className='col-span-2 flex justify-center items-center'>
                            <span className="relative inline-block">
                                {user && user.profile && (
                                    <>
                                        <img className="h-16 w-16 rounded-full object-cover italic"
                                             src={user && user.profile.photo_thumb ? user.profile.photo_thumb : default_user_profile}
                                             alt=""
                                        />
                                        <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>
                                    </>
                                )}
                            </span>
                        </div>
                        <div className="col-span-10 text-2xl gradient-color-txt font-['din2014Bold']">@{user.profile.username ? user.profile.username: user.profile.full_name}</div>
                    </div>
                    <Form className="stack-col w-full"
                          form={editForm}
                          name="editForm"
                          // onFinish={ imageFiles.length > 0 || audioFile ? attachmentSubmit : handleSubmit }
                          onFinish={handleSubmit}
                          validateMessages={VALIDATE_MESSAGES}
                    >
                        <Form.Item
                            name="post"
                            id="post"
                            className="w-full"
                            // validateTrigger="onBlur"
                            // rules={[{ required: true }]}
                        >
                            {/*<div className="form-textarea">*/}
                                <TextArea
                                    className="w-full border-none mt-5"
                                     placeholder={`Message #${spaceChannel.name}`}
                                     rows={3}
                                 />
                            {/*</div>*/}
                        </Form.Item>

                        <div className="cs-stack-attachments stack-row-wrap">
                            {(imageFiles && imageFiles.length > 0) && imageFiles.map((image, index) => (
                                <div key={index} className="form-input-media sm air aspect-cs-preview corner-i" style={ {backgroundImage: 'url(' + image.uri + ')'} }>
                                    <a>
                                        <i className="fa-solid fa-circle-xmark"
                                           onClick={()=>{
                                               imageFiles.splice(index, 1); // Remove the element at the specified index
                                               setImageFiles([...imageFiles]); // Update the state with the modified array
                                               if (image.id) {
                                                   removeAttachmentFiles.push(image.id);
                                                   setRemoveAttachmentFiles([...removeAttachmentFiles]);
                                                   setRemovedAttachmentType('image');
                                               }
                                           }}
                                        />
                                    </a>
                                </div>
                            ))}
                            {(videoFiles && videoFiles.length > 0) && videoFiles.map((file, index) => (
                                <div key={index} className="sm air aspect-video corner-i relative">
                                    <ReactPlayer
                                        playing={true}
                                        loop={true}
                                        controls={true}
                                        muted={true}
                                        width='100%'
                                        height='100%'
                                        className="form-input-media sm air aspect-cs-preview corner-i max-h-element"
                                        url={file.uri}
                                    />
                                    <i className="fa-solid fa-circle-xmark absolute -right-1 -top-1 cursor-pointer"
                                       onClick={()=>{
                                           videoFiles.splice(index, 1); // Remove the element at the specified index
                                           setVideoFiles([...videoFiles]); // Update the state with the modified array
                                           if (file.id) {
                                               removeAttachmentFiles.push(file.id);
                                               setRemoveAttachmentFiles([...removeAttachmentFiles]);
                                               setRemovedAttachmentType('video');
                                           }
                                       }}
                                    />
                                </div>

                            ))}
                        </div>
                        {audioFile &&
                            <div className="inline-flex">
                                <audio controls>
                                    <source src={audioFile.uri} type="audio/mpeg" />
                                </audio>
                                <i className="fa-solid fa-circle-xmark top-0 left-0 cursor-pointer"
                                   onClick={()=>{
                                       setAudioFile(null);
                                       if (audioFile.id) {
                                           removeAttachmentFiles.push(audioFile.id);
                                           setRemoveAttachmentFiles([...removeAttachmentFiles]);
                                           setRemovedAttachmentType('audio');
                                       }
                                   }}
                                />
                            </div>
                        }

                        <div className="stack-col sm:stack-row split">
                            { dataToEdit && !dataToEdit.comment &&
                                <div className="stack-row w-full">
                                    <a className="button-icon-square-gradient-air"
                                        // onClick={() => {
                                        //     setActivationOpen(true);
                                        //     setIsAudioUpload(false);
                                        // }}
                                    >
                                        <span className="sr-only">Attach Image</span>
                                        <i className="fa-light fa-photo-film block items-center">
                                            <Dragger
                                                className="block left-0 top-0 bg-transparent bg-none"
                                                accept={VIDEO_FILETYPES.concat(IMAGE_FILETYPES)}
                                                showUploadList="false"
                                                beforeUpload={handleUploadFileChange}
                                                // customRequest={dummyRequest}
                                            />
                                        </i>
                                    </a>
                                    {/*<a className="button-icon-square-gradient-air">*/}
                                    {/*    <span className="sr-only">Attach Link</span>*/}
                                    {/*    <i className="fa-light fa-link"></i>*/}
                                    {/*</a>*/}
                                    <a className="button-icon-square-gradient-air">
                                        <span className="sr-only">Attach Music</span>
                                        <i className="fa-light fa-music block items-center">
                                            <Dragger
                                                className="block left-0 top-0 bg-transparent bg-none"
                                                accept={AUDIO_FILETYPES}
                                                showUploadList="false"
                                                beforeUpload={handleUploadFileChange}
                                                // customRequest={dummyRequest}
                                            />
                                        </i>
                                    </a>
                                    {/*<a className="button-icon-square-gradient-air">*/}
                                    {/*    <span className="sr-only">Attach Poll</span>*/}
                                    {/*    <i className="fa-light fa-poll-people"/>*/}
                                    {/*</a>*/}
                                </div>
                            }
                            <Form.Item className='w-full'>
                                <button className="primary-gradient w-full"
                                        disabled={isSubmitting || !isBtnPostEnabled}
                                        type="submit"
                                >
                                    <span><p>Post</p></span>
                                </button>
                            </Form.Item>
                            {/*<button type="submit" className="primary-gradient w-full"><span><p>Post</p></span></button>*/}
                        </div>


                    </Form>
                </>
            )}
        </ReactModal>
    );
}

export default memo(ChannelPostForm);