import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSpaceInformation } from '../../../services/space';
import parse from 'html-react-parser';
import SpaceContact from '../CommonLayouts/SpaceContact/SpaceContact';
import { Button } from 'antd';
import routes from '../../../routes';
import { useLocation } from 'react-router-dom';

const SpaceInformationPages = (props) => {
	const { hash } = useLocation();
	const { spaceData, isPro, activeTab } = props;
	const { authUserInfo, loading } = useSelector((state) => state.general);
	// const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [spaceInformation, setSpaceInformation] = useState();
	const [spaceFAQ, setSpaceFAQ] = useState();
	const [isInformationLoading, setInformationLoading] = useState(true);
	const [showFAQ, setShowFAQ] = useState(true);

	const fetchSpaceInformation = useCallback(
		async (activeTab) => {
			try {
				if (spaceData.id) {
					let page_title = 'About';
					if (activeTab === 'support') {
						page_title = 'Get Support';
					} else if (activeTab === 'faq') {
						page_title = 'Frequently Asked Questions';
					} else if (activeTab === 'terms-services') {
						page_title = 'Terms of Service';
					} else if (activeTab === 'privacy-policy') {
						page_title = 'Privacy Policy';
					}

					// console.log(activeTab);

					const slug = (page_title + spaceData.id).toLowerCase().replace(/\s/g, '');
					const data = {
						space_id: spaceData.id,
						page_slug: slug
					};
					// console.log(data);
					const response = await getSpaceInformation(data);
					if (response && response.result) {
						// console.log('Ernz', response);

						if (response.space_information) {
							setSpaceInformation(response.space_information);
							if (activeTab === 'support' || activeTab === 'faq') {
								await fetchFAQ();
							}
						}
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo]
	);

	const redirectToPages = (url) => {
		if (url.includes('http')) {
			window.location.href = url;
		} else {
			window.location.href = routes.viewUserSpace() + spaceData?.username + url;
		}
	};

	const redirectToSection = (anchor) => {
		setTimeout(function () {
			const element = document.getElementById(anchor.replace('#', ''));
			element?.scrollIntoView({ behavior: 'smooth' });
		}, 800);
	};

	const fetchFAQ = useCallback(async () => {
		try {
			if (spaceData.id) {
				const slug = ('Frequently Asked Questions' + spaceData.id)
					.toLowerCase()
					.replace(/\s/g, '');
				const data = {
					space_id: spaceData.id,
					page_slug: slug
				};
				// console.log(data);
				const response = await getSpaceInformation(data);
				if (response && response.result) {
					//console.log(response);

					if (response.space_information) {
						setSpaceFAQ(response.space_information);
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo]);

	useEffect(() => {
		// console.log('agi');
		if (isInformationLoading) {
			// console.log('agi2');
			fetchSpaceInformation(activeTab);
			setInformationLoading(false);
		}
		redirectToSection(hash);
	}, [hash, isInformationLoading, spaceInformation, spaceFAQ]);

	return (
		<>
			{activeTab === 'terms-services' && (
				<section id="cs-space-terms-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<article className="cs-terms-article block-full order-1">
							<div className="cs-stack-title-icons stack-row-wrap">
								<h2><span>Terms</span> of Service</h2>
								<div
									className="cs-stack-link-icons stack-row">
									<a href="#xtwitter" target="_blank"
									   className="button-icon-round-gradient"><span><p><i
										className="fa-brands fa-x-twitter"></i></p></span></a>
									<a href="#instagram" target="_blank"
									   className="button-icon-round-gradient"><span><p><i
										className="fa-brands fa-instagram"></i></p></span></a>
									<a href="#facebook" target="_blank"
									   className="button-icon-round-gradient"><span><p><i
										className="fa-brands fa-facebook-f"></i></p></span></a>
									<a href="#linkedin" target="_blank"
									   className="button-icon-round-gradient"><span><p><i
										className="fa-brands fa-linkedin-in"></i></p></span></a>
								</div>
							</div>

							{spaceInformation && spaceInformation.page_content && (
								<div className="cs-terms-content-body card stack-col">
									<p>{parse(spaceInformation.page_content)}</p>
								</div>
							)}

						</article>
						<div id="cs-space-support-links" className="grid-base col-span-full order-2">
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/faq')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-comments-question-check"></i></p>
										<h4>FAQ</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/contact')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-envelope"></i></p>
										<h4>Contact</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/support')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-life-ring"></i></p>
										<h4>Support</h4>
									</hgroup>
								</a>
							</article>
						</div>
					</div>
				</section>
			)}

			{activeTab === 'privacy-policy' && (
				<section
					id="cs-space-privacy-c1"
					className="p-section-safeview theme-transparent"
				>
					<div className="centerframe-grid gap-2gutter">
						<article className="cs-privacy-article block-full order-1">
							<div className="cs-stack-title-icons stack-row-wrap">
								<h2>
									<span>Privacy</span> Policy
								</h2>
								<div className="cs-stack-link-icons stack-row">
									<a
										href="#xtwitter"
										target="_blank"
										className="button-icon-round-gradient"
									>
											<span>
												<p>
													<i className="fa-brands fa-x-twitter"></i>
												</p>
											</span>
									</a>
									<a
										href="#instagram"
										target="_blank"
										className="button-icon-round-gradient"
									>
											<span>
												<p>
													<i className="fa-brands fa-instagram"></i>
												</p>
											</span>
									</a>
									<a
										href="#facebook"
										target="_blank"
										className="button-icon-round-gradient"
									>
											<span>
												<p>
													<i className="fa-brands fa-facebook-f"></i>
												</p>
											</span>
									</a>
									<a
										href="#linkedin"
										target="_blank"
										className="button-icon-round-gradient"
									>
											<span>
												<p>
													<i className="fa-brands fa-linkedin-in"></i>
												</p>
											</span>
									</a>
								</div>
							</div>

							{spaceInformation && spaceInformation.page_content && (
								<div className="cs-privacy-content-body card stack-col">
									<p>{parse(spaceInformation.page_content)}</p>
								</div>
							)}
						</article>
						<div
							id="cs-space-support-links"
							className="grid-base col-span-full order-2"
						>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/faq')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p>
											<i className="fa-solid fa-comments-question-check"></i>
										</p>
										<h4>FAQ</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/contact')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p>
											<i className="fa-solid fa-envelope"></i>
										</p>
										<h4>Contact</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/support')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p>
											<i className="fa-solid fa-life-ring"></i>
										</p>
										<h4>Support</h4>
									</hgroup>
								</a>
							</article>
						</div>
					</div>
				</section>
			)}
			{activeTab === 'faq' && (
				<section id="cs-space-faq-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<article className="cs-faq-article block-full order-1">
							<div className="cs-stack-title-icons stack-row-wrap">
								<h2>
									<span>{spaceData.name}</span> FAQ
								</h2>
								<div className="cs-stack-link-icons stack-row">
									<a
										href="#xtwitter"
										target="_blank"
										className="button-icon-round-gradient"
									>
											<span>
												<p>
													<i className="fa-brands fa-x-twitter"></i>
												</p>
											</span>
									</a>
									<a
										href="#instagram"
										target="_blank"
										className="button-icon-round-gradient"
									>
											<span>
												<p>
													<i className="fa-brands fa-instagram"></i>
												</p>
											</span>
									</a>
									<a
										href="#facebook"
										target="_blank"
										className="button-icon-round-gradient"
									>
											<span>
												<p>
													<i className="fa-brands fa-facebook-f"></i>
												</p>
											</span>
									</a>
									<a
										href="#linkedin"
										target="_blank"
										className="button-icon-round-gradient"
									>
											<span>
												<p>
													<i className="fa-brands fa-linkedin-in"></i>
												</p>
											</span>
									</a>
								</div>
							</div>

							{spaceFAQ && spaceFAQ.page_content && (
								<div className="cs-faq-content-body card stack-col">
									<p>{parse(spaceFAQ.page_content)}</p>
								</div>
							)}
						</article>
						<div
							id="cs-space-support-links"
							className="grid-base col-span-full order-2"
						>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/community-guidelines')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p>
											<i className="fa-solid fa-users"></i>
										</p>
										<h4>Community Guidelines</h4>
									</hgroup>
								</a>
							</article>

							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/contact')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p>
											<i className="fa-solid fa-envelope"></i>
										</p>
										<h4>Contact</h4>
									</hgroup>
								</a>
							</article>

							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/support')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p>
											<i className="fa-solid fa-life-ring"></i>
										</p>
										<h4>Support</h4>
									</hgroup>
								</a>
							</article>
						</div>
					</div>
				</section>
			)}
			{activeTab === 'about' && (
				<section id="cs-space-about-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<article className="cs-about-article block-full order-1">
							<div className="cs-stack-title-icons stack-row-wrap">
								<h2><span>About</span>{' ' + spaceData.name}</h2>
								<div className="cs-stack-link-icons stack-row">
									<a href="#xtwitter" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>
									<a href="#instagram" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>
									<a href="#facebook" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>
									<a href="#linkedin" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-linkedin-in"></i></p></span></a>
								</div>
							</div>

							{(spaceData && spaceData.about && activeTab === 'about') && (
								<div className="cs-about-content-body card stack-col">
									<p>{parse(spaceData.about)}</p>
								</div>
							)}
						</article>
						<div id="cs-space-support-links" className="grid-base col-span-full order-2">
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/faq')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-comments-question-check"></i></p>
										<h4>FAQ</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/contact')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-envelope"></i></p>
										<h4>Contact</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/support')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-life-ring"></i></p>
										<h4>Support</h4>
									</hgroup>
								</a>
							</article>
						</div>
					</div>
				</section>
			)}
			{activeTab === 'contact' && (
				<section id="cs-space-contact-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<article className="cs-contact-form block-full order-1">
							<div className="cs-stack-title-icons stack-row-wrap">
								<h2><span>Contact</span>{' ' + spaceData.name}</h2>
								<div className="cs-stack-link-icons stack-row">
									<a href="#xtwitter" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>
									<a href="#instagram" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>
									<a href="#facebook" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>
									<a href="#linkedin" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-linkedin-in"></i></p></span></a>
								</div>
							</div>

							<div className="cs-stack-contact-form stack-col">
								<div className="stack-row">
									<div className="form-input air">
										<label htmlFor="name">Name</label>
										<input type="text" name="name" id="name" autoComplete="name" required/>
									</div>
									<div className="form-input air">
										<label htmlFor="email">Email</label>
										<input type="email" name="email" id="email" autoComplete="email" required/>
									</div>
								</div>
								<div className="form-input air">
									<label htmlFor="subject">Subject</label>
									<input type="text" name="subject" id="subject" autoComplete="subject"/>
								</div>
								<div className="form-textarea air">
									<label htmlFor="message">Message</label>
									<textarea id="message" name="message" rows="3"></textarea>
								</div>
							</div>
						</article>
						<div id="cs-space-support-links" className="grid-base col-span-full order-2">
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/faq')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-comments-question-check"></i></p>
										<h4>FAQ</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/community-guidelines')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-users"></i></p>
										<h4>Community Guidelines</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/support')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-life-ring"></i></p>
										<h4>Support</h4>
									</hgroup>
								</a>
							</article>
						</div>
					</div>
				</section>
			)}
			{activeTab === 'community-guidelines' && (
				<section id="cs-space-guidelines-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<article className="cs-support-article block-full order-1">
							<div className="cs-stack-title-icons stack-row-wrap">
								<h2><span>{spaceData?.name}</span> Community Guidelines</h2>
								<div className="cs-stack-link-icons stack-row">
									<a href="#xtwitter" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>
									<a href="#instagram" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>
									<a href="#facebook" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>
									<a href="#linkedin" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-linkedin-in"></i></p></span></a>
								</div>
							</div>

							<div className="cs-support-content-body card stack-col">
								<p>RTE CONTENT GOES HERE.</p>
							</div>

						</article>
						<div id="cs-space-support-links" className="grid-base col-span-full order-2">
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/faq')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-comments-question-check"></i></p>
										<h4>FAQ</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/contact')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-envelope"></i></p>
										<h4>Contact</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/support')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-life-ring"></i></p>
										<h4>Support</h4>
									</hgroup>
								</a>
							</article>
						</div>
					</div>
				</section>
			)}

			{activeTab === 'support' && (
				<section id="cs-space-guidelines-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<article className="cs-support-article block-full order-1">
							<div className="cs-stack-title-icons stack-row-wrap">
								<h2><span>{spaceData.name}</span> Support
								</h2>
								<div
									className="cs-stack-link-icons stack-row">
									<a href="#xtwitter" target="_blank"
									   className="button-icon-round-gradient"><span><p><i
										className="fa-brands fa-x-twitter"></i></p></span></a>
									<a href="#instagram" target="_blank"
									   className="button-icon-round-gradient"><span><p><i
										className="fa-brands fa-instagram"></i></p></span></a>
									<a href="#facebook" target="_blank"
									   className="button-icon-round-gradient"><span><p><i
										className="fa-brands fa-facebook-f"></i></p></span></a>
									<a href="#linkedin" target="_blank"
									   className="button-icon-round-gradient"><span><p><i
										className="fa-brands fa-linkedin-in"></i></p></span></a>
								</div>
							</div>

							{spaceInformation && spaceInformation.page_content && (
								<div className="cs-support-content-body card stack-col">
									<p>{parse(spaceInformation.page_content)}</p>
								</div>
							)}

						</article>
						<div id="cs-space-support-links" className="grid-base col-span-full order-2">
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/faq')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-comments-question-check"></i></p>
										<h4>FAQ</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/contact')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-envelope"></i></p>
										<h4>Contact</h4>
									</hgroup>
								</a>
							</article>
							<article
								className="cs-featured-support block-md card"
								onClick={(event) => redirectToPages('/community-guidelines')}
							>
								<a className="rounded-card p-card">
									<hgroup>
										<p><i className="fa-solid fa-users"></i></p>
										<h4>Community Guidelines</h4>
									</hgroup>
								</a>
							</article>
						</div>
					</div>
				</section>
			)}
		</>
	);
};
export default memo(SpaceInformationPages);
