import { memo } from 'react';
import { Popover } from 'antd';
import { HexColorPicker, HexColorInput } from 'react-colorful';

const ColorPickerAndInput = (parentProps) => {
	const { color, onColorChange, className, label } = parentProps;

	const handleColorChange = (item) => {
		onColorChange(item);
	};

	const colorPicker = <HexColorPicker color={color} onChange={handleColorChange} />;

	return (
		// <div className="cs-form-color-picker">
        //     <div className="form-input air">
        //         <label htmlFor="text">Key Color</label>
        //         <HexColorInput
        //             color={color}
        //             onChange={handleColorChange}
        //         />
        //         <Popover className="cs-color-picker-block" placement="bottom" content={colorPicker} trigger="click">
        //             <button className="cs-color-picker-swatch" style={{ backgroundColor: color }}></button>
        //         </Popover>
		// 	</div>
		// </div>
        <div className="cs-form-color-picker z-10">
            <div className="form-input air">
                <label htmlFor="text">{label}</label>
                <input id="text" name="text" onChange={handleColorChange} value={color} type="text" required placeholder="#FB03B9"/>
            </div>
            <a className="cs-color-picker-block">
                {/* <div className="cs-color-picker-swatch" style={{ backgroundColor: color }}></div> */}
                <Popover className="cs-color-picker-swatch" placement="bottom" content={colorPicker} trigger="click">
                     <div  style={{ backgroundColor: color }}></div>
                </Popover>
            </a>
        </div>
	);
};

export default memo(ColorPickerAndInput);
